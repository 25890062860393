import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface QRCodeModalProps {
    qrCodeModal: boolean
    hideQrCodeModal: () => void
    srcData: string | undefined
}
const QRCodeModal: FC<QRCodeModalProps> = ({
    qrCodeModal,
    hideQrCodeModal,
    srcData
}) => {
    const { t } = useTranslation("common")
    return (
        <Modal centered show={qrCodeModal} onHide={hideQrCodeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t("qrCodeModal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!!srcData &&
                    <div className='d-flex justify-content-center mb-3'>
                        <img src={srcData} alt='' width={400} className='img-fluid' />
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default QRCodeModal