/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import eventBus from '../../common/EventBus';
import { logoutHandler } from '../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { currentMeetingHandler } from '../store/slices/meetingSlice';

const Logout = () => {
   //single hooks starts here
    const dispatch = useDispatch()
    let navigate = useNavigate();
   //single hooks ends here


   //functions starts here
    const logOut = () => {
        dispatch(currentMeetingHandler(null))
        dispatch(logoutHandler())
        navigate("/");
    };
   //functions ends here


   //useEffects starts here
    useEffect(() => {
        logOut()
        eventBus.on("logout", logOut);
        return () => {
            eventBus.remove("logout", logOut);
        };
    }, []);
   //useEffects ends here

    return <></>
}

export default Logout;