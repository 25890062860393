/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, useParams } from "react-router-dom";
import { IQuizForm } from "../../types/quiz.type";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { quizCreateHandler, quizGetByIdHandler, quizUpdateByIdHandler } from '../store/slices/quizSlice'
import { selectedQuestionHandler } from "../store/slices/pollSlice";
import * as ACTION from "../store/actions/index";
import QuestionContainer from "../components/ManageQuestions/QuestionContainer";
import { DropArea } from "../components/DragAndDrop/DropArea";
import SelectedQuestionList from "../components/ManageQuestions/SelectedQuestionList";
import { millisecondToHMS, minuteToMillisecond, mstoMinSec, secondToMillisecond } from "../../common/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomDragLayer } from "../components/DragAndDrop/CustomDragLayer";

const QuizDetails = () => {

  //single hooks starts here
  const dispatch = useDispatch()
  let navigate = useNavigate();
  const { t } = useTranslation("common");
  let { quizId } = useParams();
  //single hooks ends here


  //custom variables starts here
  const createMode = !!quizId && quizId === "0" ? true : false
  //custom variables ends here


  //useSelectors starts here
  const { quizMessage, quizSuccessful } = useSelector((state: RootState) => state.quiz)
  const { selectedQuestion } = useSelector((state: RootState) => state.poll)
  const { questionmessage } = useSelector((state: RootState) => state.question)
  //useSelectors ends here


  //useRef starts here
  const questionContainerRef = useRef<HTMLDivElement | null>(null)
  //useRef ends here

  //useStates starts here
  const [quizMeetings, setQuizMeetings] = useState<any[]>([]);
  const [isQuestionTimer, setIsQuestionTimer] = useState<boolean>(false)
  //useStates ends here


  //formik starts here
  const initialValues: IQuizForm = {
    title: "",
    timerMinutes: 0,
    timerSeconds: 0
  };

  const [quizData, setQuizData] = useState(initialValues);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(t("validation.requiredField"))
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          return originalValue.trim();
        }
        return value;
      })
      .matches(/^[a-zA-Z0-9]/, t("validation.specialcharacterQuizTitle"))
      .strict(true)
      .test(
        "len",
        t('validation.minlengthTitle'),
        (val: any) =>
          val &&
          val.toString().length >= 3
      )
      .test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val &&
          val.trim() !== ''
      ),
    timerMinutes: Yup.number()
      .typeError('Please enter a valid number')
      .integer('Decimal is not allowed')
      .required('This field is required')
      .min(0, 'Minutes  should be greater than or equal to 0')
      .test('not-both-zero', 'Both values cannot be zero', function (value) {
        const { timerSeconds } = this.parent as any;
        return value !== 0 || timerSeconds !== 0;
      }),
    timerSeconds: Yup.number()
      .typeError('Please enter a valid number')
      .integer('Decimal is not allowed')
      .required('This field is required')
      .min(0, 'Seconds should be greater than or equal to 0')
      .max(59, 'seconds must be less than 60')
      .test('not-both-zero', 'Both values cannot be zero', function (value) {
        const { timerMinutes } = this.parent as any;
        return value !== 0 || timerMinutes !== 0;
      }),
  })
  //formik ends here


  //functions starts here
  const handleQuizCreate = async (formValue: IQuizForm) => {
    const { timerMinutes, timerSeconds } = formValue

    let minutesInMS = minuteToMillisecond(timerMinutes)
    let secondsInMS = secondToMillisecond(timerSeconds)
    let totalMS = minutesInMS + secondsInMS

    let data = {
      ID: quizId,
      title: formValue.title.trim(),
      maxTimeToFinish: isQuestionTimer ? totalMS * selectedQuestion.length : totalMS,
      maxTimeToFinishPage: isQuestionTimer ? totalMS : null,
      selectedQuestion,
    };

    if (!createMode) {
      await dispatch(quizUpdateByIdHandler(data)).then((res: any) => {
        if (res?.type === `${ACTION.QUIZUPDATE}/${ACTION.FULFILLED}`) {
          toast.success(String(t("toastMessage.quizUpdate")), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
          navigate("/quiz");
        }
        else if (res.type === `${ACTION.QUIZUPDATE}/${ACTION.REJECTED}`) {
          if (res?.payload?.response?.data?.statusCode === 403) {
            toast.error(String(t("toastMessage.quizAlredyExist")), {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2500,
            });
          } else {
            toast.error(String(t("toastMessage.quizQuestionError")), {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2500,
            });
          }
        }
      })
    } else {
      await dispatch(quizCreateHandler(data)).then((res: any) => {
        if (res?.type === `${ACTION.CREATEQUIZ}/${ACTION.FULFILLED}`) {
          toast.success(String(t("toastMessage.quizCreate")), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
          navigate("/quiz");
        } else {
          toast.error(quizMessage)
        }
      })
    }
  };

  const getQuizDetailByQuizCode = async (quizId: string) => {
    let quizDetails = await dispatch(quizGetByIdHandler(quizId))
    if (quizDetails?.payload?.status === 200) {
      let data = quizDetails?.payload?.data
      setQuizMeetings(data?.quizMeetingDetails)
      let maxTimeToFinishValue = Number(data.maxTimeToFinish)
      let maxTimeToFinishPageValue = Number(data.maxTimeToFinishPage)

      setIsQuestionTimer(!!maxTimeToFinishPageValue)

      let msValue = !!maxTimeToFinishPageValue ? maxTimeToFinishPageValue : maxTimeToFinishValue
      const { minutes, seconds } = mstoMinSec(msValue)

      let initialValues: IQuizForm = {
        title: data.title,
        timerMinutes: minutes,
        timerSeconds: seconds
      };

      setQuizData(initialValues);

      let questionData: any = [];
      data.questions.forEach((questionValue: any) => {
        questionData.push(questionValue.question);
      });
      dispatch(selectedQuestionHandler(questionData))
    }
  };
  //functions ends here


  //useEffects starts here
  useEffect(() => {
    if (!!quizId) {
      if (createMode) {
        dispatch(selectedQuestionHandler([]))
      } else {
        getQuizDetailByQuizCode(quizId);
      }
    }
  }, []);
  //useEffects ends here


  // custom components starts here
  const TooltipTimer = () => {
    return (
      <ul className="text-start vstack gap-3">
        <li>
          <div>Quiz Time</div>
          <div>Use this to set the total quiz time.</div>
        </li>
        <li>
          <div>Question Time</div>
          <div>Use this to set the total quiz time as sum of all question times.</div>
        </li>
      </ul>
    )
  }

  interface TotalQuizTimeProps {
    timerMinutes: number
    timerSeconds: number
  }
  const TotalQuizTime: FC<TotalQuizTimeProps> = useCallback(({ timerMinutes, timerSeconds }) => {
    let minutesInMS = minuteToMillisecond(timerMinutes)
    let secondsInMS = secondToMillisecond(timerSeconds)
    let totalMS = minutesInMS + secondsInMS
    let timerTotalMS = isQuestionTimer ? (totalMS * (selectedQuestion.length === 0 ? 1 : selectedQuestion.length)) : totalMS
    let hms = millisecondToHMS(timerTotalMS)
    return (
      <div className="form-control bg-light-gray shadow-in hstack justify-content-between gap-3 ps-3 pe-sm-4">
        <div>{hms}</div>
        <div>hh : mm : ss</div>
      </div>
    )
  }, [selectedQuestion.length, isQuestionTimer])
  // custom components ends here

  return (
    <>
      <div className="container-fluid">
        <h2 className="font-26 fw-semibold text-decoration-none text-dark mb-4">{createMode ? t("quiz.btnCreateQuiz") : t("quizDetails.title")}</h2>
        <div className="row g-4">
          <div className="col-md-7">
            <div className="card border-0 h-100 shadow-sm">
              <Formik
                initialValues={quizData}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleQuizCreate}>
                {(formik) => (
                  <Form>
                    <div className="card-body">
                      <div className="d-flex flex-wrap justify-content-end gap-2 mb-4">
                        <div className="hstack gap-2">
                          <Link
                            to="/quiz"
                            className="btn btn-outline-danger mw-120">
                            {t("common.cancel")}
                          </Link>
                          <button
                            className="btn btn-primary mw-120"
                            type="submit">
                            {createMode
                              ? t("quizDetails.btnSave")
                              : t("common.update")}
                          </button>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="quiz-title" className="form-label">
                          {t("quizDetails.quizTitle")}
                        </label>
                        <Field
                          type="text"
                          name="title"
                          className="form-control"
                          id="quiz-title"
                          placeholder={t(
                            "quizDetails.placeholderQuizTitle"
                          )} />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger"
                        />
                        {questionmessage && (
                          <div className="form-group">
                            <div
                              className={
                                quizSuccessful
                                  ? "alert alert-success"
                                  : "alert alert-danger p-2 rounded-0 border-0"
                              }
                              role="alert">
                              {questionmessage}
                            </div>
                          </div>
                        )}
                      </div>
                      {!createMode && !!quizMeetings && quizMeetings.length > 0 &&
                        <div className="mb-3">
                          <label className="form-label fw-medium font-20">
                            {t("quizDetails.attachedTitle")}
                          </label>
                          <div className="d-flex flex-wrap align-items-center gap-2">
                            {!!quizMeetings &&
                              quizMeetings.length > 0 &&
                              quizMeetings.map((meetingData: any) => {
                                return (
                                  <div
                                    className="badge bg-primary-light text-primary rounded-pill badge-pill py-2 fw-medium"
                                    key={"meeting_" + meetingData.meetingId}>
                                    {meetingData?.meetingDetails?.code}
                                  </div>
                                );
                              })}
                          </div>
                        </div>}
                      <div className="mb-3">
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip id="timer-tooltip">
                            <TooltipTimer />
                          </Tooltip>}
                        >
                          {({ ref, ...triggerHandler }) => (
                            <>
                              <label
                                {...triggerHandler}
                                className="form-label fw-medium font-20 d-inline-flex gap-2" ref={ref}
                              >
                                <span>Timer</span>
                                <span className="text-muted" >
                                  <i className="fa-solid fa-circle-info fa-sm"></i>
                                </span>
                              </label>
                            </>
                          )}
                        </OverlayTrigger>
                        <div className="hstack gap-2 mb-3">
                          <button
                            className={`btn ${isQuestionTimer ? 'btn-primary-light' : 'btn-primary'}`}
                            onClick={() => setIsQuestionTimer(false)}
                            type="button"
                          > Quiz Time
                          </button>

                          <button
                            className={`btn ${isQuestionTimer ? 'btn-primary' : 'btn-primary-light'}`}
                            onClick={() => setIsQuestionTimer(true)}
                            type="button"
                          > Question Time
                          </button>
                        </div>

                        <div className="d-flex gap-3 mb-3">
                          <div className="flex-fill">
                            <div className="position-relative">
                              <Field
                                type="text"
                                name="timerMinutes"
                                className="form-control"
                                id="timerMinutesId"
                              />
                              <div className="position-absolute translate-middle-y top-50 end-0 text-muted me-sm-3 pe-1 pe-sm-2">minutes</div>
                            </div>
                            <ErrorMessage
                              name="timerMinutes"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="flex-fill">
                            <div className="position-relative">
                              <Field
                                type="text"
                                name="timerSeconds"
                                className="form-control"
                                id="timerSecondsId"
                              />
                              <div className="position-absolute translate-middle-y top-50 end-0 text-muted me-sm-3 pe-1 pe-sm-2">seconds</div>
                            </div>
                            <ErrorMessage
                              name="timerSeconds"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <TotalQuizTime timerMinutes={formik.values.timerMinutes} timerSeconds={formik.values.timerSeconds} />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>

              <DropArea />

              <SelectedQuestionList meetingsLength={quizMeetings.length} />

            </div>
          </div>
          <div className="col-md-5" ref={questionContainerRef}>
            <QuestionContainer />
          </div>
          <CustomDragLayer questionContainerRef={questionContainerRef} />
        </div>
      </div>
    </>
  );
};

export default QuizDetails;
