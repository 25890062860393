import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EN } from '../../../types/commonTypes'

//setting initial states
export interface ISettings {
    language: string
    defaultFontSize: number
    fontSize: number
    minFontSize: number
    maxFontSize: number
    isMiniSidebar: boolean
    profileButtonWidth: number | undefined
    isFeedBackForm: boolean
    tourEnable: boolean
    stepIndex: number
    isHeaderShow: boolean
    isWaitingUser: boolean
}

const initialState: ISettings = {
    language: EN,
    defaultFontSize: 16,
    fontSize: 16,
    minFontSize: 12,
    maxFontSize: 20,
    isMiniSidebar: false,
    profileButtonWidth: undefined,
    isFeedBackForm: false,
    tourEnable: false,
    stepIndex: 0,
    isHeaderShow: true,
    isWaitingUser: false
}

//setting functions
export const setProperty = (property: string, value: string) => {
    document.documentElement.style.setProperty(property, value, 'important');
}

//settingSlice
export const settingsSlice = createSlice({
    name: 'settingsSlice',
    initialState,
    reducers: {
        languageHandler: (state, { payload }: PayloadAction<string>) => {
            state.language = payload;
        },
        fontSizeHandler: (state, { payload }: PayloadAction<number>) => {
            state.fontSize = payload;
            setProperty('--font-size', `${payload}px`);
        },
        miniSidebarHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isMiniSidebar = payload;
        },
        profileButtonHandler: (state, { payload }: PayloadAction<number | undefined>) => {
            state.profileButtonWidth = payload;
        },
        feedbackFormHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isFeedBackForm = payload;
        },
        tourEnableHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.tourEnable = payload;
        },
        stepIndexHandler: (state, { payload }: PayloadAction<number>) => {
            state.stepIndex = payload;
        },
        innerHeaderShowHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isHeaderShow = payload;
        },
        isWaitingUserHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isWaitingUser = payload;
        }
    }
})

export const { languageHandler, fontSizeHandler, miniSidebarHandler, profileButtonHandler, feedbackFormHandler, tourEnableHandler, stepIndexHandler, innerHeaderShowHandler, isWaitingUserHandler } = settingsSlice.actions;
export default settingsSlice.reducer; 