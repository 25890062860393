import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import NearbyMeetings from '../NearbyMeetings'
import { useNavigate } from 'react-router-dom'

interface NearbyMeetingsModalProps {
    nearbyMeetingsModal: boolean
    hideNearbyMeetingsModal: () => void
    nearByMeetings: any
}
const NearbyMeetingsModal: FC<NearbyMeetingsModalProps> = ({
    nearbyMeetingsModal,
    hideNearbyMeetingsModal,
    nearByMeetings
}) => {
    //single hooks starts here
    const { t } = useTranslation("common")
    let navigate = useNavigate()
    //single hooks ends here


    //functions starts here
    const nearMeetingJoin = (nearMeetingCode: string) => {
        hideNearbyMeetingsModal()
        navigate(`/guest/login/${nearMeetingCode}`)
    }
    //functions ends here
    return (
        <Modal show={nearbyMeetingsModal} centered onHide={hideNearbyMeetingsModal}>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='fw-semibold'>
                    {t('homePage.locationModel.lblSelectMeeting')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NearbyMeetings nearByMeetings={nearByMeetings} nearMeetingJoin={nearMeetingJoin} />
            </Modal.Body>
        </Modal>
    )
}

export default NearbyMeetingsModal