/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getMeetingNearbyMe, meetingCreate, meetingData, meetingGetByCodeForCheckMeetingPassword, meetingStatusUpdate, setMeetingSession } from '../../../services/meeting.service';
import { t } from 'i18next'
import { IMeeting } from '../../../types/meeting.type';
import { get } from 'lodash';
import { checkUserAccess } from '../../../services/guestUser.service';
import { attendeeWaitingList } from '../../../services/waitingList.service';
import { INPROGRESS } from '../../../types/commonTypes';
/* eslint-disable @typescript-eslint/no-unused-expressions */

//meetings initial states
export interface MeetingState {
    nearMeetingList: any,
    LocationdisbleMessage: string,
    currentMeeting: IMeeting | null
    brandErr: boolean
    sponsorErr: boolean
    createdMeeting: any
    codeExistMessage: string
    successful: boolean
    randomCodes: any[]
    passwordProtectedJoin: boolean
    joinMessage: string
    joinSuccessful: boolean
    joinStatus: boolean
    isCurrentMeeting: boolean
    isAllowed: boolean
    waitingList: any[]
    interestedList: any[]
    activeTab: number
    activeQuizIdGuest: number
    activeQuizIdAttendee: number
    updateMeetingId: number
}

const initialState: MeetingState = {
    nearMeetingList: [],
    LocationdisbleMessage: "",
    currentMeeting: <IMeeting>{},
    brandErr: false,
    sponsorErr: false,
    createdMeeting: [],
    codeExistMessage: '',
    successful: false,
    randomCodes: [],
    passwordProtectedJoin: false,
    joinMessage: '',
    joinSuccessful: false,
    joinStatus: true,
    isCurrentMeeting: false,
    isAllowed: false,
    waitingList: [],
    interestedList: [],
    activeTab: 0,
    activeQuizIdGuest: 0,
    activeQuizIdAttendee: 0,
    updateMeetingId: 0
}
export interface INearByMeeting {
    latitude: number,
    longitude: number
}
export interface ICreateMeeting {
    newTitle: string,
    newCode: string,
    meetingPassword: string,
    startTime: Date,
    endTime: Date,
    emailProtected: boolean,
    passwordProtected: boolean,
    geoFenceProtected: boolean,
    newLongitude: number, newLatitude: number, brandFiles: any[], sponsorsFiles: any[]
}
export interface IMeetingCheckHandle {
    code: string,
    loggedInUser: any
}
export interface ICheckUserAccess {
    meetingCodeHome: any,
    joinPassword: any,
    newLongitudeJoin: any,
    newLatitudeJoin: any,
    loggedInUser: any
}
export interface ISetMeetingSession {
    meetingCodeHome: any,
    userId: number,
    guestUserId: number
}
export interface IAttendeeWaitingList {
    meetingId: number,
    hostId: number,
    userId: number,
    guestUserId: number
}
export interface IMeetingStatusUpdate {
    id: number,
    status: any
}

export interface IMeetingData {
    userId: number,
    pageLimit: number,
    perPage: number,
    filterData: any,
    searchVal: string,
    sortColumn: any,
    sortType: any
}


//meetings api thunks
export const meetingHandler: any = createAsyncThunk('nearByMeeting', (data: INearByMeeting) =>
    getMeetingNearbyMe(data?.latitude, data?.longitude)
);
export const meetingCreateHandler: any = createAsyncThunk('createMeeting', (data: ICreateMeeting, { rejectWithValue }) =>
    meetingCreate(data?.newTitle, data?.newCode, data?.meetingPassword, data?.startTime, data?.endTime, data?.emailProtected, data?.passwordProtected, data?.geoFenceProtected, data?.newLongitude, data?.newLatitude, data?.brandFiles, data?.sponsorsFiles)
        .catch((error) => error && rejectWithValue(error))
);
export const meetingGetByCodeCheckingHandler: any = createAsyncThunk('checkingCode', (data: IMeetingCheckHandle, { rejectWithValue }) =>
    meetingGetByCodeForCheckMeetingPassword(data.code, data.loggedInUser)
        .catch((error) => error && rejectWithValue(error))
);
export const checkUserAccessHandler: any = createAsyncThunk('checkUserAccess', (data: ICheckUserAccess, { rejectWithValue }) =>
    checkUserAccess(data?.meetingCodeHome, data?.joinPassword, data?.newLongitudeJoin, data?.newLatitudeJoin, data?.loggedInUser)
        .catch((error) => error && rejectWithValue(error))
);
export const setMeetingSessionHandler: any = createAsyncThunk('setMeetingSession', (data: ISetMeetingSession, { rejectWithValue }) =>
    setMeetingSession(data?.meetingCodeHome, data?.userId, data?.guestUserId)
        .catch((error) => error && rejectWithValue(error))
);
export const attendeeWaitingListHandler: any = createAsyncThunk('attendeeWaitingList', (data: IAttendeeWaitingList, { rejectWithValue }) =>
    attendeeWaitingList(data?.meetingId, data?.hostId, data?.userId, data?.guestUserId)
        .catch((error) => error && rejectWithValue(error))
);
export const meetingStatusUpdateHandler: any = createAsyncThunk(
    'meetingStatusUpdate',
    async (data: IMeetingStatusUpdate, { rejectWithValue }) => {
        try {
            const response = await meetingStatusUpdate(data?.id, data?.status)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }
);
export const meetingDataHandler: any = createAsyncThunk(
    'meetingData',
    async (data: IMeetingData, { rejectWithValue }) => {
        try {
            const response = await meetingData(data?.userId, data?.pageLimit, data?.perPage, data?.filterData, data?.searchVal, data?.sortColumn, data?.sortType)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }

);

//meetingsSlice
export const meetingSlice = createSlice({
    name: 'meetingSlice',
    initialState,
    reducers: {
        currentMeetingHandler: (state, { payload }: PayloadAction<any>) => {
            state.currentMeeting = payload
            if (payload === null) { state.isAllowed = false }
        },
        joinStatusHandler: (state, { payload }: PayloadAction<any>) => {
            state.joinStatus = payload
        },
        joinMessageHandler: (state, { payload }: PayloadAction<any>) => {
            state.joinMessage = payload
            state.joinSuccessful = false
        },
        messageHandler: (state, { payload }: PayloadAction<string>) => {
            state.codeExistMessage = payload
        },
        isAllowedHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isAllowed = payload
        },
        waitingListHandler: (state, { payload }: PayloadAction<any>) => {
            state.waitingList = payload
        },
        interestedListHandler: (state, { payload }: PayloadAction<any>) => {
            state.interestedList = payload
        },
        activeQuizIdGuestHandler: (state, { payload }: PayloadAction<number>) => {
            state.activeQuizIdGuest = payload;
        },
        activeQuizIdAttendeeHandler: (state, { payload }: PayloadAction<number>) => {
            state.activeQuizIdAttendee = payload;
        },
        activeTabHandler: (state, { payload }: PayloadAction<number>) => {
            state.activeTab = payload;
        },
        updateMeetingIdHandler: (state, { payload }: PayloadAction<number>) => {
            state.updateMeetingId = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(meetingHandler.fulfilled, (state, action) => {
            state.nearMeetingList = action.payload
            if (action.payload.length === 0) {
                state.LocationdisbleMessage = t('toastMessage.notFoundMeetingNearBy')
            }
        })
        builder.addCase(meetingCreateHandler.pending, (state) => {
            state.brandErr = false
            state.sponsorErr = false
        })
        builder.addCase(meetingCreateHandler.fulfilled, (state, action) => {
            state.createdMeeting = action.payload?.data
            state.codeExistMessage = ""
            state.successful = true
        })
        builder.addCase(meetingCreateHandler.rejected, (state, action) => {
            state.successful = false
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const randomCodesError = get(error, 'payload.response.data.randomCodes');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.codeExistMessage = errorMessage;
            } else if (networkError) {
                state.codeExistMessage = networkError;
            } else if (randomCodesError) {
                state.randomCodes = randomCodesError;
            }
        })
        builder.addCase(meetingGetByCodeCheckingHandler.fulfilled, (state, action) => {
            if (action?.payload?.data?.status === INPROGRESS) {
                state.passwordProtectedJoin = action.payload?.data?.isPasswordProtected
            }
        })
        builder.addCase(meetingGetByCodeCheckingHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.codeExistMessage = errorMessage;
            } else if (networkError) {
                state.codeExistMessage = networkError;
            }
        });
    },
})

export const { currentMeetingHandler, joinStatusHandler, joinMessageHandler, messageHandler, isAllowedHandler, waitingListHandler, interestedListHandler, activeTabHandler, activeQuizIdGuestHandler, activeQuizIdAttendeeHandler, updateMeetingIdHandler } = meetingSlice.actions;
export default meetingSlice.reducer;