import { QUESTIONTIMELS, QUIZTIMELS, QUIZTIMERDATALS } from "../types/commonTypes";

// put common functions here
export const generateMeetingCode = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';
    for (let i = 0; i < 3; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters.charAt(randomIndex);
    }
    code += '-';
    for (let i = 0; i < 3; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters.charAt(randomIndex);
    }
    return code;
};

export const millisecondToHMS = (milliseconds: any) => {
    let hours: any = Math.floor(milliseconds / 3600000);
    let minutes: any = Math.floor((milliseconds % 3600000) / 60000);
    let seconds: any = Math.floor((milliseconds % 60000) / 1000);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return `${hours}:${minutes}:${seconds}`;
}
export const secondToHMS = (seconds: any) => {
    if (!!seconds) {

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const hoursDisplay = hours < 10 ? "0" + hours : hours;
        const minutesDisplay = minutes < 10 ? "0" + minutes : minutes;
        const secondsDisplay = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

        return `${hoursDisplay}:${minutesDisplay}:${secondsDisplay}`;
    }
}
export const minuteToMillisecond = (time: any) => {
    return time * 60 * 1000
}
export const millisecondToMinute = (time: any) => {
    return (time / 1000) / 60
}
export const millisecondToSecond = (time: any) => {
    return time / 1000
}
export const secondToMillisecond = (time: any) => {
    return time * 1000
}

export const resetQuizTimer = () => {
    localStorage.removeItem(QUIZTIMELS);
    localStorage.removeItem(QUIZTIMERDATALS);
    localStorage.removeItem(QUESTIONTIMELS);
}

export const alphanumericRegex = /^[a-zA-Z0-9-]+$/;

export const minQuestionScore = 1
export const maxQuestionScore = 10


//these are default settings
// in seconds
export const minQuestionTime = 10;
export const maxQuestionTime = 120;
// in minutes
export const minQuizTime = 1
export const maxQuizTime = 120


//use these values in application
// in milliseconds
export const minQuestionTimeMS = secondToMillisecond(minQuestionTime);
export const maxQuestionTimeMS = secondToMillisecond(maxQuestionTime);

//in milliseconds
export const minQuizTimeMS = minuteToMillisecond(minQuizTime)
export const maxQuizTimeMS = minuteToMillisecond(maxQuizTime)


export function snapToGrid(x: number, y: number): [number, number] {
    const snappedX = Math.round(x / 32) * 32
    const snappedY = Math.round(y / 32) * 32
    return [snappedX, snappedY]
}

// Function to convert a string to slug format
export const convertToSlug = (text: string): string => {
    return text.toLowerCase().replace(/[^\w\s-]/g, '').replace(/\s+/g, '-');
};

export const mstoMinSec = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return { minutes, seconds };
}

export const all = { all: 'all' }
export const anonymous = { anonymous: 'anonymous' }