import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { getMeetingNearbyMe, meetingGetByCodeForCheckMeetingPassword } from '../../services/meeting.service';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import sliderImage1 from "../../images/slider-img-01.jpg"
import sliderImage2 from "../../images/slider-img-02.jpg"
import sliderImage3 from "../../images/slider-img-03.jpg"
import networkingImage from "../../images/img-networking.webp"
import connectImage from "../../images/img-connect.webp"
import { alphanumericRegex } from '../../common/utils';
import { innerHeaderShowHandler } from '../store/slices/settingsSlice';
import { useDispatch } from 'react-redux';
import NearbyMeetingsModal from '../components/Modals/NearbyMeetingsModal';


const Home = () => {

    //single hooks starts here
    const { t } = useTranslation('common');
    let navigate = useNavigate();
    const dispatch = useDispatch()
    //single hooks ends here


    //useStates starts here
    const [nearByMeetings, setNearByMeetings] = useState<any[]>([]);
    const [meetingCodeHome, setMeetingCodeHome] = useState<string>("");
    const [nearbyMeetingsModal, setNearbyMeetingsModal] = useState<boolean>(false)
    //useStates ends here


    //functions starts here
    const hideNearbyMeetingsModal = () => {
        setNearbyMeetingsModal(false)
    }

    const functionMeetingNearMe = (latitude: number, longitude: number) => {
        getMeetingNearbyMe(latitude, longitude).then(
            (response) => {
                if (response.length === 0) {
                    toast.warning(String(t('toastMessage.allowLocationPermission')), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    });
                }
                else {
                    if (response.length > 1) {
                        setNearByMeetings(response)
                        setNearbyMeetingsModal(true)
                    }
                    else {
                        navigate(`/guest/login/${response[0].code}`)
                    }
                }

            }
        )
    }

    const btnJoinMeeting = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            functionMeetingNearMe(position.coords.longitude, position.coords.latitude)
        },
            function (error) {
                if (error.code === error.PERMISSION_DENIED)
                    toast.warning(String(t('toastMessage.enableLocationFindMeeting')), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    });
            });
    }

    const btnJoinMeetingByCode = async () => {
        dispatch(innerHeaderShowHandler(true));
        if (!alphanumericRegex.test(meetingCodeHome)) {
            toast.error(String(t('validation.enterValidCode')), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2500,
            });
            return;
        }
        try {
            const response = await meetingGetByCodeForCheckMeetingPassword(meetingCodeHome, '')
            if (response.status === 200) {
                navigate(`/guest/login/${meetingCodeHome}`);
            } else {
                toast.error(String(t('toastMessage.meetingNotFound')), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(String(t('toastMessage.meetingNotFound')), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2500,
            });
        }
    }
    //functions ends here

    return (
        <div className="home-wrapper">
            <div className="intro hstack justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <div className="mb-2 text-center">
                        <h1 className="title d-flex flex-column align-items-center">
                            <span>{t('homePage.lblIntroducing')}</span>
                            <span>{t('homePage.lblMeeting')}</span>
                            <span>{t('homePage.lblBuddy')}</span>
                        </h1>
                        <p className="subtitle">{t('homePage.lblTitle')}</p>
                    </div>
                    <div className='mb-3'>
                        <label className='text-white font-18'>{t('homePage.lblFindMeeting')}</label>
                    </div>
                    <div className='d-flex gap-3 flex-wrap justify-content-center mb-1'>
                        <button onClick={btnJoinMeeting} className="btn h-48 rounded-1 text-white bg-primary text-uppercase mw-200">{t('homePage.btnLocation')}</button>
                    </div>
                    <div className='my-3 text-white'>
                        {t('common.lblOr')}
                    </div>
                    <div>
                        <div className="input-group mb-3" style={{ maxWidth: 200 }}>
                            <input type="text" onChange={(e: any) => { setMeetingCodeHome(e.target.value.trim()) }} className="form-control rounded-45" placeholder={t('common.placeholderCode')} />
                            <button disabled={meetingCodeHome ? false : true} className="btn btn-primary rounded-end" onClick={btnJoinMeetingByCode} type="button" id="button-addon2">{t('common.btnGo')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-5">
                <div className="p-4">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-4">
                            <h2 className="mb-4">{t('homePage.lblSimplicity')}</h2>
                            <p className="mb-4">
                                {t('homePage.descSimplicity')}
                            </p>
                            <p className="mb-4">
                                {t('homePage.descGoal')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="silder">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active"></li>
                        <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></li>
                        <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={sliderImage1} className="sliderImage" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={sliderImage2} className="sliderImage" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={sliderImage3} className="sliderImage" alt="..." />
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-5">
                <div className="p-4">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-4">
                            <h2 className="mb-4">{t('homePage.lblMeetingBuddy')}</h2>
                            <p className="mb-4">{t('homePage.titleMeetingBuddy')}</p>
                            <p>{t('homePage.descMeetingBuddy')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-0">
                <div className="col-lg-6">
                    <img src={networkingImage} className="img-fluid w-100" alt='networkImage' />
                </div>
                <div className="col-lg-6 bg-black text-white">
                    <div className="d-flex flex-column h-100 align-items-center justify-content-center p-3">
                        <div className="row justify-content-center">
                            <div className="col-lg-11 col-xl-8 col-12">
                                <h3 className="mb-3 mb-lg-2 mb-xl-4">{t('homePage.lblNetworking')}</h3>
                                <p className="mb-3 mb-lg-2 mb-xl-4">{t('homePage.titleNetworking')}</p>
                                <p>{t('homePage.descNetworking')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-lg-6 order-0 order-lg-1">
                    <img src={connectImage} className="img-fluid w-100" alt='connectImage' />
                </div>
                <div className="col-lg-6 order-1 order-lg-0 bg-black text-white">
                    <div className="d-flex flex-column h-100 align-items-center justify-content-center p-3">
                        <div className="row justify-content-center">
                            <div className="col-lg-11 col-xl-8 col-12">
                                <h3 className="mb-3 mb-lg-2 mb-xl-4">{t('homePage.lblConnect')}</h3>
                                <p className="mb-3 mb-lg-2 mb-xl-4">{t('homePage.titleConnect')}</p>
                                <p>{t('homePage.descConnect')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                <Link to="/#" className="text-decoration-none text-white">
                    {t('footer.title')}</Link>
            </div>

            {/* modals starts here */}
            <NearbyMeetingsModal
                nearbyMeetingsModal={nearbyMeetingsModal}
                hideNearbyMeetingsModal={hideNearbyMeetingsModal}
                nearByMeetings={nearByMeetings}
            />
            {/* modals ends here */}

        </div>
    );
};

export default Home;