import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface PaginationBoxProps {
  currentPage: number;
  totalPage: any;
  startItem: number,
  endItem: number;
  totalRecord: number;
  handleNextPage: (page: number) => void;
  handlePrevPage: (page: number) => void;
  handlePageClick: (page: number) => void;
}

const PaginationBox: FC<PaginationBoxProps> = ({
  currentPage,
  totalPage,
  startItem,
  endItem,
  totalRecord,
  handlePrevPage,
  handleNextPage,
  handlePageClick,
}) => {

  const { t } = useTranslation('common');

  const RecordsCount = () => {
    if (totalRecord > 10) {
      return (<div className="hstack gap-2">
        <span>{t('pagination.lblStartItem')}  </span>
        <span>{startItem}</span>
        <span>{t('pagination.lblBetween')}</span>
        <span>{endItem}</span>
        <span>{t('pagination.lblEndItem')}</span>
        <span>{totalRecord}</span>
        <span>{t('pagination.lblTotalRecoreds')}</span>
      </div>)
    } else if (totalRecord > 0 && totalRecord <= 10) {
      return <div>{totalRecord} {totalRecord === 1 ? t('pagination.record') : t('pagination.records')} {t('pagination.found')}</div>
    } else {
      return <div>{t('pagination.noRecordFound')}</div>
    }
  }

  return (
    <div className="hstack justify-content-sm-between gap-3 flex-wrap justify-content-center my-3">
      <RecordsCount />

      <div className="hstack gap-1 custom-pagination">
        <button
          className="btn btn-primary text-nowrap"
          onClick={() => handlePrevPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <span className="d-sm-none">
            <i className="fa-solid fa-chevron-left"></i>
          </span>
          <span className="d-none d-sm-block">
            {t('pagination.btnPrevPage')}
          </span>
        </button>
        <ul>
          {
            [...Array(totalPage)].map((e, i) => {
              let index = i + 1
              return (
                <li className={currentPage === (index + 1) ? 'active-sibling' : `${currentPage === (index) ? 'active' : ""}`} key={`pagination-${index}`}>
                  <button className={currentPage === index ? 'active btn btn-primary disabled' : "btn btn-primary"} onClick={() => handlePageClick(index)}>
                    {index}
                  </button>
                </li>
              )
            })
          }
        </ul>
        <button
          className="btn btn-primary text-nowrap"
          onClick={() => handleNextPage(currentPage + 1)}
          disabled={currentPage === totalPage || totalPage === 0}
        >
          <span className="d-sm-none">
            <i className="fa-solid fa-chevron-right"></i>
          </span>
          <span className="d-none d-sm-block">
            {t('pagination.btnNextPage')}
          </span>
        </button>
      </div>
    </div>
  );
};

export default PaginationBox


export const Pagination: FC<PaginationBoxProps> = ({
  currentPage,
  totalPage,
  startItem,
  endItem,
  totalRecord,
  handlePrevPage,
  handleNextPage,
  handlePageClick,
}) => {


  const { t } = useTranslation('common');
  const RecordsCount = () => {
    if (totalRecord > 5) {
      return (<div className="hstack gap-2">
        <span>{t('pagination.lblStartItem')}  </span>
        <span>{startItem}</span>
        <span>{t('pagination.lblBetween')}</span>
        <span>{endItem}</span>
        <span>{t('pagination.lblEndItem')}</span>
        <span>{totalRecord}</span>
        <span>{t('pagination.lblTotalRecoreds')}</span>
      </div>)
    } else if (totalRecord > 0 && totalRecord <= 5) {
      return <div>{totalRecord} {totalRecord === 1 ? t('pagination.record') : t('pagination.records')} {t('pagination.found')}</div>
    } else {
      return <div>{t('pagination.noRecordFound')}</div>
    }
  }

  return (
    <div className="hstack justify-content-sm-between gap-1 flex-wrap justify-content-center">
      <RecordsCount />
      <div className="hstack gap-1 custom-pagination">
        <button
          className="btn btn-primary text-nowrap"
          onClick={() => handlePrevPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <span className="d-sm-none">
            <i className="fa-solid fa-chevron-left"></i>
          </span>
          <span className="d-none d-sm-block">
            {t('pagination.btnPrevPage')}
          </span>
        </button>
        <ul>
          {
            [...Array(totalPage)].map((e, i) => {
              let index = i + 1
              return (
                <li className={currentPage === (index + 1) ? 'active-sibling' : `${currentPage === (index) ? 'active' : ""}`} key={`pagination-${index}`}>
                  <button className={currentPage === index ? 'active btn btn-primary disabled' : "btn btn-primary"} onClick={() => handlePageClick(index)}>
                    {index}
                  </button>
                </li>
              )
            })
          }
        </ul>
        <button
          className="btn btn-primary text-nowrap"
          onClick={() => handleNextPage(currentPage + 1)}
          disabled={currentPage === totalPage || totalPage === 0}
        >
          <span className="d-sm-none">
            <i className="fa-solid fa-chevron-right"></i>
          </span>
          <span className="d-none d-sm-block">
            {t('pagination.btnNextPage')}
          </span>
        </button>
      </div>
    </div>
  );
};