/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { deleteMeeting, getMeetingQuestions, meetingGetByID, meetingQuestionSendViaEmail, updateActiveQuizId } from "../../services/meeting.service";
import { useTranslation } from "react-i18next";
import { getAllPollsQuestionsAnswerByMeetingId, getPollsForMeeting } from "../../services/poll.service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { currentMeetingHandler, meetingDataHandler, meetingStatusUpdateHandler, updateMeetingIdHandler } from "../store/slices/meetingSlice";
import { meetingGetByCodeHandler, meetingQuestionSendViaEmailHandler } from "../store/slices/joinMeetingSlice";
import MeetingList from "./MeetingList";
import MeetingCard from "./MeetingCard";
import { createMeetingModalHandler, shareMeetingModalHandler } from "../store/slices/modalSlice";
import { getAllSubmittedQuizzes } from "../../services/quiz.service";
import socket from "../../common/constants";
import ShareMeetingModal from "../components/Modals/ShareMeetingModal";
import PaginationBox from "../components/Pagination";
import DialogBox from "../components/DeleteBox";
import SearchBar from "../components/SearchBar";
import { ENDED } from "../../types/commonTypes";
import MeetingDetailsModal from "../components/Modals/MeetingDetailsModal"

const Meeting = () => {
  //single hooks starts here
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  //single hooks ends here


  //custom variables starts here
  const all = {
    all: 'all'
  }
  //custom variables ends here


  //useSelectors starts here
  const { user } = useSelector((state: RootState) => state.auth)
  const { currentMeeting } = useSelector((state: RootState) => state.meeting)
  const { createMeetingModal, startMeetingModal } = useSelector((state: RootState) => state.modal)
  const userId = user?.user?.id
  //useSelectors ends here


  //useStates starts here
  const [showDelete, setShowDelete] = useState(false);
  const [showEndMeetingAlert, setShowEndMeetingAlert] = useState(false);
  const [meetingDeleteID, setMeetingDeleteID] = useState();
  const [meetingEndId, setMeetingEndId] = useState<number>(0);
  const [meetingList, setMeetingData] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState<any>();
  const [filterValue, setFilterValue] = useState('');
  const [endItem, setEndItem] = useState(10);
  const [startItem, setStartItem] = useState(1);
  const [filterData, setfilterData] = useState(""); // recent, past, all
  const [sortColumn, setSortColumn] = useState<any>({ order: false, col: "endedAt" });
  const [meetingPollsList, setMeetingPollsList] = useState<any[]>([]);
  const [meetingAllPollsAnswerList, setMeetingAllPollsAnswerList] = useState<any[]>([]);
  const [shareData, setShareData] = useState<any>([]);
  const [pastMeetingDetails, setpastMeetingDetails] = useState<any>([]);
  const [pastMeetingDetailsQuestion, setMeetingDetailsQuestions] = useState<any>([]);
  const [meetingDetailsModal, setMeetingDetailsModal] = useState<boolean>(false);
  const [allSubmittedQuizAnswer, setAllSubmittedQuizAnswer] = useState<any[]>([])
  const [updateData, setUpdateData] = useState<boolean>(false)
  //useStates ends here

  const resetPagination = () => {
    setPageSize(1)
    setStartItem(1)
  }

  const btnMeetingQuestionSendViaEmail = (id: any) => {
    meetingQuestionSendViaEmail(id).then((response) => {
      if (response.status === 201) {
        toast.success(String(t('toastMessage.emailSendSuccessfully')), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      }
    },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        })
      }

    );
  }

  const meetingEdit = async (id: any) => {
    dispatch(updateMeetingIdHandler(id))
    dispatch(createMeetingModalHandler(true))
  };

  const startMeetingHandler = async (id: any, status: string) => {
    const meetingStatusValues = { id, status }
    await dispatch(meetingStatusUpdateHandler(meetingStatusValues))
    setUpdateData(!updateData)
  }

  const joinMeetingHandler = async (id: any, status: string) => {
    const meetingStatusValues = { id, status };
    await dispatch(meetingStatusUpdateHandler(meetingStatusValues)).then((res: any) => {
      if (res?.type === 'meetingStatusUpdate/fulfilled') {
        if (res?.payload?.status === 200) {
          dispatch(meetingGetByCodeHandler({ code: res?.payload?.data.code, userId: 0, guestUserId: 0 })).then((meetDetails: any) => {
            if (meetDetails?.payload.status === 200) {
              setUpdateData(!updateData)
              dispatch(currentMeetingHandler(meetDetails?.payload?.data));
              navigate('/joinMeeting/' + res?.payload?.data.code);
            }
          })
        }
      }
    })
  }

  const endMeetingHandler = async (id: any, status: string) => {
    const data = { id, status }
    let meetingStatus = await dispatch(meetingStatusUpdateHandler(data))
    if (meetingStatus.payload.status === 200) {
      updateActiveQuizId(id, 0)
      await dispatch(meetingQuestionSendViaEmailHandler({
        meetingId: id
      }));
      socket.emit('end-meeting-send', { meetingId: id })
      dispatch(currentMeetingHandler(null))
      setUpdateData(!updateData)
    }
  }

  const deleteMeetingHandler = async (id: any) => {
    let deletemeeting = await deleteMeeting(id)
    if (deletemeeting.status === 200) {
      setUpdateData(!updateData)
      toast.success(String(t('toastMessage.meetingDelete')), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
      });
    }
  }

  const shareMeeting = async (id: any) => {
    const meetingData = await meetingGetByID(id);
    setShareData(meetingData);
    dispatch(shareMeetingModalHandler(true))
  };

  const getMeetingDetails = async (id: any) => {
    const meetingDetailsData = await meetingGetByID(id);
    setpastMeetingDetails(meetingDetailsData);

    let meetingDetailsQuestion = await getMeetingQuestions(id, all)
    setMeetingDetailsQuestions(meetingDetailsQuestion.data.data)

    let pollsListData = await getPollsForMeeting(id)
    if (pollsListData.status === 200) {
      if (pollsListData.data.length > 0) {
        setMeetingPollsList(pollsListData.data)
      } else {
        setMeetingPollsList([])
      }
    }

    getAllPollsQuestionsAnswerByMeetingId(id).then(
      (response) => {
        if (response.status === 200) {
          setMeetingAllPollsAnswerList(response.data)
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("resMessage ==>", resMessage)
      }
    )

    await getAllSubmittedQuizzes(id).then((res: any) => {
      setAllSubmittedQuizAnswer(res?.data)
    })

    setMeetingDetailsModal(true)
  };

  const getMeetingData = async () => {
    const data = {
      userId: userId,
      pageLimit: pageSize,
      perPage: perPage,
      filterData: filterData,
      searchVal: filterValue,
      sortColumn: sortColumn.col,
      sortType: sortColumn.order ? 'asc' : 'desc'
    }
    await dispatch(meetingDataHandler(data)).then((res: any) => {
      if (res?.type === 'meetingData/fulfilled') {
        setMeetingData(res?.payload?.data);
        setTotalPage(res?.payload?.totalPages)
        setPageSize(res?.payload?.currentPage)
        setTotalRecord(res?.payload?.totalItems)
      }
    })
  };

  const handlePrevPage = (e: number) => {
    setPageSize(e);
    setStartItem((startItem - perPage))
    setEndItem(startItem > totalRecord ? totalRecord : startItem)
    setUpdateData(!updateData)
  };

  const handleNextPage = (e: number) => {
    setPageSize(e);
    setStartItem(endItem + 1)
    setEndItem((endItem + perPage) > totalRecord ? totalRecord : endItem + perPage)
    setUpdateData(!updateData)
  };

  const handlePageClick = (e: any) => {
    setPageSize(e);
    setStartItem(((e * perPage) + 1) - perPage)
    setEndItem((e * perPage) > totalRecord ? totalRecord : (e * perPage))
    setUpdateData(!updateData)
  }

  const deleteConfirmHandler = (id: any) => {
    setShowDelete(true)
    setMeetingDeleteID(id)
  }

  const endConfirmHandler = (id: any) => {
    setShowEndMeetingAlert(true)
    setMeetingEndId(id)
  }

  const handleTabChange = (type: number) => {
    if (type === 1) {
      setPerPage(10)
      setEndItem(10)
    } else if (type === 2) {
      setPerPage(6)
      setEndItem(6)
    }
    resetPagination()
    setUpdateData(!updateData)
  }

  const hideMeetingDetailsModal = () => {
    setMeetingDetailsModal(false)
  }
  //functions ends here


  const sharedProps = {
    meetingList: meetingList,
    joinMeetingHandler: joinMeetingHandler,
    meetingEdit: meetingEdit,
    startMeetingHandler: startMeetingHandler,
    shareMeeting: shareMeeting,
    getMeetingDetails: getMeetingDetails,
    btnMeetingQuestionSendViaEmail: btnMeetingQuestionSendViaEmail,
    deleteConfirmHandler: deleteConfirmHandler,
    endConfirmHandler: endConfirmHandler,
    filterValue: filterValue,
    setFilterValue: setFilterValue,
    setPageSize: setPageSize
  };
  

  //useEffects starts here
  useEffect(() => {
    getMeetingData();
  }, [sortColumn, filterValue, createMeetingModal, startMeetingModal, updateData, currentMeeting]);
  //useEffects ends here
  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-between gap-3 flex-wrap mb-3 mb-md-4">
          <div className="font-26 fw-semibold">{t('myMeeting.header')}</div>
          <ul className="nav nav-pills bg-primary bg-opacity-10 shadow-in border p-1 rounded-3 d-inline-flex rounded-pill" role="tablist">
            <li className="nav-item" role="presentation">
              <button onClick={() => handleTabChange(1)} className="nav-link active rounded-pill" id="listview-tab" data-bs-toggle="tab" data-bs-target="#listview" type="button" role="tab" aria-controls="listview" aria-selected="true" title="listview" aria-label="listview">
                <i className="fa-solid fa-bars-staggered"></i>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button onClick={() => handleTabChange(2)} className="nav-link rounded-pill" id="cardview-tab" data-bs-toggle="tab" data-bs-target="#cardview" type="button" role="tab" aria-controls="cardview" aria-selected="false" title="cardview" aria-label="cardview">
                <i className="fa-solid fa-border-all"></i>
              </button>
            </li>
          </ul>
        </div>
        <div className="card shadow-sm">
          <div className="card-body">
            <SearchBar filterValue={filterValue} setFilterValue={setFilterValue} resetPagination={resetPagination} />
            <div className="tab-content">
              <div className="tab-pane active" id="listview" role="tabpanel" aria-labelledby="listview-tab" tabIndex={0}>
                <MeetingList {...sharedProps} sortColumn={sortColumn} setSortColumn={setSortColumn} />
              </div>
              <div className="tab-pane" id="cardview" role="tabpanel" aria-labelledby="cardview-tab" tabIndex={1}>
                <MeetingCard {...sharedProps} />
              </div>
            </div>

            <PaginationBox
              currentPage={pageSize}
              totalPage={totalPage}
              startItem={startItem}
              endItem={endItem}
              totalRecord={totalRecord}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              handlePageClick={handlePageClick}
            />
          </div>
        </div>
      </div>


      {/* modals starts here */}
      <MeetingDetailsModal meetingDetailsModal={meetingDetailsModal}
        hideMeetingDetailsModal={hideMeetingDetailsModal}
        pastMeetingDetails={pastMeetingDetails}
        pastMeetingDetailsQuestion={pastMeetingDetailsQuestion}
        btnMeetingQuestionSendViaEmail={btnMeetingQuestionSendViaEmail}
        userId={userId}
        meetingPollsList={meetingPollsList}
        meetingAllPollsAnswerList={meetingAllPollsAnswerList}
        allSubmittedQuizAnswer={allSubmittedQuizAnswer} />

      <ShareMeetingModal userId={userId} currentMeeting={shareData} />
      {/* modals ends here */}

      {/* alerts actions starts heres */}
      <DialogBox show={showDelete} clickOk={() => { deleteMeetingHandler(meetingDeleteID); setShowDelete(false) }} clickCancel={() => { setShowDelete(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltMeetingquestion')} />
      <DialogBox show={showEndMeetingAlert} clickOk={() => { endMeetingHandler(meetingEndId, ENDED); setShowEndMeetingAlert(false) }} clickCancel={() => { setShowEndMeetingAlert(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.endMeeting')} />
      {/* alerts actions ends heres */}
    </>
  );
};

export default Meeting;
