import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllCategories } from '../../../services/master-category.service';
/* eslint-disable @typescript-eslint/no-unused-expressions */

//meetings initial states
export interface MeetingState {

}

const initialState: MeetingState = {

}
//meetings api thunks
export const getAllCategoriesHandler: any =
  createAsyncThunk(
    "getAllCategory",
    (data: any, { rejectWithValue }) =>
      getAllCategories(
        data,
      ).catch((error) => error && rejectWithValue(error))
  );



//meetingsSlice
export const masterCategorySlice = createSlice({
  name: 'masterCategorySlice',
  initialState,
  reducers: {}
})

export default masterCategorySlice.reducer;