/// reducers response ///

export const PENDING = "pending";
export const FULFILLED = "fulfilled";
export const REJECTED = "rejected";

/// dispatch auth actions ///
export const SIGNUP = "signUp";
export const LOGIN = "signIn";
export const FORGOTPASSWORD = "forgotPassword";
export const RESETPASSWORD = "resetPassword";
export const DELETEUSER = "deleteUser";
export const GOOGLELOGIN = "googleLogin";
export const MICROSOFTLOGIN = "microsoftLogin";

/// dispatch dashboard actions ///
export const DASHBOARD = "dashboard"

/// dispatch profile actions ///
export const UPDATEPROFILE = "updateProfile"

/// dispatch guestuser actions ///
export const GUESTUSERLOGIN = "guestUserLogin"
export const GUESTADDMEETINGQUESTION = "guestAddMeetingQuestion"
export const GUESTEDITMEETINGQUESTION = "guestEditMeetingQuestion"
export const ADDPOLLSQUESTIONSANSWERBYGUEST = "addPollsQuestionsAnswerByGuest"
export const GETPOLLSQUESTIONSANSWERBYMEETINGIDFORGUEST = "getPollsQuestionsAnswerByMeetingIdForGuest"
export const SAVEUPVOTEFORMEETINGQUESTIONFORGUESTPROFILE = "updatePsaveUpvoteForMeetingQuestionForGuestrofile"
export const ADDMEETINGQUESTIONREPLYFORGUEST = "addMeetingQuestionReplyForGuest"
export const SAVEUPVOTEFORMEETINGQUESTIONREPLYFORGUEST = "saveUpvoteForMeetingQuestionReplyForGuest"
export const SETMEETINGINTRESTEDGUESTUSER = "setMeetingIntrestedGuestUser"
export const CHECKUSERACCESS = "checkUserAccess"
/// dispatch poll actions ///
export const CREATEPOLL = "createPoll"
export const POLLGETBYID = "pollGetById"
export const POLLUPDATE = "pollUpdate"
export const DELETEPOLL = "deletePoll"
export const POLLDATA = "pollData"
export const CREATEQUESTION = "createquestion"
export const QUESTIONDATA = "questiondata"
export const QUESTIONGETBYID = "questiongetbyid"
export const QUETIONUPDATEBYID = "questionupdatebyid"
export const DELETEQUESTION = "deletequestion"
///dispatch quiz actions ///
export const CREATEQUIZ = 'createQuiz'
export const QUIZGETBYID = 'quizGetById'
export const QUIZUPDATE = 'quizUpdateById'
export const DELETEQUIZ = 'deleteQuiz'
export const QUIZDATA = 'quizData'
export const QUIZANSWERBYATTENDEE = 'quizAnswerByAttendee'
export const QUIZANSWERBYGUEST = 'quizAnswerByGuest'
export const GETQUIZANSWEROFGUEST = ' getQuizAnswerOfGuest'
export const GETQUIZANSWEROFATTENDIEE = ' getQuizAnswerOfAttendie'
