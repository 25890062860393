/* eslint-disable no-useless-concat */
import axios from "axios";
import authHeader from "./auth-header";
import { IMAGEPICKER } from "../types/commonTypes";

const API_URL = process.env.REACT_APP_API_URL

export const questionCreate = (title: string, options: any, score: any, categoryNames: any, difficulty: any, type: any) => {
    if (type === IMAGEPICKER) {
        let formData = new FormData();
        formData.append('title', title);
        options.forEach((option: any, index: any) => {
            formData.append(`optionsName`, option.optionName);
            formData.append(`isAnswer[${index}]`, option.isAnswer);
        });
        formData.append('score', score);
        categoryNames.forEach((categoryName: any, index: any) => {
            formData.append(`categoryNames[${index}]`, categoryName);
        });
        formData.append('difficulty', difficulty);
        formData.append('type', type);

        return axios.post(API_URL + "questions/create", formData, {
            headers: {
                ...authHeader(), 'Content-Type': 'multipart/form-data'
            }
        });
    } else {
        return axios.post(API_URL + "questions/create", {
            title,
            options,
            score,
            categoryNames,
            difficulty,
            type
        }, { headers: authHeader() });
    }
};

export const questionData = async (id: any, pageSize: any, perPage: any, searchVal: any, difficulty: any, searchByTags: any, searchByScore: number) => {
    return axios.get(API_URL + "questions" + "/user/" + id + "/?page=" + pageSize + "&limit=" + perPage + "&searchVal=" + searchVal + "&difficulty=" + difficulty + "&searchByTags=" + searchByTags + "&searchByScore=" + searchByScore)
        .then((response) => {
            return response.data;
        });
};
export const questionGetByID = async (id: any) => {
    return axios.get(API_URL + "questions/" + id)
        .then((response) => {
            return response.data;
        });
    ;
};

export const questionupdateByID = async (id: any, title: string, options: any, score: any, maxTime: any, categoryNames: any, difficulty: any, type: any) => {
    if (type === IMAGEPICKER) {
        let formData = new FormData();
        formData.append('title', title);
        options.forEach((option: any, index: any) => {
            formData.append(`optionsName`, option.optionName);
            let imageFile: string = '';
            if (option.optionName.name) {
                imageFile = option.optionName.name;
            } else {
                imageFile = option.optionName;
            }
            formData.append(`imageFiles[${index}]`, imageFile);
            formData.append(`isAnswer[${index}]`, option.isAnswer);
        });
        formData.append('score', score);
        categoryNames.forEach((categoryName: any) => {
            formData.append(`categoryNames[]`, categoryName);
        });
        formData.append('difficulty', difficulty);
        formData.append('type', type);
        return axios.put(API_URL + "questions/" + id, formData, {
            headers: {
                ...authHeader(), 'Content-Type': 'multipart/form-data'
            }
        });
    } else {
        return axios.put(API_URL + "questions/" + id, {
            title,
            options,
            score,
            maxTime,
            categoryNames,
            difficulty,
            type
        }, { headers: authHeader() });
    }
};

export const deleteQuestion = async (id: any) => {
    return axios.delete(API_URL + "questions/" + id,
        { headers: authHeader() })
        .then((response) => {
            return response;
        });
};

export const getAllQuestionByDifficulty = async (difficulty: any) => {
    return axios.get(API_URL + "questions/difficulty/" + difficulty, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
    ;
};
