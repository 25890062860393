import React, { FC } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PDFIcon from '../../../images/pdfDownload.png'

interface QuizPDFDownloadProps {
    containerRef: any
    filename: string
    linkTitle: string
}
const QuizPDFDownload: FC<QuizPDFDownloadProps> = ({ containerRef, filename, linkTitle }) => {

    const handleDownloadPDF = () => {
        const input: any = containerRef.current;
        const options = {
            scale: 0.93,
            useCORS: true
        }
        html2canvas(input, options).then((canvas) => {
            var imgData = canvas.toDataURL('image/png');
            var imgWidth = 200;
            var pageHeight = 295;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            var doc = new jsPDF('p', 'mm', 'a4');
            var position = 0;

            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save(`${filename}.pdf`);
        });
    };

    return (
        <button className='btn' onClick={handleDownloadPDF}>
            <img
                src={PDFIcon}
                title={linkTitle}
                width={30}
                height={30}
                alt='pdf'
            />
        </button>
    );
};

export default QuizPDFDownload;
