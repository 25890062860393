/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import moment from "moment";
import { useTranslation } from "react-i18next";
import QRCode, { QRCodeToDataURLOptions } from 'qrcode';
import { toast, } from 'react-toastify';


//props interface starts here
interface ShareMeetingDetailsProps {
    userId: number
    currentMeeting: any
}
//props interface ends here

const ShareMeetingDetails: FC<ShareMeetingDetailsProps> = ({ userId, currentMeeting }) => {

    //single hooks starts here
    const { t } = useTranslation('common');
    //single hooks ends here

    //custom variables starts here
    const isHost = currentMeeting?.hostId === userId
    const qrCodeOption: QRCodeToDataURLOptions = {
        type: 'image/jpeg',
        errorCorrectionLevel: 'H',
        maskPattern: 2,
        margin: 0,
    }
    //custom variables ends here

    //useStates starts here
    const [qrLink, setQrLink] = useState('');
    const [srcData, setSrc] = useState('');
    //useStates ends here


    //functions starts here
    const copyLink = async (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(qrLink)
        toast.success(String(t('toastMessage.copyLink')), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
        });
    }

    const copyCode = async (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(currentMeeting?.code)
        toast.success(String(t('toastMessage.copyCode')), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
        });
    }

    const copyPassword = async (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(currentMeeting?.password)
        toast.success(String(t('toastMessage.copyPassword')), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
        });
    }

    //functions ends here


    //useEffect starts here
    useEffect(() => {
        const hostname = typeof window !== 'undefined' ? window.location.origin : process.env.RESTURL_SPEAKERS;
        const link = hostname + `/guest/login/${currentMeeting?.code}`;
        setQrLink(link)
        QRCode.toDataURL(link, qrCodeOption).then((setSrc))

    }, [currentMeeting]);
    //useEffect ends here


    return (
        <>
            <div className='vstack gap-4'>
                <div className="hstack gap-3">
                    {
                        !!currentMeeting?.brandImageUrls &&
                        currentMeeting?.brandImageUrls?.length > 0 &&
                        currentMeeting?.brandImageUrls?.map((item: any, index: number) => (
                            <div key={`sharemeeting_brandImageUrls_${index}`}>
                                <img className='company-sponsor' src={item.brandImageUrl} alt='brandImage' />
                            </div>
                        ))
                    }
                    <div className='font-24 fw-semibold'>{currentMeeting?.title?.toUpperCase()}</div>
                </div>

                {!!srcData &&
                    <div className='text-center'>
                        <img src={srcData} alt='QR code to join meeting' width={'220px'} height={'220px'} />
                    </div>

                }

                <div className='text-center'>
                    <button onClick={copyLink} className="btn btn-primary-light text-primary d-inline-flex align-items-center gap-2">
                        <i className="fa-regular fa-copy"></i>{t('guest.guestJoin.btnCopyLink')}
                    </button>
                </div>

                <table className='table table-sm table-borderless mb-0 mt-4'>
                    <tbody>
                        <tr>
                            <td className="font-20 fw-medium">{t('guest.guestJoin.lblCode')}</td>
                            <td className='hstack gap-2 py-0'>
                                <span className="font-20 fw-semibold text-nowrap">{currentMeeting?.code}</span>
                                <button className='btn btn-primary-light text-primary size-38 p-1' title='copy meeting code' onClick={copyCode}>
                                    <i className="fa-regular fa-copy"></i>
                                </button>
                            </td>
                        </tr>
                        {isHost && !!currentMeeting?.password &&
                            <tr>
                                <td className="font-20 fw-medium">{t('common.Password')}</td>
                                <td className='hstack gap-2 py-0'>
                                    <span className="font-20 fw-semibold">{currentMeeting?.password}</span>
                                    <button className='btn btn-primary-light text-primary size-38 p-1' title='copy meeting password' onClick={copyPassword}>
                                        <i className="fa-regular fa-copy"></i>
                                    </button>
                                </td>
                            </tr>
                        }
                        <tr>
                            <td className="font-20 fw-medium">{t('guest.guestJoin.lblStartDate')}</td>
                            <td className="font-20 fw-semibold">{moment(new Date(currentMeeting?.startTime)).format('ll')}</td>
                        </tr>
                        <tr>
                            <td className="font-20 fw-medium">{t('guest.guestJoin.lblStartTime')}</td>
                            <td className="font-20 fw-semibold">{moment(new Date(currentMeeting?.startTime)).format('LT')}</td>
                        </tr>
                        <tr>
                            <td className="font-20 fw-medium">{t('guest.guestJoin.lblGeoFence')}</td>
                            <td className="font-20 fw-semibold">{currentMeeting?.isGeoFenceProtected ? t('common.lblYes') : t('common.lblNo')}</td>
                        </tr>
                    </tbody>
                </table>

                {
                    !!currentMeeting?.sponsorImageUrls && currentMeeting?.sponsorImageUrls.length > 0 &&
                    <div>
                        <label className="font-20 fw-medium mb-3">{t('guest.guestJoin.lblSponsors')}</label>
                        <div className="hstack gap-3 gap-md-4 flex-wrap">
                            {
                                currentMeeting?.sponsorImageUrls.map((item: any, index: number) => (
                                    <div key={`sharemeeting_sponsorImageUrls_${index}`}>
                                        <img className='company-sponsor' src={item.sponsorImageUrl} alt='sponsorImage' />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default ShareMeetingDetails