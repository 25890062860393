import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import ShareMeetingDetails from './ShareMeetingDetails'
import ShareMeetingModal from '../Modals/ShareMeetingModal'


const ShareMeetingContainer = () => {
    const { user, isUser } = useSelector((state: RootState) => state.auth)
    const { currentMeeting } = useSelector((state: RootState) => state.meeting)
    const userId = isUser ? user?.user?.id : 0;

    return (
        <>
            <div className="col-xl-4 d-none d-xl-block">
                <div className="card border-0 h-100">
                    <div className="card-body">
                        <ShareMeetingDetails userId={userId} currentMeeting={currentMeeting} />
                    </div>
                </div>
            </div>
            <ShareMeetingModal userId={userId} currentMeeting={currentMeeting} />
        </>
    )
}

export default ShareMeetingContainer