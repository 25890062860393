import React, { FC, useRef } from 'react'
import QuizResult from './QuizResult'
import QuizPDFDownload from './QuizPdfDownload'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

interface ShowQuizResultProps {
    allQuizAnswer: any[]
}

// Function to convert a string to slug format
const convertToSlug = (text: string): string => {
    return text.toLowerCase().replace(/[^\w\s-]/g, '').replace(/\s+/g, '-');
  };
const ShowQuizResult: FC<ShowQuizResultProps> = ({ allQuizAnswer }) => {
    
    return (
        <>
        
            <div className='vstack gap-3'>
                {
                    allQuizAnswer?.map((quiz: any, index: number) => {
                        return <QuizResult
                            key={`showQuizResult_${quiz.quizId}_${index}`}
                            quiz={quiz}
                        />
                    })
                }
            </div>
        </>
    )
}

export default ShowQuizResult