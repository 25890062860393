import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/main.scss";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { persistor, store } from './pages/store/store'
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { EN } from './types/commonTypes';
import { GoogleOAuthProvider } from '@react-oauth/google';

i18next.init({
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    de: {
      common: common_de
    },
  },
  interpolation: { escapeValue: false }, // React already does escaping
  lng: window.localStorage.language,
  fallbackLng: EN,
})


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <I18nextProvider i18n={i18next}>
          <GoogleOAuthProvider clientId="903733391227-ppshltpmnlakp6jno0k5ugd2h4qor4g0.apps.googleusercontent.com">
            <DndProvider
              backend={TouchBackend}
              options={{ enableMouseEvents: true }}
            >
              <App />
            </DndProvider>
          </GoogleOAuthProvider>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
