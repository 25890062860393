import axios from "axios";
import languageHeader from "./language-header";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL

export const register = async (firstName: string, lastName: string, email: string, password: string) => {
    return axios.post(API_URL + "auth/signup", {
        firstName,
        lastName,
        email,
        password,
    }, { headers: languageHeader() }).then((response) => {
        return response.data;
    });;
};

export const login = async (username: string, password: string) => {
    return axios
        .post(API_URL + "auth/login", {
            username,
            password,
        }, { headers: languageHeader() })
        .then((response) => {
            return response.data;
        });
};

export const loginWithGoogle = async (codeResponse: string) => {
    return axios
        .post(API_URL + "auth/google", {
            code: codeResponse
        }, { headers: languageHeader() })
        .then((response) => {
            return response.data;
        });
};

export const loginWithMicrosoft = async (microsoftCredentialResponse: any) => {
    return axios
        .post(API_URL + "auth/microsoftLogin", {
            data: microsoftCredentialResponse
        }, { headers: languageHeader() })
        .then((response) => {
            return response.data;
        });
};

export const logout = () => {
    sessionStorage.clear()
};

export const forgotPassword = async (email: string) => {
    return axios
        .post(API_URL + "auth/forgot-password", {
            email
        })
        .then((response) => {
            return response.data;
        });
};

export const resetPassword = async (token: any, newPassword: any) => {
    return axios
        .post(API_URL + "auth/reset-password/" + token, {
            newPassword
        })
        .then((response) => {
            return response.data;
        });
};

export const changePassword = async (oldPassword: any, newPassword: any) => {
    return axios
        .post(API_URL + "auth/change-password", {
            oldPassword,
            newPassword
        }, { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};

export const deleteUser = async () => {
    return axios
        .delete(API_URL + "auth/delete-user", { headers: authHeader() })
        .then((response) => {
            return response.data;
        });
};
