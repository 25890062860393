/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { IMeetingEmailGuest } from '../../../types/meeting.type';
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { guestUserEmailHandler } from '../../store/slices/guestUserSlice';


//props interface starts here
interface GuestEmailModalProps {
    guestEmailModal: boolean
    guestUserEmail: string
    hideGuestEmailModal: () => void
    intrestedGuestUserHandler: () => void
    handleChangeGuestEmail: (newValue: string) => void
}
//props interface ends here


const GuestEmailModal: FC<GuestEmailModalProps> = ({ guestEmailModal, guestUserEmail, hideGuestEmailModal, intrestedGuestUserHandler, handleChangeGuestEmail }) => {
    
   //single hooks starts here
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
   //single hooks ends here


   //formik starts here
    const initialValues: IMeetingEmailGuest = {
        email: guestUserEmail
    }
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('validation.requiredField'))
            .email(t('validation.notValidEmail')),
    });
   //formik ends here
   useEffect(()=>{
    dispatch(guestUserEmailHandler(guestUserEmail))
   },[guestUserEmail])

    return (
        //modals starts here
        <Modal show={guestEmailModal} centered onHide={hideGuestEmailModal}>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='fw-semibold'>
                    Please enter your email
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={intrestedGuestUserHandler}
                >
                    <Form>
                        <div className="mb-4">
                            <p style={{ textAlign: "justify" }}>{t('guest.guestJoin.lblDescription')}</p>
                            <label htmlFor="email" className="form-label">{t('guest.guestJoin.lblEmail')}</label>
                            <Field type="text" onChange={(e: any) => handleChangeGuestEmail(e.target.value.trim())} name='email' className="form-control" id="email" placeholder="Email Address" value={guestUserEmail} />
                            <ErrorMessage name="email" component="div" className="text-danger txt-error" />
                        </div>
                        <div className="hstack justify-content-center">
                            <button type='submit' className="btn btn-primary mw-120" data-bs-toggle="tooltip" title={t('guest.guestJoin.lblInterestedToolTip')}>{t('guest.guestJoin.lblIntrested')} </button>
                        </div>
                    </Form>
                </Formik>
            </Modal.Body>
            <Modal.Footer className='border-0'>
                <button type="button" className="btn btn-outline-danger rounded-45 mw-120" onClick={hideGuestEmailModal}
                >
                    {t('joinMeeting.intrestedModel.btnClose')}
                </button>
            </Modal.Footer>
        </Modal>
        //modals ends here
    )
}

export default GuestEmailModal