import React, { FC, useMemo } from 'react'
import { IMAGEPICKER, RANKING } from '../../../types/commonTypes';

//props interface starts here
interface QuestionOptionsProps {
    question: any
}
//props interface ends here


const QuestionOptions: FC<QuestionOptionsProps> = ({ question }) => {
    const categories = useMemo(() => {
        return question?.questionCategories?.map((value: any) => value?.category?.categoryName) || [];
    }, [question]);

    const questionOptions = useMemo(() => {
        return question?.questionOptions || [];
    }, [question]);

    return (
        <div className='vstack gap-3'>
            <table className='table mb-0'>
                <tbody>
                    {question?.difficulty &&
                        <tr>
                            <td width={100}>Severity</td>
                            <td className='fw-medium'>{question.difficulty}</td>
                        </tr>
                    }
                    {question?.score &&
                        <tr>
                            <td>Score</td>
                            <td className='fw-medium'>{question.score}</td>
                        </tr>
                    }
                    {question?.type &&
                        <tr>
                            <td>Type</td>
                            <td className='fw-medium'>{question.type}</td>
                        </tr>
                    }
                    {categories && categories?.length > 0 &&
                        <tr>
                            <td className='align-top'>Tags</td>
                            <td>
                                <div className='hstack gap-2 flex-wrap'>
                                    {categories.map((value: any) => (
                                        <div className='badge rounded-pill badge-pill bg-success fw-medium px-3 py-2' key={`categories_${value}`}>
                                            <span>{value}</span>
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>

            <ul className={question.type === RANKING ? "list-group" : "list-unstyled question-option"}>
                {questionOptions && questionOptions?.length > 0 &&
                    questionOptions?.map((optionItem: any, index: number) => (
                        <li
                            key={optionItem.id}
                            className={question.type === RANKING ? 'list-group-item hstack' : optionItem?.isAnswer ? 'correct-option' : ''}
                        >
                            {question.type === IMAGEPICKER ? (
                                <img className='img-fluid' width={"100px"} height={"100px"} src={`${optionItem.optionImageUrl}`} alt="option" />
                            ) : (
                                question.type === RANKING ? (
                                    <>
                                        <div style={{ minWidth: 42 }}>{index + 1}.</div>
                                        <div>{optionItem?.optionName}</div>
                                    </>
                                ) : (
                                    optionItem?.optionName
                                )
                            )}
                        </li>
                    ))}
            </ul>
        </div>
    );

};


export default QuestionOptions