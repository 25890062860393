import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL


export const dashboardCount = async (id: any) => {
    return axios.get(API_URL + "dashboard/" + id)
        .then((response) => {
            return response.data;
        });
};