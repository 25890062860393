/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Link } from 'react-router-dom';
import { dashboardHandler } from '../store/slices/dashboardSlice';
import { feedbackFormHandler } from '../store/slices/settingsSlice';
import IconQuiz from "../../images/icon-quiz.png"
import IconJoinMeeting from "../../images/join-meet.svg"
import IconHostMeeting from "../../images/host-meet.svg"
import IconPoll from "../../images/poll.svg"
import IconQuestions from "../../images/que-list.svg"
import { createMeetingModalHandler } from '../store/slices/modalSlice';
import { selectedQuestionHandler } from '../store/slices/pollSlice';
import { signInRememberNameHandler } from '../store/slices/authSlice';


const Dashboard = () => {
  //single hooks starts here
  const { t } = useTranslation('common');
  const dispatch = useDispatch()
  //single hooks ends here


  //useSelectors starts here
  const { user, signInRememberMe } = useSelector((state: RootState) => state.auth)
  const { dashboardDataCount }: any = useSelector((state: RootState) => state.dashboard)
  const userId = user?.user?.id
  const userEmail = user?.user?.email
  //useSelectors ends here


  //functions starts here
  const dashboardCounts = (propertyName: string) => {
    for (const item of dashboardDataCount) {
      if (item.hasOwnProperty(propertyName)) {
        return item[propertyName];
      }
    }
    return 0;
  }

  const clearEditMode = () => {
    dispatch(selectedQuestionHandler([]))
  }
  //functions ends here

  //useEffects starts here
  useEffect(() => {
    if (!!userId) {
      dispatch(dashboardHandler(userId))
      if (!signInRememberMe) {
        dispatch(signInRememberNameHandler(''))
      } else {
        dispatch(signInRememberNameHandler(userEmail))
      }
    }
  }, [userId]);

  useEffect(() => {
    dispatch(feedbackFormHandler(false))
  }, []);
  //useEffects ends here

  return (
    <div className="container-fluid h-100 vstack gap-4 dashboard-layout">
      <div className="hstack justify-content-between flex-wrap gap-1">
        <div className='font-26 fw-semibold'>
          {t('dashbord.headerDashbord')}
        </div>

        <button className="btn btn-primary px-3 py-2 text-nowrap hstack gap-2" id="tour_createMeeting" title='Create a Meeting' onClick={() => dispatch(createMeetingModalHandler(true))}>
          <i className='fa fa-plus'></i> <span className='d-none d-sm-inline'>{t('common.createMeeting')}</span>
        </button>
      </div>

      <div className="hstack gap-3 flex-wrap meeting-card">
        <div className="card border-0 text-white bg-secondary">
          <div className="card-body p-4 p-md-4">
            <div className="mb-2">
              <img className="img-fluid" src={IconJoinMeeting} alt="joined-meetings-count" />
            </div>
            <div className="fw-semibold count">{dashboardCounts("findJoinedMeetingData")}</div>
            <div className="fw-semibold font-20">{t('dashbord.lblMeetingsJoin')}</div>
          </div>
        </div>
        <div className="card border-0 text-white bg-success">
          <div className="card-body p-4 p-md-4">
            <div className="mb-2">
              <img className="img-fluid" src={IconHostMeeting} alt="meetings-list" />
            </div>
            <div className="fw-semibold count">{dashboardCounts("findHostedMeetingData")}</div>
            <div className="fw-semibold font-20">{t('dashbord.lblMeetingHost')}</div>
          </div>
          <Link to='/meeting' className='stretched-link'></Link>
        </div>
        <div className="card border-0 text-white bg-warning">
          <div className="card-body p-4 p-md-4">
            <div className="mb-2">
              <img className="img-fluid" src={IconPoll} alt="polls-list" />
            </div>
            <div className="fw-semibold count">{dashboardCounts("findPollData")}</div>
            <div className="fw-semibold font-20">{t('common.Poll')}</div>
          </div>
          <Link to='/poll' className='stretched-link'></Link>
        </div>
        <div className="card border-0 text-white bg-danger">
          <div className="card-body p-4 p-md-4">
            <div className="mb-2">
              <img className="img-fluid" src={IconQuiz} alt="quiz-list" />
            </div>
            <div className="fw-semibold count">{dashboardCounts("findQuizData")}</div>
            <div className="fw-semibold font-20">{t('common.Quiz')}</div>
          </div>
          <Link to='/quiz' className='stretched-link'></Link>
        </div>
        <div className="card border-0 text-white bg-primary">
          <div className="card-body p-4 p-md-4">
            <div className="mb-2">
              <img className="img-fluid" src={IconQuestions} alt="questions-list" />
            </div>
            <div className="fw-semibold count">{dashboardCounts("findQuestionData")}</div>
            <div className="fw-semibold font-20">{t('common.question')}</div>
          </div>
          <Link to='/poll/0' onClick={clearEditMode} className='stretched-link'></Link>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;