/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import socket from '../../common/constants';
import { addPollsQuestionsAnswerByLogInUser, deleteMeetingPoll, getAllPollsQuestionsAnswerByMeetingId, getPollsForMeeting, getPollsQuestionsAnswerByMeetingId } from '../../services/poll.service';
import { useTranslation } from "react-i18next";
import DialogBox from '../components/DeleteBox';
import { toast } from 'react-toastify';
import { getWaitingList, updateWaitingUserStatus } from '../../services/waitingList.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../store/store';
import { activeQuizIdAttendeeHandler, activeTabHandler, currentMeetingHandler, interestedListHandler, waitingListHandler } from '../store/slices/meetingSlice';
import { getMeetingIntrestedUsersHandler } from '../store/slices/joinMeetingSlice';
import { feedbackFormHandler } from '../store/slices/settingsSlice';
import { Spinner, Tab, Tabs } from 'react-bootstrap';
import { deleteMeetingQuiz, getAllSubmittedQuizzes, getQuizzesForMeeting, getSubmittedQuizzesByAttendee, updateMeetingQuizStatus } from '../../services/quiz.service';
import WaitingListModal from '../components/Modals/WaitingListModal';
import { waitingListModalHandler } from '../store/slices/modalSlice';
import InterestedListModal from '../components/Modals/InterestedListModal';
import { ACTIVE, ACTIVE1, ACTIVEPOLLSCOUNTLS, ACTIVEQUIZCOUNTLS, END, FILTERUSERLS, HOSTQUIZTIMELS, IMAGEPICKER, INACTIVE } from '../../types/commonTypes';
import ShowPollResult from '../components/Poll/ShowPollResult';
import ActivePolls from '../components/Poll/ActivePolls';
import QuizHostResult from '../components/Quiz/QuizHostResult';
import ShowQuizResult from '../components/Quiz/ShowQuizResult';
import ActiveQuiz from '../components/Quiz/ActiveQuiz';
import { all, resetQuizTimer, secondToHMS } from '../../common/utils';
import ShareMeetingContainer from '../components/ShareMeeting/ShareMeetingContainer';
import { getActiveQuizId, updateActiveQuizId } from '../../services/meeting.service';
import { activeQuizDataIdHandler, activeQuizIdHandler, allQuizAnswerHandler, meetingQuizListHandler } from '../store/slices/quizSlice';
import QuestionTab from './common/QuestionTab';
import AddPollQuizModal from '../components/Modals/AddPollQuizModal';
import ShareMeetingMobile from '../components/ShareMeeting/ShareMeetingMobile';


const JoinMeeting = () => {
   //single hooks starts here
   const dispatch = useDispatch()
   const { t } = useTranslation('common');
   let { code } = useParams();
   let navigate = useNavigate();
   //single hooks ends here


   //useSelectors starts here
   const { user } = useSelector((state: RootState) => state.auth)
   const { currentMeeting, waitingList, activeQuizIdAttendee } = useSelector((state: RootState) => state.meeting)
   const { activeQuizDataId, activeQuizId, allQuizAnswer, meetingQuizList } = useSelector((state: RootState) => state.quiz)
   const { isFeedBackForm } = useSelector((state: RootState) => state.settings)
   const userId = user?.user?.id
   const currentMeetingId = currentMeeting?.id
   const currentMeetingHostId = currentMeeting?.hostId
   const currentMeetingCode = currentMeeting?.code;
   const isHost = !!userId && userId === currentMeetingHostId
   //useSelectors ends here


   //useRefs starts here
   const hostSpentQuizTimeRef = useRef<any>(null)
   //useRefs ends here


   //useStates starts here
   const [showDeletePoll, setShowDeletePoll] = useState<boolean>(false);
   const [showDeleteQuiz, setShowDeleteQuiz] = useState<boolean>(false);
   const [meetingPollDeleteId, setMeetingPollDeleteId] = useState<number>(0);
   const [meetingQuizDeleteId, setMeetingQuizDeleteId] = useState<number>(0);
   const [meetingAllPollsAnswerList, setMeetingAllPollsAnswerList] = useState<any[]>([]);
   const [pollTab, setPollTab] = useState<boolean>(false)
   const [quizTab, setQuizTab] = useState<boolean>(false)
   const [GroupedQuestions, setGroupedQuestions] = useState<any[]>([]);
   const [GroupedAnswers, setGroupedAnswers] = useState<any[]>([]);
   const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
   const [selectedOptions, setSelectedOptions] = useState<any>([]);
   const [addModal, setAddModal] = useState<boolean>(false);
   const [addModalType, setAddModalType] = useState<number>(1);
   const [allSubmittedQuizAnswer, setAllSubmittedQuizAnswer] = useState<any[]>([])
   const [meetingPollsList, setMeetingPollsList] = useState<any[]>([]);
   const [allPollQuestions, setAllPollQuestions] = useState<any[]>([]);
   const [submitPoll, setSubmitPoll] = useState<boolean>(false);
   const [submitPollId, setSubmitPollId] = useState<number>();
   const [newSelectedAnswer, setNewSelectedAnswers] = useState<any>([]);
   const [activeQuizData, setActiveQuizData] = useState<any>([]);
   const [isShowPollResult, setIsShowPollResult] = useState<boolean>(false);
   const [isShowQuizResult, setIsShowQuizResult] = useState<boolean>(false);
   const [activePolls, setActivePolls] = useState<number>(0)
   const [activeQuiz, setActiveQuiz] = useState<number>(0)
   const [isPollAnswers, setIsPollAnswers] = useState<boolean>(false)
   const [quizSubmitCount, setQuizSubmitCount] = useState<any>([])
   const [activeQuizMaxTime, setActiveQuizMaxTime] = useState<number>(0)
   const [submitPollLoading, setSubmitPollLoading] = useState<boolean>(false);
   //useStates ends here


   //functions starts here
   const toggleFullscreen = () => {
      if (isFullscreen) {
         exitFullscreen();
      } else {
         enterFullscreen();
      }
   };

   const enterFullscreen = () => {
      var elem: any = document.documentElement;
      if (elem.requestFullscreen) {
         elem.requestFullscreen();
         setIsFullscreen(true);
      } else if (elem.webkitRequestFullscreen) { /* Safari */
         elem.webkitRequestFullscreen();
         setIsFullscreen(true);
      } else if (elem.msRequestFullscreen) { /* IE11 */
         elem.msRequestFullscreen();
         setIsFullscreen(true);
      }
   };

   const exitFullscreen = () => {
      var elem: any = document
      var isFullscreenEnabled = !!(elem.fullscreenElement || elem.mozFullScreenElement || elem.webkitFullscreenElement || elem.msFullscreenElement)
      if (isFullscreenEnabled) {
         if (elem.exitFullscreen) {
            elem.exitFullscreen();
            setIsFullscreen(false);
         } else if (elem.webkitExitFullscreen) { /* Safari */
            elem.webkitExitFullscreen();
            setIsFullscreen(false);
         } else if (elem.msExitFullscreen) { /* IE11 */
            elem.msExitFullscreen();
            setIsFullscreen(false);
         }
      }
      else {
         setIsFullscreen(false);
      }
   };

   const handlePublishPoll = (pollId: any) => {
      if (submitPollLoading) {
         return
      }
      setSubmitPollLoading(true)
      const selectedPollAnswers = selectedOptions.filter((answer: any) => answer.pollId === pollId);
      const selectedPollQuestion = allPollQuestions.filter((question: any) => question.pollId === pollId);

      if (selectedPollQuestion.length === selectedPollAnswers.length) {
         onChangeGuestAnswer(selectedPollAnswers, pollId);
         setSubmitPollLoading(false)
      }
      else {
         setSubmitPollId(pollId);
         setSubmitPoll(true);
         setSubmitPollLoading(false)
      }
   };

   const handleSubmitPoll = (pollId: any) => {
      const selectedPollAnswers = selectedOptions.filter((answer: any) => answer.pollId === pollId);
      onChangeGuestAnswer(selectedPollAnswers, pollId)
   }

   const handleCheckboxChange = (e: any, selectedAnswer: any) => {
      const selectedArray = selectedOptions.length > 0 ? selectedOptions : newSelectedAnswer
      const existingIndex = selectedArray?.some((answer: any, i: any) => (answer.pollId === selectedAnswer.pollId && answer.questionId === selectedAnswer.questionId && answer.questionOptionId === selectedAnswer.questionOptionId));

      let updateSelectedAnswer;
      if (existingIndex) {
         e.target.checked = false
         updateSelectedAnswer = selectedArray.filter((item: any) =>
            !(item.pollId === selectedAnswer.pollId &&
               item.questionId === selectedAnswer.questionId &&
               item.questionOptionId === selectedAnswer.questionOptionId));

      } else {
         updateSelectedAnswer = [...selectedArray.filter((item: any) =>
            !(item.pollId === selectedAnswer.pollId && item.questionId === selectedAnswer.questionId)
         ), selectedAnswer];
      }
      setNewSelectedAnswers(updateSelectedAnswer);
      setSelectedOptions(updateSelectedAnswer)
   };

   const onChangeGuestAnswer = async (newSelectedAnswer: any, pollId: any) => {
      if (submitPollLoading) {
         return
      }
      setSubmitPollLoading(true)
      addPollsQuestionsAnswerByLogInUser(currentMeetingId, newSelectedAnswer, pollId).then(
         (response) => {
            if (response.status === 201) {
               socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               socket.on('connect', () => {
                  socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               })
               toast.success(String(t('toastMessage.pollSubmitted')), {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2500,
               });
               setSubmitPollLoading(false)
            }
         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
            setSubmitPollLoading(false)
         }
      )
   }

   const refreshWaitingList = () => {
      if (!!currentMeetingId) {
         getWaitingList(currentMeetingId)
            .then((waitingList: any) => {
               dispatch(waitingListHandler(waitingList.data))
            })
            .catch((error) => {
               console.error("Error fetching waiting list:", error);
            });
      }
   }

   const deleteConfirmHandlerForPoll = (id: any) => {
      setShowDeletePoll(true)
      setMeetingPollDeleteId(id)
   }

   const deleteConfirmHandlerForQuiz = (id: any) => {
      setShowDeleteQuiz(true)
      setMeetingQuizDeleteId(id)
   }

   const handleDeleteMeetingPoll = (id: any) => {
      deleteMeetingPoll(id).then(
         (response) => {
            if (response.status === 200) {
               socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               socket.on('connect', () => {
                  socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
               })
            }
         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )
   }

   const handleDeleteMeetingQuiz = (id: any) => {
      deleteMeetingQuiz(id).then(
         (response) => {
            if (response.status === 200) {
               socket.emit("meeting-quiz-send", { meetingId: currentMeetingId })
            }
         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )
   }

   const updateQuizStatus = async (id: any, status: any, quizId: any, isAuto: boolean = false) => {
      if ((activeQuizId === 0 || activeQuizId === quizId) && id !== 0) {
         await updateMeetingQuizStatus(id, status).then(
            async (response) => {
               if (response.status === 200) {
                  if (status === INACTIVE) {
                     await updateActiveQuizId(currentMeetingId, quizId)
                     dispatch(activeQuizDataIdHandler(id))
                     dispatch(activeQuizIdHandler(quizId))
                  } else {
                     await updateActiveQuizId(currentMeetingId, 0)
                     dispatch(activeQuizDataIdHandler(0))
                     dispatch(activeQuizIdHandler(0))
                  }
                  if (!isAuto) {
                     stopTimer()
                     socket.emit("meeting-quiz-send", { meetingId: currentMeetingId, quizId: quizId, status: status })
                     socket.on('connect', () => {
                        socket.emit("meeting-quiz-send", { meetingId: currentMeetingId, quizId: quizId, status: status })
                     })
                  } else {
                     await getQuizzesByMeetingId(currentMeetingId, quizId, status)
                  }
               }
            },
            (error) => {
               const resMessage =
                  (error.response &&
                     error.response.data &&
                     error.response.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
      }
   }

   const getPollsByMeetingId = async (meetingId: number) => {
      if (!!meetingId) {
         let pollsListData = await getPollsForMeeting(meetingId)
         if (pollsListData.status === 200) {
            if (pollsListData.data.length > 0) {
               setMeetingPollsList(pollsListData.data)
               let allPollQuestions: any[] = []
               pollsListData.data.map((poll: any, i: any) => {
                  return poll.pollDetails.pollsQuestionDetails.map((question: any, j: any) => {
                     return allPollQuestions.push(question)
                  })
               })
               setAllPollQuestions(allPollQuestions)

               let groupedQuestions: any = []
               pollsListData.data.forEach((item: any) => {
                  const pollId = item.pollId;
                  const questionDetails = item.pollDetails.pollsQuestionDetails;
                  const length = questionDetails.length;

                  if (!groupedQuestions[pollId]) {
                     groupedQuestions[pollId] = [];
                  }

                  groupedQuestions[pollId].push(length);
               });
               setGroupedQuestions(groupedQuestions)

            } else {
               setMeetingPollsList([])
            }
         }
      }
   }

   const getAllQuizByMeetingId = async (meetingId: number) => {
      let quizzesListData = await getQuizzesForMeeting(meetingId)
      if (quizzesListData.status === 200) {
         dispatch(meetingQuizListHandler(quizzesListData.data?.meetingQuizData))
      }
   }

   const getQuizzesByMeetingId = async (meetingId: number, quizId: number, status?: string) => {
      if (!!meetingId) {
         let quizzesListData = await getQuizzesForMeeting(meetingId)
         if (quizzesListData.status === 200) {
            let meetingQuizData = quizzesListData.data?.meetingQuizData
            let meetingQuizzesData = quizzesListData.data?.meetingQuizzesData
            meetingQuizData.map((item: any, index: number) => {
               if (item.quizId === quizId) {
                  let activeQuiz = meetingQuizzesData[index]
                  setActiveQuizData(activeQuiz)

                  if (status === INACTIVE) {
                     setActiveQuizMaxTime(activeQuiz.maxTimeToFinish)
                     dispatch(activeQuizDataIdHandler(item.id))
                  }
               }
               return null
            })
            dispatch(meetingQuizListHandler(meetingQuizData))
         }
      }
   }

   const getPollAnswerList = async (meetingId: number) => {
      getPollsQuestionsAnswerByMeetingId(meetingId).then(
         (response) => {
            if (response.status === 200) {
               let groupedAnswers: any = []
               response.data.forEach((item: any) => {
                  const pollId = item.pollId;
                  if (!groupedAnswers[pollId]) {
                     groupedAnswers[pollId] = [];
                  }
                  groupedAnswers[pollId].push(pollId);
               });
               groupedAnswers = groupedAnswers.map((item: any) => [item.length]);
               setIsPollAnswers(true)
               setGroupedAnswers(groupedAnswers)
               setNewSelectedAnswers(response.data.map((item: any) => ({
                  pollId: item.pollId,
                  questionId: item.questionId,
                  questionOptionId: item.questionOptionId
               })))
            }

         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )
   }

   const getAllPollAnswerList = async (meetingId: number) => {
      getAllPollsQuestionsAnswerByMeetingId(meetingId).then(
         (response) => {
            if (response.status === 200) {
               setMeetingAllPollsAnswerList(response.data)
            }
         },
         (error) => {
            const resMessage =
               (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
               error.message ||
               error.toString();
            console.log("resMessage ==>", resMessage)
         }
      )
   }

   const checkOptionAnswerAvailable = (id: any) => {
      let checkIdInAnswerList = newSelectedAnswer?.findIndex((item: any) => (
         item.pollId === id.pollId &&
         item.questionId === id.questionId &&
         item.questionOptionId === id.questionOptionId
      ))
      if (checkIdInAnswerList > -1) {
         return true
      } else {
         return false
      }
   }

   const getListOfMeetingIntrestedUsers = async (meetingId: number) => {
      dispatch(getMeetingIntrestedUsersHandler(meetingId))
         .then(
            (response: any) => {
               if (response?.payload?.status === 200) {
                  dispatch(interestedListHandler(response?.payload?.data))
               }
            },
            (error: any) => {
               const resMessage =
                  (error.response?.payload &&
                     error.response.data &&
                     error.response?.payload?.data.message) ||
                  error.message ||
                  error.toString();
               console.log("resMessage ==>", resMessage)
            }
         )
   }

   const acceptUserInMeeting = (status: boolean, userId: any, guestUserId: any) => {
      const details = {
         status: status === true ? t('joinMeeting.approved') : t('joinMeeting.rejected'),
         meetingId: currentMeetingId,
         attendeeId: userId,
         guestUserId: guestUserId
      }

      updateWaitingUserStatus(details).then((res: any) => {
         if (res.data[0] === 1) {
            refreshWaitingList()
            socket.emit("waiting-user-accept-reject", { status, meetingId: currentMeetingId, attendeeId: userId ? userId : guestUserId });
         }
      }).catch((err: any) => {
         console.log(err.message);
      })
   }

   const getSubmittedQuizzes = async () => {
      await getSubmittedQuizzesByAttendee(currentMeetingId).then((res: any) => {
         dispatch(allQuizAnswerHandler(res.data))
         // setAllQuizAnswer(res.data)
      })
   }

   const getAllSubmittedQuizzesAnswers = () => {
      getAllSubmittedQuizzes(currentMeetingId).then((res: any) => {
         setAllSubmittedQuizAnswer(res?.data)
         getSubmittedQuizCount(res?.data);
      })
   }

   const handleTabSelect = (key: any) => {
      dispatch(activeTabHandler(key))
      if (Number(key) === 0) {
         setPollTab(false)
         setQuizTab(false)
      }
      else if (Number(key) === 1) {
         setPollTab(true)
         setQuizTab(false)
      }
      else if (Number(key) === 2) {
         setPollTab(false)
         setQuizTab(true)
      }
   };

   const getSubmittedQuizCount = (data: any) => {
      const groupedData = data.reduce((acc: any, item: any) => {
         const { quizId } = item;
         acc[quizId] = acc[quizId] || [];
         acc[quizId].push(item);
         return acc;
      }, {});

      setQuizSubmitCount(groupedData)
   };

   const getActiveQuiz = async () => {
      const activeQuiz: any = await getActiveQuizId(currentMeetingId)
      dispatch(activeQuizIdHandler(activeQuiz.data))
      getQuizzesByMeetingId(currentMeetingId, activeQuiz.data);
   }

   const hideAddModal = () => {
      setAddModal(false)
   }
   //functions ends here


   //useEffects starts here
   useEffect(() => {
      if (typeof currentMeetingId === 'undefined' || isFeedBackForm) {
         dispatch(feedbackFormHandler(false))
         dispatch(currentMeetingHandler(null))
         navigate("/dashboard")
      }
   }, [currentMeetingId, isFeedBackForm, navigate]);

   useEffect(() => {
      if (!!code) {
         localStorage.setItem(FILTERUSERLS, JSON.stringify(all))
      }
   }, [code])

   useEffect(() => {
      if (!!currentMeetingCode) {
         if (isHost) {
            getActiveQuiz();
         } else {
            getQuizzesByMeetingId(currentMeetingId, activeQuizIdAttendee);
         }
      }
   }, [currentMeetingCode])

   useEffect(() => {
      const pollcount = meetingPollsList.filter((ele) => ele.status === ACTIVE).length;
      const quizcount = !!meetingQuizList ? meetingQuizList.filter((ele: any) => ele.status === ACTIVE).length : 0;
      let storedPollCount: any;
      let storedQuizCount: any;
      if (localStorage.getItem(ACTIVEPOLLSCOUNTLS) !== null) {
         storedPollCount = localStorage.getItem(ACTIVEPOLLSCOUNTLS);
      } else {
         storedPollCount = 0;
      }
      if (localStorage.getItem(ACTIVEQUIZCOUNTLS) !== null) {
         storedQuizCount = localStorage.getItem(ACTIVEQUIZCOUNTLS);
      } else {
         storedQuizCount = 0;
      }
      const updatedPollCount = pollcount - storedPollCount;
      localStorage.setItem(ACTIVEPOLLSCOUNTLS, pollcount.toString());
      const updatedQuizCount = quizcount - storedQuizCount;
      localStorage.setItem(ACTIVEQUIZCOUNTLS, quizcount.toString());
      setActivePolls((prevActivePolls) => prevActivePolls + updatedPollCount);
      setActiveQuiz((prevActiveQuiz) => prevActiveQuiz + updatedQuizCount);

      if (pollTab) {
         setActivePolls(0);
      }
      if (quizTab) {
         setActiveQuiz(0);

      }
   }, [meetingPollsList, pollTab, meetingQuizList, quizTab]);

   useEffect(() => {
      if (isHost && waitingList?.length === 0) {
         dispatch(waitingListModalHandler(false))
      }
   }, [waitingList]);

   useEffect(() => {
      if (!!currentMeetingId) {
         getSubmittedQuizzes()
         getAllSubmittedQuizzesAnswers()
      }
   }, [isShowQuizResult])

   useEffect(() => {

      socket.on('join-meeting-res', (data) => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         const userIdSocket = state.auth?.user?.user?.id
         if (!!currentMeetingIdSocket && userIdSocket === data?.user?.id) {
            getPollsByMeetingId(currentMeetingIdSocket)
            getPollAnswerList(currentMeetingIdSocket)
            getAllPollAnswerList(currentMeetingIdSocket)
            getListOfMeetingIntrestedUsers(currentMeetingIdSocket)
            getAllQuizByMeetingId(currentMeetingIdSocket)
         }
      })

      socket.on("meeting-polls-res", async () => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         if (!!currentMeetingIdSocket) {
            getPollsByMeetingId(currentMeetingIdSocket)
            getPollAnswerList(currentMeetingIdSocket)
            getAllPollAnswerList(currentMeetingIdSocket)
         }
      })

      socket.on("meeting-quiz-res", async (data: any) => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         if (!!currentMeetingIdSocket) {
            if (isHost) {
               getQuizzesByMeetingId(currentMeetingIdSocket, data.quizId, data.status)
            } else {
               if (!!data.quizId) {

                  resetQuizTimer()
                  getQuizzesByMeetingId(currentMeetingIdSocket, data.quizId);
                  dispatch(activeQuizIdAttendeeHandler(data.quizId))
                  let allQuizAnswers: any[] = []
                  await getSubmittedQuizzesByAttendee(currentMeetingIdSocket).then((res: any) => {
                     allQuizAnswers = res.data
                  })
                  const isNewQuiz = allQuizAnswers?.every((submittedQuiz) => {
                     return data.quizId !== submittedQuiz.quizId
                  });
                  if (isNewQuiz) {
                     setIsShowQuizResult(false)
                  }
               }
            }
         }
      })

      socket.on("intrested-user-res", async () => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         if (!!currentMeetingIdSocket) {
            getListOfMeetingIntrestedUsers(currentMeetingIdSocket)
         }
      })

      socket.on('exit-waiting-list-res', async (data: any) => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
         const userIdSocket = state.auth?.user?.user?.id
         if (userIdSocket === data.hostId && currentMeetingIdSocket === data.meetingId) {
            refreshWaitingList()
         }
      });

      socket.on("quiz-submitted-users-res", async () => {
         const state = store.getState()
         const currentMeetingIdSocket = state.meeting?.currentMeeting?.id

         if (!!currentMeetingIdSocket && isHost) {
            setTimeout(async () => {
               await getAllSubmittedQuizzes(currentMeetingId).then((res: any) => {
                  getSubmittedQuizCount(res?.data);
               })
            }, 1000)

         }
      })

      return (() => {
         socket.off('join-meeting-res')
         socket.off('meeting-polls-res')
         socket.off('meeting-quiz-res')
         socket.off('intrested-user-res')
         socket.off('exit-waiting-list-res')
         socket.off('quiz-submitted-users-res')
      })

   }, [currentMeeting])
   //useEffects ends here


   //components starts here
   const PollHeader = () => {
      return (
         <div className="hstack flex-wrap justify-content-between">
            {isFullscreen && <div className="font-20 fw-semibold">Poll Results</div>}
            <div className="hstack gap-2 flex-fill justify-content-end">
               {
                  ((meetingPollsList.length > 0 && meetingPollsList.some((poll: any) => poll?.status === INACTIVE)) || isHost) &&
                  <button
                     className="btn btn-light text-nowrap rounded-45 mw-120 btn-light-reset-hover"
                     onClick={() => {
                        setIsShowPollResult(!isShowPollResult);
                        { isHost && toggleFullscreen(); }
                     }}
                  >
                     {isShowPollResult ? t('pollDetails.btnHideResult') : t('pollDetails.btnShowResult')}
                  </button>
               }

               {
                  isHost && !isFullscreen && (
                     <div className="hstack gap-2 justify-content-end">
                        <button
                           title={t('joinMeeting.addPoll')}
                           // onClick={getPollList}
                           onClick={() => { setAddModalType(1); setAddModal(true) }}
                           className="btn btn-primary rounded-45">
                           <i className="fa-solid fa-plus"></i> <span className='d-none d-md-inline'>{t('joinMeeting.addPoll')}</span>
                        </button>
                     </div>
                  )
               }
            </div>
         </div >
      )
   }

   const QuizHeader = () => {
      return (
         <div className="hstack flex-wrap justify-content-between">

            {isFullscreen && <div className="font-20 fw-semibold">Quiz Results</div>}

            {isHost ?
               <div className="hstack gap-2 flex-fill justify-content-end">
                  {meetingQuizList?.length > 0 &&
                     meetingQuizList.some((quiz: any) => quiz?.status === INACTIVE) &&
                     <button
                        className="btn btn-light text-nowrap rounded-45 mw-120 btn-light-reset-hover"
                        onClick={() => {
                           setIsShowQuizResult(!isShowQuizResult);
                           toggleFullscreen()
                        }}
                     >
                        {isShowQuizResult
                           ? t('quizDetails.btnHideResult')
                           : t('quizDetails.btnShowResult')}
                     </button>

                  }
                  {!isFullscreen &&
                     <button
                        title={t('joinMeeting.addQuiz')}
                        // onClick={getQuizList}
                        onClick={() => { setAddModalType(2); setAddModal(true) }}
                        className="btn btn-primary rounded-45"
                     >
                        <i className="fa-solid fa-plus"></i>{' '}
                        <span className="d-none d-md-inline">
                           {t('joinMeeting.addQuiz')}
                        </span>
                     </button>
                  }
               </div>

               :
               <>
                  {
                     allQuizAnswer?.length > 0 &&
                     !meetingQuizList?.some((quiz: any) => quiz.status === ACTIVE &&
                        !allQuizAnswer.some((submittedQuiz: any) => submittedQuiz.quizId === quiz.quizId)) &&
                     <div className="hstack gap-2 flex-fill justify-content-end">
                        <button
                           className="btn btn-light text-nowrap rounded-45 mw-120 btn-light-reset-hover"
                           onClick={() => setIsShowQuizResult(!isShowQuizResult)}>
                           {isShowQuizResult ? "Hide Result" : t('quizDetails.btnShowResult')}
                        </button>
                     </div>
                  }
               </>
            }
         </div>
      );
   };

   const stopTimer = async () => {
      localStorage.removeItem(HOSTQUIZTIMELS);
      hostSpentQuizTimeRef.current = null
      setActiveQuizMaxTime(0)
   };

   const QuizHostTimer = () => {
      const [time, setTime] = useState<number>(0)

      const startTimer = () => {
         const spentQuizTime: any = localStorage.getItem(HOSTQUIZTIMELS);
         let time = typeof spentQuizTime === 'object' && !!activeQuizMaxTime ? activeQuizMaxTime + 1 : Number(spentQuizTime)
         hostSpentQuizTimeRef.current = time
      };

      const endQuiz = async () => {
         stopTimer()
         await updateQuizStatus(activeQuizDataId, ACTIVE, activeQuizId, true)

         toast.success(String(t('quiz.quizComplete')), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
         })
      }

      useEffect(() => {
         startTimer()
      }, []);

      useEffect(() => {
         if (!!hostSpentQuizTimeRef.current) {
            let intervalId = setInterval(() => {
               if (hostSpentQuizTimeRef.current === 0) {
                  clearInterval(intervalId);
                  endQuiz()
               }
               else {
                  hostSpentQuizTimeRef.current = Number(hostSpentQuizTimeRef.current) - 1
                  localStorage.setItem(HOSTQUIZTIMELS, Number(hostSpentQuizTimeRef.current).toString());
                  setTime(hostSpentQuizTimeRef.current)
               }
            }, 1000);

            return () => clearInterval(intervalId);
         }
      }, []);


      return hostSpentQuizTimeRef.current > 0 ?
         <div className={hostSpentQuizTimeRef.current <= 30 ? 'heartbeat' : ''} style={{ minWidth: 70 }}>{secondToHMS(hostSpentQuizTimeRef.current)}</div>
         :
         <Spinner animation="border" size="sm" />
   }

   const QuizHost = () => {
      return (
         <>
            {meetingQuizList?.length === 0 &&
               <div className="status-block status-block-nodata"> {t('quizDetails.DataNotFound')}</div>
            }
            {!!meetingQuizList &&
               meetingQuizList.length > 0 &&
               meetingQuizList.map((quizValue: any) => {
                  return (
                     <div className="card" key={`quizhost_${quizValue.id}`}>
                        <div className='card-body section-question-container'>
                           <div className='section-question-action-header flex-wrap'>
                              <div className="fw-medium">{quizValue.quiz.title}</div>
                              {!isShowQuizResult &&
                                 <div className='hstack gap-2 flex-fill justify-content-end'>
                                    {!!quizSubmitCount && !!quizSubmitCount[quizValue.quizId]?.length &&
                                       <div className='me-2'>
                                          {quizSubmitCount[quizValue.quizId]?.length === 1
                                             ? '1 person has submitted this quiz'
                                             : `${quizSubmitCount[quizValue.quizId]?.length} people have submitted this quiz`
                                          }
                                       </div>
                                    }
                                    <button className="btn btn-primary rounded-45" disabled={activeQuizId !== 0 && activeQuizId !== quizValue.quizId} onClick={() => updateQuizStatus(quizValue.id, quizValue.status, quizValue.quizId)}>{quizValue.status === INACTIVE ? ACTIVE1 : END} Quiz</button>
                                    {quizValue.status === INACTIVE &&
                                       <button className="btn btn-danger rounded-circle size-38 text-white" title={t('common.delete')} onClick={() => deleteConfirmHandlerForQuiz(quizValue.id)}>
                                          <i className="fa-solid fa-trash"></i>
                                       </button>
                                    }
                                    {quizValue.status === ACTIVE &&
                                       <QuizHostTimer />
                                    }
                                 </div>
                              }
                           </div>
                           {!!quizValue.quiz.questions &&
                              quizValue.quiz.questions.length > 0 &&
                              quizValue.quiz.questions.map((quizQuestionValue: any) => {
                                 const questionOptions = quizQuestionValue?.question?.questionOptions || []
                                 const isImagePickerType = quizQuestionValue?.question?.type === IMAGEPICKER
                                 return (
                                    <div className='section-question-block' key={'quizhost_question' + quizQuestionValue.question.id}>
                                       <div className="section-question">
                                          <div className="section-question-header">
                                             <div className="fw-medium"> {quizQuestionValue.question.title}</div>
                                          </div>

                                          <ul className={`section-option-block ${isShowQuizResult ? 'list-unstyled' : ''} ${isImagePickerType ? 'list-unstyled row flex-row gap-0 g-4' : ''}`}>
                                             {questionOptions.map((questionOptionsValue: any) => {
                                                return (
                                                   <li key={'quizhost_question_option' + questionOptionsValue.id} className={`section-option ${isImagePickerType ? 'col-xxl-3 col-lg-4 col-sm-6' : ''}`}>
                                                      {
                                                         isImagePickerType ?
                                                            <img style={{ maxHeight: 266 }} className='w-100 h-100 object-fit-cover rounded-1' src={questionOptionsValue.optionImageUrl} alt='optionImage' />
                                                            :
                                                            <> {questionOptionsValue.optionName}</>
                                                      }
                                                   </li>
                                                )
                                             })}
                                          </ul>
                                       </div>
                                    </div>
                                 )
                              })
                           }
                        </div>
                     </div>
                  )
               })
            }
         </>
      )
   }
   //components ends here

   return (
      <>
         {isFullscreen && isHost ?
            <div className="section-tab">
               {isShowPollResult &&
                  <div className='vstack gap-4'>
                     <PollHeader />

                     {meetingPollsList.length === 0 &&
                        <div className="status-block status-block-nodata"> {t('pollDetails.DataNotFound')}</div>
                     }
                     <ShowPollResult
                        meetingPollsList={meetingPollsList}
                        meetingAllPollsAnswerList={meetingAllPollsAnswerList}
                        newSelectedAnswer={newSelectedAnswer}
                        isShowPollResult={isShowPollResult}
                        isHost={true}
                        deleteConfirmHandlerForPoll={deleteConfirmHandlerForPoll}
                     />
                  </div>
               }
               {isShowQuizResult &&
                  <div className='vstack gap-4'>
                     <QuizHeader />
                     <QuizHostResult allSubmittedQuizAnswer={allSubmittedQuizAnswer} />
                  </div>
               }
            </div>
            :
            <div className="h-100 container-fluid">
               <div className="h-100 row">
                  <ShareMeetingContainer />
                  <div className="col-xl-8 ">
                     <div className="h-100 vstack custom-tab">
                        <ShareMeetingMobile />
                        <Tabs
                           defaultActiveKey={0}
                           onSelect={(key: any) => handleTabSelect(key)}
                           variant="pills"
                        >
                           <Tab eventKey={0} title={t('common.question')}>
                              <QuestionTab />
                           </Tab>
                           <Tab eventKey={1} title={
                              <div className="hstack justify-content-center">
                                 <span className="me-2">{t('common.Poll')}</span>
                                 {!isHost && (
                                    <span className="position-relative translate-middle badge rounded-pill badge-notification bg-danger fs-9 ms-1">
                                       {activePolls > 0 ? activePolls : ""}
                                    </span>
                                 )}
                              </div>
                           }>
                              <div className="section-tab position-relative h-100">
                                 <PollHeader />
                                 <div className='polls-card'>
                                    <div className="vstack gap-3">

                                       {meetingPollsList.length === 0 &&
                                          <div className="status-block status-block-nodata"> {t('pollDetails.DataNotFound')}</div>
                                       }
                                       {isHost ?
                                          <ShowPollResult
                                             meetingPollsList={meetingPollsList}
                                             meetingAllPollsAnswerList={meetingAllPollsAnswerList}
                                             isShowPollResult={isShowPollResult}
                                             isHost={true}
                                             deleteConfirmHandlerForPoll={deleteConfirmHandlerForPoll}
                                          />

                                          :

                                          isShowPollResult ?

                                             <ShowPollResult
                                                meetingPollsList={meetingPollsList}
                                                meetingAllPollsAnswerList={meetingAllPollsAnswerList}
                                                newSelectedAnswer={newSelectedAnswer}
                                                isShowPollResult={isShowPollResult}
                                                deleteConfirmHandlerForPoll={deleteConfirmHandlerForPoll}
                                             />

                                             :
                                             <>
                                                {isPollAnswers &&
                                                   <ActivePolls
                                                      meetingPollsList={meetingPollsList}
                                                      GroupedQuestions={GroupedQuestions}
                                                      GroupedAnswers={GroupedAnswers}
                                                      handlePublishPoll={handlePublishPoll}
                                                      isPollLoading={submitPollLoading}
                                                      handleCheckboxChange={handleCheckboxChange}
                                                      checkOptionAnswerAvailable={checkOptionAnswerAvailable}
                                                   />
                                                }
                                             </>
                                       }
                                    </div>
                                 </div>
                              </div>
                           </Tab>
                           <Tab eventKey={2} title={
                              <div className="hstack justify-content-center">
                                 <span className="me-2">{t('common.Quiz')}</span>
                                 {!isHost && (
                                    <span className="position-relative translate-middle badge rounded-pill badge-notification bg-danger fs-9 ms-1">
                                       {activeQuiz > 0 ? activeQuiz : ""}
                                    </span>
                                 )}
                              </div>
                           } >
                              <div className="section-tab position-relative h-100">
                                 <QuizHeader />
                                 <div className="quiz-card">
                                    <div className="vstack gap-3">
                                       {isHost ?
                                          <QuizHost />

                                          :
                                          isShowQuizResult ?
                                             <ShowQuizResult allQuizAnswer={allQuizAnswer} />

                                             :
                                             <ActiveQuiz
                                                meetingQuizList={meetingQuizList}
                                                activeQuizData={activeQuizData}
                                                currentMeetingId={currentMeetingId}
                                                allQuizAnswer={allQuizAnswer}
                                                getSubmittedQuizzes={getSubmittedQuizzes}
                                             />
                                       }
                                    </div>
                                 </div>
                              </div>
                           </Tab>
                        </Tabs>
                     </div>
                  </div>
               </div>
            </div>
         }

         {/* modals starts here */}
         <InterestedListModal />
         <WaitingListModal acceptUserInMeeting={acceptUserInMeeting} />

         <AddPollQuizModal
            addModalType={addModalType}
            addModal={addModal}
            hideAddModal={hideAddModal}
            currentMeetingId={currentMeetingId}
            userId={userId}
            meetingQuizList={meetingQuizList}
            meetingPollsList={meetingPollsList}
         />
         {/* modals ends here */}

         {/* alerts actions starts heres */}
         <DialogBox show={showDeletePoll} clickOk={() => { handleDeleteMeetingPoll(meetingPollDeleteId); setShowDeletePoll(false) }} clickCancel={() => { setShowDeletePoll(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltPoll')} />
         <DialogBox show={showDeleteQuiz} clickOk={() => { handleDeleteMeetingQuiz(meetingQuizDeleteId); setShowDeleteQuiz(false) }} clickCancel={() => { setShowDeleteQuiz(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltQuiz')} />
         <DialogBox show={submitPoll} clickOk={() => { handleSubmitPoll(submitPollId); setSubmitPoll(false) }} clickCancel={() => { setSubmitPoll(false) }} btncancel={t('deleteBox.btnNo')} btnyes={t('deleteBox.btnYes')} question={t('deleteBox.submitPollQuestion')} />
         {/* alerts actions ends heres */}
      </>
   )
}
export default JoinMeeting;