import React, { FC } from 'react'
import { useTranslation } from 'react-i18next';

interface NearbyMeetingsProps {
    nearByMeetings: any[]
    nearMeetingJoin: (code: string) => void
}
const NearbyMeetings: FC<NearbyMeetingsProps> = ({ nearByMeetings, nearMeetingJoin }) => {
    const { t } = useTranslation('common');

    return (
        <ul className='list-unstyled vstack mb-0'>
            {!!nearByMeetings &&
                nearByMeetings.length > 0 &&
                nearByMeetings.map((nearMeeting, index) => (
                    <li key={nearMeeting.uuid} className={`hstack justify-content-between gap-3 py-3 ${index > 0 ? 'border-top' : ''}`}>
                        <div className='hstack justify-content-between flex-fill gap-3'>
                            <div className='flex-fill'>{nearMeeting.title}</div>
                            <div className='text-nowrap'>{nearMeeting.code}</div>
                        </div>
                        <button className='btn btn-sm btn-primary rounded-pill text-nowrap' onClick={() => nearMeetingJoin(nearMeeting.code)} type='button'>{t('common.btnJoinMeeting')}</button>
                    </li>
                ))
            }
        </ul>
    )
}

export default NearbyMeetings