import React, { FC } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ShowPollResult from '../Poll/ShowPollResult'
import QuizHostResult from '../Quiz/QuizHostResult'
import ConversationList from '../../JoinMeeting/common/ConversationList'
interface MeetingDetailsModalProps {
    meetingDetailsModal: boolean
    hideMeetingDetailsModal: () => void
    pastMeetingDetails: any
    pastMeetingDetailsQuestion: any
    btnMeetingQuestionSendViaEmail: (id: number) => void
    userId: number,
    meetingPollsList: any
    meetingAllPollsAnswerList: any
    allSubmittedQuizAnswer: any
}
const MeetingDetailsModal: FC<MeetingDetailsModalProps> = ({
    meetingDetailsModal,
    hideMeetingDetailsModal,
    pastMeetingDetails,
    pastMeetingDetailsQuestion,
    btnMeetingQuestionSendViaEmail,
    userId,
    meetingPollsList,
    meetingAllPollsAnswerList,
    allSubmittedQuizAnswer
}) => {
    const { t } = useTranslation("common")

    return (
        <Modal centered scrollable show={meetingDetailsModal} onHide={hideMeetingDetailsModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('pastMeetingDetails.header')} {pastMeetingDetails.title} - {pastMeetingDetails.code}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="custom-tab">
                    <button
                        className="ms-auto btn btn-outline-primary hstack gap-2 mb-3"
                        onClick={() => btnMeetingQuestionSendViaEmail(pastMeetingDetails.id)}
                        title={t('pastMeetingDetails.btnSendEmail')}
                    >
                        <i className="fas fa-envelope"></i>
                        <span className="d-none d-sm-inline text-nowrap">{t('pastMeetingDetails.btnSendEmail')}</span>
                    </button>

                    <Tabs variant="pills" defaultActiveKey={0}>
                        <Tab eventKey={0} title={t('pastMeetingDetails.btnQuestion')}>
                            <div className="section-tab">
                                <ConversationList
                                    isHistory={true}
                                    isHost={true}
                                    isGuest={false}
                                    userId={userId}
                                    meetingQuestions={pastMeetingDetailsQuestion}
                                    currentMeetingHostId={userId}
                                    guestUserId={undefined}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey={1} title={t('common.Poll')}>
                            <div className="section-tab gap-4">
                                <ShowPollResult
                                    meetingPollsList={meetingPollsList}
                                    meetingAllPollsAnswerList={meetingAllPollsAnswerList}
                                    isMeetingDetails={true}
                                />
                            </div>
                        </Tab>
                        <Tab eventKey={2} title={t('common.Quiz')} >
                            <div className="section-tab">
                                <QuizHostResult allSubmittedQuizAnswer={allSubmittedQuizAnswer} />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default MeetingDetailsModal