/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, useParams } from "react-router-dom";
import { IPoll } from "../../types/poll.type";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { pollCreateHandler, pollGetByIdHandler, pollUpdateByIdHandler, selectedQuestionHandler } from "../store/slices/pollSlice";
import * as ACTION from "../store/actions/index";
import { DropArea } from "../components/DragAndDrop/DropArea";
import SelectedQuestionList from "../components/ManageQuestions/SelectedQuestionList";
import QuestionContainer from "../components/ManageQuestions/QuestionContainer";
import { CustomDragLayer } from "../components/DragAndDrop/CustomDragLayer";

const PollDetails = () => {

  //single hooks starts here
  const dispatch = useDispatch()
  let navigate = useNavigate();
  const { t } = useTranslation("common");
  let { pollId } = useParams();
  //single hooks ends here


  //useSelectors starts here
  const { pollMessage, selectedQuestion, pollSuccessful: successful } = useSelector((state: RootState) => state.poll)
  const { questionmessage } = useSelector((state: RootState) => state.question)
  //useSelectors ends here


  //useRef starts here
  const questionContainerRef = useRef<HTMLDivElement | null>(null)
  //useRef ends here


  //custom variables starts here
  const createMode = !!pollId && pollId === "0" ? true : false
  //custom variables ends here


  //useStates starts here
  const initialValues: IPoll = {
    title: "",
  };
  const [currentPoll, setPollData] = useState(initialValues);
  const [pollMeetings, setPollMeetings] = useState<any[]>([]);
  //useStates ends here


  //formik starts here
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(t("validation.requiredField"))
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          return originalValue.trim();
        }
        return value;
      })
      .matches(/^[a-zA-Z0-9]/, t("validation.specialcharacterPollTitle"))
      .strict(true)
      .test(
        "len",
        t('validation.minlengthTitle'),
        (val: any) =>
          val &&
          val.toString().length >= 3
      )
      .test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val &&
          val.trim() !== ''
      ),
  });
  //formik ends here


  //functions starts here
  const handlePollCreate = async (formValue: IPoll) => {
    let { title } = formValue;
    title = title.trim();
    if (!createMode) {
      const data = {
        ID: pollId, title, selectedQuestion
      }
      await dispatch(pollUpdateByIdHandler(data)).then((res: any) => {
        if (res?.type === `${ACTION.POLLUPDATE}/${ACTION.FULFILLED}`) {
          toast.success(String(t("toastMessage.pollUpdate")), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });

          navigate("/poll");
        }
        else if (res.type === `${ACTION.POLLUPDATE}/${ACTION.REJECTED}`) {
          toast.error(String(t("toastMessage.pollAlredyExist")), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });

        }
      })
    } else {
      const formValue = {
        title, selectedQuestion
      }
      await dispatch(pollCreateHandler(formValue)).then((res: any) => {
        if (res?.type === `${ACTION.CREATEPOLL}/${ACTION.FULFILLED}`) {
          toast.success(String(t("toastMessage.pollCreate")), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
          navigate("/poll");
        } else {
          toast.error(pollMessage)
        }
      })
    }
  };

  const getPollDetailByPollCode = async (pollId: string) => {
    let pollDetails = await dispatch(pollGetByIdHandler(pollId))
    if (pollDetails?.payload?.status === 200) {
      setPollData(pollDetails?.payload?.data);
      const data = pollDetails?.payload?.data
      setPollMeetings(data?.pollsMeetingDetails)
      let questionData: any = [];
      pollDetails.payload?.data.pollsQuestionDetails.forEach((questionValue: any) => {
        questionData.push(questionValue.questionDetails);
      });
      dispatch(selectedQuestionHandler(questionData))
    }
  };
  //functions ends here


  //useEffects starts here
  useEffect(() => {
    if (!!pollId && !createMode) {
      getPollDetailByPollCode(pollId);
    }
  }, [pollId]);

  useEffect(() => {
    return (() => {
      if (!!pollId && createMode) {
        dispatch(selectedQuestionHandler([]))
      }
    })
  }, []);
  //useEffects ends here

  return (
    <div className="container-fluid">
      <h2 className="font-26 fw-semibold text-decoration-none text-dark mb-4">{createMode ? t("poll.btnCreatePoll") : t("pollDetails.title")}</h2>
      <div className="row g-4">
        <div className="col-md-7">
          <div className="card border-0 h-100">
            <Formik
              initialValues={currentPoll}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handlePollCreate}>
              <Form>
                <div className="card-body">
                  <div className="d-flex flex-wrap justify-content-end">
                    <div className="hstack gap-2 mb-2">
                      <Link
                        to="/poll"
                        className="btn btn-outline-danger rounded-45 mw-120">
                        {t("common.cancel")}
                      </Link>
                      <button
                        className="btn btn-primary rounded-45 mw-120"
                        type="submit">
                        {createMode
                          ? t("pollDetails.btnSave")
                          : t("common.update")}
                      </button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="poll-title" className="form-label">
                      {t("pollDetails.pollTitle")}
                    </label>
                    <Field
                      type="text"
                      name="title"
                      className="form-control"
                      id="poll-title"
                      placeholder={t(
                        "pollDetails.placeholderPollTitle"
                      )}></Field>
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger"
                    />
                    {questionmessage && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger p-2 rounded-0 border-0"
                          }
                          role="alert">
                          {questionmessage}
                        </div>
                      </div>
                    )}
                  </div>
                  {!createMode && !!pollMeetings && pollMeetings.length > 0 &&
                    <div className="mb-3">
                      <label className="form-label fw-medium font-20">
                        {t("pollDetails.attachedTitle")}
                      </label>
                      <div className="d-flex flex-wrap align-items-center gap-2">
                        {!!pollMeetings &&
                          pollMeetings.length > 0 &&
                          pollMeetings.map((meetingData: any) => {
                            return (
                              <div
                                className="badge bg-primary-light text-primary rounded-pill badge-pill py-2 fw-medium"
                                key={"meeting_" + meetingData.meetingId}>
                                {meetingData?.meetingDetails?.code}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  }
                </div>
              </Form>
            </Formik>

            <DropArea />
            <CustomDragLayer questionContainerRef={questionContainerRef} />
            <SelectedQuestionList meetingsLength={pollMeetings.length} />

          </div>
        </div>
        <div className="col-md-5" ref={questionContainerRef}>
          <QuestionContainer />
        </div>
      </div>
    </div>
  );
};

export default PollDetails;
