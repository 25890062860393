import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { FC } from 'react'
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IMeetingSendQuestionReply } from '../../../types/meeting.type';
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { addMeetingQuestionReplyHandler } from '../../store/slices/joinMeetingSlice';
import socket from '../../../common/constants';

interface QuestionReplyModalProps {
    replyModal: boolean
    hideReplyModal: () => void,
    replyQuestionId: number,
    currentMeetingId: number
}
const QuestionReplyModal: FC<QuestionReplyModalProps> = ({
    replyModal,
    hideReplyModal,
    replyQuestionId,
    currentMeetingId

}) => {
    //single hooks starts here
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    //single hooks ends here


    //formik starts here
    const initialValuesSendQuestionReply: IMeetingSendQuestionReply = {
        questionReply: ""
    }

    const validationSchemaSendQuestionReply = Yup.object().shape({
        questionReply: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
    });
    //formik ends here


    //functions starts here
    const handleSendQuestionReply = (formValue: IMeetingSendQuestionReply, onSubmitProps: any) => {
        const { questionReply } = formValue;
        const data = { questionReply: questionReply.trim(), meetingQuestionId: replyQuestionId }
        dispatch(addMeetingQuestionReplyHandler(data)).then(
            (response: any) => {
                if (response?.payload?.status === 201) {
                    hideReplyModal()
                    onSubmitProps.resetForm(initialValuesSendQuestionReply);
                    socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                    socket.on('connect', () => {
                        socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                    })
                }
            },
            (error: any) => {
                const resMessage =
                    (error.response.payload &&
                        error.response.payload?.data &&
                        error.response.payload?.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    };
    //functions ends here
    return (
        <Modal show={replyModal} centered onHide={hideReplyModal}>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='position-relative fw-semibold'>
                    {t('questionReply.title')}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValuesSendQuestionReply}
                validationSchema={validationSchemaSendQuestionReply}
                onSubmit={handleSendQuestionReply}
                enableReinitialize={false}
            >
                <Form>
                    <Modal.Body>
                        <Field as="textarea" name="questionReply" className="form-control" id="reply" placeholder={t('questionReply.ansPlaceHolder')}
                            onKeyDown={(e: any) => {
                                if (e.ctrlKey && e.key === 'Enter') {
                                    e.currentTarget.form?.requestSubmit();
                                }
                            }}>
                        </Field>
                        <ErrorMessage name="questionReply" component="div" className="text-danger" />
                    </Modal.Body>
                    <Modal.Footer className='border-0'>
                        <button type='submit' className="btn btn-primary rounded-45 mw-120">{t('questionReply.addAnswer')}</button>
                    </Modal.Footer>
                </Form>
            </Formik>
        </Modal>
    )
}

export default QuestionReplyModal