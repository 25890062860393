import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ACTIVE } from '../../../types/commonTypes'


interface ActivePollsProps {
    meetingPollsList: any[]
    GroupedQuestions: any[]
    GroupedAnswers: any[]
    handlePublishPoll: (id: any) => void
    isPollLoading:boolean
    handleCheckboxChange: (e: any, { pollId, questionId, questionOptionId }: { pollId: number, questionId: number, questionOptionId: number }) => void
    checkOptionAnswerAvailable: ({ pollId, questionId, questionOptionId }: { pollId: number, questionId: number, questionOptionId: number }) => boolean
}
const ActivePolls: FC<ActivePollsProps> = ({ meetingPollsList, GroupedQuestions, GroupedAnswers, handlePublishPoll, handleCheckboxChange, checkOptionAnswerAvailable,isPollLoading }) => {
    const { t } = useTranslation('common');

    return (
        <>
            {!!meetingPollsList &&
                meetingPollsList?.length > 0 &&
                meetingPollsList?.map((pollValue: any, i: any) => {
                    if (pollValue.status === ACTIVE) {
                        var pollId = pollValue.pollDetails.id
                        return (
                            <div className="card" key={`poll_user_${pollValue.pollDetails.id}_${i}}`}>
                                <div className='card-body section-question-container'>

                                    {GroupedQuestions[pollId]?.[0] !== GroupedAnswers[pollId]?.[0] ?
                                        <>
                                            <div className="section-question-action-header">
                                                <div className="font-20 fw-semibold">{pollValue.pollDetails.title}</div>
                                                <button className="btn btn-dark text-nowrap rounded-45" title={t('pollDetails.titlePublishPoll')} onClick={() => handlePublishPoll(pollId)} disabled={isPollLoading}>{t('pollDetails.btnSubmitPoll')}</button>
                                            </div>


                                            {!!pollValue.pollDetails.pollsQuestionDetails &&
                                                pollValue.pollDetails.pollsQuestionDetails.length > 0 &&
                                                pollValue.pollDetails?.pollsQuestionDetails?.map((pollsQuestionValue: any, j: any) => {
                                                    var questionId = pollsQuestionValue.questionDetails.id
                                                    return (
                                                        <div className='section-question-block' key={`poll_question_${pollValue.pollDetails.id}_${j}}`}>
                                                            <div className="section-question">
                                                                <div className="section-question-header">
                                                                    <div className="fw-medium">{pollsQuestionValue.questionDetails.title}</div>
                                                                </div>
                                                                <ul className='section-option-block list-unstyled'>
                                                                    {
                                                                        !!pollsQuestionValue.questionDetails.questionOptions &&
                                                                        pollsQuestionValue.questionDetails.questionOptions.length > 0 &&
                                                                        pollsQuestionValue.questionDetails.questionOptions.map((questionOptionsValue: any, k: any) => {
                                                                            var questionOptionId = questionOptionsValue.id
                                                                            return (
                                                                                <li className="section-option custom-radio" key={`question-radio_${pollId}_${questionId}_${questionOptionId}`}>
                                                                                    <input type="radio"
                                                                                        name={`poll_que_${pollId}_${questionId}`}
                                                                                        id={`poll_${pollId}_${questionId}_${questionOptionId}`}
                                                                                        onClick={(e) => handleCheckboxChange(e, { pollId, questionId, questionOptionId })}
                                                                                        defaultChecked={checkOptionAnswerAvailable({ pollId, questionId, questionOptionId })}
                                                                                    />
                                                                                    <label htmlFor={`poll_${pollId}_${questionId}_${questionOptionId}`}>
                                                                                        <span className='label-text'>{questionOptionsValue.optionName}</span>
                                                                                    </label>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>

                                        :
                                        <div className='hstack justify-content-between gap-3'>
                                            <div className="font-20 fw-semibold">{pollValue.pollDetails.title}</div>
                                            <div>{t('pollDetails.pollSubmitted')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                    return null;
                })
            }
            {!!meetingPollsList &&
                meetingPollsList.length > 0 &&
                !meetingPollsList.find((pollValue: any) => pollValue?.status === ACTIVE) &&
                <div className="status-block status-block-nodata"> {t('pollDetails.DataNotFound')}</div>
            }
        </>
    )
}

export default ActivePolls