import React, { FC, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import { CREATED, ENDED, INPROGRESS, STARTED } from '../../types/commonTypes';

interface MeetingListProps {
    meetingList: any[]
    meetingEdit: any;
    shareMeeting: any;
    startMeetingHandler: any;
    joinMeetingHandler: any;
    getMeetingDetails: any;
    btnMeetingQuestionSendViaEmail: any
    deleteConfirmHandler: (id: number) => void
    endConfirmHandler: (id: number) => void
    sortColumn: any
    setSortColumn: any
}

const MeetingList: FC<MeetingListProps> = ({
    meetingList,
    meetingEdit,
    shareMeeting,
    startMeetingHandler,
    joinMeetingHandler,
    getMeetingDetails,
    btnMeetingQuestionSendViaEmail,
    deleteConfirmHandler,
    endConfirmHandler,
    sortColumn,
    setSortColumn,
}) => {

    const { t } = useTranslation('common');

    const [sortableCols, setSortableCols] = useState([
        {
            order: false,
            col: 'title'
        },
        {
            order: false,
            col: 'createdAt'
        },
        {
            order: false,
            col: 'startTime'
        },
        {
            order: false,
            col: 'endedAt'
        },
    ])


    const onSort = (index: number) => {
        setSortableCols((prevSortableCols) => {
            const updateCols = [...prevSortableCols]
            updateCols[index].order = !updateCols[index].order
            return updateCols
        })
        setSortColumn({ order: sortableCols[index].order, col: sortableCols[index].col })
    }

    interface ActionProps {
        id: number
        status: string
    }
    const Actions: FC<ActionProps> = ({ id, status }) => {
        if (status === CREATED) {
            return (
                <div className='d-flex justify-content-end gap-3'>
                    <button
                        onClick={() => meetingEdit(id)}
                        title="edit meeting"
                        aria-label="edit meeting"
                        className="btn btn-primary-light text-primary rounded-circle size-38"
                    >
                        <i className="fa-solid fa-pen"></i>
                    </button>
                    <button
                        onClick={() => deleteConfirmHandler(id)}
                        title="delete meeting"
                        aria-label="delete meeting"
                        className="btn btn-primary-light text-primary rounded-circle size-38"
                    >
                        <i className="fa-solid fa-trash"></i>
                    </button>
                    <button
                        className="btn btn-primary-light text-primary rounded-circle size-38"
                        onClick={() => shareMeeting(id)}
                        title='share meeting'
                        aria-label="share meeting"
                    >
                        <i className="fa-solid fa-share-nodes"></i>
                    </button>
                    <button
                        onClick={() => startMeetingHandler(id, INPROGRESS)}
                        className="btn btn-primary text-nowrap mw-120 text-nowrap"
                    >
                        {t('myMeeting.btnStartMeeting')}
                    </button>
                </div>
            );
        } else if (status === STARTED || status === INPROGRESS) {
            return (
                <div className='d-flex justify-content-end gap-3'>
                    <button
                        className="btn btn-primary-light text-primary rounded-circle size-38"
                        onClick={() => shareMeeting(id)}
                        title='share meeting'
                        aria-label="share meeting"
                    >
                        <i className="fa-solid fa-share-nodes"></i>
                    </button>
                    <button
                        onClick={() => joinMeetingHandler(id, INPROGRESS)}
                        className="btn btn-primary text-nowrap mw-120"
                    >
                        {t('myMeeting.btnJoinMeeting')}
                    </button>
                    <button
                        onClick={() => endConfirmHandler(id)}
                        className="btn btn-danger text-white text-nowrap mw-120"
                    >
                        {t('joinMeeting.endMeeting')}
                    </button>
                </div>
            );
        } else if (status === ENDED) {
            return (
                <div className='d-flex justify-content-end gap-3'>
                    <button
                        className="btn btn-outline-primary text-nowrap"
                        onClick={() => btnMeetingQuestionSendViaEmail(id)}
                        title={t('pastMeetingDetails.btnSendEmail')}
                    >
                        <i className="fas fa-envelope"></i>
                        <span className="text-nowrap ms-2">{t('pastMeetingDetails.btnSendEmail')}</span>
                    </button>
                    <button
                        className="btn btn-primary text-nowrap"
                        onClick={() => getMeetingDetails(id)}
                    >
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                        <span className="text-nowrap ms-2">{t('myMeeting.btnDetailMeeting')}</span>
                    </button>
                </div>
            );
        } else {
            return <>-</>
        }
    }

    interface SortIconsProps {
        index: number
    }

    const SortIcons: FC<SortIconsProps> = ({ index }) => {
        if (sortColumn.col === sortableCols[index].col) {
            return sortColumn.order ? <i className="fa-solid fa-sort-up"></i> : <i className="fa-solid fa-sort-down"></i>
        } else {
            return <i className="fa-solid fa-sort"></i>
        }
    }

    interface SortableColProps {
        name: string
        index: number
    }

    const SortableCol: FC<SortableColProps> = ({ name, index }) => {
        return (
            <button
                className='btn p-0 w-100 h-100 hstack gap-2 fw-bold'
                onClick={() => onSort(index)}
            >
                <span>{name}</span>
                <SortIcons index={index} />
            </button>
        )
    }

    return (
        <>
            <Table responsive>
                <thead>
                    <tr>
                        <th style={{ minWidth: 200, maxWidth: 200 }}>
                            <SortableCol name={t('myMeeting.lbltitle')} index={0} />
                        </th>
                        <th className='text-center'>{t('myMeeting.lblCode')}</th>
                        <th className='text-center'>{t('common.Password')}</th>
                        <th className='text-center'>{t('common.lblGeoFence')}</th>
                        <th>
                            <SortableCol name={t('myMeeting.lblCreateAt')} index={1} />
                        </th>
                        <th>
                            <SortableCol name={t('myMeeting.lblStartAt')} index={2} />
                        </th>
                        <th>
                            <SortableCol name={t('myMeeting.lblEndAt')} index={3} />
                        </th>
                        <th className='text-center'>{t('myMeeting.actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {meetingList.length > 0 && meetingList.map((meeting) => {
                        return (
                            <tr key={`meeting-${meeting.code}`}>
                                <td>{meeting.title}</td>
                                <td className='text-nowrap text-center'>{meeting.code}</td>
                                <td className='text-nowrap text-center'>
                                    {meeting.password ?
                                        <>{meeting.password}</>
                                        :
                                        <>-</>
                                    }
                                </td>
                                <td className='text-nowrap text-center'>
                                    {meeting.isGeoFenceProtected ?
                                        t('common.lblYes')
                                        :
                                        t('common.lblNo')
                                    }
                                </td>
                                <td className='text-nowrap'>{moment(new Date(meeting.createdAt)).format('lll')}</td>
                                <td className='text-nowrap'>{moment(new Date(meeting.startTime)).format('lll')}</td>
                                <td className='text-nowrap'>
                                    {meeting.endedAt ?
                                        moment(new Date(meeting.endedAt)).format('lll')
                                        :
                                        <>-</>
                                    }
                                </td>
                                <td className='text-nowrap text-center'>
                                    <Actions id={meeting.id} status={meeting.status} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    )
};

export default MeetingList;