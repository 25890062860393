import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { profileupdate } from "../../../services/profile.service";
import { get } from 'lodash'
import * as actions from '../actions/index';

/* eslint-disable @typescript-eslint/no-unused-expressions */

//profile initial states
interface IUserProfile {
    id: any
    firstName: string,
    lastName: string,
    files: any[],
}
interface UpdateProfileInitialState {
    isLoading: boolean,
    successful: boolean,
    message: string,
    profilEdit: boolean,
    ProfileImage: string,
    Profile: any[]
}
const initialState: UpdateProfileInitialState = {
    isLoading: false,
    message: '',
    successful: false,
    profilEdit: false,
    ProfileImage: '',
    Profile: []
}


//profile api thunks
export const UpdateProfile: any = createAsyncThunk(actions.UPDATEPROFILE, (data: IUserProfile, { rejectWithValue }) =>
    profileupdate(data?.id, data?.firstName, data?.lastName, data?.files).catch((error) => error && rejectWithValue(error))
);

//profileSlice
export const updateProfileSlice = createSlice({
    name: 'updateProfileSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(UpdateProfile.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(UpdateProfile.fulfilled, (state, action) => {
            state.isLoading = false;
            const checkProfileUrl = action.payload.data?.usersImageDetails[0]?.imageUrl;
            const ResponseMessage = get(action, 'payload.response.data.message');
            if (ResponseMessage) {
                state.message = ResponseMessage;
            }
            if (checkProfileUrl) {
                state.ProfileImage = action.payload.data?.usersImageDetails[0]?.imageUrl;
            }
            state.successful = true;
        })
        builder.addCase(UpdateProfile.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, 'payload.response.data.message');
            const networkError = get(error, 'payload.message');
            if (errorMessage) {
                state.message = errorMessage;
            } else if (networkError) {
                state.message = networkError;
            }
            state.isLoading = false;
        })
    }
})

export default updateProfileSlice.reducer;
