import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { waitingListModalHandler } from '../../store/slices/modalSlice'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'


//props interface starts here
interface WaitingListModalProps {
    acceptUserInMeeting: (status: boolean, userId: any, guestUserId: any) => void
}
//props interface ends here

const WaitingListModal: FC<WaitingListModalProps> = ({ acceptUserInMeeting }) => {
   //single hooks starts here
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
   //single hooks ends here


   //useSelectors starts here
    const { waitingList } = useSelector((state: RootState) => state.meeting)
    const { waitingListModal } = useSelector((state: RootState) => state.modal)
   //useSelectors ends here


   //functions starts here
    const hideModal = () => {
        dispatch(waitingListModalHandler(false))
    }
   //functions ends here

    return (
        //modals starts here
        <Modal show={waitingListModal} centered onHide={hideModal}>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='position-relative fw-semibold'>
                    <span>{t('joinMeeting.lblWaitingListJoinee')}</span>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-notification bg-danger">{waitingList?.length}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {
                        !!waitingList && waitingList?.length > 0 && waitingList?.map(
                            (ele: {
                                userDetails: { firstName: any; lastName: any; email: string | null | undefined; id: number };
                                guestUserDetails: { isAnonymous: boolean; name: string; email: string | null | undefined; id: number }
                            }, index: number) => {
                                return (
                                    <div className='hstack justify-content-between mb-2 mb-sm-3' key={`waitingList_${index}`}>
                                        <div className='waiting-user-details flex-fill ms-3'>
                                            <h6 className='mb-1 text-truncate'>{`${ele.userDetails?.firstName ? ele.userDetails?.firstName : (ele.guestUserDetails?.isAnonymous ? 'Anonymous' : ele.guestUserDetails?.name)} ${ele.userDetails?.lastName ? ele.userDetails?.lastName : ""}`}</h6>
                                            <p className='text-secondary mb-0 text-truncate'>{ele?.userDetails?.email ? ele?.userDetails?.email : ele.guestUserDetails?.email}</p>
                                        </div>
                                        <div className='align-self-center hstack gap-2 gap-sm-3'>
                                            <button className='btn btn-success text-white hstack justify-content-center gap-1 size-32-sm'
                                                onClick={() => acceptUserInMeeting(true, ele?.userDetails?.id, ele?.guestUserDetails?.id)}
                                            >
                                                <i className="fa-solid fa-check fa-lg"></i>
                                                <span className="d-none d-sm-inline">Accept</span>
                                            </button>
                                            <button className='btn btn-danger text-white hstack justify-content-center gap-1 size-32-sm'
                                                onClick={() => acceptUserInMeeting(false, ele?.userDetails?.id, ele?.guestUserDetails?.id)}
                                            >
                                                <i className="fa-solid fa-xmark fa-lg"></i>
                                                <span className="d-none d-sm-inline">Reject</span>
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className='border-0'>
                <button type="button" className="btn btn-outline-danger rounded-45 mw-120" onClick={hideModal}
                >
                    {t('joinMeeting.intrestedModel.btnClose')}
                </button>
            </Modal.Footer>
        </Modal>
        //modals ends here
    )
}

export default WaitingListModal