import type { CSSProperties, FC } from 'react'
import { memo } from 'react'


export interface BoxDragPreviewProps {
    title: string
    width: number
}

export const BoxDragPreview: FC<BoxDragPreviewProps> = memo(
    function BoxDragPreview({ title, width }) {
        const styles: CSSProperties = {
            border: '1px dashed gray',
            cursor: 'grab',
            width: width,
        }
        return (
            <div className='form-control' style={styles}>
                {title}
            </div>
        )
    },
)
