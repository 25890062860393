import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL


export const getAllCategories = async (id: any) => {
    return axios.get(API_URL + "categories/all", { headers: authHeader() } )
        .then((response) => {
            return response.data;
        });
};