/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { currentMeetingHandler } from '../store/slices/meetingSlice';
import { feedbackFormHandler } from '../store/slices/settingsSlice';
import clockImg from "../../images/clock.svg"
import { useTranslation } from 'react-i18next';
import ShareMeetingContainer from '../components/ShareMeeting/ShareMeetingContainer';
import ShareMeetingMobile from '../components/ShareMeeting/ShareMeetingMobile';

const WaitingArea = () => {
   //single hooks starts here
   const dispatch = useDispatch()
   let navigate = useNavigate();
   const { t } = useTranslation('common');
   //single hooks ends here


   //useSelectors starts here
   const { isGuest } = useSelector((state: RootState) => state.guestUser)
   const { isFeedBackForm } = useSelector((state: RootState) => state.settings)
   const { currentMeeting } = useSelector((state: RootState) => state.meeting)
   const currentMeetingId = currentMeeting?.id
   //useSelectors ends here


   //useEffect starts here
   useEffect(() => {
      if (typeof currentMeetingId === 'undefined' || isFeedBackForm) {
         dispatch(feedbackFormHandler(false))
         dispatch(currentMeetingHandler(null))
         if (isGuest) {
            navigate("/")
         } else {
            navigate("/dashboard")
         }
      }
   }, [currentMeetingId, isFeedBackForm, navigate]);
   //useEffect ends here

   return (
      <div className="h-100 flex-fill d-flex">
         <div className={`flex-fill ${isGuest ? 'pt-4 mb-3 mb-md-4' : ''}`}>
            <div className="h-100 container-fluid">
               <div className="h-100 row">
                  <ShareMeetingContainer />
                  <div className="vstack col-xl-8">
                     <ShareMeetingMobile />
                     <div className='flex-fill card'>
                        <div className='card-body hstack justify-content-center p-4'>
                           <div className='d-flex flex-column align-items-center gap-4 mb-5'>
                              <div>
                                 <img src={clockImg} alt="clock-image" className='img-fluid' style={{ width: "100px" }} />
                              </div>
                              <p className='font-22 mb-0 fw-medium text-center'>{t('toastMessage.waitingArea')} </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default WaitingArea;