import React, { FC } from 'react'
import { useTranslation } from 'react-i18next';

interface ConversationListProps {
    isHistory: boolean
    isHost: boolean
    isGuest: boolean
    userId: number
    meetingQuestions: any[]
    currentMeetingHostId: number | undefined
    guestUserId: number | undefined
    handleUpvoteMeetingQuestion?: (meetingQuestionId: number) => void
    getUpvotedAvaliable?: (meetingQuestionId: number) => boolean
    getUpvotedCount?: (meetingQuestionId: number) => number
    editQuestion?: (question: string, meetingQuestionId: number) => void
    handleArchiveMeetingQuestion?: (meetingQuestionId: number, archive: boolean) => void
    handleOnClickReplyButton?: (meetingQuestionId: number) => void
    deleteConfirmHandler?: (meetingQuestionId: number) => void
    handleUpvoteMeetingQuestionReply?: (meetingQuestionId: number, meetingQuestionReplyId: number) => void
    getReplyUpvotedAvaliable?: (meetingQuestionsReplyUpvoteDetails: any[]) => boolean
    deleteConfirmHandlerReply?: (meetingQuestionReplyId: number) => void
}
const ConversationList: FC<ConversationListProps> = ({
    isHistory,
    isHost,
    isGuest,
    userId,
    meetingQuestions,
    currentMeetingHostId,
    guestUserId,
    handleUpvoteMeetingQuestion,
    getUpvotedAvaliable,
    getUpvotedCount,
    editQuestion,
    handleArchiveMeetingQuestion,
    handleOnClickReplyButton,
    deleteConfirmHandler,
    handleUpvoteMeetingQuestionReply,
    getReplyUpvotedAvaliable,
    deleteConfirmHandlerReply,
}) => {
    const { t } = useTranslation('common');

    return (
        <>
            {!!meetingQuestions && meetingQuestions.length > 0 ?
                meetingQuestions.map((meetingQuestion: any) => {
                    let userFullName = "";
                    if (!!meetingQuestion.joinUser && !!meetingQuestion.userId) {
                        userFullName = meetingQuestion.joinUser?.firstName + " " + meetingQuestion.joinUser?.lastName;
                    }

                    if (!!meetingQuestion.joinGuestUser && !!meetingQuestion.guestUserId) {
                        userFullName = meetingQuestion.joinGuestUser.isAnonymous ? t('common.lblAnonymous') : meetingQuestion.joinGuestUser?.name
                    }

                    if (!meetingQuestion.isArchive || (meetingQuestion.isArchive && isHost) || isHistory) {
                        let isHostQuestion = currentMeetingHostId === meetingQuestion.userId
                        return (
                            <div key={meetingQuestion.id}>
                                <div className={`meeting-questioncard ${isHostQuestion ? 'meeting-host' : ''} ${meetingQuestion.isArchive ? 'unread' : 'read'}`}>
                                    <div className="hstack flex-wrap justify-content-between">
                                        <div className="meeting-user-badge">
                                            <i className={`meeting-user-icon fa-solid ${isHostQuestion ? 'fa-user-tie' : 'fa-circle-user'}`}></i>
                                            <div className="fw-medium">{guestUserId === meetingQuestion.guestUserId ? 'You' : userId === meetingQuestion.userId ? 'You' : userFullName}</div>
                                        </div>
                                        {
                                            !isHistory &&
                                            <>
                                                <div className="dropdown d-md-none">
                                                    <button data-bs-toggle="dropdown" data-bs-auto-close="outside" className="btn text-black rounded-1 py-1" type="button" aria-expanded="false">
                                                        <i className="fa fa-ellipsis-v"></i>
                                                    </button>
                                                    <div className="dropdown-menu border-0 shadow py-1">
                                                        <li>
                                                            <div className='dropdown-item px-2 py-1 hstack justify-content-between gap-2'>
                                                                <button className='btn hstack gap-2 p-0 flex-fill' onClick={() => { !!handleUpvoteMeetingQuestion && handleUpvoteMeetingQuestion(meetingQuestion.id) }}>
                                                                    <span className={`btn ${!!getUpvotedAvaliable && getUpvotedAvaliable(meetingQuestion.id) === true ? 'btn-primary' : 'btn-secondary'} btn-sm text-white size-32`}>
                                                                        <i className="fa-regular fa-thumbs-up me-1"></i>
                                                                    </span>
                                                                    <span>Votes</span>
                                                                </button>
                                                                <span className='badge bg-primary bg-opacity-25 text-primary'>{!!getUpvotedCount && getUpvotedCount(meetingQuestion.id)}</span>
                                                            </div>
                                                        </li>
                                                        {meetingQuestion.meetingQuestionsReplyDetails.length <= 0 &&
                                                            !!getUpvotedCount && getUpvotedCount(meetingQuestion.id) <= 0 &&
                                                            (isGuest ?
                                                                (meetingQuestion.guestUserId === guestUserId) :
                                                                (userId === meetingQuestion.userId)) &&
                                                            <li>
                                                                <div className="dropdown-item px-2">
                                                                    <button className='btn hstack gap-2 p-0 w-100' onClick={() => { !!editQuestion && editQuestion(meetingQuestion.question, meetingQuestion.id) }}>
                                                                        <span
                                                                            className="btn btn-success btn-sm text-white size-32"
                                                                        >
                                                                            <i className="fa fa-pen"></i>
                                                                        </span>
                                                                        <span>Edit</span>
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        }
                                                        {isHost &&
                                                            <>
                                                                <li>
                                                                    <div className="dropdown-item px-2">
                                                                        <button className='btn hstack gap-2 p-0 w-100' onClick={() => { !!handleArchiveMeetingQuestion && handleArchiveMeetingQuestion(meetingQuestion.id, !meetingQuestion.isArchive) }}>
                                                                            <span className="btn btn-secondary btn-sm text-white size-32" >
                                                                                <i className={meetingQuestion.isArchive ? 'fa-solid fa-check-double' : 'fa-solid fa-check'}></i>
                                                                            </span>
                                                                            <span>{meetingQuestion.isArchive ? 'Archived' : 'Archive'}</span>
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="dropdown-item px-2">
                                                                        <button className='btn hstack gap-2 p-0 w-100' onClick={() => { !!handleOnClickReplyButton && handleOnClickReplyButton(meetingQuestion.id) }}>
                                                                            <span className="btn btn-success btn-sm text-white size-32">
                                                                                <i className="fas fa-reply"></i>
                                                                            </span>
                                                                            <span>Reply</span>
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                            </>

                                                        }
                                                        {meetingQuestion.meetingQuestionsReplyDetails.length <= 0 &&
                                                            !!getUpvotedCount && getUpvotedCount(meetingQuestion.id) <= 0 &&
                                                            (isGuest ?
                                                                (meetingQuestion.guestUserId === guestUserId) :
                                                                (userId === meetingQuestion.userId)) &&
                                                            <li>
                                                                <div className="dropdown-item px-2">
                                                                    <button className='btn hstack gap-2 p-0 w-100' onClick={() => { !!deleteConfirmHandler && deleteConfirmHandler(meetingQuestion.id) }}>
                                                                        <span className="btn btn-danger btn-sm text-white size-32">
                                                                            <i className="fa-solid fa-trash"></i>
                                                                        </span>
                                                                        <span>Delete</span>
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-none d-md-flex gap-2">
                                                    {meetingQuestion.meetingQuestionsReplyDetails.length <= 0 &&
                                                        !!getUpvotedCount && getUpvotedCount(meetingQuestion.id) <= 0 &&
                                                        (isGuest ?
                                                            (meetingQuestion.guestUserId === guestUserId) :
                                                            (userId === meetingQuestion.userId)) &&
                                                        <button
                                                            className="btn btn-success btn-sm text-white size-32"
                                                            title="edit"
                                                            onClick={() => { !!editQuestion && editQuestion(meetingQuestion.question, meetingQuestion.id) }}
                                                        >
                                                            <i className="fa fa-pen"></i>
                                                        </button>
                                                    }
                                                    <button
                                                        style={{ height: '2rem' }}
                                                        className={`btn ${!!getUpvotedAvaliable && getUpvotedAvaliable(meetingQuestion.id) === true ? 'btn-primary' : 'btn-secondary'} btn-sm text-white`}
                                                        title={t('joinMeeting.titleUpvote')}
                                                        onClick={() => { !!handleUpvoteMeetingQuestion && handleUpvoteMeetingQuestion(meetingQuestion.id) }}
                                                    >
                                                        <i className="fa-regular fa-thumbs-up me-1"></i>
                                                        <span>{!!getUpvotedCount && getUpvotedCount(meetingQuestion.id)}</span>
                                                    </button>

                                                    {isHost &&
                                                        <>
                                                            <button className="btn btn-secondary btn-sm text-white size-32" title={t('joinMeeting.titleArchive')} onClick={() => { !!handleArchiveMeetingQuestion && handleArchiveMeetingQuestion(meetingQuestion.id, !meetingQuestion.isArchive) }}>
                                                                <i className={meetingQuestion.isArchive ? 'fa-solid fa-check-double' : 'fa-solid fa-check'}></i>
                                                            </button>
                                                            <button type="button" className="btn btn-success btn-sm text-white size-32" onClick={() => { !!handleOnClickReplyButton && handleOnClickReplyButton(meetingQuestion.id) }} title={t('joinMeeting.titleReply')}>
                                                                <i className="fas fa-reply"></i>
                                                            </button>
                                                        </>
                                                    }
                                                    {meetingQuestion.meetingQuestionsReplyDetails.length <= 0 &&
                                                        !!getUpvotedCount && getUpvotedCount(meetingQuestion.id) <= 0 &&
                                                        (isGuest ?
                                                            (meetingQuestion.guestUserId === guestUserId) :
                                                            (userId === meetingQuestion.userId)) &&
                                                        <button className="btn btn-danger btn-sm text-white size-32" title={t('common.delete')} onClick={() => { !!deleteConfirmHandler && deleteConfirmHandler(meetingQuestion.id) }}>
                                                            <i className="fa-solid fa-trash"></i>
                                                        </button>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="message-box">
                                        {meetingQuestion.question}
                                        {meetingQuestion.isEdited && <small className='edited-label'>edited</small>}
                                    </div>
                                    <div className='vstack gap-2'>
                                        {!!meetingQuestion.meetingQuestionsReplyDetails &&
                                            meetingQuestion.meetingQuestionsReplyDetails.length > 0 &&
                                            meetingQuestion.meetingQuestionsReplyDetails.map((meetingQuestionsReplyValue: any) => {
                                                let userFullNameReply = ""

                                                if (!!meetingQuestionsReplyValue.userDetails && !!meetingQuestionsReplyValue.userId) {
                                                    userFullNameReply = meetingQuestionsReplyValue.userDetails?.firstName + " " + meetingQuestionsReplyValue.userDetails?.lastName
                                                }
                                                if (!!meetingQuestionsReplyValue.joinGuestUser && !!meetingQuestionsReplyValue.guestUserId) {
                                                    if (meetingQuestionsReplyValue.joinGuestUser.isAnonymous) {
                                                        userFullNameReply = t('common.lblAnonymous')
                                                    } else {
                                                        userFullNameReply = meetingQuestionsReplyValue.joinGuestUser?.name
                                                    }
                                                }

                                                let isHostReply = currentMeetingHostId === meetingQuestionsReplyValue.userId
                                                return (
                                                    <div className={`question-reply ${isHostReply ? 'meeting-host' : ''}`} key={'meetingQuestionsReply_' + meetingQuestionsReplyValue.id}>
                                                        <div className='hstack flex-wrap justify-content-between'>
                                                            <div className="meeting-user-badge">
                                                                <i className={`meeting-user-icon fa-solid ${isHostReply ? 'fa-user-tie' : 'fa-circle-user'}`}></i>
                                                                <div className="fw-medium">{guestUserId === meetingQuestionsReplyValue.guestUserId ? 'You' : userId === meetingQuestionsReplyValue.userId ? 'You' : userFullNameReply}</div>
                                                            </div>

                                                            {
                                                                !isHistory &&
                                                                <>
                                                                    <div className="dropdown d-md-none">
                                                                        <button data-bs-toggle="dropdown" data-bs-auto-close="outside" className="btn text-black rounded-1 py-1" type="button" aria-expanded="false">
                                                                            <i className="fa fa-ellipsis-v"></i>
                                                                        </button>
                                                                        <div className="dropdown-menu border-0 shadow py-1">
                                                                            <li>
                                                                                <div className='dropdown-item px-2 py-1 hstack justify-content-between gap-2'>
                                                                                    <button className='btn hstack gap-2 p-0 flex-fill' onClick={() => { !!handleUpvoteMeetingQuestionReply && handleUpvoteMeetingQuestionReply(meetingQuestion.id, meetingQuestionsReplyValue.id) }}>
                                                                                        <span className={`btn ${!!getReplyUpvotedAvaliable && getReplyUpvotedAvaliable(meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails) === true ? 'btn-primary' : 'btn-secondary'} btn-sm text-white size-32`}>
                                                                                            <i className="fa-regular fa-thumbs-up me-1"></i>
                                                                                        </span>
                                                                                        <span>Votes</span>
                                                                                    </button>
                                                                                    <span className='badge bg-primary bg-opacity-25 text-primary'>
                                                                                        {meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails.length > 0 ? meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails.length : 0}
                                                                                    </span>
                                                                                </div>
                                                                            </li>
                                                                            {isHost &&
                                                                                <li>
                                                                                    <div className="dropdown-item px-2">
                                                                                        <button className='btn hstack gap-2 p-0 w-100' onClick={() => { !!deleteConfirmHandlerReply && deleteConfirmHandlerReply(meetingQuestionsReplyValue.id) }}>
                                                                                            <span className="btn btn-danger btn-sm text-white size-32">
                                                                                                <i className="fa-solid fa-trash"></i>
                                                                                            </span>
                                                                                            <span>Delete</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </li>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-none d-md-flex gap-2">
                                                                        <button
                                                                            className={`btn ${!!getReplyUpvotedAvaliable && getReplyUpvotedAvaliable(meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails) === true ? 'btn-primary' : 'btn-secondary'} btn-sm text-white`}
                                                                            title={t('joinMeeting.titleUpvote')}
                                                                            onClick={() => { !!handleUpvoteMeetingQuestionReply && handleUpvoteMeetingQuestionReply(meetingQuestion.id, meetingQuestionsReplyValue.id) }}
                                                                        >
                                                                            <i className="fa-regular fa-thumbs-up me-1"></i>
                                                                            <span>{meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails.length > 0 ? meetingQuestionsReplyValue.meetingQuestionsReplyUpvoteDetails.length : 0}</span>
                                                                        </button>
                                                                        {isHost &&
                                                                            <button className="btn btn-danger btn-sm text-white" title={t('common.delete')} onClick={() => { !!deleteConfirmHandlerReply && deleteConfirmHandlerReply(meetingQuestionsReplyValue.id) }}>
                                                                                <i className="fa-solid fa-trash"></i>
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="message-box">{meetingQuestionsReplyValue.questionReply}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div >
                        )
                    }
                    return null
                })
                :
                isHistory ?
                    <div className="status-block status-block-nodata"> {t('pollDetails.DataNotFound')}</div>
                    : <></>

            }
        </>
    )
}

export default ConversationList