import React, { FC, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserQuizResultModal from '../Modals/UserQuizResultModal';
import { userQuizResultModalHandler } from '../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import pdfImage from '../../../images/pdfDownload.png'
import { getAttendeeQuizListing } from '../../../services/quiz.service';

interface QuizHostResultProps {
    allSubmittedQuizAnswer: any[]
}

const QuizHostResult: FC<QuizHostResultProps> = ({ allSubmittedQuizAnswer }) => {

    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const quizResultsByQuizId: any = {};
    const highestScoresByQuizId: any = {};

    const [quizResultDetails, setQuizResultDetails] = useState<any>([])

    allSubmittedQuizAnswer.forEach((quiz) => {
        const quizId = quiz.quizId;
        if (!quizResultsByQuizId[quizId]) {
            quizResultsByQuizId[quizId] = [];
        }
        quizResultsByQuizId[quizId].push(quiz);
        const currentScore = quiz.score;

        if (!highestScoresByQuizId[quizId] || currentScore > highestScoresByQuizId[quizId]) {
            highestScoresByQuizId[quizId] = currentScore;
        }
    });

    const handleDownloadAttendeeListing = async (quizId: any) => {
        try {
            const submittedAnswers = quizResultsByQuizId[quizId];
            const quizTitle = submittedAnswers[0]?.quizJson?.title || 'Untitled'
            const response = await getAttendeeQuizListing(quizId, submittedAnswers)
            // Handling the PDF file download or other actions as needed
            const blob = new Blob([response.data], { type: 'application/pdf' });
            // Creating a URL for the Blob
            const url = window.URL.createObjectURL(blob);
            // Create an anchor element to trigger the download
            const a = document.createElement('a');
            a.href = url;
            a.download = `${quizTitle}-attendee-listing.pdf`;
            a.click();
            // Revoking the URL to release resources
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <>
            <div className='section-question-container'>
                {allSubmittedQuizAnswer.length > 0 ?

                    Object.keys(quizResultsByQuizId).map((quizId: any, index: number) => {

                        // Sort quiz results by score in descending order
                        quizResultsByQuizId[quizId].sort((a: any, b: any) => b.score - a.score);
                        const quizTitleIndex = quizResultsByQuizId[quizId].findIndex(
                            (quiz: any) => quiz.quizJson && quiz.quizJson.title
                        );

                        // Use the quizJson title if found, otherwise use "Untitled Quiz"
                        const quizTitle =
                            quizTitleIndex !== -1
                                ? quizResultsByQuizId[quizId][quizTitleIndex].quizJson.title
                                : "Untitled Quiz";
                        return (
                            <div className="card section-question-container" key={`QuizHostResult_${quizId}_${index}`}>
                                <div className='card-body section-question-block'>
                                    <div className='section-question-header'>
                                        <div className="fw-medium">{quizTitle}</div>
                                        <div className='hstack gap-3'>
                                            {
                                                quizResultsByQuizId[quizId].length === 1
                                                    ? '1 person has submitted this quiz'
                                                    : `${quizResultsByQuizId[quizId].length} people have submitted this quiz`
                                            }
                                            {
                                                allSubmittedQuizAnswer && allSubmittedQuizAnswer?.length > 0 &&
                                                <button className='btn' onClick={() => handleDownloadAttendeeListing(quizId)}>
                                                    <img
                                                        src={pdfImage}
                                                        title="Download attendee's listing"
                                                        width={32}
                                                        height={32}
                                                        alt='pdf'
                                                    />
                                                </button>
                                            }

                                        </div>
                                    </div>

                                    <div className="section-question table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "400px" }}>Name</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {quizResultsByQuizId[quizId].map((quiz: any) => (


                                                    <tr key={quiz.id}>
                                                        <td>
                                                            {!!quiz.guestUserId ?
                                                                quiz.questionAnswerGuestUser.isAnonymous ? 'Anonymous' : quiz.questionAnswerGuestUser.name
                                                                : quiz?.questionAnswerHostUser?.firstName + ' ' + quiz?.questionAnswerHostUser?.lastName}
                                                        </td>
                                                        <td><Button className='text-nowrap' onClick={() => { setQuizResultDetails(quiz); dispatch(userQuizResultModalHandler(true)) }}>Detailed result</Button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                    :
                    <div className="status-block status-block-nodata"> {t('quizDetails.DataNotFound')}</div>
                }
            </div>
            <UserQuizResultModal quiz={quizResultDetails} />
        </>
    );
}

export default QuizHostResult