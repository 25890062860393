/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ENDED, FILTERUSERLS, SORTBYALL, SORTBYLS, SORTBYTIME, SORTBYVOTES } from '../../../types/commonTypes';
import { all, anonymous } from '../../../common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { addMeetingQuestionHandler, deleteMeetingQuestionHandler, deleteMeetingQuestionReplyHandler, editMeetingQuestionHandler, getAllUpvoteMeetingQuestionListrByMeetingIdHandler, getJoinedMeetingUsersByMeetingIdHandler, getMeetingQuestionsHandler, meetingGetByCodeHandler, saveUpvoteForMeetingQuestionHandler, saveUpvoteForMeetingQuestionReplyHandler, updateMeetingQuestionHandler } from '../../store/slices/joinMeetingSlice';
import socket from '../../../common/constants';
import { RootState, store } from '../../store/store';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { IMeetingSendQuestion } from '../../../types/meeting.type';
import DialogBox from '../../components/DeleteBox';
import { toast } from 'react-toastify';
import { currentMeetingHandler, waitingListHandler } from '../../store/slices/meetingSlice';
import { getWaitingList, userExitWaitingList } from '../../../services/waitingList.service';
import { useNavigate } from 'react-router';
import { guestAddMeetingQuestionHandler, guestEditMeetingQuestionHandler, saveUpvoteForMeetingQuestionForGuestHandler, saveUpvoteForMeetingQuestionReplyForGuestHandler } from '../../store/slices/guestUserSlice';
import QuestionReplyModal from '../../components/Modals/QuestionReplyModal';
import ConversationList from './ConversationList';

interface QuestionTabProps { }

const QuestionTab: FC<QuestionTabProps> = () => {
    //single hooks starts here
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    let navigate = useNavigate();
    //single hooks ends here


    //useSelectors starts here
    const { user } = useSelector((state: RootState) => state.auth)
    const { guestUser, isGuest } = useSelector((state: RootState) => state.guestUser)
    const { currentMeeting, isAllowed } = useSelector((state: RootState) => state.meeting)
    const userId = user?.user?.id
    const guestUserId = guestUser?.id;
    const currentMeetingId = currentMeeting?.id
    const currentMeetingHostId = currentMeeting?.hostId
    const currentMeetingCode = currentMeeting?.code;
    const isHost = !!userId && userId === currentMeetingHostId
    //useSelectors ends here


    //useRefs starts here
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const questionsListRef = useRef<HTMLDivElement>(null)
    const questionBoxRef = useRef<HTMLTextAreaElement>()
    //useRefs ends here


    //useStates starts here
    const [sortBy, setSortBy] = useState<string>(SORTBYALL);
    const [selectedUser, setSelectedUser] = useState<any>(all);
    const [meetingQuestions, setMeetingQuestions] = useState<any[]>([])
    const [showAnswerQuestion, setShowAnswerQuestion] = useState<boolean>(false)
    const [showArchiveQuestion, setShowArchiveQuestion] = useState<boolean>(false)
    const [showUnanswerQuestion, setShowUnanswerQuestion] = useState<boolean>(false)
    const [scrollBottom, setScrollBottom] = useState<boolean>(false)
    const [allJoinedUsers, setAllJoinedUsers] = useState<any[]>([])
    const [allUpvoteMeetingQuestionList, setAllUpvoteMeetingQuestionList] = useState<any[]>([]);
    const [questionBox, setQuestionBox] = useState<string>("")
    const [isEditQuestion, setIsEditQuestion] = useState<boolean>(false)
    const [editedQuestionId, setEditedQuestionId] = useState<number | any>()
    const [replyQuestionId, setReplyQuestionId] = useState<number>(0)
    const [replyModal, setReplyModal] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [meetingQuestionDeleteId, setMeetingQuestionDeleteId] = useState<number>(0);
    const [showReplyDelete, setShowReplyDelete] = useState<boolean>(false);
    const [meetingQuestionReplyDeleteId, setMeetingQuestionReplyDeleteId] = useState<number>(0);
    const [sendQuestionLoading, setSendQuestionLoading] = useState<boolean>(false);
    //useStates ends here


    //formik starts here
    const initialValuesSendQuestion: IMeetingSendQuestion = {
        question: questionBox.trim()
    }
    //formik ends here


    //functions starts here
    const scrollBottomCheck = () => {
        if (!!questionsListRef.current) {
            let element = questionsListRef.current
            const isAtBottom = Math.floor(element.scrollTop + element.clientHeight) === element.scrollHeight;
            setScrollBottom(!isAtBottom)
        }
    }

    const filterDatabyOnChecked = (data: any) => {
        let filter = data;
        if (showAnswerQuestion && !showArchiveQuestion && !showUnanswerQuestion) {
            // Show Only Answer Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0))

        } else if (!showAnswerQuestion && showArchiveQuestion && !showUnanswerQuestion) {
            // Show Only Archive Question
            return filter = data.filter((x: any) => (x['isArchive'] === true))

        } else if (!showAnswerQuestion && !showArchiveQuestion && showUnanswerQuestion) {
            // Show Only Unanswer Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length === 0))

        } else if (showAnswerQuestion && showArchiveQuestion && !showUnanswerQuestion) {
            // Show Only Answer Question and Show Only Archive Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0 && x['isArchive'] === true))

        } else if (!showAnswerQuestion && showArchiveQuestion && showUnanswerQuestion) {
            // Show Only Archive Question and Show Only Unanswer Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length === 0 && x['isArchive'] === true))

        } else if (showAnswerQuestion && !showArchiveQuestion && showUnanswerQuestion) {
            // Show Only Answer Question and  Show Only Unanswer Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0 && x['meetingQuestionsReplyDetails'].length === 0))

        } else if (showAnswerQuestion && showArchiveQuestion && showUnanswerQuestion) {
            // Show Only Answer Question and how Only Archive Question and Show Only Unanswer Question
            return filter = data.filter((x: any) => (x['meetingQuestionsReplyDetails'].length > 0 && x['meetingQuestionsReplyDetails'].length === 0 && showUnanswerQuestion))

        } else {
            // none of above ( default)
            return filter
        }
    }

    const sortQuestions = async (sortByValue: string, data: any) => {
        let dataSort;
        let filterOnchecked;
        switch (sortByValue) {
            case SORTBYALL:
                dataSort = data.sort((a: any, b: any) => (a['createdAt'] > b['createdAt'] ? 1 : -1))
                filterOnchecked = filterDatabyOnChecked(dataSort)
                setMeetingQuestions(filterOnchecked)
                break;
            case SORTBYVOTES:
                dataSort = data.sort((a: any, b: any) => (a['meetingsQuestionUpvoteDetails'] > b['meetingsQuestionUpvoteDetails'] ? 1 : -1))
                filterOnchecked = filterDatabyOnChecked(dataSort)
                setMeetingQuestions(filterOnchecked)
                break;
            case SORTBYTIME:
                dataSort = data.sort((a: any, b: any) => (a['createdAt'] < b['createdAt'] ? 1 : -1))
                filterOnchecked = filterDatabyOnChecked(dataSort)
                setMeetingQuestions(filterOnchecked)
                break;
            default:
                dataSort = data.sort((a: any, b: any) => (a['createdAt'] > b['createdAt'] ? 1 : -1))
                filterOnchecked = filterDatabyOnChecked(dataSort)
                setMeetingQuestions(filterOnchecked)
                break;
        }
    }

    const getMeetingQuestionsByMeetingId = async (meetingId: number) => {
        let filterData = {};
        let sortByValue = sortBy;
        if (typeof (localStorage.getItem(FILTERUSERLS)) === "string") {
            filterData = JSON.parse(localStorage.getItem(FILTERUSERLS) as string)
        } else {
            filterData = selectedUser
        }

        if (typeof (localStorage.getItem(SORTBYLS)) === "string") {
            sortByValue = localStorage.getItem(SORTBYLS) as string
        }

        if (!!meetingId) {
            const data = {
                currentMeetingId: meetingId, selectedUser: filterData
            }
            dispatch(getMeetingQuestionsHandler(data)).then((res: any) => {
                if (res?.payload?.status === 200) {
                    if (meetingQuestions.length === 0) {
                        setMeetingQuestions([])
                    } else if (meetingQuestions.length > 0) {
                        if (meetingQuestions[0]?.meetingId === currentMeetingId) {
                            setMeetingQuestions(res.payload?.data.data)
                        } else {
                            setMeetingQuestions([])
                        }
                    }
                    sortQuestions(sortByValue, res.payload?.data.data)
                }
            })
        }
        scrollBottomCheck()
    }

    const getJoinedMeetingUsers = async (meetingId: number) => {
        dispatch(getJoinedMeetingUsersByMeetingIdHandler(meetingId)).then(
            (response: any) => {
                if (response?.payload?.status === 200) {
                    let data: any = [];
                    response.payload?.data.data.forEach((usersData: any) => {
                        if (!!usersData.joinUser && !!usersData.userId) {
                            data.push(usersData)
                        }
                        if (!!usersData.joinGuestUser && !!usersData.guestUserId && !usersData.joinGuestUser.isAnonymous) {
                            data.push(usersData)
                        }
                    });
                    setAllJoinedUsers(data)
                }
            },
            (error: any) => {
                const resMessage =
                    (error.response.payload &&
                        error.response.payload?.data &&
                        error.response.payload?.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    const getAllUpvoteMeetingQuestionList = async (meetingId: number) => {
        dispatch(getAllUpvoteMeetingQuestionListrByMeetingIdHandler(meetingId)).then(
            (response: any) => {
                if (response.payload.status === 200) {
                    setAllUpvoteMeetingQuestionList(response.payload.data)
                }
            },
            (error: any) => {
                const resMessage =
                    (error.response.payload &&
                        error.response.payload.data &&
                        error.response.payload.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    const handleSorting = (sortByValue: string) => {
        localStorage.setItem(SORTBYLS, sortByValue)
        setSortBy(sortByValue)
        getMeetingQuestionsByMeetingId(currentMeetingId)
    }

    const handleUserFilter = (e: any) => {
        localStorage.setItem(FILTERUSERLS, e.target.value)
        setSelectedUser(JSON.parse(e.target.value))
    }

    const handleResetFilter = async () => {
        localStorage.setItem(FILTERUSERLS, JSON.stringify(all));
        setSelectedUser(all);
        setShowAnswerQuestion(false);
        setShowArchiveQuestion(false);
        setShowUnanswerQuestion(false);
    }

    const handleApplyFilter = async () => {
        let sortByValue = sortBy;
        if (typeof (localStorage.getItem(SORTBYLS)) === "string") {
            sortByValue = localStorage.getItem(SORTBYLS) as string;
        }
        const data = {
            currentMeetingId, selectedUser
        }
        let meetingQues = await dispatch(getMeetingQuestionsHandler(data));
        if (meetingQues.payload.status === 200) {
            sortQuestions(sortByValue, meetingQues?.payload?.data?.data);
        }
    }

    const handleUpvoteMeetingQuestion = async (meetingQuestionId: number) => {
        const data = isGuest ? {
            meetingId: currentMeetingId, meetingQuestionId, guestUserId: guestUserId
        } : { meetingId: currentMeetingId, meetingQuestionId }

        dispatch(isGuest ? saveUpvoteForMeetingQuestionForGuestHandler(data) : saveUpvoteForMeetingQuestionHandler(data)).then(
            () => {
                socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                socket.on('connect', () => {
                    socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                })
            },
            (error: any) => {
                const resMessage =
                    (error.response.payload &&
                        error.response.payload.data &&
                        error.response.payload.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    const getUpvotedAvaliable = (meetingQuestionId: number) => {
        let indexValue = allUpvoteMeetingQuestionList.findIndex((x: any) => (
            x.meetingQuestionId === meetingQuestionId &&
            (isGuest ? x.guestUserId === guestUserId : x.userId === userId)
        ))
        if (indexValue > -1) {
            return true
        } else {
            return false
        }
    }

    const getUpvotedCount = (meetingQuestionId: number) => {
        let dataList = allUpvoteMeetingQuestionList.filter((x: any) => (x.meetingQuestionId === meetingQuestionId))
        return dataList.length
    }

    const editQuestion = (meetingQuestion: any, meetingQuestionId: any) => {
        setQuestionBox(meetingQuestion);
        setEditedQuestionId(meetingQuestionId)
        setIsEditQuestion(true);
        questionBoxRef.current?.focus()
    }

    const handleArchiveMeetingQuestion = (id: number, isArchive: boolean) => {
        const data = { id, isArchive }
        dispatch(updateMeetingQuestionHandler(data)).then(
            (response: any) => {
                if (response.payload.status === 200) {
                    socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                    socket.on('connect', () => {
                        socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                    })
                    socket.emit("meeting-question-archive", { meetingId: currentMeetingId })
                    socket.on('connect', () => {
                        socket.emit("meeting-question-archive", { meetingId: currentMeetingId })
                    })
                }
            },
            (error: any) => {
                const resMessage =
                    (error.response.payload &&
                        error.response.payload.data &&
                        error.response.payload.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    const handleOnClickReplyButton = async (meetingQuestionIdValue: number) => {
        setReplyQuestionId(meetingQuestionIdValue);
        setReplyModal(true)
    }

    const hideReplyModal = () => {
        setReplyModal(false)
    }

    const deleteConfirmHandler = (id: any) => {
        setShowDelete(true)
        setMeetingQuestionDeleteId(id)
    }

    const resetQuestionBox = () => {
        setQuestionBox("");
        setIsEditQuestion(false);
    }

    const handleDeleteMeetingQuestion = (id: number) => {
        if (id > 0) {
            dispatch(deleteMeetingQuestionHandler(id)).then(
                (response: any) => {
                    if (response.payload.status === 200) {
                        socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                        socket.on('connect', () => {
                            socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                        })
                        resetQuestionBox()
                    }
                },
                (error: any) => {
                    const resMessage =
                        (error.response.payload &&
                            error.response.payload.data &&
                            error.response.payload.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
        } else {
            console.log("meeting question not found...")
        }
    }

    const getReplyUpvotedAvaliable = (meetingQuestionsReplyUpvoteDetails: any[]) => {
        let indexValue = meetingQuestionsReplyUpvoteDetails.findIndex((x: any) => (isGuest ? x.guestUserId === guestUserId : x.userId === userId))
        if (indexValue > -1) {
            return true
        } else {
            return false
        }
    }

    const handleSendQuestion = async () => {
        if (sendQuestionLoading) {
            return;
        }
        setSendQuestionLoading(true);
        const queData = questionBox.trim()
        if (queData.length === 0) return;

        const data = isGuest
            ? {
                question: queData,
                meetingId: currentMeetingId,
                guestUserId: guestUserId,
                ...(isEditQuestion && { meetingQuestionId: editedQuestionId, isEdited: true }),
            }
            : {
                queData,
                ...(isEditQuestion ? { editedQuestionId } : { currentMeetingId }),
            };

        const handler = (data: any) => {
            return isEditQuestion ? (isGuest ? guestEditMeetingQuestionHandler(data) : editMeetingQuestionHandler(data))
                : (isGuest ? guestAddMeetingQuestionHandler(data) : addMeetingQuestionHandler(data));
        }

        dispatch(await handler(data)).then(
            (response: any) => {
                if (response?.payload?.status === 201) {
                    socket.emit("meeting-question-send", { meetingId: currentMeetingId });
                    socket.on('connect', () => {
                        socket.emit("meeting-question-send", { meetingId: currentMeetingId });
                    });

                    setQuestionBox("");
                    if (isEditQuestion) {
                        setIsEditQuestion(false);
                    } else {
                        scrollBottomCheck();
                        setTimeout(() => {
                            scrollToBottom();
                        }, 250);
                    }
                }
            },
            (error: any) => {
                const resMessage = (error.response.payload?.data?.message) || error.message || error.toString();
                console.log("resMessage ==>", resMessage);
            }
        ).finally(() => setSendQuestionLoading(false));
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const deleteConfirmHandlerReply = (id: any) => {
        setShowReplyDelete(true)
        setMeetingQuestionReplyDeleteId(id)
    }

    const handleDeleteMeetingQuestionReply = (id: number) => {
        if (id > 0) {
            dispatch(deleteMeetingQuestionReplyHandler(id)).then(
                (response: any) => {
                    if (response?.payload?.status === 200) {
                        socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                        socket.on('connect', () => {
                            socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                        })
                    }
                },
                (error: any) => {
                    const resMessage =
                        (error.response?.payload &&
                            error.response?.payload?.data &&
                            error.response?.payload?.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
        } else {
            console.log("meeting question not found...")
        }
    }

    const handleUpvoteMeetingQuestionReply = async (meetingQuestionId: number, meetingQuestionsReplyId: number) => {
        const data = isGuest ? {
            meetingQuestionId: meetingQuestionId,
            meetingQuestionsReplyId: meetingQuestionsReplyId,
            guestUserId: guestUserId
        } : {
            meetingQuestionId, meetingQuestionsReplyId
        }

        dispatch(isGuest ? saveUpvoteForMeetingQuestionReplyForGuestHandler(data) : saveUpvoteForMeetingQuestionReplyHandler(data)).then(
            () => {
                socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                socket.on('connect', () => {
                    socket.emit("meeting-question-send", { meetingId: currentMeetingId })
                })
            },
            (error: any) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    const refreshWaitingList = () => {
        if (!!currentMeetingId) {
            getWaitingList(currentMeetingId).then((waitingList: any) => {
                dispatch(waitingListHandler(waitingList.data))
            }).catch((error) => {
                console.error("Error fetching waiting list:", error);
            });
        }
    }

    const handleLeaveMeeting = async () => {
        if (isAllowed) {
            navigate('/feedback')
        } else {
            await userExitWaitingList({
                meetingId: currentMeetingId,
                attendeeId: null,
                guestUserId: guestUserId
            })
            socket.emit('exit-waiting-list', { hostId: currentMeetingHostId, meetingId: currentMeetingId })
            dispatch(currentMeetingHandler(null))
            navigate("/")
        }
    }

    const handleLeaveMeetingHome = () => {
        navigate("/")
    }

    const getMeetingDetailByMeetingCode = async (code: string) => {
        const data = {
            code, userId: isGuest ? 0 : userId, guestUserId: isGuest ? guestUserId : 0
        }

        dispatch(meetingGetByCodeHandler(data)).then(
            (response: any) => {
                if (response?.payload.status === 200) {
                    let res = response?.payload.data
                    if (isGuest) {
                        if (res.status === ENDED) {
                            toast.success(String(t('toastMessage.meetingEndedHost')), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2500,
                            });
                            handleLeaveMeeting()
                        } else {
                            dispatch(currentMeetingHandler(res))
                            if (isAllowed) {
                                socket.emit('join-meeting-send', { meetingId: res.id, guestUser: guestUser })
                                socket.on('connect', () => {
                                    socket.emit('join-meeting-send', { meetingId: res.id, guestUser: guestUser })
                                })
                            }
                        }
                    } else {
                        dispatch(currentMeetingHandler(res))
                        refreshWaitingList()
                        socket.emit('join-meeting-send', { meetingId: res.id, user: user.user, hostId: res.hostId })
                        socket.on('connect', () => {
                            socket.emit('join-meeting-send', { meetingId: res.id, user: user.user, hostId: res.hostId })
                        })
                    }
                } else if (response.payload.status === 404) {
                    toast.error(response.payload.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    });
                }
            },
            (error: any) => {
                const resMessage =
                    (error.response.payload &&
                        error.response.payload.data &&
                        error.response.payload.data.message) ||
                    error.message ||
                    error.toString();
                toast.error(resMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                });
                if (isGuest) {
                    handleLeaveMeetingHome()
                } else {
                    navigate('/meeting')
                }
            }
        )
    }
    //functions ends here


    //useEffects starts here
    useEffect(() => {
        // for reset filter
        if (!showAnswerQuestion && !showArchiveQuestion && !showUnanswerQuestion && selectedUser.all === SORTBYALL) {
            getMeetingQuestionsByMeetingId(currentMeetingId);
        }
    }, [currentMeetingId, showAnswerQuestion, showArchiveQuestion, showUnanswerQuestion, selectedUser]);

    useEffect(() => {
        if (!!currentMeetingCode) {
            getMeetingDetailByMeetingCode(currentMeetingCode)
        }
    }, [currentMeetingCode])

    useEffect(() => {

        socket.on('join-meeting-res', (data) => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            const userIdSocket = state.auth?.user?.user?.id
            const guestUserIdSocket = state.guestUser?.guestUser?.id
            const user = guestUserIdSocket ? guestUserIdSocket === data?.guestUser?.id : userIdSocket === data?.user?.id

            if (!!currentMeetingIdSocket && user) {
                getMeetingQuestionsByMeetingId(currentMeetingIdSocket)
                getJoinedMeetingUsers(currentMeetingIdSocket)
                getAllUpvoteMeetingQuestionList(currentMeetingIdSocket)
            }
        })

        socket.on("meeting-question-res", async () => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            if (!!currentMeetingIdSocket) {
                getMeetingQuestionsByMeetingId(currentMeetingIdSocket)
                getAllUpvoteMeetingQuestionList(currentMeetingIdSocket)
            }
        })

        return (() => {
            socket.off('join-meeting-res')
            socket.off('meeting-question-res')
        })

    }, [currentMeeting])
    //useEffects ends here

    return (
        <>
            <div className="section-tab position-relative h-100 justify-content-between">
                <div className="hstack justify-content-end">
                    <div className="hstack gap-2">
                        <div className='dropdown'>
                            <button data-bs-toggle="dropdown" data-bs-auto-close="outside" className='btn btn-sm btn-primary size-32'><i className='fa fa-sort'></i></button>
                            <div className='dropdown-menu border-0 shadow p-0' style={{ width: 100 }}>
                                <div className="font-18 fw-medium border-bottom p-2">{t('joinMeeting.lblSortBy')}</div>
                                <div className='vstack justify-content-between gap-3 p-2'>
                                    <button className={`btn ${sortBy === SORTBYALL ? 'btn-primary' : 'btn-primary-light text-primary'} rounded-1 btn-sm`} onClick={() => handleSorting(SORTBYALL)}>{t('joinMeeting.btnAll')}</button>
                                    <button className={`btn ${sortBy === SORTBYVOTES ? 'btn-primary text-white' : 'btn-primary-light text-primary'} rounded-1 btn-sm`} onClick={() => handleSorting(SORTBYVOTES)}>
                                        <i className="fa-regular fa-thumbs-up "></i> Upvotes
                                    </button>
                                    <button className={`btn ${sortBy === SORTBYTIME ? 'btn-primary text-white' : 'btn-primary-light text-primary'} rounded-1 btn-sm`} onClick={() => handleSorting(SORTBYTIME)}>
                                        <i className="fa-regular fa-clock "></i> Time
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='dropdown'>
                            <button data-bs-toggle="dropdown" data-bs-auto-close="outside" className='btn btn-sm btn-primary size-32'><i className='fa fa-filter'></i></button>
                            <div className='dropdown-menu border-0 p-0 shadow' style={{ minWidth: 290 }}>
                                <div className="font-18 fw-medium border-bottom p-2">{t('joinMeeting.lblFilter')}</div>
                                <div className='vstack gap-3 p-2'>
                                    <div className='hstack gap-2'>
                                        <label className='text-nowrap'>{t('joinMeeting.lblSelectUser')}</label>
                                        <div className='flex-fill'>
                                            <select className="form-select" onChange={handleUserFilter} value={JSON.stringify(selectedUser)}>
                                                <option value={JSON.stringify(all)}>{t('joinMeeting.lblAllUser')}</option>
                                                <option value={JSON.stringify(anonymous)}>{t('joinMeeting.lblAllAnonymous')}</option>
                                                {!!allJoinedUsers && allJoinedUsers.length > 0 && allJoinedUsers.map((usersData: any) => {
                                                    let userFullName = '';
                                                    let value = {};
                                                    if (!!usersData.joinUser && !!usersData.userId) {
                                                        userFullName = usersData.joinUser?.firstName + " " + usersData.joinUser?.lastName;
                                                        value = { "userId": usersData.userId };
                                                    }
                                                    if (!!usersData.joinGuestUser && !!usersData.guestUserId && !usersData.joinGuestUser.isAnonymous) {
                                                        userFullName = usersData.joinGuestUser?.name;
                                                        value = { "guestUserId": usersData.guestUserId }
                                                    }
                                                    return (
                                                        <option key={'option_' + usersData.id} value={JSON.stringify(value)}>{userFullName}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='form-check'>
                                        <input type="checkbox" id='showAnswerQuestion' className='form-check-input' checked={showAnswerQuestion} onChange={() => setShowAnswerQuestion(!showAnswerQuestion)} />
                                        <label className='form-check-label text-nowrap' htmlFor='showAnswerQuestion'>{t('joinMeeting.lblOnlyAnswer')}</label>
                                    </div>
                                    {isHost &&
                                        <div className='form-check'>
                                            <input type="checkbox" id='showArchiveQuestion' className='form-check-input' checked={showArchiveQuestion} onChange={() => setShowArchiveQuestion(!showArchiveQuestion)} />
                                            <label className='form-check-label text-nowrap' htmlFor='showArchiveQuestion'>{t('joinMeeting.lblOnlyArchive')}</label>
                                        </div>
                                    }
                                    <div className='form-check'>
                                        <input type="checkbox" id='showUnanswerQuestion' className='form-check-input' checked={showUnanswerQuestion} onChange={() => setShowUnanswerQuestion(!showUnanswerQuestion)} />
                                        <label className='form-check-label text-nowrap' htmlFor='showUnanswerQuestion'>{t('joinMeeting.lblOnlyUnanswer')}</label>
                                    </div>
                                </div>
                                <div className='hstack justify-content-between gap-3 border-top p-2'>
                                    <button type="reset" className='btn btn-danger text-white btn-sm' onClick={() => handleResetFilter()}>{t('joinMeeting.btnResetFilter')}</button>
                                    <button type="button" className='btn btn-primary btn-sm' onClick={handleApplyFilter}>{t('joinMeeting.btnApply')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={questionsListRef} onScroll={scrollBottomCheck} className="conversation-list">
                    <ConversationList
                        isHistory={false}
                        isHost={isHost}
                        isGuest={isGuest}
                        userId={userId}
                        meetingQuestions={meetingQuestions}
                        currentMeetingHostId={currentMeetingHostId}
                        guestUserId={guestUserId}
                        handleUpvoteMeetingQuestion={handleUpvoteMeetingQuestion}
                        getUpvotedAvaliable={getUpvotedAvaliable}
                        getUpvotedCount={getUpvotedCount}
                        editQuestion={editQuestion}
                        handleArchiveMeetingQuestion={handleArchiveMeetingQuestion}
                        handleOnClickReplyButton={handleOnClickReplyButton}
                        deleteConfirmHandler={deleteConfirmHandler}
                        handleUpvoteMeetingQuestionReply={handleUpvoteMeetingQuestionReply}
                        getReplyUpvotedAvaliable={getReplyUpvotedAvaliable}
                        deleteConfirmHandlerReply={deleteConfirmHandlerReply}
                    />
                    <div ref={messagesEndRef} />
                </div>
                <div>
                    <Formik
                        initialValues={initialValuesSendQuestion}
                        onSubmit={handleSendQuestion}
                        enableReinitialize={false}
                    >
                        <Form>
                            <div className='hstack gap-1 gap-sm-4'>
                                <div className='flex-fill position-relative'>
                                    <Field
                                        as="textarea"
                                        name="question"
                                        className="form-control border-0"
                                        id="question"
                                        placeholder={t('searchplaceholder.question')}
                                        innerRef={(input: any) => { questionBoxRef.current = input }}
                                        value={questionBox}
                                        onChange={(e: any) => {
                                            setQuestionBox(e.target.value)
                                        }}
                                        onKeyDown={(e: any) => {
                                            if (e.ctrlKey && e.key === 'Enter') {
                                                e.currentTarget.form?.requestSubmit();
                                            }
                                        }}
                                    >
                                    </Field>
                                    <ErrorMessage name="question" component="div" className="text-danger validation-absolute" />
                                </div>
                                <div className='hstack gap-1 gap-sm-2'>
                                    {isEditQuestion &&
                                        <button className="btn shadow btn-light rounded-1" onClick={resetQuestionBox}>
                                            <i className="fa fa-close"></i>
                                        </button>
                                    }
                                    <button type='submit' className="btn shadow btn-light rounded-1">
                                        <i className="fa-solid fa-paper-plane text-primary"></i>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
                {
                    scrollBottom &&
                    <button onClick={scrollToBottom} className="scroll-bottom"><i className="fa-solid fa-angles-down"></i></button>
                }
            </div>

            {/* modals starts here */}
            <QuestionReplyModal
                replyModal={replyModal}
                hideReplyModal={hideReplyModal}
                replyQuestionId={replyQuestionId}
                currentMeetingId={currentMeetingId}
            />
            {/* modals ends here */}

            {/* alerts actions starts heres */}
            <DialogBox show={showDelete} clickOk={() => { handleDeleteMeetingQuestion(meetingQuestionDeleteId); setShowDelete(false) }} clickCancel={() => { setShowDelete(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltquestion')} />
            <DialogBox show={showReplyDelete} clickOk={() => { handleDeleteMeetingQuestionReply(meetingQuestionReplyDeleteId); setShowReplyDelete(false) }} clickCancel={() => { setShowReplyDelete(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltReply')} />
            {/* alerts actions ends heres */}
        </>
    )
}

export default QuestionTab