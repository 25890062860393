import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { dashboardCount } from '../../../services/dashbord.service';
import * as actions from '../actions/index';

/* eslint-disable @typescript-eslint/no-unused-expressions */


//dashboard initial states
export interface DashboardState {
    dashboardDataCount: any[]
}

const initialState: DashboardState = {
    dashboardDataCount: []
}

//dashboard api thunks
export const dashboardHandler: any = createAsyncThunk(actions.DASHBOARD,
    (data, { rejectWithValue }) =>
        dashboardCount(data).catch(
            (error) => error && rejectWithValue(error)
        )
);

//dashboardSlice
export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(dashboardHandler.fulfilled, (state, action) => {
            state.dashboardDataCount = action.payload?.data;
        })
        builder.addCase(dashboardHandler.rejected, (state, action) => {
            console.error('Dashboard request failed:', action.error.message);
        });
    },
})

export default dashboardSlice.reducer;