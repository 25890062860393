import React from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IUser } from "../../types/user.type";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { forgotPasswordHandler } from '../store/slices/authSlice';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
const ForgotPassword = () => {
    //single hooks starts here
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    //single hooks ends here


    //useSelectors starts here
    const { forgotPasswordIsLoading } = useSelector((state: RootState) => state.auth)
    //useSelectors ends here


    //functions starts here

    const handleRegister = async (formValue: IUser, { resetForm }: any) => {
        const email = formValue?.email;
        const data = {
            email
        }
        try {

            await dispatch(forgotPasswordHandler(data)).then((result: any) => {
                if (result?.payload?.statusCode === 404) {
                    toast.error(result?.payload?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    });

                }
                else if (result?.payload?.statusCode === 201) {
                    toast.success(result?.payload?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    });
                    resetForm();
                    navigate('/signin')
                }
            }).catch((error: any) => {
                console.log(error)
            })
        } catch (error) {
            console.error(error, "error");
        }

    };
    //functions ends here



    //formik starts here
    const initialValues: any = {
        email: "",
    };
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .email(t('validation.notValidEmail'))
            .test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ),


    });
    //formik ends here   


    return (
        <div className="signup-wrap d-flex flex-column">
            <div className="hstack justify-content-center flex-fill">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 col-xxl-4 col-xl-6 my-1">
                            <div className="card border-0 rounded-4">
                                <div className="card-body d-flex flex-column flex-fill justify-content-center px-3 px-sm-5">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleRegister}
                                    >
                                        <Form>
                                            <h1 className="fs-4 fw-bold text-center mb-3">{t('signIn.lblForgotPassword')}</h1>

                                            <div className="mb-4">
                                                <Field name="email" placeholder={t('signUp.placeholderEmail')}
                                                    className="form-control" />
                                                <ErrorMessage name="email" component="div" className="text-danger txt-error" />

                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className='hstack justify-content-center'>
                                                    <Link to="/signin" className="text-decoration-none">{t('signUp.linkLogin')}</Link>
                                                </div>
                                                <button className="btn btn-primary rounded-3 hstack gap-2" type="submit" disabled={forgotPasswordIsLoading}>
                                                    {forgotPasswordIsLoading && <Spinner animation={'border'} size='sm' />}
                                                    {t('signIn.lblResetPassword')}
                                                </button>
                                            </div>

                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword
