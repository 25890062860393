import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import * as Yup from "yup";
import { changePassword } from "../../services/auth.service";

const ChangePassword = () => {
    //single hooks starts here
    const { t } = useTranslation('common');
    //single hooks ends here


    //useSelectors starts here
    //useSelectors ends here


    //useState starts here
    const [showOldPassword, setShowOldPassword] = useState<boolean>(true)
    const [showNewPassword, setShowNewPassword] = useState<boolean>(true)
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(true)
    const [changePasswordLoading, setChangePasswordLoading] = useState<boolean>(false)
    //useState ends here


    //formik starts here
    const initialValues: any = {
        oldPassword: '',
        newPassword: "",
        confirmPassword: ''
    };
    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string()
            .required(t('validation.requiredField')).transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .matches(/^[A-Za-z0-9#@$\s]+$/, (t('validation.specialcharacterPassword')))
            .test(
                "len",
                t('validation.PasswordLimit'),
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
                }
            ).test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ),
        newPassword: Yup.string()
            .required(t('validation.requiredField')).transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .matches(/^[A-Za-z0-9#@$\s]+$/, (t('validation.specialcharacterPassword')))
            .test(
                "len",
                t('validation.PasswordLimit'),
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
                }
            ).test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ).notOneOf([Yup.ref('oldPassword'), null], 'New password cannot be the same as old password'),


        confirmPassword: Yup.string()
            .required(t('validation.requiredField')).transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .matches(/^[A-Za-z0-9#@$\s]+$/, (t('validation.specialcharacterPassword')))
            .test(
                "len",
                t('validation.PasswordLimit'),
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 40;
                }
            ).test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ).oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    });
    //formik ends here


    //functions starts here
    const handleRegister = async (formValue: any, { resetForm }: any) => {
        if (changePasswordLoading) {
            return
        }
        setChangePasswordLoading(true)
        const oldPassword = formValue?.oldPassword
        const newPassword = formValue?.newPassword

        await changePassword(oldPassword, newPassword)
            .then((result) => {
                if (result?.statusCode === 201) {
                    toast.success(result?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    });
                    resetForm();
                    setChangePasswordLoading(false)
                }
                else if (result?.statusCode === 400) {
                    toast.error(result?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    });
                    setChangePasswordLoading(false)
                }
            })
            .catch((err: any) => {
                console.error(err, "error");
                setChangePasswordLoading(false)
            })

    };
    //functions ends here
    return (
        <div className="container-fluid">
            <div className="font-26 fw-semibold mb-3 mb-md-4">Change Password</div>
            <div className="row">
                <div className="col-md-8 col-xl-6 my-1">
                    <div className="card border-0">
                        <div className="card-body">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleRegister}
                            >
                                <Form>
                                    <div className="password-toggle mb-4">
                                        <Field name="oldPassword" placeholder={t('ChangePassword.oldPassword')}
                                            className="form-control" type={showOldPassword ? "password" : "text"} />
                                        <button type='button' onClick={() => setShowOldPassword(prevState => !prevState)} className="password-toggle-btn btn btn-link p-0 text-black">
                                            <i className={`fa-solid ${showOldPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                        </button>
                                        <ErrorMessage name="oldPassword" component="div" className="text-danger txt-error" />
                                    </div>
                                    <div className="password-toggle mb-4">
                                        <Field name="newPassword" placeholder={t('ChangePassword.newPassword')}
                                            className="form-control" type={showNewPassword ? "password" : "text"} />
                                        <button type='button' onClick={() => setShowNewPassword(prevState => !prevState)} className="password-toggle-btn btn btn-link p-0 text-black">
                                            <i className={`fa-solid ${showNewPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                        </button>
                                        <ErrorMessage name="newPassword" component="div" className="text-danger txt-error" />
                                    </div>
                                    <div className="password-toggle mb-4">
                                        <Field name="confirmPassword" placeholder={t('ChangePassword.confirmPassword')}
                                            className="form-control" type={showConfirmPassword ? "password" : "text"} />
                                        <button type='button' onClick={() => setShowConfirmPassword(prevState => !prevState)} className="password-toggle-btn btn btn-link p-0 text-black">
                                            <i className={`fa-solid ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                        </button>
                                        <ErrorMessage name="confirmPassword" component="div" className="text-danger txt-error" />
                                    </div>
                                    <div className="text-end">
                                        <button className="btn btn-primary" type="submit" disabled={changePasswordLoading}  >
                                            Set new Password
                                        </button>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;
