/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useRef, useState } from 'react'
import { Accordion, Modal, useAccordionButton } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { createMeetingModalHandler, startMeetingModalHandler } from '../../store/slices/modalSlice';
import { alphanumericRegex, generateMeetingCode } from '../../../common/utils';
import { meetingCreateHandler, messageHandler, updateMeetingIdHandler } from '../../store/slices/meetingSlice';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { IMeeting } from '../../../types/meeting.type';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import de from 'date-fns/locale/de';
import MapComponent from '../MapComponent';
import { meetingGetByID, meetingupdateByID } from '../../../services/meeting.service';
import { PASSWORD } from '../../../types/commonTypes';

registerLocale('de', de)

const CreateMeetingModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');


    const brandUploadCount = process.env.REACT_APP_NUMBER_OF_BRAND_UPLOAD_IMAGES ?? 'defaultBrandCount';
    const sponsorUploadCount = process.env.REACT_APP_NUMBER_OF_SPONSORS_UPLOAD_IMAGES ?? 'defaultBrandCount';


    const { codeExistMessage, updateMeetingId } = useSelector((state: RootState) => state.meeting);
    const { createMeetingModal } = useSelector((state: RootState) => state.modal);
    const isUpdate = !!updateMeetingId && updateMeetingId > 0 ? true : false


    const brandFilesRef = useRef<HTMLInputElement>(null);
    const sponsorsFilesRef = useRef<HTMLInputElement>(null);


    const [passwordProtected, setPasswordProtected] = useState<boolean>(false);
    const [emailProtected, setEmailProtected] = useState<boolean>(false);
    const [geoFenceProtected, setGEOFenceProtected] = useState<boolean>(false);
    const [newLatitude, setNewLatitude] = useState<number>(0);
    const [newLongitude, setNewLongitude] = useState<number>(0);
    const [meetingPassword, setMeetingPassword] = useState("");
    const [meetingTitle, setMeetingTitle] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [brandFiles, setBrandFiles] = useState<any[]>([]);
    const [sponsorsFiles, setSponsorsFiles] = useState<any[]>([]);
    const [meetingCode, setMeetingCode] = useState(generateMeetingCode());
    const [brandErr, setBrandErr] = useState(false)
    const [sponsorErr, setSponsorError] = useState(false)
    const [fileValidationError, setFileValidationError] = useState(false);
    const [sponserfileValidationError, setSponserFileValidationError] = useState(false);
    const [fileSizevalidation, setFileSizevalidation] = useState(false);
    const [sponserfileSizevalidation, setsponserFileSizevalidation] = useState(false);
    const [newRandomCodes, setRandomCodes] = useState<any[]>([]);
    const [editMeetingData, setEditMeetingData] = useState<any>();
    const [createMeetingLoading, setCreateMeetingLoading] = useState(false);
    const [passwordType, setPasswordType] = useState(PASSWORD);


    const initialValues: IMeeting = {
        title: meetingTitle.trim(),
        code: meetingCode.trim(),
        password: meetingPassword,
        passwordProtected: passwordProtected,
        startTime: new Date(startDate),
        endTime: new Date(endDate)
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .test(
                "len",
                t('validation.minlengthTitle'),
                (val: any) =>
                    val &&
                    val.toString().length >= 3
            )
            .test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ),
        code: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .test(
                "len",
                t('validation.minlengthCode'),
                (val: any) =>
                    val &&
                    val.toString().length >= 3
            )
            .test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ).test(
                "alphanumeric-dash",
                "Special characters other than '-' are not allowed",
                (val: any) => alphanumericRegex.test(val)
            ),
        passwordProtected: Yup.boolean(),
        password: Yup.string().when("passwordProtected", {
            is: true,
            then: Yup.string()
                .required(t('validation.requiredField'))
                .trim(t('trimMessage.trimPassword'))
                .matches(/^[A-Za-z0-9#@!%^&*()_+\-=[\]{}|;:'",.<>?/\\`~]+$/, (t('validation.specialcharacterMeetingPassword')))
                .strict(true)
                .test(
                    "len",
                    t('validation.minlengthPassword'),
                    (val: any) =>
                        val &&
                        val.toString().length >= 3
                ),
        }),

        startTime: Yup.date()
            .nullable()
            .required(t('validation.requiredField'))
            .min(new Date(), t('validation.startTime')),
        endTime: Yup.date()
            .nullable()
            .required(t('validation.requiredField'))
            .when('startTime', (startTime, schema) => {
                const expiryDate = moment(startTime);
                const enteredDate = moment(endDate);
                if (!enteredDate.isAfter(expiryDate)) {
                    const currentDay = new Date(startTime.getTime());
                    return schema
                        .min(currentDay, t('validation.endTime'))
                } else {
                    return schema;
                }
            })

    });

    const togglePassword = () => {
        if (passwordType === PASSWORD) {
            setPasswordType("text")
            return;
        }
        setPasswordType(PASSWORD)
    }

    const clearForm = () => {
        setEditMeetingData(null)
        setPasswordProtected(false);
        setEmailProtected(false);
        setGEOFenceProtected(false);
        setNewLatitude(0);
        setNewLongitude(0);
        setMeetingPassword("");
        setMeetingTitle('');
        setStartDate(new Date());
        setEndDate(new Date());
        setBrandFiles([]);
        setSponsorsFiles([]);
        setMeetingCode(generateMeetingCode());
        dispatch(messageHandler(''));
        dispatch(updateMeetingIdHandler(0));
        setBrandErr(false)
        setSponsorError(false)
        setFileValidationError(false)
        setSponserFileValidationError(false)
        setFileSizevalidation(false)
        setsponserFileSizevalidation(false)
        const brandFilesRefd = brandFilesRef.current;
        const sponsorsFilesRefd = sponsorsFilesRef.current;

        if (brandFilesRefd) {
            brandFilesRefd.value = '';
            brandFilesRefd.focus();
        }
        if (sponsorsFilesRefd) {
            sponsorsFilesRefd.value = '';
            sponsorsFilesRefd.focus();
        }
    };

    const handleMeetingCreate = async (formValue: IMeeting) => {
        if (createMeetingLoading) {
            return
        }
        const { title, code, password, startTime, endTime } = formValue;
        const newTitle = title.trim();
        const newCode = code.trim();
        const rawSponsorMaxFileCount = process.env.REACT_APP_NUMBER_OF_SPONSORS_UPLOAD_IMAGES;
        const sponsorMaxFileCount = rawSponsorMaxFileCount ? parseInt(rawSponsorMaxFileCount, 10) : 0;
        const meetingPassword = passwordProtected ? password : '';
        if (brandFiles.length <= 3 && sponsorsFiles.length <= sponsorMaxFileCount && !fileValidationError && !sponserfileValidationError && !fileSizevalidation && !sponserfileSizevalidation) {

            if (isUpdate) {
                await meetingupdateByID(updateMeetingId, newTitle, newCode, meetingPassword, startDate, endDate, emailProtected, passwordProtected, geoFenceProtected, newLongitude, newLatitude, brandFiles, sponsorsFiles)
                    .then(() => {
                        toast.success(String(t('toastMessage.meetingUpdate')), {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2500,
                        });
                        clearForm()
                        dispatch(createMeetingModalHandler(false))
                        setCreateMeetingLoading(false)
                    }
                    ).catch((error: any) => {
                        dispatch(messageHandler(error.response?.data?.message));
                    })
            } else {
                const MeetingValues = { newTitle, newCode, meetingPassword, startTime, endTime, emailProtected, passwordProtected, geoFenceProtected, newLongitude, newLatitude, brandFiles, sponsorsFiles }
                await dispatch(meetingCreateHandler(MeetingValues)).then((res: any) => {
                    if (res?.type === 'createMeeting/fulfilled') {
                        toast.success(String(t('toastMessage.meetingCreate')), {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2500,
                        });
                        clearForm()
                        dispatch(createMeetingModalHandler(false))
                        dispatch(startMeetingModalHandler(true))
                        setCreateMeetingLoading(false)
                    }
                })
            }
        } else {
            if (brandFiles.length > 3) {
                setBrandErr(true);
            }
            if (sponsorsFiles.length > sponsorMaxFileCount) {
                setSponsorError(true);
            }
        }
    };

    const handleRandomCodeClick = (code: any) => {
        setMeetingCode(code);
        dispatch(messageHandler(''));
    };

    const filterPassedStartTime = (time: any) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const filterPassedEndTime = (time: any) => {
        const meetingStartDate = new Date(startDate);
        const selectedDate = new Date(time);

        return meetingStartDate.getTime() < selectedDate.getTime();
    };

    const updatedBrandFilesValidation = t('deafultLeftLayout.hostMeeting.lblBrandFilesValidation', { brandUploadCount })
        .replace('{BrandUploadCount}', brandUploadCount)
        .replace('{BrandUploadCount}', brandUploadCount)


    const updatedSponsorFilesValidation = t('deafultLeftLayout.hostMeeting.lblSponsorFilesValidation', { brandUploadCount })
        .replace('{SponsorUploadCount}', sponsorUploadCount)
        .replace('{SponsorUploadCount}', sponsorUploadCount)


    const handleChangeLatitude = (latitude: number) => {
        setNewLatitude(latitude)
    }
    const handleChangeLongitude = (longitude: number) => {
        setNewLongitude(longitude)
    }

    const handleGeoFenceProtected = (e: any) => {
        setGEOFenceProtected(e.target.checked);
        if (!geoFenceProtected) {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    setNewLatitude(position.coords.latitude)
                    setNewLongitude(position.coords.longitude)
                }, function (error) {
                    if (error.code === error.PERMISSION_DENIED) {
                        toast.warning(String(t('toastMessage.enableLocationCreateMeeting')), {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                });
            }
        }
    }


    useEffect(() => {
        if (isUpdate) {
            meetingGetByID(updateMeetingId).then((res: any) => {
                setEditMeetingData(res)
                setMeetingTitle(res.title)
                setMeetingCode(res.code)
                setMeetingPassword(!!res.password ? res.password : '')
                setStartDate(new Date(res.startTime));
                setEndDate(new Date(res.endTime));
                setEmailProtected(res.isEmailProtected)
                setPasswordProtected(res.isPasswordProtected)
                setGEOFenceProtected(res.isGeoFenceProtected)
                setNewLatitude(res.location.coordinates[1])
                setNewLongitude(res.location.coordinates[0])
            })
        }
    }, [isUpdate])

    useEffect(() => {
        if (!isUpdate) {
            setStartDate((new Date(new Date().setTime(new Date().getTime() + (5 * 60 * 1000)))));
            setEndDate(((new Date(new Date().setTime(new Date().getTime() + (30 * 60 * 1000))))));
        }
    }, [createMeetingModal])


    interface CustomToggleProps {
        eventKey: string
    }

    const CustomToggle: FC<CustomToggleProps> = ({ eventKey }) => {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <button
                type="button"
                onClick={decoratedOnClick}
                className='btn ps-0'
            >
                <span className='fw-medium font-18'>{t('deafultLeftLayout.hostMeeting.lblMeetingTime')} </span>
                <span className='ms-2'>
                    <i className='fa fa-edit'></i>
                </span>
            </button>
        );
    }

    return (
        <Modal centered backdrop="static" show={createMeetingModal} onHide={() => { dispatch(createMeetingModalHandler(false)); clearForm() }}>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='fw-semibold'> {isUpdate ? t('deafultLeftLayout.hostMeeting.header') : t('common.createMeeting')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="font-22 fw-medium mb-4">{t('deafultLeftLayout.hostMeeting.tagLine')}</div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleMeetingCreate}
                    >
                        <Form id='hostMeetingForm' className='vstack gap-4'>
                            <div>
                                <label htmlFor="meeting-title" className="form-label">{t('deafultLeftLayout.hostMeeting.lblTitle')}</label>
                                <Field onChange={(e: any) => setMeetingTitle(e.target.value)} type="text" name='title' className="form-control" id="meeting-title" placeholder={t('deafultLeftLayout.hostMeeting.placeholderMeetingTitle')} value={meetingTitle} />
                                <ErrorMessage name="title" component="div" className="text-danger txt-error" />
                            </div>

                            <div>
                                <label htmlFor="meeting-host-code" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingCode')}</label>
                                <div className='form-control'>
                                    {meetingCode}
                                </div>
                                {/* <Field type="text" onChange={(e: any) => setMeetingCode(e.target.value.split(" ").join(""))} name='code' className="form-control" id="meeting-host-code" placeholder={t('deafultLeftLayout.hostMeeting.placeholderMeetingCode')} value={meetingCode} /> */}
                                <ErrorMessage name="code" component="div" className="text-danger txt-error" />
                                {!!codeExistMessage && codeExistMessage.length > 0 && (
                                    <div className="form-group">
                                        <div
                                            role="alert"
                                        >
                                            {codeExistMessage}
                                        </div>
                                    </div>
                                )}
                                {!isUpdate && newRandomCodes && newRandomCodes.length > 0 &&
                                    <div className="hstack flex-wrap gap-3 mt-3">
                                        <div>
                                            <span title='New available codes generation, Click to use code from here.'>Suggestions:</span>
                                        </div>
                                        <div className="d-flex flex-wrap gap-2">
                                            {newRandomCodes.map((item, index) => (
                                                <span
                                                    key={`newRandomCodes_${index}`}
                                                    onClick={() => handleRandomCodeClick(item)}
                                                    className="meeting-code-tag"
                                                >
                                                    {item}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="form-check form-check-inline">
                                <input className="form-check-input" checked={emailProtected} onChange={(e: any) => setEmailProtected(e.target.checked)} type="checkbox" id="emailProtectedCheckbox" />
                                <label className="form-label mb-0" htmlFor="emailProtectedCheckbox">{t('deafultLeftLayout.hostMeeting.lblEmailProtected')}</label>
                            </div>

                            <div className='d-flex justify-content-between flex-wrap gap-3'>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" checked={passwordProtected} onChange={(e: any) => setPasswordProtected(e.target.checked)} type="checkbox" id="passwordProtectedCheckbox" />
                                    <label className="form-label mb-0" htmlFor="passwordProtectedCheckbox">{t('common.lblPasswordProtected')}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" checked={geoFenceProtected} onChange={handleGeoFenceProtected} type="checkbox" id="geoFenceProtectedCheckbox" />
                                    <label className="form-label mb-0" htmlFor="geoFenceProtectedCheckbox">{t('common.lblGeoFenceProtected')}</label>
                                </div>
                            </div>

                            {passwordProtected &&
                                <div>
                                    <label htmlFor="meeting-host-password" className="form-label">{t('common.Password')}</label>
                                    <div className='password-toggle'>
                                        <Field type={passwordType} name='password' onChange={(e: any) => setMeetingPassword(e.target.value.split(" ").join(""))} className=" form-control password " id="meeting-host-password" placeholder={t('deafultLeftLayout.hostMeeting.placeholderMeetingPassword')} />
                                        <button type='button' onClick={togglePassword} className="password-toggle-btn btn btn-link p-0 text-black">
                                            <i className={`fa-solid ${passwordType === PASSWORD ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                        </button>
                                        <ErrorMessage name="password" component="div" className="text-danger txt-error" />
                                    </div>
                                </div>
                            }

                            <MapComponent newLatitude={newLatitude} newLongitude={newLongitude} setNewLatitude={handleChangeLatitude} setNewLongitude={handleChangeLongitude} />

                            <Accordion defaultActiveKey={isUpdate ? "meetingTime" : ""} className='border-top pt-3'>
                                <CustomToggle eventKey="meetingTime" />
                                <Accordion.Collapse eventKey="meetingTime">
                                    <div className="row g-4 mt-0">
                                        <div className="col-md-6">
                                            <label htmlFor="meeting-start-time" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingStartTime')}</label>
                                            <ReactDatePicker locale={window.localStorage.language} className='form-control' name='startTime'
                                                selected={startDate}
                                                onChange={(date: Date) => setStartDate(date)}
                                                minDate={new Date()}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                filterTime={filterPassedStartTime}
                                                dateFormat="MMM d, yyyy hh:mm aa"
                                                showTimeSelect
                                            />

                                            <ErrorMessage name="startTime" component="div" className="text-danger txt-error" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="meeting-end-time" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingEndTime')}</label>
                                            <ReactDatePicker locale={window.localStorage.language} className='form-control' name='endTime'
                                                selected={endDate}
                                                onChange={(date: Date) => setEndDate(date)}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                filterTime={filterPassedEndTime}
                                                dateFormat="MMM d, yyyy hh:mm aa"
                                                showTimeSelect
                                            />
                                            <ErrorMessage name="endTime" component="div" className="text-danger txt-error" />
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>

                            <div>
                                <div className='hstack justify-content-between'>
                                    <label htmlFor="meeting-host-formFile-brand" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingUploadBrand')}</label>
                                    <span><small>{t('deafultLeftLayout.hostMeeting.lblUploadOptional')}</small></span>
                                </div>
                                <input ref={brandFilesRef} className="form-control" type="file" accept=".jpg, .jpeg, .png" id="meeting-host-formFile-brand" onChange={(event: any) => {
                                    const bfiles = event.target.files;
                                    let mybFiles: any = Array.from(bfiles);
                                    const brandimge = process.env.REACT_APP_NUMBER_OF_BRAND_UPLOAD_IMAGES;
                                    if (mybFiles.length === 0) {
                                        setFileValidationError(false)
                                        setFileSizevalidation(false)
                                    }
                                    if (mybFiles.length > Number(brandimge)) {
                                        setBrandErr(true);
                                    } else {
                                        for (let i = 0; i < mybFiles.length; i++) {
                                            const fileType = mybFiles[i].type;
                                            const fileSize = mybFiles[i].size;
                                            if (fileSize > 2 * 1024 * 1024) {
                                                setFileSizevalidation(true)
                                                break;
                                            } else {
                                                setFileSizevalidation(false)
                                            }

                                            if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg') {
                                                setFileValidationError(true)
                                            } else {
                                                setFileValidationError(false)
                                            }
                                        }
                                        setBrandErr(false);
                                    }
                                    setBrandFiles(mybFiles)
                                }} />
                                <span style={{ color: "red" }}>
                                    {fileValidationError ? t("validation.FormatNotAllowed")
                                        : brandErr ? updatedBrandFilesValidation : fileSizevalidation ? t("validation.FileSizeValidation") : ""}
                                </span>
                            </div>

                            {isUpdate &&
                                !!editMeetingData &&
                                !!editMeetingData.brandImageUrls &&
                                editMeetingData.brandImageUrls.length > 0 &&
                                <div className='d-flex flex-wrap gap-3 mb-4'>
                                    {
                                        editMeetingData.brandImageUrls.map((imageData: any, index: number) => {
                                            return (
                                                <div key={`isUpdate_brandImageUrls_${index}`} className="image-profile-page">
                                                    <img className='img-fluid' src={imageData.brandImageUrl} alt='brandImage' />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                            <div>
                                <div className='hstack justify-content-between'>
                                    <label htmlFor="meeting-host-formFile-sponsors" className="form-label">{t('deafultLeftLayout.hostMeeting.lblMeetingUploadSponsors')}</label>
                                    <span><small>{t('deafultLeftLayout.hostMeeting.lblUploadOptional')}</small></span>
                                </div>
                                <input ref={sponsorsFilesRef} className="form-control" type="file" accept=".jpg, .jpeg, .png" id="meeting-host-formFile-sponsors" multiple={true} onChange={(event: any) => {
                                    const sfiles = event.target.files;
                                    let mysFiles: any = Array.from(sfiles);
                                    const rawSponsorMaxFileCount = process.env.REACT_APP_NUMBER_OF_SPONSORS_UPLOAD_IMAGES;
                                    const sponsorMaxFileCount = rawSponsorMaxFileCount ? parseInt(rawSponsorMaxFileCount, 10) : 0;
                                    if (mysFiles.length === 0) {
                                        setSponserFileValidationError(false)
                                        setsponserFileSizevalidation(false)
                                    }
                                    if (mysFiles.length > sponsorMaxFileCount) {
                                        setSponsorError(true);
                                    } else {
                                        for (let i = 0; i < mysFiles.length; i++) {
                                            const fileType = mysFiles[i].type;
                                            const fileSize = mysFiles[i].size;
                                            if (fileSize > 2 * 1024 * 1024) {
                                                setsponserFileSizevalidation(true);
                                                break;
                                            } else {
                                                setSponsorsFiles(mysFiles);
                                                setsponserFileSizevalidation(false);
                                            }
                                            if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg') {
                                                setSponserFileValidationError(true);
                                            } else {
                                                setSponsorsFiles(mysFiles);
                                                setSponserFileValidationError(false);
                                            }
                                        }
                                        setSponsorError(false);
                                    }
                                }} />
                                <span style={{ color: "red" }}>
                                    {sponserfileValidationError
                                        ? t("validation.FormatNotAllowed")
                                        : sponsorErr
                                            ? updatedSponsorFilesValidation : sponserfileSizevalidation ? t("validation.FileSizeValidation")
                                                : ""}
                                </span>
                            </div>

                            {isUpdate && !!editMeetingData &&
                                !!editMeetingData.sponsorImageUrls &&
                                editMeetingData.sponsorImageUrls.length > 0 &&
                                <div className='d-flex flex-wrap gap-3'>
                                    {
                                        editMeetingData.sponsorImageUrls.map((imageData: any, index: number) => {
                                            return (
                                                <div key={`isUpdate_brandImageUrls_${index}`} className="image-profile-page">
                                                    <img className='img-fluid' src={imageData.sponsorImageUrl} alt='sponsorImage' />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                            <div className="text-center">
                                <button type='submit' className="btn btn-primary mw-120" disabled={createMeetingLoading}>{isUpdate ? t('common.update') : t('deafultLeftLayout.hostMeeting.btnHostMeeting')}</button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CreateMeetingModal

