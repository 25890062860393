import React, { FC, useEffect, useRef, useState } from 'react'
import { Model, Serializer } from 'survey-core'
import { ACTIVE, INACTIVE, QUESTIONTIMELS, QUIZTIMELS, QUIZTIMERDATALS } from '../../../types/commonTypes'
import * as SurveyTheme from "survey-core/themes";
import { quizAnswerByAttendeeHandler, quizAnswerByGuestHandler } from '../../store/slices/quizSlice';
import { activeQuizIdAttendeeHandler, activeQuizIdGuestHandler } from '../../store/slices/meetingSlice';
import { Survey } from 'survey-react-ui';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { resetQuizTimer } from '../../../common/utils';
import socket from '../../../common/constants';

interface ActiveQuizProps {
    meetingQuizList: any[]
    activeQuizData: any
    currentMeetingId: number
    allQuizAnswer: any[]
    getSubmittedQuizzes: () => void
}
const ActiveQuiz: FC<ActiveQuizProps> = ({ meetingQuizList, activeQuizData, currentMeetingId, allQuizAnswer, getSubmittedQuizzes }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation('common');


    const maxTimeToFinishPage = activeQuizData?.maxTimeToFinishPage


    const { guestUser, isGuest } = useSelector((state: RootState) => state.guestUser)
    const guestUserId = guestUser?.id

    const spentQuizTimeRef = useRef<any>(0)
    const spentQuestionTimeRef = useRef<any>(0)
    const intervalRef = useRef<any>(0)
    const doSubmitRef = useRef<boolean>(false)
    const quizStartedRef = useRef<boolean>(false)


    const startTimer = () => {
        if (!!maxTimeToFinishPage) {
            const spentQuestionTime: any = localStorage.getItem(QUESTIONTIMELS);
            spentQuestionTimeRef.current = typeof spentQuestionTime === 'object' ? 0 : Number(spentQuestionTime)
        }

        const spentQuizTime: any = localStorage.getItem(QUIZTIMELS);
        spentQuizTimeRef.current = typeof spentQuizTime === 'object' ? 0 : Number(spentQuizTime)
    };

    const stopTimer = () => {
        spentQuizTimeRef.current = 0
        spentQuestionTimeRef.current = 0
        resetQuizTimer()
    };


    useEffect(() => {
        const spentQuizTime = localStorage.getItem(QUIZTIMELS);
        const spentQuestionTime = localStorage.getItem(QUESTIONTIMELS);

        spentQuizTimeRef.current = typeof spentQuizTime === 'object' ? 0 : Number(spentQuizTime)

        if (spentQuestionTimeRef.current === maxTimeToFinishPage) {
            spentQuestionTimeRef.current = 0
        } else {
            spentQuestionTimeRef.current = typeof spentQuestionTime === 'object' ? 0 : Number(spentQuestionTime)
        }

        if (typeof spentQuizTime === 'object') {
            localStorage.removeItem(QUIZTIMELS);
        }
        if (typeof spentQuestionTime === 'object') {
            localStorage.removeItem(QUESTIONTIMELS);
        }

    }, [activeQuizData]);

    useEffect(() => {

        const areAllQuizzesInactive = meetingQuizList?.every(quiz => quiz.status === INACTIVE);
        const activeQuiz = meetingQuizList?.find(quiz => quiz.status === ACTIVE);
        const isCompleted = activeQuiz ? allQuizAnswer?.some(completedQuiz => completedQuiz.quizId === activeQuiz.quizId) : false;
        const showQuizSubmitted = !(isCompleted || areAllQuizzesInactive || meetingQuizList?.length === 0)
        if (showQuizSubmitted) {
            const intervalId = setInterval(() => {
                spentQuizTimeRef.current = Number(spentQuizTimeRef.current) + 1
                localStorage.setItem(QUIZTIMELS, Number(spentQuizTimeRef.current).toString());

                if (!!maxTimeToFinishPage) {
                    if (spentQuestionTimeRef.current === maxTimeToFinishPage) {
                        spentQuestionTimeRef.current = 1
                    } else {
                        spentQuestionTimeRef.current = Number(spentQuestionTimeRef.current) + 1
                    }
                    localStorage.setItem(QUESTIONTIMELS, Number(spentQuestionTimeRef.current).toString());
                }

            }, 1000);
            intervalRef.current = intervalId
        }
        return () => clearInterval(intervalRef.current);

    }, [maxTimeToFinishPage, meetingQuizList]);

    const [doSubmit, setDoSubmit] = useState(false)

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                const areAllQuizzesInactive = meetingQuizList?.every(quiz => quiz.status === INACTIVE);
                const activeQuiz = meetingQuizList?.find(quiz => quiz.status === ACTIVE);
                const isCompleted = activeQuiz ? allQuizAnswer?.some(completedQuiz => completedQuiz.quizId === activeQuiz.quizId) : false;

                const showQuizSubmitted = !(isCompleted || areAllQuizzesInactive || meetingQuizList?.length === 0 || quizStartedRef.current)
                if (showQuizSubmitted) {
                    alert(t("quiz.quizLeaveSubmitted"))
                    setDoSubmit(true)
                    doSubmitRef.current = true
                } else {
                    setDoSubmit(false)
                    doSubmitRef.current = false
                }
            }
        };

        const handleBeforeunload = (e: any) => {
            e.preventDefault();
            e.returnValue = 'Confirm';
        }

        document.addEventListener("visibilitychange", handleVisibilityChange);
        document.addEventListener("beforeunload", handleBeforeunload);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            document.removeEventListener("beforeunload", handleBeforeunload);
        };
    }, [meetingQuizList, allQuizAnswer]);

    return (
        <>
            {!!meetingQuizList &&
                meetingQuizList?.length > 0 &&
                meetingQuizList?.map((quizValue: any, index: number) => {
                    if (quizValue.status === ACTIVE) {
                        const quizId = quizValue.quizId
                        Serializer.addProperty("question", {
                            name: "score:number"
                        });
                        const saveSurveyData = (survey: any) => {
                            var data = undefined;

                            if (spentQuizTimeRef.current === 0) { startTimer() }
                            if (survey.state === 'running') {
                                var data = survey.data;

                                data.pageNo = survey.currentPageNo;
                                data.timeSpent = spentQuizTimeRef.current;
                                data.pageTimeSpent = spentQuestionTimeRef.current;
                            }
                            localStorage.setItem(QUIZTIMERDATALS, JSON.stringify(data));
                        }
                        const survey = new Model(activeQuizData);
                        const prevData = localStorage.getItem(QUIZTIMERDATALS) || null;
                        if (prevData) {
                            var data = JSON.parse(prevData);
                            survey.start();
                            survey.data = data;

                            if (data.pageNo) {
                                survey.currentPageNo = data.pageNo;
                            }
                            survey.timeSpent = spentQuizTimeRef.current;
                            if (data.pageTimeSpent && !!survey.currentPage) {
                                survey.currentPage.timeSpent = spentQuestionTimeRef.current
                            }
                        }
                        survey.onTimer.add((sender, options) => {
                            saveSurveyData(survey);
                        });

                        survey.applyTheme(SurveyTheme.BorderlessLight);
                        const calculateMaxScore = (questions: any) => {
                            var maxScore = 0;
                            questions.forEach((question: any) => {
                                if (!!question.score) {
                                    maxScore += question.score;
                                }
                            });
                            return maxScore;
                        }
                        const calculateTotalScore = (data: any) => {
                            var totalScore = 0;
                            Object.keys(data).forEach((qName) => {
                                const question = survey.getQuestionByValueName(qName);
                                if (!!question && question.isAnswerCorrect()) {
                                    if (!!question.score) {
                                        totalScore += question.score;
                                    }
                                }
                            });
                            return totalScore;
                        }


                        survey.onCompleting.add(async (sender) => {
                            let questionsAnswers = sender.data
                            const totalScore = calculateTotalScore(questionsAnswers);
                            const maxScore = calculateMaxScore(sender.getAllQuestions());
                            const percentageScore = Number((100 * totalScore / maxScore).toFixed(2))
                            stopTimer()
                            socket.emit("quiz-submitted-users-send")
                            socket.on('connect', () => {
                                socket.emit("quiz-submitted-users-send")
                            })
                            if (isGuest) {
                                let data = { meetingId: currentMeetingId, quizId, guestUserId, questionsAnswers, score: percentageScore, quizJson: activeQuizData }
                                await dispatch(quizAnswerByGuestHandler(data))
                                    .then(() => {
                                        getSubmittedQuizzes()
                                        dispatch(activeQuizIdGuestHandler(0))
                                    })
                            } else {
                                let data = { meetingId: currentMeetingId, quizId, questionsAnswers, score: percentageScore, quizJson: activeQuizData }
                                await dispatch(quizAnswerByAttendeeHandler(data))
                                    .then(() => {
                                        getSubmittedQuizzes()
                                        dispatch(activeQuizIdAttendeeHandler(0))
                                    })
                            }
                        });
                        if (doSubmit && doSubmitRef.current) {
                            setDoSubmit(false)
                            doSubmitRef.current = false
                            if (!allQuizAnswer?.some((item: any) => item.quizId === quizId)) {
                                survey.doComplete()
                            }
                        }
                        if (allQuizAnswer?.find((item: any) => item.quizId === quizId)) {
                            return (
                                <div key={`quizSubmitted1_${quizId}${index}`} className='status-block status-block-submitted'>
                                    <div>{quizValue.quiz.title}</div>
                                    <div className='font-16'>{t('quizDetails.quizSubmitted')}</div>
                                </div>
                            )
                        } else {
                            if (survey.isEmpty) {
                                quizStartedRef.current = true
                                return <div key={`quizStarted1_${quizId}${index}`} className='status-block status-block-started'>{t('quizDetails.quizStarted')}</div>
                            } else {
                                quizStartedRef.current = false
                                return (
                                    <div key={`quizRunning1_${quizId}${index}`}>
                                        <div className='mb-2 p-3  fw-medium bg-warning text-white border border-light rounded-4 '>
                                            <h4><i className='fa fa-exclamation-triangle '></i>{t('quiz.quizWarning')}</h4></div>
                                        <Survey model={survey} />
                                    </div>
                                )
                            }
                        }

                    }
                })
            }
            {meetingQuizList?.length >= 0 &&
                !meetingQuizList?.find((quizValue: any) => quizValue?.status === ACTIVE) &&
                <div className="status-block status-block-nodata"> {t('quizDetails.DataNotFound')}</div>
            }
        </>
    )
}

export default ActiveQuiz