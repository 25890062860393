import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { shareMeetingModalHandler } from '../../store/slices/modalSlice';
import QRCode from 'qrcode';
import QRCodeModal from '../Modals/QRCodeModal';

const ShareMeetingMobile = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation('common');

    const { currentMeeting } = useSelector((state: RootState) => state.meeting);
    const currentMeetingCode = currentMeeting?.code


    //useStates starts here
    const [meetingLink, setMeetingLink] = useState('');
    const [srcData, setSrc] = useState('');
    const [qrCodeModal, setQrCodeModal] = useState<boolean>(false);

    //useStates ends here


    const copyCode = async () => {
        if (!!currentMeetingCode) {
            navigator.clipboard.writeText(currentMeetingCode)
            toast.success(String(t('toastMessage.copyCode')), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2500,
            });
        }
    }
    const copyLink = async (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(meetingLink)
        toast.success(String(t('toastMessage.copyLink')), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
        });
    }

    const hideQrCodeModal = () => {
        setQrCodeModal(false)
    }

    //useEffect starts here
    useEffect(() => {
        const hostname = typeof window !== 'undefined' ? window.location.origin : process.env.RESTURL_SPEAKERS;
        const link = hostname + `/guest/login/${currentMeeting?.code}`;
        setMeetingLink(link)
        QRCode.toDataURL(link, { errorCorrectionLevel: 'H', maskPattern: 2 }).then((res) => (setSrc(res)))

    }, [currentMeeting]);
    //useEffect ends here

    return (
        <>
            <div className='hstack justify-content-between flex-wrap gap-2 d-xl-none mb-3'>
                <div className='hstack gap-2 text-truncate'>
                    {
                        !!currentMeeting?.brandImageUrls &&
                        currentMeeting?.brandImageUrls?.length > 0 &&
                        currentMeeting?.brandImageUrls?.map((item: any, index: number) => (
                            <div key={`sharemeeting_brandImageUrls_${index}`}>
                                <img className='company-sponsor size-38' src={item.brandImageUrl} alt='brandImage' />
                            </div>
                        ))
                    }
                    <div className='font-18 fw-medium text-truncate text-uppercase'>{currentMeeting?.title}</div>
                </div>
                <div className='hstack gap-3 flex-wrap'>
                    <button className='btn btn-primary-light text-primary hstack gap-2' title='Copy Meeting Code' onClick={copyCode}><i className="fa-regular fa-copy"></i> {currentMeeting?.code}</button>
                    <button className="btn btn-primary-light text-primary size-38 p-1" title='Copy Meeting Link' onClick={copyLink}><i className="fa-solid fa-link"></i></button>
                    <button className="btn btn-primary-light text-primary size-38 p-1" title='Meeting QR code' onClick={() => setQrCodeModal(true)}><i className="fa-solid fa-qrcode"></i></button>
                    <button className='btn btn-primary-light text-primary size-38 p-1' title='Meeting Details' onClick={() => dispatch(shareMeetingModalHandler(true))}><i className='fa-solid fa-share-nodes'></i></button>
                </div>
            </div>

            <QRCodeModal
                qrCodeModal={qrCodeModal}
                hideQrCodeModal={hideQrCodeModal}
                srcData={srcData}
            />
        </>
    )
}

export default ShareMeetingMobile