/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { IUserLogin } from "../../types/user.type";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import * as ACTION from "../store/actions/index";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { signInHandler, LoginWithGoogle, MicrosoftLogins, signInMessageHandler, signInRememberMeHandler, signInRememberNameHandler } from '../store/slices/authSlice';
import { RootState } from '../store/store';
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import MicrosoftLogin from "react-microsoft-login";
import { logoutGuestUserHandler } from '../store/slices/guestUserSlice';
import { Spinner } from 'react-bootstrap';
import GoogleIcon from '../../images/icon-google-color.svg'

const Signin = () => {
    //single hooks starts here
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    let navigate = useNavigate();
    //single hooks ends here

    //useSelectors starts here
    const { signInMessage: message, signInIsLoading: loading } = useSelector((state: RootState) => state.auth)
    const { signInRememberMe, signInRememberName } = useSelector((state: RootState) => state.auth)
    //useSelectors ends here

    //useStates starts here
    const [passwordType, setPasswordType] = useState("password");
    //useStates ends here

    //functions starts here
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const handleLogin = async (formValue: { username: string; password: string }) => {
        await dispatch(signInHandler(formValue)).then((res: any) => {
            if (res?.type === `${ACTION.LOGIN}/${ACTION.FULFILLED}`) {
                toast.success(String(t('toastMessage.loginSuccessFul')), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                })
                dispatch(logoutGuestUserHandler())
                navigate("/dashboard");
            }
            else {
                if (res?.type === `${ACTION.LOGIN}/${ACTION.REJECTED}`) {
                    dispatch(signInMessageHandler(res?.payload?.response?.data?.message))
                    toast.error(res?.payload?.response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    })
                } else {
                    toast.error(String(t('toastMessage.loginFail')), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    })
                }
            }
        })

    };

    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async codeResponse => {
            await dispatch(LoginWithGoogle(codeResponse.code)).then((res: any) => {
                if (res?.type === `${ACTION.GOOGLELOGIN}/${ACTION.FULFILLED}`) {
                    toast.success(String(t('toastMessage.loginSuccessFul')), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    })
                    dispatch(logoutGuestUserHandler())
                    navigate("/dashboard");
                } else {
                    toast.error(String(t('toastMessage.loginFail')), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    })
                }
            });

        },
        onError: errorResponse => console.log(errorResponse),
    });


    const microsoftLogin = async (err: any, microsoftCredentialResponse: any) => {
        if (err) {
            toast.error(String(t('toastMessage.loginFail')), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2500,
            })
        } else {
            await dispatch(MicrosoftLogins(microsoftCredentialResponse)).then((res: any) => {
                if (res?.type === `${ACTION.MICROSOFTLOGIN}/${ACTION.FULFILLED}`) {
                    toast.success(String(t('toastMessage.loginSuccessFul')), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    })
                    dispatch(logoutGuestUserHandler())
                    navigate("/dashboard");
                } else {
                    toast.error(String(t('toastMessage.loginFail')), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    })
                }
            });
        };
    }
    //functions ends here

    //useEffects starts here
    useEffect(() => {
        if (signInRememberMe === false) {
            dispatch(signInRememberNameHandler(''));
        }
        dispatch(signInMessageHandler())
    }, [])
    //useEffects ends here

    //formik starts here
    const initialValues: IUserLogin = {
        username: signInRememberName,
        password: "",
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .email(t('validation.notValidEmail')),
        password: Yup.string()
            .required(t('validation.requiredField')).transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            }),
    });
    //formik ends here

    return (
        <div className="signup-wrap d-flex flex-column">
            <div className="hstack justify-content-center flex-fill">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 col-xxl-4 col-xl-6 my-1">
                            <div className="card border-0 rounded-4">
                                <div className="card-body d-flex flex-column flex-fill justify-content-center px-3 px-sm-5 py-4">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleLogin}>
                                        <Form className='vstack gap-4'>
                                            <h1 className="fs-4 fw-bold text-center mb-1">{t('header.btnLogin')}</h1>
                                            <div className="signin-option text-center">
                                                <div className='mb-3 d-flex justify-content-center'>
                                                    <button type='button' className='google-signin-btn' onClick={() => googleLogin()}>
                                                        <img src={GoogleIcon} width={19} />
                                                        <span className='flex-fill text-center'>Sign in with Google</span>
                                                    </button>
                                                </div>
                                                <MicrosoftLogin
                                                    clientId={process.env.REACT_APP_MICROSOFT_APP_ID || ''}
                                                    authCallback={microsoftLogin}
                                                    redirectUri={process.env.REACT_APP_BASE_URL}
                                                    children={""}
                                                />
                                            </div>
                                            <div className="text-center"><small>{t('signIn.title')}</small></div>
                                            {message && (
                                                <div className="form-group">
                                                    <div
                                                        role="alert"
                                                    >
                                                        {message}
                                                    </div>
                                                </div>
                                            )}
                                            <div>
                                                <Field name="username" type="text" className="form-control"
                                                    placeholder={t('signIn.placeholderUsername')} />
                                                <ErrorMessage name="username" component="div" className="text-danger txt-error" />
                                            </div>
                                            <div className="password-toggle">
                                                <Field name="password" type={passwordType} className="form-control password"
                                                    placeholder={t('common.Password')} />
                                                <button type='button' onClick={togglePassword} className="password-toggle-btn btn btn-link p-0 text-black">
                                                    <i className={`fa-solid ${passwordType === 'password' ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                </button>
                                                <ErrorMessage name="password" component="div" className="text-danger txt-error" />
                                                <div className='text-end mt-2'>
                                                    <Link to="/forgot-password" className="text-decoration-none">{t('signIn.lblForgotPassword')}</Link>
                                                </div>
                                            </div>
                                            <div className="hstack justify-content-between">
                                                <div className="form-check">
                                                    <Field className="form-check-input" name="isRememberMe" type="checkbox" id="user-remember" checked={signInRememberMe} onChange={(e: any) => dispatch(signInRememberMeHandler(e.target.checked))} />
                                                    <label className="form-check-label" htmlFor="user-remember">
                                                        {t('signIn.lblRememberMe')}
                                                    </label>
                                                </div>
                                                <div>
                                                    <button className="btn btn-primary rounded-3 hstack gap-2" type="submit" disabled={loading}>
                                                        {loading && <Spinner animation={'border'} size='sm' />}
                                                        {t('signIn.btnSignIn')}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <Link to="/signup" className="text-decoration-none">{t('signIn.linkSignUp')}</Link>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signin;