import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { updateMeetingPollStatus } from '../../../services/poll.service'
import socket from '../../../common/constants'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { ACTIVE, ACTIVE1, END, INACTIVE } from '../../../types/commonTypes'

interface ShowPollResultProps {
    meetingPollsList: any[]
    meetingAllPollsAnswerList: any[]
    newSelectedAnswer?: any[]
    isShowPollResult?: boolean
    isHost?: boolean
    isMeetingDetails?: boolean
    deleteConfirmHandlerForPoll?: (id: any) => void
}

const ShowPollResult: FC<ShowPollResultProps> = ({ meetingPollsList, meetingAllPollsAnswerList, newSelectedAnswer = [], isShowPollResult = false, isHost = false, isMeetingDetails = false, deleteConfirmHandlerForPoll }) => {
    const { t } = useTranslation('common');

    const { currentMeeting } = useSelector((state: RootState) => state.meeting)
    const currentMeetingId = currentMeeting?.id

    const isHostResult = !isHost || (isHost && isShowPollResult) || isMeetingDetails

    const getTotalVote = (pollId: number, questionId: number) => {
        let totalQuestionAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId))
        return totalQuestionAnswerdUser.length
    }

    const getTotalPercentage = (pollId: number, questionId: number, questionOptionId: number) => {
        let totalPercentage = 0;
        let totalAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId && x.questionOptionId === questionOptionId))
        let totalQuestionAnswerdUser = meetingAllPollsAnswerList.filter((x: any) => (x.pollId === pollId && x.questionId === questionId))
        totalPercentage = (totalAnswerdUser.length * 100) / totalQuestionAnswerdUser.length;
        let tempTotalpercentage = Number(totalPercentage.toFixed(2))

        return totalPercentage > 0 ? tempTotalpercentage : 0
    }


    const updatePollStatus = async (id: any, status: any) => {
        updateMeetingPollStatus(id, status).then(
            (response) => {
                if (response.status === 200) {
                    socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
                    socket.on('connect', () => {
                        socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
                    })
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }
    const deletePoll = (pollId: any) => {
        if (deleteConfirmHandlerForPoll) {
            deleteConfirmHandlerForPoll(pollId)
        }
    }

    return (
        <>
            {!!meetingPollsList &&
                meetingPollsList.length > 0 &&
                meetingPollsList.map((pollValue: any, i: any) => {
                    if (isHost || pollValue.status !== ACTIVE || isMeetingDetails) {
                        const pollId = pollValue.pollDetails.id
                        return (
                            <div className="card" key={`ShowPollResult_${pollId}`}>
                                <div className='card-body section-question-container'>
                                    <div className={isHost ? 'section-question-action-header' : 'section-question-header'}>
                                        <div className="fw-medium"> {pollValue.pollDetails.title}</div>
                                        {isHost && !isShowPollResult && !isMeetingDetails &&
                                            <div className='hstack gap-2'>
                                                <button className="btn btn-primary rounded-45" onClick={() => updatePollStatus(pollValue.id, pollValue.status)}>{pollValue.status === INACTIVE ? ACTIVE1 : END} Poll</button>
                                                <button className="btn btn-danger rounded-circle size-38 text-white" title={t('common.delete')} onClick={() => deletePoll(pollValue.id)}>
                                                    <i className="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    {!!pollValue.pollDetails.pollsQuestionDetails &&
                                        pollValue.pollDetails.pollsQuestionDetails.length > 0 &&
                                        pollValue.pollDetails.pollsQuestionDetails.map((pollsQuestionValue: any, j: any) => {
                                            const questionId = pollsQuestionValue.questionDetails.id
                                            return (
                                                <div className='section-question-block' key={'pollAnswer_question1' + questionId}>
                                                    <div className="section-question">
                                                        <div className="section-question-header">
                                                            <div className="fw-medium">{pollsQuestionValue.questionDetails.title}</div>
                                                            {isHostResult &&
                                                                <span>{t('pastMeetingDetails.lblTotalVote')} {getTotalVote(pollId, questionId)}</span>
                                                            }
                                                        </div>

                                                        <ul className={`section-option-block ${isHostResult ? 'list-unstyled' : ''}`}>
                                                            {!!pollsQuestionValue.questionDetails.questionOptions &&
                                                                pollsQuestionValue.questionDetails.questionOptions.length > 0 &&
                                                                pollsQuestionValue.questionDetails.questionOptions.map((questionOptionsValue: any, k: any) => {
                                                                    const questionOptionId = questionOptionsValue.id
                                                                    const totalPercentage = getTotalPercentage(pollId, questionId, questionOptionId)
                                                                    const isSelected = newSelectedAnswer.some((answer: any) =>
                                                                        answer.pollId === pollId &&
                                                                        answer.questionId === questionId &&
                                                                        answer.questionOptionId === questionOptionId
                                                                    );
                                                                    return (
                                                                        <li className="section-option" key={'pollAnswer_question_option1' + questionOptionId}>
                                                                            <div>{questionOptionsValue.optionName}</div>

                                                                            {isHostResult &&
                                                                                <div className="progress poll-progress">
                                                                                    <div className={`progress-bar ${isSelected ? 'bg-danger' : ''}`} role="progressbar" aria-label="Basic example" aria-valuenow={totalPercentage} style={{ width: totalPercentage + "%" }}>
                                                                                        {totalPercentage} %
                                                                                    </div>
                                                                                </div>

                                                                            }
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div >
                        )
                    }
                    return null
                })
            }
            {
                !isHost && meetingPollsList?.length <= 0 && !meetingPollsList.some((pollValue: any) => pollValue?.status !== ACTIVE) &&
                <div className="status-block status-block-nodata"> {t('pollDetails.DataNotFound')}</div>
            }
        </>
    )
}

export default ShowPollResult