/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../../store/store';
import { findMeetingModalHandler, meetingPasswordModalHandler } from '../../store/slices/modalSlice';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MeetingPasswordModal from './MeetingPasswordModal';
import { IMeetingJoin } from '../../../types/meeting.type';
import { attendeeWaitingListHandler, checkUserAccessHandler, currentMeetingHandler, isAllowedHandler, joinMessageHandler, meetingGetByCodeCheckingHandler, meetingHandler, setMeetingSessionHandler } from '../../store/slices/meetingSlice';
import { meetingGetByCode } from '../../../services/meeting.service';
import { useNavigate } from 'react-router-dom';
import socket from '../../../common/constants';
import { alphanumericRegex } from '../../../common/utils';
import { CREATED, ENDED, INPROGRESS, STARTED } from '../../../types/commonTypes';
import NearbyMeetings from '../NearbyMeetings';

const FindMeetingModal = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { t } = useTranslation('common');

    const inputRef = useRef<HTMLInputElement>(null);
    const { user } = useSelector((state: RootState) => state.auth);
    const { nearMeetingList, passwordProtectedJoin, joinStatus } = useSelector((state: RootState) => state.meeting);
    const { findMeetingModal } = useSelector((state: RootState) => state.modal);
    const userId = user?.user?.id;

    const [meetingCodeHome, setMeetingCodeHome] = useState("");
    const [newLatitudeJoin, setNewLatitudeJoin] = useState<number>(0);
    const [newLongitudeJoin, setNewLongitudeJoin] = useState<number>(0);
    const [LocationdisbleMessage, setLocationdisbleMessage] = useState<string>("");

    const initialValuesJoinMeeting: IMeetingJoin = {
        passwordProtected: passwordProtectedJoin,
        joinPassword: ""
    }

    const btnJoinMeetingByCode = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setNewLatitudeJoin(position.coords.latitude);
            setNewLongitudeJoin(position.coords.longitude);
        },
            function (error) {
                if (error.code === error.PERMISSION_DENIED) {
                    toast.warning(String(t('toastMessage.enableLocationJoinMeeting')), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            });
        const inputElement = inputRef.current;
        if (inputElement) {
            inputElement.value = '';
            inputElement.focus();
        }
        setMeetingCodeHome(meetingCodeHome);
        if (!alphanumericRegex.test(meetingCodeHome)) {
            toast.error(String(t('validation.enterValidCode')), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2500,
            });
        }
        else {
            joinMeetingHost(meetingCodeHome);

        }
    }

    const joinMeetingHost = (meetingCode: any) => {
        const form = initialValuesJoinMeeting
        const { joinPassword } = form;
        dispatch(meetingGetByCodeCheckingHandler({ code: meetingCode, loggedInUser: userId })).then((res: any) => {
            if (res?.type === 'checkingCode/fulfilled') {
                if (res?.payload?.status === 200) {
                    if (res?.payload?.data?.status !== INPROGRESS) {
                        if (res?.payload?.data?.status === CREATED) {
                            toast.error(String(t('toastMessage.NotStartedMeeting')), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2500,
                            });
                        }
                        else if (res?.payload?.data?.status === STARTED) {
                            toast.success(String(t('toastMessage.NotStartedMeetingByHost')), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2500,
                            });
                        }
                        else if (res?.payload?.data?.status === ENDED) {
                            toast.success(String(t('toastMessage.meetingEnded')), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2500,
                            });
                        }

                    }
                    else {
                        if (res?.payload?.data?.isPasswordProtected) {
                            dispatch(findMeetingModalHandler(false))
                            if (joinStatus === true) {
                                dispatch(meetingPasswordModalHandler(true))

                            } else {
                                toast.warning(String(t('toastMessage.waitingListReject')), {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 3000,
                                });
                            }
                        }
                        else {
                            checkAccess(meetingCode, joinPassword, newLongitudeJoin, newLatitudeJoin)
                        }
                    }
                }
            } else {
                toast.error(String(t('toastMessage.meetingNotFound')), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                });
            }
        })

    }

    const checkAccess = (meetingCodeHome: any, joinPassword: any, newLongitudeJoin: any, newLatitudeJoin: any) => {
        const formValue = { meetingCodeHome, joinPassword, newLongitudeJoin, newLatitudeJoin, loggedInUser: userId }
        meetingGetByCode(meetingCodeHome, 0, 0).then((resMeet: any) => {
            dispatch(checkUserAccessHandler(formValue)).then(async (res: any) => {
                if (res?.type === 'checkUserAccess/fulfilled') {
                    const state = store.getState();
                    meetingGetByCode(meetingCodeHome, 0, 0).then(async (meetingData: any) => {
                        if (res.payload.data.joinStatus === true) {
                            dispatch(isAllowedHandler(true))
                            const guestUserId = 0
                            let sessionFormValue = { meetingCodeHome, userId, guestUserId }
                            let sessionManage = await dispatch(setMeetingSessionHandler(sessionFormValue))
                            if (sessionManage?.payload?.status === 200) {
                                dispatch(findMeetingModalHandler(false))
                                dispatch(meetingPasswordModalHandler(false))
                                setTimeout(() => {
                                    dispatch(currentMeetingHandler(resMeet.data))
                                    navigate('joinMeeting/' + meetingCodeHome)
                                }, 1000);
                            } else {
                                dispatch(joinMessageHandler(t('toastMessage.meetingNotLongerAvailable')))
                            }
                        } else {
                            if (resMeet?.data?.isPasswordProtected) {
                                if (res.payload.data.passwordAuthenticated) {
                                    if (meetingData.data.isGeoFenceProtected) {
                                        /**
                                         * @todo: Refactor this functionality
                                         */
                                        // NOTE: Changed by Gaurang/Junaid. Let's verifye this functionality again.
                                        if (res.payload.data.joinStatus === true) {
                                            dispatch(isAllowedHandler(true))
                                            const guestUserId = 0
                                            let sessionFormValue = { meetingCodeHome, userId, guestUserId }
                                            let sessionManage = await dispatch(setMeetingSessionHandler(sessionFormValue))
                                            if (sessionManage?.payload?.status === 200) {
                                                dispatch(findMeetingModalHandler(false))
                                                dispatch(meetingPasswordModalHandler(false))
                                                setTimeout(() => {
                                                    dispatch(currentMeetingHandler(resMeet.data))
                                                    navigate('joinMeeting/' + meetingCodeHome)
                                                }, 1000);
                                            } else {
                                                dispatch(joinMessageHandler(t('toastMessage.meetingNotLongerAvailable')))
                                            }
                                        } else {
                                            const guestUserId = 0
                                            const attendeeWaitingFormValue = { meetingId: resMeet.data.id, hostId: resMeet.data.hostId, userId, guestUserId }
                                            if (resMeet.data.hostId !== userId) {
                                                dispatch(attendeeWaitingListHandler(attendeeWaitingFormValue)).then((res: any) => {
                                                    if (res?.payload?.status === 201) {
                                                        dispatch(findMeetingModalHandler(false))
                                                        dispatch(meetingPasswordModalHandler(false))
                                                        socket.emit("add-waiting-user", { meetingId: resMeet.data.id, user: state?.auth?.user ? state?.auth?.user : state?.guestUser?.guestUser })
                                                        setTimeout(() => {
                                                            dispatch(currentMeetingHandler(resMeet.data))
                                                            navigate(`/waiting-area/` + resMeet.data.code)
                                                        }, 1000);
                                                    } else {
                                                        if (res?.payload?.data.statusCode === 403) {
                                                            dispatch(findMeetingModalHandler(false))
                                                            dispatch(meetingPasswordModalHandler(false))
                                                            setTimeout(() => {
                                                                dispatch(currentMeetingHandler(resMeet.data))
                                                                navigate(`/waiting-area/` + resMeet.data.code)
                                                            }, 1000);
                                                        } else if (res?.payload?.data?.statusCode === 307) {
                                                            dispatch(findMeetingModalHandler(false))
                                                            dispatch(meetingPasswordModalHandler(false))
                                                            setTimeout(() => {
                                                                dispatch(currentMeetingHandler(resMeet.data))
                                                                navigate(`joinMeeting/` + resMeet.data.code)
                                                            }, 1000);
                                                        } else if (res?.payload?.data?.statusCode === 409) {
                                                            dispatch(currentMeetingHandler(null))
                                                            dispatch(findMeetingModalHandler(false))
                                                            dispatch(meetingPasswordModalHandler(false))
                                                            toast.warning(res?.payload?.data?.message, {
                                                                position: toast.POSITION.TOP_RIGHT,
                                                                autoClose: 2500,
                                                            });
                                                            navigate('/dashboard');
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    } else {

                                        const guestUserId = 0
                                        let sessionFormValue = { meetingCodeHome, userId, guestUserId }
                                        let sessionManage = await dispatch(setMeetingSessionHandler(sessionFormValue))
                                        if (sessionManage?.payload?.status === 200) {
                                            dispatch(findMeetingModalHandler(false))
                                            dispatch(meetingPasswordModalHandler(false))
                                            setTimeout(() => {
                                                dispatch(currentMeetingHandler(resMeet.data))
                                                navigate('joinMeeting/' + meetingCodeHome)
                                            }, 1000);
                                        } else {
                                            dispatch(joinMessageHandler(t('toastMessage.meetingNotLongerAvailable')))
                                        }
                                    }
                                } else {
                                    return toast.error(res?.payload?.data?.message, {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 2500,
                                    });
                                }
                            } else {
                                if (resMeet.data?.isGeoFenceProtected) {
                                    if (res.payload.data.joinStatus === true) {
                                        dispatch(isAllowedHandler(true))
                                        const guestUserId = 0
                                        let sessionFormValue = { meetingCodeHome, userId, guestUserId }
                                        let sessionManage = await dispatch(setMeetingSessionHandler(sessionFormValue))
                                        if (sessionManage?.payload?.status === 200) {
                                            dispatch(findMeetingModalHandler(false))
                                            dispatch(meetingPasswordModalHandler(false))
                                            setTimeout(() => {
                                                dispatch(currentMeetingHandler(resMeet.data))
                                                navigate('joinMeeting/' + meetingCodeHome)
                                            }, 1000);
                                        } else {
                                            dispatch(joinMessageHandler(t('toastMessage.meetingNotLongerAvailable')))
                                        }
                                    } else {
                                        const guestUserId = 0
                                        const attendeeWaitingFormValue = { meetingId: resMeet.data.id, hostId: resMeet.data.hostId, userId, guestUserId }
                                        if (resMeet.data.hostId !== userId) {
                                            dispatch(attendeeWaitingListHandler(attendeeWaitingFormValue)).then((res: any) => {
                                                if (res?.payload?.status === 201) {
                                                    dispatch(findMeetingModalHandler(false))
                                                    dispatch(meetingPasswordModalHandler(false))
                                                    socket.emit("add-waiting-user", { meetingId: resMeet.data.id, user: state?.auth?.user ? state?.auth?.user : state?.guestUser?.guestUser })

                                                    setTimeout(() => {
                                                        dispatch(currentMeetingHandler(resMeet.data))
                                                        navigate(`/waiting-area/` + resMeet.data.code)
                                                    }, 1000);
                                                } else {
                                                    if (res?.payload?.data.statusCode === 403) {
                                                        dispatch(findMeetingModalHandler(false))
                                                        dispatch(meetingPasswordModalHandler(false))
                                                        setTimeout(() => {
                                                            dispatch(currentMeetingHandler(resMeet.data))
                                                            navigate(`/waiting-area/` + resMeet.data.code)
                                                        }, 1000);
                                                    } else if (res?.payload?.data?.statusCode === 307) {
                                                        dispatch(findMeetingModalHandler(false))
                                                        dispatch(meetingPasswordModalHandler(false))
                                                        setTimeout(() => {
                                                            dispatch(currentMeetingHandler(resMeet.data))
                                                            navigate(`joinMeeting/` + resMeet.data.code)
                                                        }, 1000);
                                                    } else if (res?.payload?.data?.statusCode === 409) {
                                                        dispatch(currentMeetingHandler(null))
                                                        setTimeout(() => {
                                                            dispatch(findMeetingModalHandler(false))
                                                            dispatch(meetingPasswordModalHandler(false))
                                                        }, 1000);
                                                        toast.warning(res?.payload?.data?.message, {
                                                            position: toast.POSITION.TOP_RIGHT,
                                                            autoClose: 2500,
                                                        });
                                                        navigate('/dashboard')
                                                    }
                                                }
                                            })
                                        }
                                    }
                                } else {
                                    const guestUserId = 0
                                    let sessionFormValue = { meetingCodeHome, userId, guestUserId }
                                    let sessionManage = await dispatch(setMeetingSessionHandler(sessionFormValue))
                                    if (sessionManage?.payload?.status === 200) {
                                        dispatch(findMeetingModalHandler(false))
                                        dispatch(meetingPasswordModalHandler(false))
                                        setTimeout(() => {
                                            dispatch(currentMeetingHandler(resMeet.data))
                                            navigate('joinMeeting/' + meetingCodeHome)
                                        }, 1000);
                                    } else {
                                        dispatch(joinMessageHandler(t('toastMessage.meetingNotLongerAvailable')))
                                    }
                                }
                            }
                        }
                    })
                }
            })
        })
    }

    const nearMeetingJoin = (nearMeetingCode: any) => {
        setMeetingCodeHome(nearMeetingCode);
        joinMeetingHost(nearMeetingCode);
    }

    const functionMeetingNearMe = async (latitude: number, longitude: number) => {
        try {
            const formValue = {
                latitude, longitude
            }
            await dispatch(meetingHandler(formValue)).unwrap();

        } catch (err) {
            console.log(err);
        }
    }

    const refreshNearMeeting = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setNewLatitudeJoin(position.coords.latitude);
            setNewLongitudeJoin(position.coords.longitude);
            functionMeetingNearMe(position.coords.longitude, position.coords.latitude);
        },
            function (error) {
                if (error.code === error.PERMISSION_DENIED) {
                    setLocationdisbleMessage(t('toastMessage.enableLocationFindMeeting'));
                }
            });
    }

    useEffect(() => {
        if (findMeetingModal) {
            refreshNearMeeting()
        }
    }, [findMeetingModal])

    return (
        <>
            <Modal centered show={findMeetingModal} onHide={() => dispatch(findMeetingModalHandler(false))}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='fw-semibold'>{t('common.findMeeting')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-group mb-3">
                        <input ref={inputRef} type="text" onChange={(e: any) => { setMeetingCodeHome(e.target.value.trim()) }} className="form-control rounded-custom" placeholder={t('common.placeholderCode')} />
                        <button disabled={!!meetingCodeHome && meetingCodeHome.length < 3 ? true : false} className="btn btn-primary" onClick={btnJoinMeetingByCode} type="button" id="button-addon2">{t('common.btnGo')}</button>
                    </div>
                    <div className='mb-3 text-black text-center'>
                        {t('common.lblOr')}
                    </div>
                    <div className="font-22 fw-medium mb-4">{t('deafultLeftLayout.joinMeetingModal.lblNearBy')}</div>
                    <NearbyMeetings nearByMeetings={nearMeetingList} nearMeetingJoin={nearMeetingJoin} />
                    {!!nearMeetingList &&
                        nearMeetingList.length === 0 &&
                        <div className='d-flex flex-wrap align-items-center'>
                            {LocationdisbleMessage}
                            <button type='button' onClick={refreshNearMeeting} className='btn btn-link'>{t('deafultLeftLayout.joinMeetingModal.btnClickHere')}</button>
                        </div>
                    }
                </Modal.Body>
            </Modal>

            <MeetingPasswordModal checkAccess={checkAccess} meetingCodeHome={meetingCodeHome} newLongitudeJoin={newLongitudeJoin} newLatitudeJoin={newLatitudeJoin} />
        </>
    )
}

export default FindMeetingModal