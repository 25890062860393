/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, FC, useEffect, useState } from "react";
import type { XYCoord } from "react-dnd";
import { useDragLayer } from "react-dnd";

import { BoxDragPreview } from "./BoxDragPreview";
import { DragItemTypes } from "../../../types/dragItemTypes";
import { snapToGrid } from "../../../common/utils";


function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
    isSnapToGrid: boolean
) {
    if (!initialOffset || !currentOffset) {
        return {
            display: "none",
        };
    }

    let { x, y } = currentOffset;

    if (isSnapToGrid) {
        x -= initialOffset.x;
        y -= initialOffset.y;
        [x, y] = snapToGrid(x, y);
        x += initialOffset.x;
        y += initialOffset.y;
    }

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}

export interface CustomDragLayerProps {
    questionContainerRef: React.RefObject<HTMLDivElement>
}

export const CustomDragLayer: FC<CustomDragLayerProps> = ({ questionContainerRef }) => {
    const layerStyles: CSSProperties = {
        position: "fixed",
        pointerEvents: "none",
        zIndex: 1060,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
    };

    const [previewBoxWidth, setPreviewBoxWidth] = useState(300)

    const { itemType, isDragging, item, initialOffset, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }));

    const WithSnapToGrid = (snapToGrid: boolean) => {
        return (
            <div style={getItemStyles(initialOffset, currentOffset, snapToGrid)}>
                <BoxDragPreview title={item.title} width={previewBoxWidth} />
            </div>
        )
    }

    const renderItem = () => {
        switch (itemType) {
            case DragItemTypes.QUESTION:
                return WithSnapToGrid(false);
            case DragItemTypes.SELECTEDQUESTION:
                return WithSnapToGrid(true);
            case DragItemTypes.RANKINGOPTION:
                return WithSnapToGrid(true);
            default:
                return null;
        }
    }

    useEffect(() => {
        if (questionContainerRef && questionContainerRef.current) {
            setPreviewBoxWidth(questionContainerRef.current?.clientWidth - 80)
        }
    }, [])

    if (!isDragging) {
        return null;
    }
    return (
        <div style={layerStyles}>
            {renderItem()}
        </div>
    );
};
