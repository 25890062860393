import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../pages/store/store';
import { miniSidebarHandler, tourEnableHandler, stepIndexHandler } from '../pages/store/slices/settingsSlice';
import { debounce } from 'lodash';
import { hideBackButtonHandler, tourCompleteHandler } from '../pages/store/slices/authSlice';
import IconCreateMeeting from "../images/icon-create-meeting.svg"
import IconDashboard from "../images/icon-dashboard.svg"
import IconFindMeeting from "../images/icon-find-meeting.svg"
import IconLogout from "../images/icon-logout.svg"
import IconMyMeetings from "../images/icon-my-meetings.svg"
import IconPoll from "../images/icon-poll.svg"
import IconQuiz from "../images/icon-quiz.png"
import { createMeetingModalHandler, findMeetingModalHandler, userQuizResultModalHandler } from '../pages/store/slices/modalSlice';
import StartMeetingModal from '../pages/components/Modals/StartMeetingModal';
import FindMeetingModal from '../pages/components/Modals/FindMeetingModal';
import CreateMeetingModal from '../pages/components/Modals/CreateMeetingModal';
import CreateMeetingImage from '../images/tour/CreateMeeting.webp'
import FindMeetingImage from '../images/tour/FindaMeeting.webp'
import MymeetingMeetingImage from '../images/tour/MyMeetings.webp'
import PollListImage from '../images/tour/PollList.webp'
import QuizListImage from '../images/tour/QuizList.webp'
import ProfileImage from '../images/tour/ProfileImage.webp'
import Logo from '../images/logo.webp'


const Sidebar = () => {
  //single hooks starts here
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation('common');
  //single hooks ends here


  //useSelectors starts here
  const { tourComplete, hideBackButton } = useSelector((state: RootState) => state.auth);
  const { tourEnable, stepIndex } = useSelector((state: RootState) => state.settings);
  const tourMeeingListWidth = window.innerWidth <= 1180 ? '680px' : '900px'
  //useSelectors ends here


  const steps: any = [
    {
      target: "body",
      content: (
        <div className='tour-item'>
          <img src={Logo} alt="Logo" width="100" className='h-auto rounded' />

          <h1 className='font-34 mt-5 mb-4' >{t("common.welcome_tour")}</h1>
        </div>
      ),
      disableBeacon: true,
      placement: "center",
      styles: {
        options: {
          primaryColor: '#3C5EAB', // Change the primary button color
          zIndex: 10000,
          width: "700px",
        },
      },
    },
    {
      target: "#tour-find-meeting",
      content: (
        <div className='tour-item'>
          <img src={FindMeetingImage} className="img-fluid" />
        </div>
      ),
      placement: "right",
      hideBackButton: hideBackButton,
      styles: {
        options: {
          zIndex: 10000,
          width: "600px",
          primaryColor: '#3C5EAB',
        },
      },
    },
    {
      target: "#tour-create-meeting",
      content: (
        <div className='tour-item'>
          <img src={CreateMeetingImage} className="img-fluid" />
        </div>
      ),
      disableBeacon: true,
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
          width: "500px",
          primaryColor: '#3C5EAB',
        },
      },
    },
    {
      target: "#tour-meeting-list",
      content: (
        <div className='tour-item'>
          <img src={MymeetingMeetingImage} className="img-fluid" />
        </div>
      ),
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
          width: tourMeeingListWidth,
          primaryColor: '#3C5EAB',
        },
      },
    },
    {
      target: "#tour-poll",
      content: (
        <div className='tour-item'>
          <img src={PollListImage} className="img-fluid" />
        </div>
      ),
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
          width: "900px",
          primaryColor: '#3C5EAB',
        },
      },
    },
    {
      target: "#tour-quiz",
      content: (
        <div className='tour-item'>
          <img src={QuizListImage} className="img-fluid" />
        </div>
      ),
      placement: "right",
      styles: {
        options: {
          zIndex: 10000,
          width: "900px",
          primaryColor: '#3C5EAB',
        },
      },
    },
    {
      target: "#tour-profile",
      content: (
        <div className='tour-item'>
          <img src={ProfileImage} className="img-fluid" />
        </div>
      ),
      placement: "right",
      locale: {
        last: 'Done'
      },
      styles: {
        options: {
          zIndex: 10000,
          width: "600px",
          primaryColor: '#3C5EAB',
        },
      },
    },
  ];


  //useEffects starts here
  useEffect(() => {
    if (tourComplete) {
      dispatch(hideBackButtonHandler(false));
      dispatch(tourEnableHandler(true))
    } else {
      dispatch(stepIndexHandler(0))
      dispatch(hideBackButtonHandler(true));
    }
  }, [])

  useEffect(() => {
    const navigation = () => {
      var width = window.innerWidth;
      if (width >= 992 && width <= 1496.98) {
        dispatch(miniSidebarHandler(true));
      } else {
        dispatch(miniSidebarHandler(false));
      }

      if (store.getState().modal.userQuizResultModal) {
        dispatch(userQuizResultModalHandler(false))
      }

    }
    navigation();
    const debouncedNavigation = debounce(navigation, 100);
    window.addEventListener("resize", debouncedNavigation);
    return () => window.removeEventListener("resize", debouncedNavigation);
  }, [])
  //useEffects ends here



  //functions starts here
  const closeSidebarMobile = () => {
    if (window.innerWidth < 992) {
      dispatch(miniSidebarHandler(false))
    }
  }

  const handleCallback = (data: any) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      dispatch(stepIndexHandler(index + (action === ACTIONS.PREV ? -1 : 1)))
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      dispatch(tourEnableHandler(false))
      dispatch(hideBackButtonHandler(true));
      dispatch(stepIndexHandler(1))
      dispatch(tourCompleteHandler(false));

    }
    if (action === "close" || type === "finished") {
      dispatch(tourEnableHandler(false))
      dispatch(hideBackButtonHandler(true));
      dispatch(stepIndexHandler(1))
      dispatch(tourCompleteHandler(false));
    }
  };

  const handleTour = () => {
    dispatch(tourEnableHandler(true))
    dispatch(stepIndexHandler(1))
    navigate("/dashboard");
  }
  //functions end here

  return (
    <>
      <Joyride
        callback={handleCallback}
        steps={steps}
        run={tourEnable}
        continuous={true}
        stepIndex={stepIndex}
        showSkipButton={true}
        scrollToFirstStep
      />
      <nav className="align-items-start bg-primary" id="side-menu">
        <div className="flex-column h-100" id="mainNavigation">
          <div className="d-flex flex-column align-items-center justify-content-between h-100">
            <ul className="navbar-nav flex-column gap-4 mt-4 h-100">
              <li className="nav-item">
                <Link onClick={closeSidebarMobile} className="nav-link fw-medium" to="/dashboard">
                  <span className="hstack gap-4">
                    <span className="size-32">
                      <img className="img-fluid" src={IconDashboard} alt={t('deafultLeftLayout.navigation.dashboard')} />
                    </span>
                    <span className="nav-link-label flex-fill">{t('deafultLeftLayout.navigation.dashboard')}</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <button className="btn nav-link fw-medium" onClick={() => { dispatch(findMeetingModalHandler(true)); closeSidebarMobile() }}>
                  <span className="hstack gap-4" id="tour-find-meeting">
                    <span className="size-32">
                      <img className="img-fluid" src={IconFindMeeting} alt={t('common.findMeeting')} />
                    </span>
                    <span className="nav-link-label flex-fill">{t('common.findMeeting')}</span>
                  </span>
                </button>
              </li>
              <li className="nav-item">
                <button className="btn nav-link fw-medium" onClick={() => { dispatch(createMeetingModalHandler(true)); closeSidebarMobile() }}>
                  <span className="hstack gap-4" id="tour-create-meeting">
                    <span className="size-32">
                      <img className="img-fluid" src={IconCreateMeeting} alt={t('common.createMeeting')} />
                    </span>
                    <span className="nav-link-label flex-fill">{t('common.createMeeting')}</span>
                  </span>
                </button>
              </li>
              <li className="nav-item">
                <Link onClick={closeSidebarMobile} className="nav-link fw-medium" to="/meeting"  >
                  <span className="hstack gap-4" id='tour-meeting-list'>
                    <span className="size-32">
                      <img className="img-fluid" src={IconMyMeetings} alt={t('deafultLeftLayout.navigation.myMeeting')} />
                    </span>
                    <span className="nav-link-label flex-fill">{t('deafultLeftLayout.navigation.myMeeting')}</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeSidebarMobile} className="nav-link fw-medium" to="/poll" >
                  <span className="hstack gap-4" id='tour-poll'>
                    <span className="size-32">
                      <img className="img-fluid" src={IconPoll} alt={t('common.Poll')} />
                    </span>
                    <span className="nav-link-label flex-fill">{t('common.Poll')}</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={closeSidebarMobile} className="nav-link fw-medium" to="/quiz" >
                  <span className="hstack gap-4" id='tour-quiz'>
                    <span className="size-32">
                      <img className="img-fluid" src={IconQuiz} alt={t('common.Quiz')} />
                    </span>
                    <span className="nav-link-label flex-fill">{t('common.Quiz')}</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item d-flex flex-column flex-fill justify-content-end">
                <Link onClick={closeSidebarMobile} className="nav-link fw-medium" to="/logout">
                  <span className="hstack gap-4">
                    <span className="size-32">
                      <img className="img-fluid" src={IconLogout} alt={t('deafultLeftLayout.navigation.logout')} />
                    </span>
                    <span className="nav-link-label flex-fill">{t('deafultLeftLayout.navigation.logout')}</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item pb-5 pb-md-0">
                <button className="btn nav-link fw-medium mb-5 mb-md-0" onClick={handleTour}>
                  <span className="hstack gap-4">
                    <span className="size-32">
                      <i className="font-32 fa-regular fa-circle-question"></i>
                    </span>
                    <span className="nav-link-label flex-fill">{t("deafultLeftLayout.navigation.help")}</span>
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <FindMeetingModal />

      <StartMeetingModal />

      <CreateMeetingModal />

    </>
  );
};

export default Sidebar;
