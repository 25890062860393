import React, { FC, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { userQuizResultModalHandler } from '../../store/slices/modalSlice';
import QuizResult from '../Quiz/QuizResult';
import QuizPDFDownload from '../Quiz/QuizPdfDownload';
import { convertToSlug } from '../../../common/utils';
import { useTranslation } from 'react-i18next';

interface UserQuizResultModalProps {
  quiz: any;
}

const UserQuizResultModal: FC<UserQuizResultModalProps> = ({ quiz }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const { userQuizResultModal } = useSelector((state: RootState) => state.modal);

  const modalContentRef = useRef(null);

  const filename = !!quiz.guestUserId ?
    (quiz.questionAnswerGuestUser.isAnonymous ? 'guest-scorecard' : `${quiz.questionAnswerGuestUser.name}-scorecard`)
    : `${quiz?.questionAnswerHostUser?.firstName}-${quiz?.questionAnswerHostUser?.lastName}-scorecard`;

  const quizTitle = quiz?.quizJson?.title || 'UnknownQuizTitle';

  // Used convertToSlug to create a slug from quizTitle
  const slugQuizTitle = convertToSlug(quizTitle);
  const pdfFileName = `${slugQuizTitle}-${filename}`;

  return (
    <Modal show={userQuizResultModal} centered onHide={() => dispatch(userQuizResultModalHandler(false))} className='quiz-result-modal' dialogClassName="quiz-result-dialog" backdropClassName='quiz-result-backdrop' id='quiz-pd'>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title className='fw-semibold me-2'>Detailed Result</Modal.Title>
      <QuizPDFDownload containerRef={modalContentRef} filename={pdfFileName} linkTitle={t('pdfLinkTitle.detailedResult')} />
      </Modal.Header>
      <Modal.Body ref={modalContentRef}>
        <div className='hstack justify-content-between gap-2 mb-3'>
          <div className='fw-medium font-20'>
            <span>Name: </span>
            <span>
              {!!quiz.guestUserId ?
                quiz.questionAnswerGuestUser.isAnonymous ? 'Anonymous' : quiz.questionAnswerGuestUser.name
                : quiz?.questionAnswerHostUser?.firstName + ' ' + quiz?.questionAnswerHostUser?.lastName}
            </span>
          </div>
        </div>
        <QuizResult
          key={`showQuizResultModal_${quiz.quizId}`}
          quiz={quiz}
        />
      </Modal.Body>
    </Modal>
  );
};

export default UserQuizResultModal;
