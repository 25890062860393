import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { shareMeetingModalHandler } from '../../store/slices/modalSlice';
import ShareMeetingDetails from '../ShareMeeting/ShareMeetingDetails';


interface ShareMeetingModalProps {
    userId: number
    currentMeeting: any
}
const ShareMeetingModal: FC<ShareMeetingModalProps> = ({ userId, currentMeeting }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation('common');

    const { shareMeetingModal } = useSelector((state: RootState) => state.modal);
    return (

        <Modal centered show={shareMeetingModal} onHide={() => dispatch(shareMeetingModalHandler(false))}>
            <Modal.Header closeButton>
                <Modal.Title>{t('shareMeeting.header')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ShareMeetingDetails userId={userId} currentMeeting={currentMeeting} />
            </Modal.Body>
        </Modal>

    )
}

export default ShareMeetingModal