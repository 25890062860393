/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  DatatableWrapper,
  Filter,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import DialogBox from "../components/DeleteBox";
import { useTranslation } from "react-i18next";
import { DeletePollHandler, PollDataHandler, selectedQuestionHandler } from "../store/slices/pollSlice";
import { useNavigate } from 'react-router-dom';
import PaginationBox from '../components/Pagination';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";

const PollList = () => {

  //single hooks starts here
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation('common');
  //single hooks ends here


  //useSelectors starts here
  const { user } = useSelector((state: RootState) => state.auth)
  const { pollList, totalPage, totalRecord } = useSelector((state: RootState) => state.poll);
  const userId = user?.user?.id
  //useSelectors ends here


  //useStates starts here
  const [showDelete, setShowDelete] = useState(false);
  const [PollDeleteID, setPollDeleteID] = useState();
  const [pageSize, setpageSize] = useState(1); // Page number
  const [perPage, setperPage] = useState(10); // per page Limit
  const [filterValue, setFilterValue] = useState("");
  const [endItem, setendItem] = useState(0);
  const [startItem, setStartItem] = useState(1);
  const [sortColumn, setsortColumn] = useState<any>({ order: "desc", prop: "createdAt" }); // per page Limit 
  //useStates ends here


  //functions starts here
  const getPollData = async (
    hostID: any,
    pageLimit: number,
    perPage: number,
    searchVal: string,
    sortColumn: any
  ) => {
    const sortColumnProp = sortColumn.prop;
    const sortColumnOrder = sortColumn.order
    const data = {
      hostID, pageLimit, perPage, searchVal, sortColumnProp, sortColumnOrder
    };
    const pollDataList = await dispatch(PollDataHandler(data));
    setpageSize(pollDataList?.payload?.currentPage)
  };

  const handlePrevPage = (e: number) => {
    setpageSize(e);
    setStartItem(startItem - perPage);
    totalrecordHandle();
  };

  const handleNextPage = (e: number) => {
    setpageSize(e);
    setStartItem(endItem + 1);
    totalrecordHandle();
  };

  const handlePageClick = (e: any) => {
    setpageSize(e);
    setStartItem(e * perPage + 1 - perPage);
    totalrecordHandle();
  };

  const totalrecordHandle = () => {
    var totalRecordTemp = startItem - 1 + perPage;
    if (totalRecordTemp > totalRecord) {
      totalRecordTemp = totalRecord;
    }
    setendItem(totalRecordTemp);
  };

  const deleteConfirmHandler = (id: any) => {
    setShowDelete(true)
    setPollDeleteID(id)
  }

  const DeletePollHandlers = async (id: any) => {
    await dispatch(DeletePollHandler(id)).then((response: any) => {
      if (response?.payload?.status === 200) {
        getPollData(userId, pageSize, perPage, filterValue, sortColumn);
        toast.success(String(t("toastMessage.pollDelete")), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      }
    })
  };

  const EditPollHandler = async (id: any) => {
    navigate("/poll/" + id);
  };
  //functions ends here


  //useEffects starts here
  useEffect(() => {
    getPollData(userId, pageSize, perPage, filterValue, sortColumn);
  }, [perPage, pageSize, sortColumn, filterValue]);

  useEffect(() => {
    totalrecordHandle()
  }, [startItem, endItem, totalRecord, pollList]);
  //useEffects ends here


  //table header starts here
  const header = [
    {
      title: 'Poll Name',
      prop: "title",
      isSortable: true,
      isFilterable: true,
    },
    {
      title: t("poll.lblTag"),
      prop: "tag",
      cell: (row: any) => {
        return (
          row.pollsMeetingDetails.length > 0 &&
          row.pollsMeetingDetails.map((tags: any, index: any) => {
            return (
              <div
                key={index}
                className="badge bg-primary-light text-primary rounded-pill badge-pill py-2 fw-medium me-2 mb-2">
                <div>{tags?.meetingDetails?.code}</div>
              </div>
            );
          })
        );
      },
    },
    // {
    //     title: t('poll.lblStatus'), prop: 'status',
    //     cell: (row :any) => {
    //         return (
    //             <>
    //                 {row.pollsMeetingDetails.length === 0 ? (
    //                     <>

    //                     </>
    //                 ) :
    //                     (
    //                         row.pollsMeetingDetails.map((tags: any, index: any) => {
    //                             return (
    //                                 <div key={index} className="badge rounded-pill badge-pill bg-danger text-white py-2 fw-medium mw-120">
    //                                     <div>{tags.status}</div>
    //                                 </div>
    //                             )
    //                         })
    //                     )

    //                 }

    //             </>

    //         )
    //     }
    // },
    // {
    //     title: t('poll.lblVotes'), prop: 'votes',
    //     cell: (row :any) => {
    //         return (
    //             <>
    //                 <div className="fw-medium">35</div>
    //             </>
    //         )
    //     }
    // },
    {
      title: "Actions",
      prop: "actions",
      thProps: {
        className: "text-end"
      },
      cell: (row: any) => {
        return (
          <>
            <div className="hstack justify-content-end gap-2">
              {row.pollsMeetingDetails.length === 0 &&
                <button
                  title="Delete"
                  onClick={() => deleteConfirmHandler(row.id)}
                  className="btn bg-danger text-danger bg-opacity-25 rounded-1 size-38">
                  <i className="fa-solid fa-trash"></i>
                </button>
              }
              <button
                title="Edit"
                onClick={() => EditPollHandler(row.id)}
                className="btn bg-success bg-opacity-25 rounded-1 size-38">
                <i className="fa-solid fa-pen text-success"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ];
  //table header ends here

  return (
    <div className="container-fluid">
      <div className="poll">
        <div className="row">
          <div className="col-12">
            <div className="hstack justify-content-between mb-4">
              <div className="font-26 fw-semibold">{t("poll.title")}</div>
              <button
                onClick={() => { navigate('/poll/0'); dispatch(selectedQuestionHandler([])) }}
                className="btn btn-primary mw-120">
                {t("poll.btnCreatePoll")}
              </button>
            </div>
            <div className="card shadow-sm">
              <div className="card-body">
                <DatatableWrapper body={pollList} headers={header}>
                  <div className="d-flex justify-content-end mb-3">
                    <Filter
                      classes={{
                        inputGroup: 'w-auto',
                        input: "rounded-pill rounded-end"
                      }}
                      placeholder={t("searchplaceholder.placeholder")}
                      controlledProps={{
                        filter: filterValue,
                        onFilterChange: setFilterValue,
                      }}
                    />
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <TableHeader
                        controlledProps={{
                          onSortChange: setsortColumn,
                          sortState: sortColumn,
                        }}
                      />
                      <TableBody />
                    </table>
                  </div>

                  <PaginationBox
                    currentPage={pageSize}
                    totalPage={totalPage}
                    startItem={startItem}
                    endItem={endItem}
                    totalRecord={totalRecord}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                    handlePageClick={handlePageClick}
                  />
                </DatatableWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogBox
        show={showDelete}
        clickOk={() => {
          DeletePollHandlers(PollDeleteID);
          setShowDelete(false);
        }}
        clickCancel={() => {
          setShowDelete(false);
        }}
        btncancel={t("common.cancel")}
        btnyes={t("common.delete")}
        question={t("deleteBox.dltPoll")}
      />
    </div>
  );
};

export default PollList;
