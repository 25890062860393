/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import socket from '../../common/constants';
import { store } from '../store/store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { currentMeetingHandler, isAllowedHandler, joinMessageHandler, joinStatusHandler, setMeetingSessionHandler, waitingListHandler } from '../store/slices/meetingSlice';
import { useTranslation } from 'react-i18next';
import { getWaitingList } from '../../services/waitingList.service';
import { findMeetingModalHandler, meetingPasswordModalHandler } from '../store/slices/modalSlice';

const CommonSocket = () => {

    //single hooks starts here
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation('common');
    const states = store.getState();
    //single hooks ends here


    //useEffects starts here
    useEffect(() => {
        socket.on("end-meeting-res", async (data) => {
            const state = store.getState();
            const userIdSocket = state?.auth?.user?.user?.id
            const hostIdSocket = state?.meeting?.currentMeeting?.hostId
            const currentMeetingIdSocket = state?.meeting?.currentMeeting?.id
            dispatch(joinStatusHandler(true))
            dispatch(joinMessageHandler(''))
            if (data.meetingId === currentMeetingIdSocket) {
                if (hostIdSocket === userIdSocket) {
                    navigate('/meeting');
                } else {
                    toast.success(String(t('toastMessage.meetingEndedByHost')), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    });
                    navigate('/feedback');
                }
            }
        })

        return () => {
            socket.off('end-meeting-res');
        };

    }, [states?.meeting?.currentMeeting])

    useEffect(() => {

        socket.on('waiting-user-accept-reject-res', async (data: any) => {
            const state = store.getState();
            const userIdSocket = state?.auth?.user?.user?.id
            const hostIdSocket = state?.meeting?.currentMeeting?.hostId
            const currentMeetingCodeSocket = state?.meeting?.currentMeeting?.code
            const currentMeetingIdSocket = state?.meeting?.currentMeeting?.id
            const guestUserIdSocket = state?.guestUser?.guestUser?.id
            if (data.meetingId === currentMeetingIdSocket) {
                if (!!userIdSocket && data.attendeeId === userIdSocket) {
                    dispatch(joinStatusHandler(data.status))
                    if (!!data.status) {
                        socket.emit('join-meeting-send', { meetingId: currentMeetingIdSocket, user: userIdSocket, hostId: hostIdSocket });

                        await dispatch(setMeetingSessionHandler
                            ({ meetingCodeHome: currentMeetingCodeSocket, userId: userIdSocket, guestUserId: 0 })).then((res: any) => {
                                if (res?.payload?.status === 200) {
                                    dispatch(joinStatusHandler(true))
                                    dispatch(isAllowedHandler(true))
                                    dispatch(findMeetingModalHandler(false))
                                    dispatch(meetingPasswordModalHandler(false))
                                    setTimeout(() => {
                                        navigate('joinMeeting/' + currentMeetingCodeSocket);
                                    }, 100);
                                } else {
                                    dispatch(joinMessageHandler(t('toastMessage.meetingNotLongerAvailable')));
                                }
                            }).catch((err: any) => {
                                console.log("waiting-user-accept-reject-res attendee err", err)
                            })
                    } else {
                        dispatch(isAllowedHandler(false))
                        dispatch(joinMessageHandler(t('toastMessage.waitingListReject')));
                        toast.warning(String(t('toastMessage.waitingListReject')), {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2500,
                        });
                        dispatch(currentMeetingHandler(null))
                        navigate("/dashboard")
                    }
                }
                else if (!!guestUserIdSocket && data.attendeeId === guestUserIdSocket) {
                    if (!!data.status) {
                        await dispatch(setMeetingSessionHandler({ meetingCodeHome: currentMeetingCodeSocket, userId: 0, guestUserId: guestUserIdSocket })).then((res: any) => {
                            if (res?.payload?.status === 200) {
                                dispatch(isAllowedHandler(true))
                                socket.emit('join-meeting-send', { meetingId: currentMeetingIdSocket, guestUser: state?.guestUser?.guestUser, hostId: hostIdSocket });
                                setTimeout(() => {
                                    navigate('/guest/join/' + currentMeetingCodeSocket);
                                }, 100);
                            }
                        }).catch((err: any) => { })

                    } else {
                        dispatch(isAllowedHandler(false))
                        dispatch(joinStatusHandler(false))
                        toast.warning(String(t('toastMessage.waitingListReject')), {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2500,
                        });
                        dispatch(currentMeetingHandler(null))
                        navigate("/")
                    }
                }
            }
        })

        return () => {
            socket.off('waiting-user-accept-reject-res');
        };

    }, [socket])

    useEffect(() => {
        socket.on("add-waiting-user-res", async (data: any) => {
            const state = store.getState();
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id;
            let name: any;
            if (data?.user?.user) {
                name = `${data.user.user.firstName}`;
            } else if (data.user) {
                name = data.user;
            } else {
                name = 'Attendee';
            }
            if (currentMeetingIdSocket === data?.meetingId && state?.auth?.user?.user?.id === state?.meeting?.currentMeeting?.hostId) {
                getWaitingList(currentMeetingIdSocket)
                    .then((waitingList: any) => {
                        dispatch(waitingListHandler(waitingList.data));
                        let joinNotifyMsg = `${t('toastMessage.waitingListUpdate')}`.replace("{USER}", name);
                        toast.info(`${joinNotifyMsg}`, {
                            position: toast.POSITION.BOTTOM_LEFT,
                            hideProgressBar: true,
                            draggable: false,
                            bodyStyle: { width: "800px" },
                            autoClose: 2500,
                        });
                    })
                    .catch((error) => {
                        console.error("Error fetching waiting list:", error);
                    });
            }
        })
        return () => {
            socket.off('add-waiting-user-res');
        };
    }, [socket])
    //useEffects ends here


    return <></>
}

export default CommonSocket