/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Identifier, XYCoord } from 'dnd-core'
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IQuestion } from "../../../types/question.type";
import DialogBox from "../DeleteBox";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { QuestionCreateHandler, QuestionDataHandler, QuestionGetByIdHandler, QuestionUpdateById, DeleteQuestionHandler } from "../../store/slices/questionSlice";
import * as ACTION from "../../store/actions/index";
import { Accordion, Modal } from "react-bootstrap";
import { selectedQuestionHandler } from "../../store/slices/pollSlice";
import { useDrag, useDrop } from "react-dnd";
import QuestionOptions from "./QuestionOptions";
import PaginationBox from "../Pagination";
import { DragItemTypes } from "../../../types/dragItemTypes";
import { maxQuestionScore, minQuestionScore } from "../../../common/utils";
import Tags from "@yaireo/tagify/dist/react.tagify"
import { getAllCategoriesHandler } from "../../store/slices/masterCategoriesSlice";
import { RADIOGROUP, BOOLEAN, RANKING, RATING, IMAGEPICKER } from '../../../types/commonTypes';


const filterOptions = ['Title', 'Tags', 'Score']
const QuestionContainer = () => {
    //single hooks starts here
    const dispatch = useDispatch()
    const { t } = useTranslation("common");
    //single hooks ends here


    //custom variables starts here
    const defaultQuestionTitle = ''
    const newOption = { optionName: "", isAnswer: false };
    const defaultOption = [{ optionName: "", isAnswer: true }, { optionName: "", isAnswer: false }]
    const booleanOption = [{ optionName: "true", isAnswer: true }, { optionName: "false", isAnswer: false }]
    const imageOption: any = [{ optionName: [], isAnswer: true }, { optionName: [], isAnswer: false }]
    const ratingOption: any = [...Array(5)].map((item, index) => {
        return { optionName: `${index + 1}`, isAnswer: index === 0 }
    })
    const maxOptions = String(process.env.REACT_APP_NUMBER_OF_QUESTION_OPTIONS_UPLOAD_IMAGES) ?? "10"

    //custom variables ends here


    //useSelectors starts here
    const { user } = useSelector((state: RootState) => state.auth)
    const { questionList, totalQuestionsPage, totalQuestions } = useSelector((state: RootState) => state.question)
    const { selectedQuestion } = useSelector((state: RootState) => state.poll)
    const userId = user?.user?.id
    //useSelectors ends here


    //useRefs starts here
    const tagifyRef = useRef<any>()
    const searchbarRef = useRef<HTMLInputElement>(null)
    const rankingRefs = useRef<any>([])
    const rankingTextRefs = useRef<any>([])
    //useRefs ends here


    //useStates starts here
    const [questionTitle, setQuestionTitle] = useState(defaultQuestionTitle);
    const [questionType, setQuestionType] = useState(RADIOGROUP);
    const [defaultOptions, setDefaultOptions] = useState(defaultOption);
    const [questionOptions, setQuestionOptions] = useState(defaultOptions);
    const [questionScore, setQuestionScore] = useState(minQuestionScore);
    const [message1, setMessage1] = useState<string>("");
    const [nextId, setNextId] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [questionDeleteID, setquestionDeleteID] = useState();
    const [questionEditId, setQuestionEditId] = useState(0);
    const [addQuestionModal, setAddQuestionModal] = useState<boolean>(false)
    const [pageSize, setpageSize] = useState(1); // Page number
    const [perPage, setperPage] = useState(10); // per page Limit
    const [searchVal, setSearchVal] = useState("");
    const [endItem, setendItem] = useState(0);
    const [startItem, setStartItem] = useState(1);
    const [tagSuggestion, setTagSuggestion] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);
    const [getAllCategory, setGetAllCategory] = useState<any>([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [searchByCategory, setSearchByCategory] = useState('');
    const [searchByScores, setSearchByScore] = useState('');
    const [severity, setSeverity] = useState('');
    const [filterSeverity, setFilterSeverity] = useState('');
    const [previewImages, setPreviewImages] = useState<(any[])>([]);
    const [indexValidation, setIndexValidation] = useState<(any[])>([]);
    const [filterType, setFilterType] = useState<string>(filterOptions[0]);
    const [validationMessages, setValidationMessages] = useState<string[]>([]);
    const [fileSizeTypeValidation, setFileSizeTypeValidation] = useState(false);


    //useStates ends here


    //formik starts here
    const questioninitialValues: IQuestion = {
        questionTitle: questionTitle,
        options: questionOptions,
        score: questionScore
    };

    const validationSchemaQuestion = Yup.object().shape({
        questionTitle: Yup.string()
            .required(t('validation.requiredField'))
            .transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .test(
                "len",
                t('validation.minlengthTitle'),
                (val: any) =>
                    val &&
                    val.toString().length >= 3
            )
            .test(
                "no-spaces",
                t('trimMessage.trimTitle'),
                (val: any) =>
                    val &&
                    val.trim() !== ''
            ),
        options: questionType !== IMAGEPICKER ? Yup.array().of(
            Yup.object().shape({
                optionName: Yup.string()
                    .required(t('validation.requiredField'))
                    .transform((value, originalValue) => {
                        if (typeof originalValue === 'string') {
                            return originalValue.trim();
                        }
                        return value;
                    })
                    .strict(true)
                    .test(
                        "len",
                        t('validation.minlengthTitlequestionOption'),
                        (val: any) =>
                            val &&
                            val.toString().length >= 1
                    )
                    .test(
                        "no-spaces",
                        t('trimMessage.optionName'),
                        (val: any) =>
                            val &&
                            val.trim() !== ''
                    )
            })
        ) : Yup.array(),
        score: Yup.number()
            .required(t("validation.requiredField"))
            .integer('Please enter a positive number')
            .min(minQuestionScore, 'Score must be greater than 0')
            .max(maxQuestionScore, `Score must be less than or equal to ${maxQuestionScore}`)
    });

    //formik ends here


    //functions starts here
    const getQuestionList = async () => {
        const data = { userId, pageSize, perPage, searchVal, difficulty: filterSeverity, searchByTags: searchByCategory, searchByScore: searchByScores }
        return await dispatch(QuestionDataHandler(data))
    }

    const onFilterChange = (e: any) => {
        if (e.target.value.length > 0) {
            switch (filterType) {
                case 'Tags':
                    setSearchByCategory(e.target.value)
                    break
                case 'Title':
                    setSearchVal(e.target.value)
                    break
                case 'Score':
                    setSearchByScore(e.target.value)
                    break
                default:
                    setSearchVal('');
                    setSearchByCategory('')
                    setSearchByScore('')
                    break
            }
        }
        else {
            setSearchVal('');
            setSearchByCategory('')
            setSearchByScore('')
        }
    };

    const resetFilter = () => {
        setFilterSeverity('')
        setSearchVal('');
        setSearchByCategory('');
        setSearchByScore('');
        if (searchbarRef.current) {
            searchbarRef.current.value = '';
        }
    };

    const deleteConfirmHandler = (id: any) => {
        setShowDelete(true);
        setquestionDeleteID(id);
    };

    const deleteQuestionHandler = async (id: any) => {
        await dispatch(DeleteQuestionHandler(id)).then(async (response: any) => {
            if (response?.payload?.status === 200) {
                getQuestionList()
                let updateSelectedQuestions = selectedQuestion.filter(item => item.id !== id);
                dispatch(selectedQuestionHandler(updateSelectedQuestions))
                toast.success(String(t("toastMessage.questionDelete")), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                });
            }
        })
    };

    const questionEdit = async (id: any) => {
        setIsEditMode(true)
        await dispatch(QuestionGetByIdHandler(id)).then((response: any) => {
            setQuestionEditId(id);
            setQuestionTitle(response?.payload?.title)
            setQuestionOptions(response?.payload?.questionOptions);
            setQuestionScore(response?.payload?.score)
            setQuestionType(response?.payload?.type)

            let questionOption
            switch (response?.payload?.type) {
                case BOOLEAN:
                    questionOption = booleanOption
                    break
                case IMAGEPICKER:
                    let imageUrlArray: Array<any> = []
                    response?.payload?.questionOptions.map((option: any, index: number) => {
                        imageUrlArray.push(option.optionImageUrl)
                        setPreviewImages(imageUrlArray)

                    })
                    questionOption = imageOption
                    break
                case RATING:
                    response?.payload?.questionOptions.map((item: any) => {
                        return !!rankingTextRefs.current ? rankingTextRefs.current.push(item.optionName) : null
                    })
                    questionOption = ratingOption
                    break
                default:
                    questionOption = defaultOption
                    break
            }
            setDefaultOptions(questionOption)

            setAddQuestionModal(true)
            const editCategories = response.payload?.questionCategories?.map((value: any) => {
                return value.category.categoryName
            })
            setCategories(editCategories)
            setSeverity(response?.payload?.difficulty)
        })
    };

    const handleFormChange = (index: any, event: any) => {
        let value = questionType === RATING ? rankingRefs.current[index].innerText : event.target.value
        let newOption = [...questionOptions]
        newOption[index].optionName = value
        setQuestionOptions(newOption);
    };
    const handleFileChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const file: any = event.target.files && event.target.files[0];
        const maxSize = Number(process.env.REACT_APP_MAX_FILE_SIZE) * 1024 * 1024;
        const allowedTypes = ["image/jpeg", "image/png"];
        const newValidationMessages = [...validationMessages];
        // Check file size
        if (!file) {
            // Clear validation message for this index
            newValidationMessages[index] = "";
            setValidationMessages(newValidationMessages);
            setFileSizeTypeValidation(false)
            const updatedPreviewImages = [...previewImages];
            updatedPreviewImages[index] = null;
            setPreviewImages(updatedPreviewImages);
            let data = [...questionOptions];
            data[index]["optionName"] = '';
            return;
        }
        if (file.size > maxSize) {
            newValidationMessages[index] = t('validation.FileSizeValidation')
            setValidationMessages(newValidationMessages);
            setFileSizeTypeValidation(true)
            const updatedPreviewImages = [...previewImages];
            updatedPreviewImages[index] = null;
            setPreviewImages(updatedPreviewImages);
            return;
        } else {
            setFileSizeTypeValidation(false)

        }

        // Check file type
        if (!allowedTypes.includes(file.type)) {
            // File type is not allowed
            newValidationMessages[index] = t('validation.FormatNotAllowed');
            setValidationMessages(newValidationMessages);
            setFileSizeTypeValidation(true)
            return;
        }
        else {
            setFileSizeTypeValidation(false)
        }
        newValidationMessages[index] = "";
        setValidationMessages(newValidationMessages);
        let data = [...questionOptions];
        data[index]["optionName"] = file;
        setQuestionOptions(data);
        if (file) {
            const url = window.URL.createObjectURL(file);
            const updatedPreviewImages = [...previewImages];
            updatedPreviewImages[index] = url;
            setPreviewImages(updatedPreviewImages);
        } else {
            const updatedPreviewImages = [...previewImages];
            updatedPreviewImages[index] = null;
            setPreviewImages(updatedPreviewImages);
        }

    }

    const handleRemoveOption = (option: any) => {
        let remaningItems = [...questionOptions].filter(
            (_option) => _option !== option
        );
        setQuestionOptions(remaningItems);
    }

    const handleRemoveImageOption = (optionIndex: any) => {
        const updatedOptions = questionOptions.filter((_, index) => index !== optionIndex);
        const updatedPreviews = previewImages.filter((_, index) => index !== optionIndex);
        setQuestionOptions(updatedOptions);
        setPreviewImages(updatedPreviews);
        setMessage1("")
    }

    const handleRemoveLastOption = () => {
        let remaningItems = questionOptions.slice(0, -1)
        if (questionOptions.slice(-1)[0].isAnswer) {
            remaningItems[remaningItems.length - 1].isAnswer = true
        }
        setQuestionOptions(remaningItems);
    }

    const handleAddOption = () => {
        if (questionType === IMAGEPICKER) {
            if ([...questionOptions, newOption].length > Number(process.env.REACT_APP_NUMBER_OF_QUESTION_OPTIONS_UPLOAD_IMAGES)) {
                const MaxOptionsValidations = t('question.maxOptionsForImagePicker', {
                    maxOptions
                })
                    .replace('{NumberOfoptions}', maxOptions)
                setMessage1(MaxOptionsValidations);
            } else {
                setQuestionOptions([...questionOptions, newOption]);
                setMessage1("")
            }
        }
        else if (questionType === RATING) {
            rankingTextRefs.current[questionOptions.length] = questionOptions.length + 1
            setQuestionOptions([...questionOptions, { optionName: `${rankingTextRefs.current[questionOptions.length]}`, isAnswer: false }]);
        } else {
            setQuestionOptions([...questionOptions, newOption]);
            setMessage1("")
        }
        setNextId(nextId + 1);
    }

    const resetQuestionForm = () => {
        setAddQuestionModal(false)
        getQuestionList()
        setQuestionEditId(0);
        setQuestionTitle(defaultQuestionTitle)
        setQuestionOptions(defaultOption);
        setQuestionScore(minQuestionScore)
        setQuestionType(RADIOGROUP)
        setIsEditMode(false)
        setCategories([])
        setSeverity('')
        setPreviewImages([])
        setIndexValidation([])
        setValidationMessages([])
        setFileSizeTypeValidation(false)
        rankingTextRefs.current = []
        setMessage1("")
    }

    const handleGetSeverity = (e: any) => {
        setSeverity(e.target.value)
    }

    const handleQuestionType = (e: any) => {
        setQuestionType(e.target.value)
        let questionOption
        switch (e.target.value) {
            case BOOLEAN:
                questionOption = booleanOption
                break
            case IMAGEPICKER:
                questionOption = imageOption
                break
            case RATING:
                ratingOption.map((item: any) => {
                    return !!rankingTextRefs.current ? rankingTextRefs.current.push(item.optionName) : null
                })
                questionOption = ratingOption
                break
            default:
                questionOption = defaultOption
                break
        }
        setQuestionOptions(questionOption)
    }

    const handleQuestionCreate = async (PageValue: IQuestion) => {
        let indexArr: any = questionOptions.map((e: any, index) => {
            if (validationMessages[index]) {
                return true
            }

            if (e.optionName.length === 0) {
                return index
            }
        }).filter(index => index !== undefined);
        if (indexArr.length > 0) {
            setIndexValidation(indexArr)
        } else {
            const { questionTitle, score } = PageValue;
            const newQuestionTitle = questionTitle.trim();
            const fiterQuestionOptions = questionOptions.map((item) => {
                return {
                    ...item,
                    optionName: item.optionName,
                };
            });
            const emptyOptionIndices: any = [];
            PageValue.options.forEach((item: any, index: number) => {
                if (item.optionName.length === 0) {
                    emptyOptionIndices.push(index);
                }
            });
            setIndexValidation(emptyOptionIndices)

            if (fiterQuestionOptions.length > 1) {
                if (questionEditId === 0) {
                    const data = { newQuestionTitle, fiterQuestionOptions, score, categoryNames: categories, difficulty: severity, type: questionType }
                    dispatch(QuestionCreateHandler(data)).then((response: any) => {
                        if (response.type === `${ACTION.CREATEQUESTION}/${ACTION.FULFILLED}`) {
                            toast.success(String(t("toastMessage.questionCreate")), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2500,
                            });
                            resetQuestionForm()
                        }
                    })
                } else {
                    const data = {
                        questionEditId,
                        newQuestionTitle,
                        fiterQuestionOptions,
                        score,
                        categoryNames: categories,
                        difficulty: severity,
                        type: questionType
                    }
                    dispatch(QuestionUpdateById(data)).then((response: any) => {
                        if (response.type === `${ACTION.QUETIONUPDATEBYID}/${ACTION.FULFILLED}`) {
                            let data = response.payload.data
                            toast.success(String(t("toastMessage.questionUpdate")), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2500,
                            });
                            const updateSelectedQuestions = selectedQuestion.map(item => (item.id === data.id ? data : item));
                            dispatch(selectedQuestionHandler(updateSelectedQuestions))
                            resetQuestionForm()
                        }
                        else if (response.type === `${ACTION.QUETIONUPDATEBYID}/${ACTION.REJECTED}`) {
                            const resMessage =
                                (response.payload &&
                                    response.payload.data &&
                                    response.payload.data.message) ||
                                response.message ||
                                response.toString();
                            setMessage1(resMessage);
                        }
                    });
                }
            } else {
                setMessage1(t("question.mustTwoOption"));
                setTimeout(() => {
                    setMessage1("");
                }, 2000);
            }
        }
    };

    const getAllCategories = () => {
        dispatch(getAllCategoriesHandler()).then((data: any) => {
            const alltags = data.payload.map((sugg: any) => {
                return sugg.categoryName
            })
            setGetAllCategory(alltags)
        }).catch((err: any) => {
            console.log(err)
        });
    }

    const updateIsAnswer = (index: number) => {
        let updatedOptions = questionOptions.map((option, i) => {
            return { ...option, isAnswer: i === index };
        })
        setQuestionOptions(updatedOptions);
    };

    const handleUpdateAnswer = (index: number) => {
        if (questionType === BOOLEAN || questionType === RATING) {
            updateIsAnswer(index)
        } else {
            return undefined
        }
    }

    const optionStyles = () => {
        if (questionType === BOOLEAN) {
            if (questionOptions[0].isAnswer) {
                return 'isChecked'
            } else {
                return ''
            }
        } else if (questionType === RATING) {
            if (questionOptions.length > 16) {
                return 'rounded-2'
            } else {
                return ''
            }
        }
        else {
            return ''
        }
    }

    const handlePrevPage = (e: number) => {
        setpageSize(e);
        setStartItem(startItem - perPage);
        totalrecordHandle();
    };

    const handleNextPage = (e: number) => {
        setpageSize(e);
        setStartItem(endItem + 1);
        totalrecordHandle();
    };

    const handlePageClick = (e: any) => {
        setpageSize(e);
        setStartItem(e * perPage + 1 - perPage);
        totalrecordHandle();
    };

    const totalrecordHandle = () => {
        var totalRecordTemp = startItem - 1 + perPage;
        if (totalRecordTemp > totalQuestions) {
            totalRecordTemp = totalQuestions;
        }
        setendItem(totalRecordTemp);
    };

    const handleChangeCategory = useCallback((e: any) => {
        setTagSuggestion(JSON.parse(e.detail.value));
    }, [])

    const showCategoryMenu = useCallback(() => {
        if (tagifyRef.current) {
            tagifyRef.current.settings.showDropdown = true;
            tagifyRef.current.dropdown.show(); // Manually show the dropdown
        }
    }, [tagifyRef]);

    const handleFilterChange = (e: any) => {
        setFilterType(e.target.value)
        resetFilter()
    }
    //functions ends here


    //useEffects starts here
    useEffect(() => {
        const categories = tagSuggestion?.map((elem: any) => {
            return elem.value
        });
        setCategories(categories)
    }, [tagSuggestion]);

    useEffect(() => {
        getQuestionList()
    }, [pageSize, perPage, searchVal, questionEditId, filterSeverity, searchByCategory, searchByScores]);

    useEffect(() => {
        totalrecordHandle()
    }, [startItem, endItem, totalQuestions, questionList]);

    //useEffects ends here


    //components starts here
    interface DropResult {
        name: string
    }
    interface QuestionListProps {
        question: any
        index: number
        title: string
    }
    const QuestionList: FC<QuestionListProps> = ({ question, index, title }) => {
        const dragRef = useRef<HTMLDivElement>(null)
        const [{ isDragging }, drag] = useDrag(() => ({
            type: DragItemTypes.QUESTION,
            item: { title },
            end: (item: any, monitor) => {
                const dropResult = monitor.getDropResult<DropResult>()
                if (item && dropResult) {
                    let findIndex = selectedQuestion?.findIndex(
                        (x) => x.id === questionList[index]["id"]
                    );
                    if (findIndex === -1) {
                        const cloneQuestionIndex = [
                            ...selectedQuestion,
                            questionList[index],
                        ];
                        dispatch(selectedQuestionHandler(cloneQuestionIndex.sort((a, b) =>
                            a.createdAt < b.createdAt ? 1 : -1
                        )))

                    }
                }
            },
            collect: (monitor: any) => ({
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId(),
            }),
        }))

        const opacity = isDragging ? 0.4 : 1
        drag(dragRef)

        return (
            <Accordion.Item
                eventKey={`${index}`}
                style={{ opacity }}
                data-testid={DragItemTypes.QUESTION}
                className="question-item"
                role="DraggableBox"
                key={question.id}
            >
                <Accordion.Header ref={dragRef}>
                    <div className="question-item-header">
                        <i className="fa-solid fa-grip-vertical text-muted"></i>
                        <div className='fw-medium'>{question.title}</div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <QuestionOptions question={question} />
                    <div className="d-flex justify-content-end gap-3 pt-2">
                        <button
                            className="btn bg-success bg-opacity-25 text-success size-38"
                            title='edit question'
                            aria-label='edit-question'
                            onClick={() => { questionEdit(question.id); getAllCategories() }}
                        >
                            <i className="fa-solid fa-pen"></i>
                        </button>
                        <button
                            className="btn bg-danger bg-opacity-25 text-danger size-38"
                            title='delete question'
                            aria-label='delete-question'
                            onClick={() => deleteConfirmHandler(question.id)}
                        >
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        )
    }


    interface DragItem {
        index: number
        id: string
        type: string
    }
    interface RankingOptionProps {
        index: number
        option: any
        validateField: (field: string) => void
    }
    const RankingOption: FC<RankingOptionProps> = ({ index, option, validateField }) => {
        const optionRef = useRef<HTMLDivElement>(null)
        let title = option.optionName
        const [options, setOptions] = useState(questionOptions)
        const handleFormChange = (index: any, event: any) => {
            let data = [...questionOptions];
            data[index]["optionName"] = event.target.value;
            setOptions(data);
        };

        const moveOption = useCallback((dragIndex: number, hoverIndex: number) => {
            const draggedCard = options[dragIndex];
            const updatedQuestions = [...options];
            updatedQuestions.splice(dragIndex, 1);
            updatedQuestions.splice(hoverIndex, 0, draggedCard);
            setQuestionOptions(updatedQuestions)
        }, [options]);

        const [{ handlerId }, drop] = useDrop<
            DragItem,
            void,
            { handlerId: Identifier | null }
        >({
            accept: DragItemTypes.RANKINGOPTION,
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                }
            },
            hover(item: DragItem, monitor) {
                if (!optionRef.current) {
                    return
                }
                const dragIndex = item.index
                const hoverIndex = index

                if (dragIndex === hoverIndex) {
                    return
                }
                const hoverBoundingRect = optionRef.current?.getBoundingClientRect()
                const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                const clientOffset = monitor.getClientOffset()
                const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return
                }
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return
                }
                moveOption(dragIndex, hoverIndex)
                item.index = hoverIndex
            },
        })

        const [{ isDragging }, drag] = useDrag({
            type: DragItemTypes.RANKINGOPTION,
            item: { index, title },
            collect: (monitor: any) => ({
                isDragging: monitor.isDragging(),
            }),
        })

        const opacity = isDragging ? 0 : 1
        drag(drop(optionRef))

        return (
            <div style={{ opacity }} data-handler-id={handlerId}>
                <div ref={optionRef} className="hstack gap-2">
                    <div className="flex-fill hstack gap-3">
                        <i className="fa-solid fa-grip-vertical text-muted"></i>
                        <Field
                            type="text"
                            name="option"
                            className="form-control"
                            value={title}
                            onChange={(e: any) => {
                                handleFormChange(index, e);
                                validateField(`options[${index}].optionName`)
                            }}
                            placeholder={`option ${index + 1}`}
                        >
                        </Field>
                    </div>

                    {options.length > 2 &&
                        <button
                            type="button"
                            className="btn"
                            onClick={() => handleRemoveOption(option)}
                        >
                            <i className="fa-solid fa-minus text-danger"></i>
                        </button>
                    }
                </div>
                <ErrorMessage
                    name={`options[${index}].optionName`} component="div"
                    className="text-danger ms-4 ps-3"
                />
            </div>
        )
    }

    const renderRankingQuestion = useCallback(
        (option: any, index: number, validateField: (field: string) => void) => {
            return (
                <RankingOption
                    key={`RankingQuestion${index}`}
                    index={index}
                    option={option}
                    validateField={validateField}
                />
            )
        }, [questionOptions])
    //components ends here
    return (
        <>
            <div className="card border-0 h-100 shadow-sm">
                <div className="card-body">
                    <div className="d-flex flex-wrap justify-content-between ">
                        <div className="font-20 fw-semibold mb-4">
                            {t("question.title")}
                        </div>
                        <div className="mb-4">
                            <button
                                type="button"
                                className="btn btn-primary rounded-45 mw-120"
                                onClick={() => { setAddQuestionModal(true); getAllCategories() }}>
                                {t("question.btnAddQuestion")}
                            </button>
                        </div>
                    </div>
                    <div className="hstack justify-content-end">
                        <div className="hstack flex-wrap gap-2">
                            <div className="mb-4">
                                Search By:
                            </div>

                            {filterOptions.map((item: string) => {
                                return (
                                    <div className="mb-4" key={`filter-type-${item}`}>
                                        <div className='custom-radio-btn'>
                                            <input
                                                value={item}
                                                checked={filterType === item}
                                                id={`radio-${item}`}
                                                name="searchby"
                                                type="radio"
                                                onChange={handleFilterChange}
                                            />
                                            <label htmlFor={`radio-${item}`} >{item}</label>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className='flex-fill mb-4'>
                                <select
                                    className="form-select"
                                    value={filterSeverity}
                                    onChange={(e) => setFilterSeverity(e.target.value)}
                                >
                                    <option value="">Select Severity</option>
                                    <option value="Easy">{t('severityLevel.Easy')}</option>
                                    <option value="Medium">{t('severityLevel.Medium')}</option>
                                    <option value="Hard">{t('severityLevel.Hard')}</option>
                                </select>
                            </div>
                            <div className='flex-fill mb-4'>
                                <button
                                    type="button"
                                    className={"btn btn-danger  text-white rounded-3 btn-md"} onClick={() => resetFilter()}>
                                    Reset
                                </button>
                            </div>

                        </div>
                    </div>
                    <div className="search-filter">
                        <input
                            ref={searchbarRef}
                            type={filterType === 'Score' ? 'number' : 'text'}
                            onChange={(e) => onFilterChange(e)}
                            className="form-control ps-5"
                            id="question-list-filter"
                            placeholder={t("searchplaceholder.search")}
                        />
                        <i className="fa-solid fa-magnifying-glass icon"></i>
                    </div>
                    <Accordion defaultActiveKey={['0']} alwaysOpen className="question-list py-3">
                        {!!questionList &&
                            questionList.length > 0 &&
                            questionList.map((question: any, index: number) =>
                                <QuestionList
                                    key={`question_list${question.id}`}
                                    index={index}
                                    question={question}
                                    title={question.title}
                                />
                            )}
                    </Accordion>
                    {totalQuestions > perPage &&
                        <PaginationBox
                            currentPage={pageSize}
                            totalPage={totalQuestionsPage}
                            startItem={startItem}
                            endItem={endItem}
                            totalRecord={totalQuestions}
                            handlePrevPage={handlePrevPage}
                            handleNextPage={handleNextPage}
                            handlePageClick={handlePageClick}
                        />
                    }
                </div>
            </div>

            {/* modals starts here */}
            <Modal show={addQuestionModal} backdrop="static" centered onHide={resetQuestionForm}>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title className='fw-semibold'>
                        {questionEditId === 0
                            ? t("question.btnCreate")
                            : t("common.update")}{" "}
                        {t("question.header")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={questioninitialValues}
                        validationSchema={validationSchemaQuestion}
                        enableReinitialize={true}
                        onSubmit={handleQuestionCreate}>

                        {({ validateField }) => (
                            <Form className="vstack gap-3">
                                {message1 && (
                                    <div className="form-group">
                                    <div className={"alert alert-danger p-2 rounded-0 border-0"}
                                            role="alert">
                                            {message1}
                                        </div>
                                    </div>
                                )}

                                <div>
                                    <label htmlFor="question-title" className="form-label">
                                        {t("question.lblTitle")}
                                    </label>
                                    <Field

                                        type="text"
                                        onChange={(e: any) => setQuestionTitle(e.target.value)}
                                        name="questionTitle"
                                        className="form-control"
                                        id="question-title"
                                        placeholder={t("question.placeholderTitle")}></Field>
                                    <ErrorMessage
                                        name="questionTitle"
                                        component="div"
                                        className="text-danger"
                                    />
                                </div>

                                {!!questionOptions &&
                                    <div className={`option-${questionType} option-container ${optionStyles()}`}>
                                        {(questionType === RADIOGROUP || questionType === BOOLEAN || questionType === RATING) &&
                                            questionOptions?.length > 0 &&
                                            questionOptions?.map((option, index) => {
                                                return (
                                                    <div key={`questionOptions${index}`}>
                                                        <div className="hstack gap-2">
                                                            <div className={`option-${questionType}-wrapper flex-fill hstack gap-3`}>
                                                                <Field
                                                                    type="radio"
                                                                    name="selectedOption"
                                                                    className="selected-options-radio"
                                                                    value={option.isAnswer}
                                                                    onChange={() => updateIsAnswer(index)}
                                                                    checked={option.isAnswer}
                                                                    required
                                                                />
                                                                {questionType === RATING ?
                                                               <span
                                                               ref={(el) => {
                                                                   if (el) {
                                                                       rankingRefs.current[index] = el;
                                                                   } else {
                                                                       rankingRefs.current[index] = null;
                                                                   }
                                                               }}
                                                               contentEditable
                                                               suppressContentEditableWarning={true}
                                                               onClick={() => handleUpdateAnswer(index)}
                                                               onInput={(e: any) => {
                                                                   handleFormChange(index, e);
                                                                   validateField(`options[${index}].optionName`);
                                                               }}
                                                               className="rating-textbox form-control"
                                                           >
                                                               {!!rankingTextRefs.current && rankingTextRefs.current[index]}
                                                           </span>
                                                           
                                                                    :
                                                                    <Field
                                                                        type="text"
                                                                        name="option"
                                                                        className="form-control"
                                                                        value={option.optionName}
                                                                        onChange={(e: any) => {
                                                                            handleFormChange(index, e);
                                                                            validateField(`options[${index}].optionName`);
                                                                        }}
                                                                        onClick={(e: any) => handleUpdateAnswer(index)}
                                                                        placeholder={`option ${index + 1}`}
                                                                        autoComplete="off"
                                                                    >
                                                                    </Field>
                                                                }
                                                            </div>

                                                            {questionOptions.length > 2 && questionType === RADIOGROUP &&
                                                                <button
                                                                    type="button"
                                                                    className="btn"
                                                                    onClick={() => handleRemoveOption(option)}
                                                                >
                                                                    <i className="fa-solid fa-minus text-danger"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                        {questionType !== BOOLEAN &&
                                                            <ErrorMessage
                                                                name={`options[${index}].optionName`} component="div"
                                                                className={`text-danger ${questionType === RADIOGROUP ? 'ms-4 ps-3' : ''}`}
                                                            />
                                                        }
                                                    </div >
                                                )
                                            })
                                        }

                                        {
                                            questionType === RANKING &&
                                            questionOptions?.length > 0 &&
                                            questionOptions?.map((option, index) =>
                                                renderRankingQuestion(option, index, validateField)
                                            )
                                        }
                                        {
                                            questionType === IMAGEPICKER &&
                                            questionOptions?.length > 0 &&
                                            questionOptions?.map((option, index: number) => (

                                                <div key={`questionOptions${index}`}>
                                                    <div className="hstack gap-3">
                                                        <div className={`option-${questionType}-wrapper flex-fill hstack gap-3`}>
                                                            <Field
                                                                type="radio"
                                                                name="selectedOption"
                                                                className="selected-options-radio"
                                                                value={option.isAnswer}
                                                                onChange={() => updateIsAnswer(index)}
                                                                checked={option.isAnswer}
                                                                required
                                                            />
                                                            <Field
                                                                type="file"
                                                                name={`options[${index}].optionFile`}
                                                                className="form-control"
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    handleFileChange(index, e);
                                                                }}
                                                                accept=".jpg, .jpeg, .png"
                                                            >
                                                            </Field>
                                                            {previewImages[index] &&
                                                                <>
                                                                    {isEditMode ?
                                                                        <img className='img-fluid' width={"100px"} height={"100px"}
                                                                            src={
                                                                                previewImages[index].includes("blob")
                                                                                    ? previewImages[index]
                                                                                    : `${previewImages[index]}`
                                                                            }

                                                                            alt="option" />
                                                                        :
                                                                        <img
                                                                            src={previewImages[index]}
                                                                            width="100px"
                                                                            height="100px"
                                                                            alt="Preview"
                                                                        />
                                                                    }
                                                                </>
                                                            }
                                                        </div>

                                                        {questionOptions.length > 2 &&
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                onClick={() => handleRemoveImageOption(index)}
                                                            >
                                                                <i className="fa-solid fa-minus text-danger"></i>
                                                            </button>
                                                        }
                                                    </div>
                                                    {!validationMessages[index] &&
                                                        indexValidation.includes(index) ? <span style={{ color: "red" }}>{t('validation.requiredField')}</span> : ""
                                                    }
                                                    {validationMessages[index] && <span style={{ color: "red" }}>{validationMessages[index]}</span>}
                                                </div>
                                            )
                                            )
                                        }
                                    </div >
                                }
                                {
                                    questionType === BOOLEAN &&
                                    <div className='d-flex mx-3 mx-md-5 mb-3'>
                                        <ErrorMessage
                                            name={`options[0].optionName`} component="div"
                                            className="text-danger flex-fill"
                                        />
                                        <ErrorMessage
                                            name={`options[1].optionName`} component="div"
                                            className="text-danger flex-fill d-flex justify-content-end"
                                        />
                                    </div>
                                }

                                {
                                    questionType !== BOOLEAN &&
                                    <div className="text-center my-4">
                                        <button
                                            type="button"
                                            onClick={handleAddOption}
                                            className="btn btn-primary-light"
                                        >
                                            Add More Option
                                        </button>

                                        {questionOptions.length > 2 && questionType === RATING &&
                                            <button
                                                type="button"
                                                className="btn bg-danger bg-opacity-10 ms-3"
                                                onClick={handleRemoveLastOption}
                                            >
                                                Remove Option
                                            </button>
                                        }
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-md-4 hstack">
                                        <label htmlFor="question-severity" className="form-label mb-0">
                                            Type :
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <select className="form-select" required value={questionType}
                                            onChange={(e) => { handleQuestionType(e) }}>
                                            <option value="" disabled>Select Question Type</option>
                                            <option value={RADIOGROUP}>Radio</option>
                                            <option value={BOOLEAN}>Boolean</option>
                                            <option value={RANKING}>Ranking</option>
                                            <option value={RATING}>Rating</option>
                                            <option value={IMAGEPICKER}>Image Picker</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 hstack">
                                        <label htmlFor="question-severity" className="form-label mb-0">
                                            Severity :
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <select className="form-select" required value={severity}
                                            onChange={(e) => { handleGetSeverity(e) }}>
                                            <option value="" disabled>Select Severity Level</option>
                                            <option value="Easy">{t("severityLevel.Easy")}</option>
                                            <option value="Medium">{t("severityLevel.Medium")}</option>
                                            <option value="Hard">{t("severityLevel.Hard")}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 hstack">
                                        <label htmlFor="question-score" className="form-label mb-0">
                                            Score :
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <Field
                                            type="number"
                                            name="score"
                                            className="form-control"
                                            id="question-score"
                                            onChange={(e: any) => setQuestionScore(e.target.value)}
                                            placeholder={`Score can be between ${minQuestionScore} to ${maxQuestionScore}`}
                                        ></Field>
                                        <ErrorMessage
                                            name="score"
                                            component="div"
                                            className="text-danger"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 hstack">
                                        <label htmlFor="question-severity" className="form-label mb-0">
                                            Tags :
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <Tags
                                            tagifyRef={tagifyRef}
                                            onChange={handleChangeCategory}
                                            placeholder="tags for current question. ex: science"
                                            whitelist={getAllCategory}
                                            showDropdown={true}
                                            onInput={showCategoryMenu}
                                            {...(isEditMode && { value: categories })}

                                        />
                                    </div>
                                </div>
                                <div className="modal-footer hstack justify-content-center border-top-0">
                                    <button
                                        type="submit"
                                        className="btn btn-primary rounded-45 mw-120">
                                        {questionEditId === 0
                                            ? t("question.btnCreate")
                                            : t("common.update")}
                                    </button>
                                </div>
                            </Form >
                        )}
                    </Formik >
                </Modal.Body >
            </Modal >
            {/* modals ends here */}


            {/* alerts actions starts heres */}
            <DialogBox
                show={showDelete}
                clickOk={() => {
                    deleteQuestionHandler(questionDeleteID);
                    setShowDelete(false);
                }}
                clickCancel={() => {
                    setShowDelete(false);
                }}
                btncancel={t("common.cancel")}
                btnyes={t("common.delete")}
                question={t("deleteBox.dltquestion")}
            />
            {/* alerts actions ends heres */}
        </>
    )
}


export default QuestionContainer
