import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL

export const pollCreate = (title: string, selectedQuestion: any[]) => {
    return axios.post(API_URL + "polls", {
        title,
        selectedQuestion
    }, { headers: authHeader() });
};

export const pollData = async (id: any, pageSize: any, perPage: any, searchVal: any, sortColumn: any, sortType: any) => {
    return axios.get(API_URL + "polls" + "/user/" + id + "/?page=" + pageSize + "&limit=" + perPage + "&searchVal=" + searchVal + "&sortColumn=" + sortColumn + "&sortType=" + sortType)
        .then((response) => {
            return response.data;
        });
};

export const deletePoll = async (id: any) => {
    return axios.delete(API_URL + "polls/" + id,
        { headers: authHeader() })
        .then((response) => {
            return response;
        });
};

export const pollUpdateByID = async (id: any, title: string, selectedQuestion: any[]) => {
    return axios.put(API_URL + "polls/" + id, {
        title,
        selectedQuestion
    }, { headers: authHeader() });
};

export const pollGetById = async (id: string) => {
    return axios.get(API_URL + "polls/" + id)
        .then((response) => {
            return response;
        });
    ;
};

export const addPollForMeeting = (pollIds: any[], meetingId: number) => {
    return axios.post(API_URL + "polls-meetings/create", {
        pollIds,
        meetingId
    }, { headers: authHeader() });
};

export const getPollsForMeeting = async (meetingId: number) => {
    return axios.get(API_URL + "polls-meetings/" + meetingId)
        .then((response) => {
            return response;
        });
};

export const deleteMeetingPoll = async (pollMeetingId: number) => {
    return axios.delete(API_URL + "polls-meetings/" + pollMeetingId,
        { headers: authHeader() })
        .then((response) => {
            return response;
        });
}

export const addPollsQuestionsAnswerByLogInUser = async (meetingId: number, newSelectedAnswer:any, pollId: any) => {
    return axios.post(API_URL + "polls-questions-answers/create-by-login-user", {
        meetingId,
        newSelectedAnswer,
        pollId
    }, { headers: authHeader() });
}

export const getPollsQuestionsAnswerByMeetingId = async (meetingId: number) => {
    return axios.get(API_URL + "polls-questions-answers/by-meeting-login-user/" + meetingId, { headers: authHeader() })
        .then((response) => {
            return response;
        });
}

export const getPollQuestionsAnswerByGuest = async (meetingId:number , guestUserId:number) =>{
    return axios.get(API_URL + `polls-questions-answers/by-meeting-guest/${meetingId}/${guestUserId}`)
    .then((response) =>{
        return response;
    })
}

export const getAllPollsQuestionsAnswerByMeetingId = async (meetingId: number) => {
    return axios.get(API_URL + "polls-questions-answers/all-answer/" + meetingId)
    .then((response) => {
        return response;
    });
}

export const updateMeetingPollStatus = async (id: any, status: string) => {
    return axios.put(API_URL + "polls-meetings/" + id, {
        status
    }, { headers: authHeader() });
};

