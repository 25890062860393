import React, { FC } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import MeetingImage from "../../images/meeting.svg"
import { CREATED, ENDED, INPROGRESS, STARTED } from "../../types/commonTypes";


//props interface starts here
interface MeetingCardProps {
  meetingList: any[]
  meetingEdit: any
  shareMeeting: any
  startMeetingHandler: any
  joinMeetingHandler: any
  getMeetingDetails: any
  btnMeetingQuestionSendViaEmail: any
  deleteConfirmHandler: (id: number) => void
  endConfirmHandler: (id: number) => void
}
//props interface ends here

const MeetingCard: FC<MeetingCardProps> = ({
  meetingList,
  meetingEdit,
  shareMeeting,
  startMeetingHandler,
  joinMeetingHandler,
  getMeetingDetails,
  btnMeetingQuestionSendViaEmail,
  deleteConfirmHandler,
  endConfirmHandler
}) => {

  const { t } = useTranslation('common');

  return (
    <>
      <div className="row gx-4 gy-5">
        {meetingList.length === 0 &&
          <div className="col-12 font-22 fw-medium mb-3" >{t('myMeeting.dataNotFound')}</div>
        }

        {!!meetingList &&
          meetingList.length > 0 &&
          meetingList.map((meeting) => (
            <div className="col-12 col-lg-6 col-xxl-4" key={`meetingCard-${meeting.code}`}>
              <div className="card h-100 bg-primary-light">
                <div className="card-body vstack gap-4 pb-1">
                  <div className="d-flex justify-content-between gap-2">
                    <div>
                      <img
                        width={40}
                        className="img-fluid"
                        src={MeetingImage}
                        alt="meeting"
                      />
                    </div>
                    <div className='hstack justify-content-end flex-wrap gap-2'>
                      {meeting.status === CREATED &&
                        <button onClick={() => startMeetingHandler(meeting.id, INPROGRESS)} className="btn btn-primary">
                          {t('myMeeting.btnStartMeeting')}
                        </button>
                      }

                      {(meeting.status === STARTED || meeting.status === INPROGRESS) &&
                        <>
                          <button onClick={() => joinMeetingHandler(meeting.id, INPROGRESS)} className="btn btn-primary">
                            {t('myMeeting.btnJoinMeeting')}
                          </button>
                          <button onClick={() => endConfirmHandler(meeting.id)} className="btn btn-danger text-white">
                            {t('joinMeeting.endMeeting')}
                          </button>
                        </>
                      }

                      {meeting.status === ENDED &&
                        <>
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => btnMeetingQuestionSendViaEmail(meeting.id)}
                            title={t('pastMeetingDetails.btnSendEmail')}
                          >
                            <i className="fas fa-envelope"></i>
                            <span className="d-none d-sm-inline text-nowrap ms-2">{t('pastMeetingDetails.btnSendEmail')}</span>
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => getMeetingDetails(meeting.id)}
                          >
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                            <span className="d-none d-sm-inline text-nowrap ms-2">{t('myMeeting.btnDetailMeeting')}</span>
                          </button>
                        </>
                      }
                    </div>
                  </div>
                  <div className="vstack gap-3 my-3">
                    <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-0">
                      <label className="form-label mb-0 mw-160">{t('myMeeting.lbltitle')}</label>
                      <div className="fw-bold" title={meeting.title}>{meeting.title}</div>
                    </div>

                    {meeting.status === ENDED ?
                      <>
                        <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-0">
                          <label className="form-label mb-0 mw-160">{t('myMeeting.lblStartAt')}</label>
                          <div className="fw-bold" title={moment(new Date(meeting.startTime)).format("lll")}>
                            {moment(new Date(meeting.startTime)).format("lll")}
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-0">
                          <label className="form-label mb-0 mw-160">{t('myMeeting.lblEndAt')}</label>
                          <div className="fw-bold" title={moment(new Date(meeting.endedAt)).format("lll")}>
                            {moment(new Date(meeting.endedAt)).format("lll")}
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-0">
                          <label className="form-label mb-0 mw-160">{t('myMeeting.lblCreateAt')}</label>
                          <div className="fw-bold" title={moment(new Date(meeting.createdAt)).format("lll")}>
                            {moment(new Date(meeting.createdAt)).format("lll")}
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-0">
                          <label className="form-label mb-0 mw-160">{t('myMeeting.lblStartAt')}</label>
                          <div className="fw-bold" title={moment(new Date(meeting.startTime)).format("lll")}>
                            {moment(new Date(meeting.startTime)).format("lll")}
                          </div>
                        </div>
                      </>
                    }

                    <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-0">
                      <label className="form-label mb-0 mw-160">{t('common.Password')}</label>
                      <div className="fw-bold">
                        {meeting.password ?
                          meeting.password
                          :
                          '-'
                        }
                      </div>
                    </div>
                    <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-0">
                      <label className="form-label mb-0 mw-160">{t('common.lblGeoFence')}</label>
                      <div className="fw-bold">
                        {meeting.isGeoFenceProtected ?
                          t('common.lblYes')
                          :
                          t('common.lblNo')
                        }
                      </div>
                    </div>
                    <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-0">
                      <label className="form-label mb-0 mw-160">{t('myMeeting.lblCode')}</label>
                      <div className="fw-bold">{meeting.code}</div>
                    </div>
                  </div>
                  <div className="vstack justify-content-end align-items-end gap-2">
                    {(meeting.status === CREATED) && (
                      <div className='hstack justify-content-between flex-wrap gap-3'>
                        <div className='hstack flex-wrap gap-3'>
                          <button onClick={() => meetingEdit(meeting.id)} title="edit meeting" aria-label="edit meeting"
                            className="btn btn-outline-primary rounded-circle size-38">
                            <i className="fa-solid fa-pen "></i>
                          </button>
                          <button onClick={() => deleteConfirmHandler(meeting.id)} title="delete meeting" aria-label="delete meeting"
                            className="btn btn-outline-primary rounded-circle size-38">
                            <i className="fa-solid fa-trash "></i>
                          </button>
                        </div>
                        <button className="btn btn-outline-primary rounded-circle size-38" onClick={() => shareMeeting(meeting.id)} title='share meeting' aria-label="share meeting">
                          <i className="fa-solid fa-share-nodes "></i>
                        </button>
                      </div>
                    )}

                    {(meeting.status === STARTED || meeting.status === INPROGRESS) && (
                      <>
                        <button className="btn btn-outline-primary rounded-circle size-38" onClick={() => shareMeeting(meeting.id)} title='share meeting' aria-label="share meeting">
                          <i className="fa-solid fa-share-nodes "></i>
                        </button>
                      </>
                    )}
                    <small className="text-muted">
                      Created {moment(new Date(meeting.createdAt)).fromNow()}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
};
export default MeetingCard;