/* eslint-disable no-useless-concat */
import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL

export const quizCreate = (title: string, maxTimeToFinish: number, maxTimeToFinishPage: number, selectedQuestion: any[]) => {
    return axios.post(API_URL + "quiz", {
        title,
        maxTimeToFinish,
        maxTimeToFinishPage,
        selectedQuestion
    }, { headers: authHeader() });
};

export const quizData = async (id: any, pageSize: any, perPage: any, searchVal: any, sortColumn: any, sortType: any) => {
    return axios.get(API_URL + "quiz" + "/user/" + id + "/?page=" + pageSize + "&limit=" + perPage + "&searchVal=" + searchVal + "&sortColumn=" + sortColumn + "&sortType=" + sortType)
        .then((response) => {
            return response.data;
        });
};

export const deleteQuiz = async (id: any) => {
    return axios.delete(API_URL + "quiz/" + id,
        { headers: authHeader() })
        .then((response) => {
            return response;
        });
};

export const quizUpdateByID = async (id: any, title: string, maxTimeToFinish: number, maxTimeToFinishPage: number, selectedQuestion: any[]) => {
    return axios.put(API_URL + "quiz/" + id, {
        title,
        maxTimeToFinish,
        maxTimeToFinishPage,
        selectedQuestion
    }, { headers: authHeader() });
};

export const quizGetById = async (id: string) => {
    return axios.get(API_URL + "quiz/" + id)
        .then((response) => {
            return response;
        });
    ;
};

export const addQuizForMeeting = (quizIds: any[], meetingId: number) => {
    return axios.post(API_URL + "quiz-meetings/create", {
        quizIds,
        meetingId
    }, { headers: authHeader() });
};

export const getQuizzesForMeeting = async (meetingId: number) => {
    return axios.get(API_URL + "quiz-meetings/" + meetingId)
        .then((response) => {
            return response;
        });
};

export const deleteMeetingQuiz = async (quizMeetingId: number) => {
    return axios.delete(API_URL + "quiz-meetings/" + quizMeetingId,
        { headers: authHeader() })
        .then((response) => {
            return response;
        });
}

export const addQuizzesQuestionsAnswerByLogInUser = async (meetingId: number, newSelectedAnswer: any, quizId: any) => {
    return axios.post(API_URL + "quiz-questions-answers/create-by-login-user", {
        meetingId,
        newSelectedAnswer,
        quizId
    }, { headers: authHeader() });
}

export const getQuizzesQuestionsAnswerByMeetingId = async (meetingId: number) => {
    return axios.get(API_URL + "quiz-questions-answers/by-meeting-login-user/" + meetingId, { headers: authHeader() })
        .then((response) => {
            return response;
        });
}

export const getQuizQuestionsAnswerByGuest = async (meetingId: number, guestUserId: number) => {
    return axios.get(API_URL + `quiz-questions-answers/by-meeting-guest/${meetingId}/${guestUserId}`)
        .then((response) => {
            return response;
        })
}

export const getAllQuizzesQuestionsAnswerByMeetingId = async (meetingId: number) => {
    return axios.get(API_URL + "quiz-questions-answers/all-answer/" + meetingId)
        .then((response) => {
            return response;
        });
}

export const updateMeetingQuizStatus = async (id: any, status: string) => {
    return axios.put(API_URL + "quiz-meetings/" + id, {
        status
    }, { headers: authHeader() });
};

export const quizAnswersByAttendee = async (meetingId: any, quizId: any, questionsAnswers: any, score: any, quizJson: object) => {
    return axios.post(API_URL + "quiz-questions-answers/quiz-answers-by-attendee", {
        meetingId,
        quizId,
        questionsAnswers,
        score,
        quizJson
    }, { headers: authHeader() });
}

export const quizAnswersByGuest = async (meetingId: any, quizId: any, guestUserId: any, questionsAnswers: any, score: any, quizJson: object) => {
    return axios.post(API_URL + "quiz-questions-answers/quiz-answers-by-guest-user", {
        meetingId,
        quizId,
        guestUserId,
        questionsAnswers,
        score,
        quizJson
    }, { headers: authHeader() });
}

export const getQuizAnswerOfGuest = async (meetingId: number, guestUserId: number, quizId: number) => {
    return axios.get(API_URL + "quiz-questions-answers/by-meeting-guest/" + meetingId + "/" + guestUserId + "/" + quizId)
        .then((response) => {
            return response;
        });
}

export const getQuizAnswerOfAttendee = async (meetingId: number, quizId: number) => {
    return axios.get(API_URL + "quiz-questions-answers/by-meeting-attendee/" + meetingId + "/" + quizId, { headers: authHeader() })
        .then((response) => {
            return response;
        });
}

export const getSubmittedQuizzesByGuest = async (meetingId: number, guestUserId: number) => {
    return axios.get(API_URL + "quiz-questions-answers/guest-submitted-quizzes/" + meetingId + "/" + guestUserId)
        .then((response) => {
            return response;
        });
}

export const getSubmittedQuizzesByAttendee = async (meetingId: number) => {
    return axios.get(API_URL + "quiz-questions-answers/attendee-submitted-quizzes/" + meetingId, { headers: authHeader() })
        .then((response) => {
            return response;
        });
}

export const getAllSubmittedQuizzes = async (meetingId: number) => {
    return axios.get(API_URL + "quiz-questions-answers/submitted-quizzes/" + meetingId, { headers: authHeader() })
        .then((response) => {
            return response;
        });
}

export const getAttendeeQuizListing = async (quizId: any, submittedAnswers: any[]) => {
    try {
        const response = await axios.post(API_URL + `quiz/${quizId}/template-pdf/`, submittedAnswers, {
            headers: authHeader(),
            responseType: 'arraybuffer',
        });
        return response;
    } catch (error) {
        throw error;
    }
};
