import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { addPollForMeeting, pollData } from '../../../services/poll.service';
import { addQuizForMeeting, quizData } from '../../../services/quiz.service';
import socket from '../../../common/constants';
import PaginationBox from '../Pagination';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { selectedQuestionHandler } from '../../store/slices/pollSlice';

interface addPollModalProps {
    addModalType: number
    addModal: boolean,
    hideAddModal: () => void
    currentMeetingId: number
    userId: number,
    meetingQuizList: any[]
    meetingPollsList: any[]
}
const AddPollQuizModal: FC<addPollModalProps> = ({
    addModalType,
    addModal,
    hideAddModal,
    currentMeetingId,
    userId,
    meetingQuizList,
    meetingPollsList
}) => {
    //single hooks starts here
    const { t } = useTranslation('common');
    const navigate = useNavigate()
    const dispatch = useDispatch()
    //single hooks ends here


    const modalType = addModalType === 1 ? 'pollModel' : 'quizModel'


    //useStates starts here
    const [list, setList] = useState<any[]>([]);
    const [totalPage, setTotalPage] = useState<number>();
    const [pageSize, setpageSize] = useState<number>(1);
    const [perPage, setperPage] = useState<number>(5);
    const [filterValue, setFilterValue] = useState<string>('');
    const [sortColumn, setsortColumn] = useState<any>({ order: "desc", prop: "createdAt" });
    const [totalRecord, setTotalRecord] = useState<number | any>();
    const [startItem, setStartItem] = useState<number>(1);
    const [endItem, setEndItem] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [addList, setAddList] = useState<any[]>([])
    //useStates ends here


    //functions starts here
    const emitSocketEvent = (event: string) => {
        socket.emit(event, { meetingId: currentMeetingId })
        socket.on('connect', () => {
            socket.emit(event, { meetingId: currentMeetingId })
        })
    }

    const getListData = async (userId: any, pageLimit: number, perPage: number, searchVal: string, sortColumn: any) => {
        let listData
        if (addModalType === 1) {
            listData = await pollData(userId, pageLimit, perPage, searchVal, sortColumn.prop, sortColumn.order)
        } else {
            listData = await quizData(userId, pageLimit, perPage, searchVal, sortColumn.prop, sortColumn.order)
        }
        setList(listData.data);
        setTotalPage(listData.totalPages)
        setpageSize(listData.currentPage)
        setTotalRecord(listData.totalItems)
    }

    const resetPagination = () => {
        setTotalRecord('');
        setpageSize(1);
        setStartItem(1);
        setEndItem(0);
        setFilterValue('')
    }

    const handleAddList = (listId: number) => {
        if (addList.includes(listId)) {
            setAddList(prev => prev.filter(id => id !== listId));
        } else {
            setAddList(prev => [...prev, listId]);
        }
    };

    const handleAdd = async () => {
        if (isLoading) {
            return
        }
        setIsLoading(true)
        if (addList.length > 0) {
            let response
            if (addModalType === 1) {
                response = await addPollForMeeting(addList, currentMeetingId)
            } else {
                response = await addQuizForMeeting(addList, currentMeetingId)
            }
            if (response.status === 201) {
                setAddList([])
                hideAddModal()

                emitSocketEvent(addModalType === 1 ? "meeting-polls-send" : "meeting-quiz-send")

                setFilterValue('')
                setTotalRecord('');
                setpageSize(1);
                setStartItem(1);
                setEndItem(0)
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        } else {
            setIsLoading(false)
        }
    }

    const checkIsAvailableInList = (listId: number) => {
        if (addModalType === 1) {
            return meetingPollsList.some((item) => item.pollId === listId)
        } else {
            return meetingQuizList.some((item) => item.quizId === listId)
        }
    }

    const checkIdIsSelected = (id: number) => {
        return addList.includes(id);
    };

    const onFilterChange = (e: any) => {
        setFilterValue(e.target.value)
        setpageSize(1)
        setStartItem(1);
        setEndItem(0)
    }

    const handleCreate = () => {
        navigate(`/${addModalType === 1 ? 'poll' : 'quiz'}/0`);
        dispatch(selectedQuestionHandler([]))
    }

    const handlePrevPage = (e: number) => {
        setpageSize((e));
        setStartItem((startItem - perPage))
        totalrecordHandle()
    };

    const handleNextPage = (e: number) => {
        setpageSize((e));
        setStartItem(endItem + 1)
        totalrecordHandle()
    };

    const handlePageClick = (e: any) => {
        setpageSize(e);
        setStartItem(((e * perPage) + 1) - perPage)
        totalrecordHandle()
    }

    const totalrecordHandle = () => {
        var totalRecordTemp = ((startItem - 1) + perPage);
        if (totalRecordTemp > totalRecord) {
            totalRecordTemp = totalRecord;
        }
        setEndItem(totalRecordTemp)
    }
    //functions ends here


    //useEffects starts here
    useEffect(() => {
        getListData(userId, pageSize, perPage, filterValue, sortColumn);
    }, [filterValue, pageSize, addModalType]);

    useEffect(() => {
        totalrecordHandle()
    }, [startItem, endItem, totalRecord]);
    //useEffects ends here

    return (
        <Modal show={addModal} centered onHide={() => { hideAddModal(); resetPagination() }}>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='position-relative fw-semibold'>
                    {t(`${modalType}.header`)}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className='vstack gap-3'>
                <div className="search-filter">
                    <input type="text" value={filterValue} onChange={onFilterChange} className="form-control ps-5" id="search-box" placeholder={t(`${modalType}.placeholderSearch`)} />
                    <i className="fa-solid fa-magnifying-glass icon"></i>
                </div>
                {!!list && list.length > 0 ?
                    <>
                        <div className='vstack gap-3'>
                            {list.map((list) => {
                                const isAvailable = checkIsAvailableInList(list.id)
                                return (
                                    <div className="hstack justify-content-between" key={`addlist-${list.id}`} title={isAvailable ? `${list.title}  ${t(`${modalType}.helpTitle`)}` : ''}>
                                        <div>{list.title}</div>
                                        <button className="btn btn-primary-light size-38" onClick={() => handleAddList(list.id)} disabled={isAvailable} >
                                            {isAvailable ?
                                                <i className="fa-solid fa-minus"></i>
                                                :
                                                <i className={`fa-solid ${checkIdIsSelected(list.id) ? 'fa-minus' : 'fa-plus'}`}></i>
                                            }
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                        {
                            totalRecord > perPage &&
                            <PaginationBox
                                currentPage={pageSize}
                                totalPage={totalPage}
                                startItem={startItem}
                                endItem={endItem}
                                totalRecord={totalRecord}
                                handlePrevPage={handlePrevPage}
                                handleNextPage={handleNextPage}
                                handlePageClick={handlePageClick}
                            />
                        }
                    </>
                    :
                    <div className='font-22 text-center'>{t(`${modalType}.noData`)}</div>
                }

            </Modal.Body>

            {!!list && list.length > 0 ?
                <Modal.Footer className='border-0'>
                    <button type="button" className="btn btn-primary rounded-45 mw-120" onClick={handleAdd} disabled={isLoading}>{t(`${modalType}.btnAdd`)}</button>
                </Modal.Footer>
                : <Modal.Footer className='border-0 justify-content-center'>
                    <button type="button" className="btn btn-primary rounded-45 mw-120" onClick={handleCreate}>{t(`${modalType}.create`)}</button>
                </Modal.Footer>
            }
        </Modal>
    )
}

export default AddPollQuizModal