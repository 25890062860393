import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef } from 'react'
import { Accordion } from 'react-bootstrap'
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { selectedQuestionHandler } from '../../store/slices/pollSlice'
import QuestionOptions from './QuestionOptions'
import { DragItemTypes } from '../../../types/dragItemTypes'

//props interface starts here
export interface SelectedQuestionProps {
    index: number
    question: any
    title: string
    meetingsLength: number
    moveQuestion: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
    index: number
    id: string
    type: string
}
//props interface ends here


export const SelectedQuestion: FC<SelectedQuestionProps> = ({ index, question, title, meetingsLength, moveQuestion }) => {
    //single hooks starts here
    const dispatch = useDispatch()
    //single hooks ends here

    //useRefs starts here
    const questionRef = useRef<HTMLDivElement>(null)
    //useRefs ends here

    //useSelectors starts here
    const { selectedQuestion } = useSelector((state: RootState) => state.poll)
    //useSelectors ends here


    //components starts here
    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: DragItemTypes.SELECTEDQUESTION,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!questionRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = questionRef.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveQuestion(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: DragItemTypes.SELECTEDQUESTION,
        item: { index, title },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(questionRef))
    //components ends here


    //functions starts here
    const removeQuestion = (id: number) => {
        const removeQuestionPollList = selectedQuestion.filter((item) => item.id !== id);
        dispatch(selectedQuestionHandler(removeQuestionPollList))
    };
    //functions ends here

    return (
        <Accordion.Item
            style={{ opacity }}
            data-handler-id={handlerId}
            className="question-item"
            eventKey={`${index}`}
        >
            <Accordion.Header className='position-relative'>
                {meetingsLength === 0 && (
                    <button
                        type="button"
                        onClick={() => removeQuestion(question.id)}
                        className="btn bg-danger bg-opacity-25 text-danger size-38 position-absolute translate-middle-y top-50 z-4 btn-remove-question">
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                )}
                <div ref={questionRef} className="question-item-header me-5">
                    <i className="fa-solid fa-grip-vertical text-muted"></i>
                    <div className='fw-medium'>{question.title}</div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <QuestionOptions question={question} />
            </Accordion.Body>
        </Accordion.Item>
    )
}
