import socketIOClient from "socket.io-client";
import authHeader from "../services/auth-header";

//socketIOClient connection
const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL as string, {
    auth: authHeader(),
    transports: ['websocket', 'polling']
});

//On Socekt Disconnect
socket.on("disconnect", (reason) => {
    console.log("reason ==>", reason)
    if (reason === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        console.log("the disconnection was initiated by the server, you need to reconnect manually")
        socket.connect();
    }
    // else the socket will automatically try to reconnect
    console.log("else the socket will automatically try to reconnect")
    let socketConnectTimeInterval = setInterval(function () {
        socket.connect();
        if (socket.connected) { clearInterval(socketConnectTimeInterval); }
    }, 3000);
});

export default socket;