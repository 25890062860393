/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { IUserProfile } from '../../types/user.type';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import * as Yup from "yup";
import { UpdateProfile } from '../store/slices/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { deleteuserHandler, logoutHandler, userHandler } from "../store/slices/authSlice";
import { languageHandler } from "../store/slices/settingsSlice";
import * as ACTION from "../store/actions/index";
import germanyFlag from "../../images/germany-flag.svg"
import usaFlag from "../../images/usa-flag.svg"
import { useNavigate } from "react-router-dom";
import DialogBox from "../components/DeleteBox";
import { DE, EN } from "../../types/commonTypes";



const Profile = () => {

  //single hooks starts here
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('common');
  //single hooks ends here


  //useSelectors starts here
  const { user, isUser } = useSelector((state: RootState) => state.auth)
  const userId = user?.user?.id
  const { message, ProfileImage } = useSelector((state: RootState) => state.profile)
  const { language } = useSelector((state: RootState) => state.settings)
  const usersImageDetails = user?.user?.usersImageDetails && user?.user?.usersImageDetails?.length > 0 && user?.user?.usersImageDetails[0]?.imageUrl
  //useSelectors ends here


  //useStates starts here
  const [profilEdit, setprofilEdit] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [fileValidationError, setFileValidationError] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [fileSizevalidation, setFileSizevalidation] = useState(false);
  const [showDelete, setShowDelete] = useState<any>(false);
  const [profileSaveLoading, setProfileSaveLoading] = useState<boolean>(false)

  let initialValues: IUserProfile = {
    firstName: '',
    lastName: '',
    email: '',
  }
  const [profile, setProfile] = useState(initialValues);
  //useStates ends here


  //useEffects starts here
  useEffect(() => {
    if (isUser) {
      if (!!user.user?.usersImageDetails && user.user?.usersImageDetails.length > 0) {
        setProfileImage(user.user?.usersImageDetails[0]?.imageUrl)
      }
      setProfile({
        firstName: user.user.firstName,
        lastName: user.user.lastName,
        email: user.user.email,
      })
    }
  }, []);
  //useEffects ends here


  //functions starts here
  const handleprofileUpdate = (formValue: IUserProfile) => {
    if(profileSaveLoading){
      return
    }
    setProfileSaveLoading(true)
    const { firstName, lastName } = formValue;
    const data = {
      id: userId,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      files: files
    }
    if (!fileValidationError && !fileSizevalidation) {
      dispatch(UpdateProfile(data)).then((response: any) => {
        if (response.type === `${ACTION.UPDATEPROFILE}/${ACTION.FULFILLED}`) {
          if (response.payload.status === 201) {
            setProfileImage(ProfileImage);
            dispatch(userHandler({ ...user, user: response.payload.data }));
          }
          setprofilEdit(false);
          toast.success(String(t('profile.profileUpdateSuccefully')), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
          setProfileSaveLoading(false)
        } else {
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
          });
          setProfileSaveLoading(false)
        }
      })
    }
  };

  const changeLanguage = (e: string) => {
    i18n.changeLanguage(e);
    dispatch(languageHandler(e));
  }
  //functions ends here


  //formik starts here
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('validation.requiredField'))
      .transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      }).test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val.trim() !== ''
      ),
    lastName: Yup.string()
      .required(t('validation.requiredField'))
      .transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          return originalValue.trim();
        }
        return value;
      }).test(
        "no-spaces",
        t('trimMessage.trimTitle'),
        (val: any) =>
          val.trim() !== ''
      ),
  });


  const DeleteAccount = async () => {
    await dispatch(deleteuserHandler()).then((result: any) => {
      if (result?.payload?.statusCode === 200) {
        toast.success(result?.payload?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
        setTimeout(() => {
          dispatch(logoutHandler())
          navigate('/')
        }, 1000);
      }
      else if (result?.payload?.statusCode === 500) {
        toast.warning(result?.payload?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      }
      else if (result?.payload?.statusCode === 404) {
        toast.error(result?.payload?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2500,
        });
      }

    }).catch((err: any) => {
      console.log(err, "error")
    });
  }


  //formik ends here

  return (
    <>

      <div className="container-fluid">
        <div className="font-26 fw-semibold mb-3 mb-md-4">{t('profile.header')}</div>
        <div className="row">
          <div className="col-md-8 col-xl-6 my-1">
            <div className="card border-0">
              <div className="card-body">
                {!profilEdit &&
                  <div className="hstack justify-content-between mb-4">
                    <div>
                      <button type="button" className="btn btn-danger text-white rounded-45 mw-120 me-2" onClick={() => setShowDelete(true)}
                      >{t('profile.btnDeleteAccount')}</button>
                      <button type="button" onClick={() => setprofilEdit(true)} className="btn btn-primary rounded-45 mw-120">{t('profile.btnEdit')}</button>
                    </div>
                    <div className='hstack gap-1'>
                      <div className={`border ${language === DE ? 'border-dark' : 'border-white'}`}>
                        <button className='btn px-1 py-0 rounded-0' title="german language" type="button" onClick={() => changeLanguage(DE)}>
                          <img className='img-fluid' width={30} src={germanyFlag} alt="germany-icon" />
                        </button>
                      </div>
                      <div className={`border ${language === EN ? 'border-dark' : 'border-white'}`}>
                        <button className='btn px-1 py-0 rounded-0' title="english language" type="button" onClick={() => changeLanguage(EN)}>
                          <img className='img-fluid' width={30} src={usaFlag} alt="usa-icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                }

                <Formik
                  enableReinitialize={true}
                  initialValues={profile}
                  onSubmit={handleprofileUpdate}
                  validationSchema={validationSchema}
                >
                  <Form className="vstack gap-3">

                    {profilEdit &&
                      <div className="hstack justify-content-between mb-4">
                        <button type="button" onClick={() => setprofilEdit(false)} className="btn btn-outline-danger rounded-45 me-2 mw-120">{t('profile.btnCancel')}</button>
                        <button type="submit" className="btn btn-primary rounded-45 mw-120" disabled={profileSaveLoading}>{t('profile.btnSave')}</button>
                      </div>
                    }

                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label">{t('profile.lblProfileImage')}</label>
                        <div className="">
                          {!!usersImageDetails ?
                            <img src={usersImageDetails} className="img-thumbnail image-profile-page" alt="userImage" />
                            :
                            <i className="fa-solid fa-circle-user fa-3x text-black text-opacity-50"></i>
                          }
                        </div>
                      </div>
                      {profilEdit &&
                        <div className="col-md-6">
                          <label className="form-label">{t('profile.lblUploadImage')}</label>
                          <div className="mb-4">
                            <div className='hstack justify-content-between'>
                            </div>
                            <Field onChange={(event: any) => {
                              const files = event.target.files;
                              let myFiles: any = Array.from(files);
                              if (myFiles.length === 0) {
                                setFileValidationError(false)
                                setFileSizevalidation(false)
                              }
                              for (let i = 0; i < myFiles.length; i++) {
                                const fileType = myFiles[i].type;
                                const fileSize = myFiles[i].size; // in bytes4
                                // Check file size (2MB limit)
                                if (fileSize > 2 * 1024 * 1024) {
                                  setFileSizevalidation(true)
                                }
                                else {
                                  setFileSizevalidation(false)
                                }

                                if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg') {
                                  setFileValidationError(true)
                                } else {
                                  setFileValidationError(false)
                                }
                              }
                              setFiles(myFiles);
                            }} className="form-control" name="photo" type="file" id="meeting-host-formFile" accept=".jpg, .jpeg, .png"
                            />
                            <span style={{ color: "red" }}>{fileValidationError ? t("validation.FormatNotAllowed") : fileSizevalidation ? t("validation.FileSizeValidation") : ""}</span>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label">{t('profile.lblFirstName')}</label>
                        <div className="">
                          <Field type="text" className="form-control" name="firstName" disabled={profilEdit ? false : true} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">{t('profile.lblLastName')}</label>
                        <div className="">
                          <Field type="text" className="form-control" name="lastName" disabled={profilEdit ? false : true} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="form-label">{t('profile.lblEmail')}</label>
                        <div className="">
                          <Field type="text" name="email" className="form-control" readOnly disabled />
                        </div>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* alerts actions starts heres */}
      <DialogBox show={showDelete} clickOk={() => { DeleteAccount(); setShowDelete(false) }} clickCancel={() => { setShowDelete(false) }} btncancel={t('common.cancel')} btnyes={t('common.delete')} question={t('deleteBox.dltAccount')} description={'You will lose your all Data!'} />
      {/* alerts actions ends heres */}
    </>
  )
}

export default Profile;
