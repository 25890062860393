/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Button, Modal } from 'react-bootstrap';
import { quizWarningModalHandler } from '../../store/slices/modalSlice';
import { activeQuizIdAttendeeHandler, activeQuizIdGuestHandler } from '../../store/slices/meetingSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import localStorage from 'redux-persist/es/storage';
import { WARNINGTIMELS } from '../../../types/commonTypes';
import { resetQuizTimer } from '../../../common/utils';
import { useTranslation } from 'react-i18next';

const QuizWarningModal = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isGuest } = useSelector((state: RootState) => state.guestUser);
    const { currentMeeting } = useSelector((state: RootState) => state.meeting);
    const { quizWarningModal } = useSelector((state: RootState) => state.modal);
    const currentMeetingCode = currentMeeting?.code

    const [timer, setTimer] = useState(5);

    const stopQuizTimer = () => {
        if (isGuest) {
            dispatch(activeQuizIdGuestHandler(0))
        } else {
            dispatch(activeQuizIdAttendeeHandler(0))
        }
        resetQuizTimer()
    }

    const doNotGoBackHandler = () => {
        localStorage.removeItem(WARNINGTIMELS)
        stopQuizTimer()
        dispatch(quizWarningModalHandler(false))
        toast.warning(String(t("quiz.quizDiscarded")), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2500,
        })
    }

    const goBackHandler = () => {
        localStorage.removeItem(WARNINGTIMELS)
        dispatch(quizWarningModalHandler(false))
        if (isGuest) {
            navigate(`/guest/join/${currentMeetingCode}`)
        } else {
            navigate(`/joinMeeting/${currentMeetingCode}`)
        }
    }



    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1)
            } else {
                clearInterval(interval)
                doNotGoBackHandler()
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [timer])

    return (
        <Modal centered backdrop="static" show={quizWarningModal}>
            <Modal.Header className='border-0'>
                <Modal.Title className='fw-semibold'>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('quiz.quizLeaveDiscard')}
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-danger text-white' onClick={doNotGoBackHandler}>OK</Button>
                <Button className='btn btn-primary' onClick={goBackHandler}>Go Back</Button>
                <div className='text-danger text-end' style={{ width: '2rem' }}>{timer} s</div>
            </Modal.Footer>
        </Modal>
    )
}

export default QuizWarningModal