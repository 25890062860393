import React, { FC, useRef } from "react";
import { useSelector } from "react-redux";
import { Model } from "survey-core";
import * as SurveyTheme from "survey-core/themes";
import { Survey } from "survey-react-ui";
import { RootState } from "../../store/store";
import QuizPDFDownload from "./QuizPdfDownload";
import { convertToSlug } from "../../../common/utils";


interface QuizResultProps {
    quiz: any
}
const QuizResult: FC<QuizResultProps> = ({ quiz }) => {
    const resultContainerRef = useRef(null);
    const { user } = useSelector((state: RootState) => state.auth)
    const attendeeName = user?.user?.firstName
    const { currentMeeting } = useSelector((state: RootState) => state.meeting)
    const userId = user?.user?.id
    const currentMeetingHostId = currentMeeting?.hostId
    const isHost = !!userId && userId === currentMeetingHostId

    const survey = new Model(quiz.quizJson);
    survey.applyTheme(SurveyTheme.BorderlessLight);
    survey.data = quiz.questionsAnswers
    survey.mode = "display";
    survey.questionsOnPageMode = "singlePage";
    survey.showNavigationButtons = "none";
    survey.showProgressBar = "off";
    survey.showTimerPanel = "none";
    survey.maxTimeToFinishPage = 0;
    survey.maxTimeToFinish = 0;
    const correctStr = "Correct";
    const inCorrectStr = "Incorrect";
    const guestUserId = quiz?.guestUserId; 
    const quizTitle = quiz?.quizJson?.title || 'UnknownQuizTitle';
    const filename = guestUserId !== null
      ? `${convertToSlug(quizTitle)}-guest-scorecard`
      : `${convertToSlug(quizTitle)}-${convertToSlug(attendeeName)}-scorecard`;

    const getTextHtml = (text: string, str: string, isCorrect: boolean) => {
        if (text.indexOf(str) < 0) return undefined;
        return text.substring(0, text.indexOf(str)) + "<span class='" + (isCorrect ? "correctAnswer" : "incorrectAnswer") + "'>" + str + "</span>";
    }

    function renderCorrectAnswer(q: any) {
        if (!q) return;
        const isCorrect = q.isAnswerCorrect();
        if (!q.prevTitle) {
            q.prevTitle = q.title;
        }
        if (isCorrect === undefined) {
            q.title = q.prevTitle;
        }
        q.title = q.prevTitle + ' ' + (isCorrect ? correctStr : inCorrectStr);
    }

    survey.onValueChanged.add((sender, options) => {
        renderCorrectAnswer(options.question);

    });

    survey.onTextMarkdown.add((sender, options) => {
        var text = options.text;
        if (options.element.getType() !== "survey") {
            var html = getTextHtml(text, correctStr, true);
            if (!html) {
                html = getTextHtml(text, inCorrectStr, false);
            }
            if (!!html) {
                options.html = html;
            }
        }
    });

    survey.getAllQuestions().forEach(q => { renderCorrectAnswer(q) });


    if (survey.isEmpty) {
        return <div className='status-block status-block-started'>Something went wrong</div>
    } else {
        return (
            <>
                 {!isHost && <div className="text-end">
                   <QuizPDFDownload containerRef={resultContainerRef} filename={filename} linkTitle="Download attendee's scorecard" />
                </div>}

                <div className="position-relative" ref={resultContainerRef}>
                    <div className="quiz-result-score text-primary">
                        {quiz.score}%
                    </div>
                    <Survey key={`UserQuizResult_${quiz.id}`} model={survey} />
                </div>
            </>
        )
    }

}

export default QuizResult