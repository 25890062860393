import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './pages/store/store';
import { ToastContainer } from 'react-toastify';
import CommonSocket from './pages/components/CommonSocket';
import Home from './pages/Home/Home';
import Signin from './pages/Auth/Signin';
import Signup from './pages/Auth/Signup';
import GuestLogin from './pages/Auth/GuestLogin';
import GuestJoin from './pages/JoinMeeting/GuestJoin';
import AuthVerify from './common/AuthVerify';
import ReturnToMeeting from './pages/components/ReturnToMeeting';
import Header from './layouts/Header';
import Sidebar from './layouts/Sidebar';
import Dashboard from './pages/Dashboard/Dashboard';
import Meeting from './pages/MeetingList/Meeting';
import PollList from './pages/Poll/PollList';
import PollDetails from './pages/Poll/PollDetails';
import JoinMeeting from './pages/JoinMeeting/JoinMeeting';
import Profile from './pages/Profile/Profile';
import FeedbackForm from './pages/Feedback/FeedbackForm';
import WaitingArea from './pages/JoinMeeting/WaitingArea';
import QuizList from './pages/Quiz/QuizList';
import QuizDetails from './pages/Quiz/QuizDetails';
import Logout from './pages/Auth/Logout';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import ChangePassword from './pages/ChangePassword/ChangePassword';

const App = () => {
  const { isUser } = useSelector((state: RootState) => state.auth)
  const { isMiniSidebar, isFeedBackForm } = useSelector((state: RootState) => state.settings)

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <CommonSocket />
        <Header />

        {
          !isUser ?
            <>
              <Routes>
                <Route path="*" caseSensitive={false} element={<Home />} />
                <Route path="/signin" caseSensitive={false} element={<Signin />} />
                <Route path="/signup" caseSensitive={false} element={<Signup />} />
                <Route path="/forgot-password" caseSensitive={false} element={<ForgotPassword />} />
                <Route path="/reset-password/:token" caseSensitive={false} element={<ResetPassword />} />
                <Route path="/guest/login/:code" caseSensitive={false} element={<GuestLogin />} />
                <Route path="/guest/join/:code" caseSensitive={false} element={<GuestJoin />} />
                <Route path="/feedback" caseSensitive={false} element={<FeedbackForm />} />
                <Route path="/waiting-area/:code" caseSensitive={false} element={<WaitingArea />} />
              </Routes>
            </>
            :
            <>
            
              <AuthVerify />
              <ReturnToMeeting />
              <div className={`${isMiniSidebar ? 'mini-sidebar' : ''} flex-fill vstack`}>
                <div className="flex-fill d-flex">

                  {!isFeedBackForm && <Sidebar />}
                  <div className="flex-fill mb-3 mb-md-4 pt-4 content-wrapper">
                    <Routes>
                      <Route path="*" caseSensitive={false} element={<Dashboard />} />
                      <Route path="/dashboard" caseSensitive={false} element={<Dashboard />} />
                      <Route path="/reset-password/:token" caseSensitive={false} element={<ResetPassword />} />
                      <Route path="/meeting" caseSensitive={false} element={<Meeting />} />
                      <Route path="/poll" caseSensitive={false} element={<PollList />} />
                      <Route path="/poll/:pollId" caseSensitive={false} element={<PollDetails />} />
                      <Route path="/joinMeeting/:code" caseSensitive={false} element={<JoinMeeting />} />
                      <Route path="/profile" caseSensitive={false} element={<Profile />} />
                      <Route path="/changePassword" caseSensitive={false} element={<ChangePassword />} />
                      <Route path="/feedback" caseSensitive={false} element={<FeedbackForm />} />
                      <Route path="/waiting-area/:code" caseSensitive={false} element={<WaitingArea />} />
                      <Route path="/quiz" caseSensitive={false} element={<QuizList />} />
                      <Route path="/quiz/:quizId" caseSensitive={false} element={<QuizDetails />} />
                      <Route path="/logout" caseSensitive={false} element={<Logout />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </>
        }
      </Router>
    </div>
  );
}

export default App;
