/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { DatatableWrapper, Filter, TableBody, TableHeader } from "react-bs-datatable";
import DialogBox from "../components/DeleteBox";
import { useTranslation } from "react-i18next";
import { quizDataHandler, deleteQuizHandler } from "../store/slices/quizSlice";
import { useNavigate } from 'react-router-dom';
import PaginationBox from '../components/Pagination';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { selectedQuestionHandler } from "../store/slices/pollSlice";

const QuizList = () => {

    //single hooks starts here
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { t } = useTranslation('common');
    //single hooks ends here


    //useSelectors starts here
    const { user } = useSelector((state: RootState) => state.auth)
    const { quizList, totalQuizPage, totalQuizRecord } = useSelector((state: RootState) => state.quiz)
    const userId = user?.user?.id
    //useSelectors ends here


    //useStates starts here
    const [showDelete, setShowDelete] = useState(false);
    const [pageSize, setpageSize] = useState(1); // Page number
    const [perPage, setperPage] = useState(10); // per page Limit
    const [filterValue, setFilterValue] = useState("");
    const [endItem, setendItem] = useState(0);
    const [startItem, setStartItem] = useState(1);
    const [QuizDeleteId, setQuizDeleteID] = useState();
    const [sortColumn, setsortColumn] = useState<any>({ order: "desc", prop: "createdAt" }); // per page Limit 
    //useStates ends here


    //functions starts here
    const getQuizData = async (
        hostID: any,
        pageLimit: number,
        perPage: number,
        searchVal: string,
        sortColumn: any
    ) => {
        const sortColumnProp = sortColumn.prop;
        const sortColumnOrder = sortColumn.order
        const data = {
            hostID, pageLimit, perPage, searchVal, sortColumnProp, sortColumnOrder
        };

        const quizDataList = await dispatch(quizDataHandler(data));
        if (quizDataList?.payload?.currentPage !== undefined) {
            setpageSize(quizDataList?.payload?.currentPage)
        } else {
            setpageSize(prev => prev)
        }
    };

    const handlePrevPage = (e: number) => {
        setpageSize(e);
        setStartItem(startItem - perPage);
        totalrecordHandle();
    };

    const handleNextPage = (e: number) => {
        setpageSize(e);
        setStartItem(endItem + 1);
        totalrecordHandle();
    };

    const handlePageClick = (e: any) => {
        setpageSize(e);
        setStartItem(e * perPage + 1 - perPage);
        totalrecordHandle();
    };

    const totalrecordHandle = () => {
        var totalRecordTemp = startItem - 1 + perPage;
        if (totalRecordTemp > totalQuizRecord) {
            totalRecordTemp = totalQuizRecord;
        }
        setendItem(totalRecordTemp);
    };

    const DeletePollHandlers = async (id: any) => {
        await dispatch(deleteQuizHandler(id)).then((response: any) => {
            if (response?.payload?.status === 200) {
                getQuizData(userId, pageSize, perPage, filterValue, sortColumn);
                toast.success(String(t("toastMessage.quizDelete")), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                });
            }
        })
    };
    const deleteConfirmHandler = (id: any) => {
        setShowDelete(true)
        setQuizDeleteID(id)
    }
    //functions ends here


    //useEffect starts here
    useEffect(() => {
        getQuizData(userId, pageSize, perPage, filterValue, sortColumn);
    }, [perPage, pageSize, sortColumn, filterValue]);

    useEffect(() => {
        totalrecordHandle()
    }, [startItem, endItem, totalQuizRecord, quizList]);
    //useEffect ends here


    //table header starts here
    const header = [
        {
            title: 'Quiz Name',
            prop: "title",
            isSortable: true,
            isFilterable: true,
        },
        {
            title: t("quiz.lblTag"),
            prop: "tag",
            cell: (row: any) => {
                return (
                    row.quizMeetingDetails?.length > 0 &&
                    row.quizMeetingDetails.map((tags: any, index: any) => {

                        return (
                            <div
                                key={`quizlistrow_${index}`}
                                className="badge bg-primary-light text-primary rounded-pill badge-pill py-2 fw-medium me-2 mb-2">
                                <div>{tags?.meetingDetails?.code}</div>
                                <div></div>
                            </div>
                        );
                    })
                );
            },
        },
        {
            title: "Actions",
            prop: "actions",
            thProps: {
                className: "text-end"
            },
            cell: (row: any) => {
                return (
                    <>
                        <div className="hstack justify-content-end gap-2">
                            {row.quizMeetingDetails?.length === 0 &&
                                <button
                                    title="Delete"
                                    onClick={() => deleteConfirmHandler(row.id)}
                                    className="btn bg-danger bg-opacity-25 text-danger rounded-1 size-38">
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            }
                            <button
                                title="Edit"
                                onClick={() => navigate("/quiz/" + row.id)}
                                className="btn bg-success bg-opacity-25 text-success rounded-1 size-38">
                                <i className="fa-solid fa-pen"></i>
                            </button>
                        </div>
                    </>
                );
            },
        },
    ];
    //table header ends here

    return (
        <div className="container-fluid">
            <div className="quiz">
                <div className="row">
                    <div className="col-12">
                        <div className="hstack justify-content-between mb-4">
                            <div className="font-26 fw-semibold">{t("quiz.title")}</div>
                            <button
                                onClick={() => { navigate('/quiz/0'); dispatch(selectedQuestionHandler([])) }}
                                className="btn btn-primary mw-120">
                                {t("quiz.btnCreateQuiz")}
                            </button>
                        </div>
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <DatatableWrapper body={quizList} headers={header}>
                                    <div className="d-flex justify-content-end mb-3">
                                        <Filter
                                            classes={{
                                                inputGroup: 'w-auto',
                                                input: "rounded-pill rounded-end"
                                            }}
                                            placeholder={t("searchplaceholder.placeholder")}
                                            controlledProps={{
                                                filter: filterValue,
                                                onFilterChange: setFilterValue,
                                            }}
                                        />
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <TableHeader
                                                controlledProps={{
                                                    onSortChange: setsortColumn,
                                                    sortState: sortColumn,
                                                }}
                                            />
                                            <TableBody />
                                        </table>
                                    </div>

                                    <PaginationBox
                                        currentPage={pageSize}
                                        totalPage={totalQuizPage}
                                        startItem={startItem}
                                        endItem={endItem}
                                        totalRecord={totalQuizRecord}
                                        handlePrevPage={handlePrevPage}
                                        handleNextPage={handleNextPage}
                                        handlePageClick={handlePageClick}
                                    />
                                </DatatableWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DialogBox
                show={showDelete}
                clickOk={() => {
                    DeletePollHandlers(QuizDeleteId);
                    setShowDelete(false);
                }}
                clickCancel={() => {
                    setShowDelete(false);
                }}
                btncancel={t("common.cancel")}
                btnyes={t("common.delete")}
                question={t("deleteBox.dltQuiz")}
            />
        </div>
    );
};

export default QuizList;
