/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../pages/store/store';
import { feedbackFormHandler, innerHeaderShowHandler, miniSidebarHandler } from '../pages/store/slices/settingsSlice';
import { activeQuizIdAttendeeHandler, activeQuizIdGuestHandler, currentMeetingHandler, joinMessageHandler } from '../pages/store/slices/meetingSlice';
import { meetingQuestionSendViaEmailHandler, setMeetingIntrestedUserHandler } from '../pages/store/slices/joinMeetingSlice';
import socket from '../common/constants';
import DialogBox from '../pages/components/DeleteBox';
import { hostQuizWarningModalHandler, interestedListModalHandler, quizWarningModalHandler, waitingListModalHandler } from '../pages/store/slices/modalSlice';
import { userExitWaitingList } from '../services/waitingList.service';
import GuestEmailModal from '../pages/components/Modals/InterestedGuestEmailModal';
import { interestedUserHandler, setMeetingIntrestedGuestUserHandler } from '../pages/store/slices/guestUserSlice';
import FontSizeChanger from '../pages/components/FontSizeChanger';
import logo from "../images/logo.webp"
import waitingListLogo from "../images/waiting-list-users.svg"
import interestLogo from "../images/interest.svg"
import interestedLogo from "../images/interested.svg"
import QuizWarningModal from '../pages/components/Modals/QuizWarningModal';
import { resetQuizTimer } from '../common/utils';
import { meetingStatusUpdate, updateActiveQuizId } from '../services/meeting.service';
import HostQuizWarningModal from '../pages/components/Modals/HostQuizWarningModal';
import { ACTIVE, ENDED, HOSTQUIZTIMELS, INACTIVE } from '../types/commonTypes';
import { getQuizzesForMeeting, updateMeetingQuizStatus } from '../services/quiz.service';
import { meetingQuizListHandler } from '../pages/store/slices/quizSlice';
import { toast } from 'react-toastify';

const Header = () => {

    //single hooks starts here
    const dispatch = useDispatch()
    let navigate = useNavigate();
    let location = useLocation();
    const { t } = useTranslation('common');
    //single hooks ends here

    //custom variables starts here
    let pathname = location.pathname
    //custom variables ends here


    //useSelectors starts here
    const { user, isUser } = useSelector((state: RootState) => state.auth)
    const { guestUser, isGuest, guestEmail, isUserInterested } = useSelector((state: RootState) => state.guestUser)
    const { isFeedBackForm, isHeaderShow, isWaitingUser } = useSelector((state: RootState) => state.settings)
    const { isMiniSidebar } = useSelector((state: RootState) => state.settings)
    const { currentMeeting, waitingList, interestedList, activeQuizIdAttendee, activeQuizIdGuest } = useSelector((state: RootState) => state.meeting)
    const { allQuizAnswer, meetingQuizList, activeQuizDataId, activeQuizId } = useSelector((state: RootState) => state.quiz)
    const { hostQuizWarningModal, quizWarningModal } = useSelector((state: RootState) => state.modal)

    const firstName = user?.user?.firstName
    const lastName = user?.user?.lastName
    const usersImageDetails = user?.user?.usersImageDetails && user?.user?.usersImageDetails?.length > 0 && user?.user?.usersImageDetails[0]?.imageUrl
    const userId = user?.user?.id
    const guestUserId = guestUser?.id;
    const currentMeetingId = currentMeeting?.id
    const currentMeetingTitle = currentMeeting?.title
    const currentMeetingHostId = currentMeeting?.hostId
    const currentMeetingGeoFenceProtected = currentMeeting?.isGeoFenceProtected
    const currentMeetingEmailProtected = currentMeeting?.emailProtected || currentMeeting?.isEmailProtected
    const isHost = !!userId && userId === currentMeetingHostId
    //useSelectors ends here


    //useStates starts here
    const [guestUserEmail, setGuestUserEmail] = useState<string>(guestUser.email);
    const [endMeeting, setEndMeeting] = useState(false);
    const [leaveMeeting, setLeaveMeeting] = useState<boolean>(false);
    const [isMeetingScreen, setIsMeetingScreen] = useState<boolean>(false)
    const [isWaitingScreen, setIsWaitingScreen] = useState<boolean>(false)
    const [guestEmailModal, setGuestEmailModal] = useState<boolean>(false)
    const [showHeader, setShowHeader] = useState<boolean>(false)
    const [guestLoginHeader, setGuestLoginHeader] = useState<boolean>(false)

    //useStates ends here


    //functions starts here
    const handleChangeGuestEmail = (e: string) => {
        setGuestUserEmail(e)
    }

    const handleLeaveMeeting = async () => {
        if (isWaitingScreen) {
            await userExitWaitingList({
                meetingId: currentMeeting?.id,
                attendeeId: userId ? userId : null,
                guestUserId: isGuest ? guestUserId : null
            })
            socket.emit('exit-waiting-list', { hostId: currentMeeting?.hostId, meetingId: currentMeeting?.id })
            dispatch(joinMessageHandler(''))
            dispatch(currentMeetingHandler(null))
            if (isGuest) {
                navigate("/")
            } else {
                navigate("/dashboard")
            }
        } else {
            dispatch(feedbackFormHandler(true))
            navigate('/feedback')
        }
        const leaveParams = {
            meetingId: currentMeeting?.id,
            userId: isGuest ? null : userId,
            guestUserId: isGuest ? guestUserId : null
        };
        socket.emit('leave-meeting-send', { meetingId: leaveParams?.meetingId, userId: leaveParams.userId, guestUserId: leaveParams.guestUserId });
        socket.on('connect', () => {
            socket.emit('leave-meeting-send', { meetingId: leaveParams?.meetingId, userId: leaveParams.userId, guestUserId: leaveParams.guestUserId })
        })
        setGuestUserEmail("")
        dispatch(interestedUserHandler(false))
        if (isGuest) {
            dispatch(activeQuizIdGuestHandler(0))
        } else {
            dispatch(activeQuizIdAttendeeHandler(0))
        }

        resetQuizTimer()
    }

    const handleEndMeeting = async () => {
        let meetingStatus = await meetingStatusUpdate(currentMeetingId, ENDED)
        if (meetingStatus.status === 200) {
            updateActiveQuizId(currentMeetingId, 0)
            await dispatch(meetingQuestionSendViaEmailHandler({
                meetingId: currentMeetingId
            }));
            socket.emit('end-meeting-send', { meetingId: Number(currentMeetingId) })
            dispatch(currentMeetingHandler(null))
            navigate('/meeting')
        }
    }


    const hideGuestEmailModal = () => {
        setGuestEmailModal(false)
    }

    const intrestedUserHandler = async () => {
        dispatch(setMeetingIntrestedUserHandler(currentMeetingId))
            .then(() => {
                socket.emit("intrested-user-send", { meetingId: currentMeetingId })
                socket.on('connect', () => {
                    socket.emit("intrested-user-send", { meetingId: currentMeetingId })
                })
                dispatch(interestedUserHandler(!isUserInterested))
            },
                (error: any) => {
                    const resMessage =
                        (error.response.payload &&
                            error.response.data.payload &&
                            error.response.payload.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
    }

    const intrestedGuestUserHandler = async () => {
        let data = {
            meetingId: currentMeetingId,
            guestUserId: guestUserId,
            email: guestEmail ? guestEmail : guestUser?.email
        }
        dispatch(setMeetingIntrestedGuestUserHandler(data))
            .then(
                (response: any) => {
                    socket.emit("intrested-user-send", { meetingId: currentMeetingId })
                    socket.on('connect', () => {
                        socket.emit("intrested-user-send", { meetingId: currentMeetingId })
                    })
                    setGuestEmailModal(false)
                    dispatch(interestedUserHandler(!isUserInterested))
                },
                (error: any) => {
                    setGuestEmailModal(false)
                    const resMessage =
                        (error.response.payload &&
                            error.response.data.payload &&
                            error.response.payload.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
    }

    const interestedGuestHandler = () => {
        if (isUserInterested) {
            intrestedGuestUserHandler()
        }
        else {
            if (currentMeetingEmailProtected) {
                intrestedGuestUserHandler()
            } else {
                setGuestEmailModal(true)
            }
        }
    }

    const leaveMeetingHandler = () => {
        setLeaveMeeting(true)
    }

    const toggleSideBar = () => {
        dispatch(miniSidebarHandler(!isMiniSidebar))
    }

    const handleLeaveMessage = () => {
        const areAllQuizzesInactive = meetingQuizList?.every(quiz => quiz.status === INACTIVE);
        const activeQuiz = meetingQuizList?.find(quiz => quiz.status === ACTIVE);
        const isCompleted = activeQuiz ? allQuizAnswer?.some(completedQuiz => completedQuiz.quizId === activeQuiz.quizId) : false;

        const showWarningMessage = !(isCompleted || areAllQuizzesInactive || meetingQuizList?.length === 0)
        if (showWarningMessage) {
            return t('deleteBox.leaveMeetingQuiz')
        }
        else {
            return t('deleteBox.leaveMeeting')
        }
    }
    //functions end here

    // //useEffects starts here
    useEffect(() => {
        const paths = ['/waiting-area', '/feedback', '/guest/join'];
        const isMatching = paths.some((path: string) => pathname.includes(path));

        if (isUser || (isGuest && isMatching)) {
            setShowHeader(true)
        } else {
            if (!isHeaderShow) {
                innerHeaderShowHandler(false);
                setGuestLoginHeader(false);
            }
            const guestJoinPattern = /^\/guest\/login\/[a-zA-Z0-9_-]+$/;
            if (guestJoinPattern.test(pathname)) {
                innerHeaderShowHandler(true);
                setGuestLoginHeader(true);
            } else {
                setGuestLoginHeader(false);

                innerHeaderShowHandler(false);
            }
            setShowHeader(false)

        }
    }, [isUser, isGuest, pathname, isWaitingUser, innerHeaderShowHandler, guestLoginHeader]);

    useEffect(() => {
        if (interestedList?.findIndex(x => !!x.userId ? x.userId === userId : !!x.guestUserId ? x.guestUserId === guestUserId : false) > -1) {
            dispatch(interestedUserHandler(true))
        } else {
            dispatch(interestedUserHandler(false))
        }
    }, [interestedList, userId, guestUserId])


    //to display quiz warning modal for attendee
    useEffect(() => {
        const isShowWarningModal = !!currentMeetingId && !isHost && !(pathname.includes(isGuest ? '/guest/join' : '/joinMeeting') || pathname.includes('/feedback') || pathname.includes('/logout'))
        if (isShowWarningModal) {
            const activeQuizIdUser = isGuest ? activeQuizIdGuest : activeQuizIdAttendee;
            if (activeQuizIdUser > 0) {
                const areAllQuizzesInactive = meetingQuizList?.every(quiz => quiz.status === INACTIVE);
                const activeQuiz = meetingQuizList?.find(quiz => quiz.status === ACTIVE);
                const isCompleted = activeQuiz ? allQuizAnswer?.some(completedQuiz => completedQuiz.quizId === activeQuiz.quizId) : false;

                const showWarningModal = !(isCompleted || areAllQuizzesInactive || meetingQuizList?.length === 0)
                if (showWarningModal) {
                    dispatch(quizWarningModalHandler(true));
                }
            }
        }
    }, [pathname, currentMeetingId, isHost, isGuest, activeQuizIdGuest, activeQuizIdAttendee, meetingQuizList])


    //to display quiz warning modal for host
    useEffect(() => {
        const isShowHostWarningModal = !!currentMeetingId && isHost && !(pathname.includes('/joinMeeting'))
        if (isShowHostWarningModal) {
            const activeQuiz = meetingQuizList?.find(quiz => quiz.status === ACTIVE);
            if (activeQuiz) {
                const spentQuizTime: any = typeof localStorage.getItem(HOSTQUIZTIMELS) !== 'object' ? true : false;
                if (spentQuizTime) {
                    dispatch(hostQuizWarningModalHandler(true));
                } else {
                    let status = ACTIVE
                    let quizValueId = activeQuizDataId
                    let quizId = activeQuizId
                    updateMeetingQuizStatus(quizValueId, status).then(
                        async (response) => {
                            if (response.status === 200) {

                                await updateActiveQuizId(currentMeetingId, 0)

                                socket.emit("meeting-quiz-send", { meetingId: currentMeetingId, quizId: quizId, status: status })
                                socket.on('connect', () => {
                                    socket.emit("meeting-quiz-send", { meetingId: currentMeetingId, quizId: quizId, status: status })
                                })

                                let quizzesListData = await getQuizzesForMeeting(currentMeetingId)
                                if (quizzesListData.status === 200) {
                                    dispatch(meetingQuizListHandler(quizzesListData.data?.meetingQuizData))
                                }

                                toast.warning(String(t("quiz.quizNotStarted")), {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 2500,
                                })
                            }
                        },
                        (error) => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            console.log("resMessage ==>", resMessage)
                        }
                    )
                }
            }
        }
    }, [pathname, currentMeetingId, isHost, meetingQuizList, activeQuizDataId, activeQuizId])

    useEffect(() => {
        setIsMeetingScreen(pathname.includes('/joinMeeting') || pathname.includes('/waiting-area') || pathname.includes('/guest/join'))
        setIsWaitingScreen(pathname.includes('/waiting-area'))
    }, [pathname])


    return (
        <>
            {showHeader ?
                <>
                    <div className="sticky-top bg-white shadow-sm" id="top-navbar">
                        <div className="d-flex">
                            <div className={`${isMiniSidebar ? 'mini-logo' : ''} logo hstack justify-content-center`}>
                                {isFeedBackForm && (isGuest || isUser) ?
                                    <span> <img src={logo} alt="logo" /></span>
                                    :
                                    <Link to='/dashboard'>
                                        <img src={logo} alt="logo" />
                                    </Link>
                                }
                            </div>

                            <div className="flex-fill hstack justify-content-between p-1 pe-3 gap-1">
                                <div className='hstack gap-2 gap-sm-3 flex-wrap justify-content-center'>
                                    <FontSizeChanger />
                                    {(!isFeedBackForm && !isGuest) &&
                                        <button className="btn rounded-0 p-0" onClick={toggleSideBar}>
                                            {isMiniSidebar && window.innerWidth <= 991 ?
                                                <i className="fa-solid fa-times text-primary fa-2xl"></i>
                                                :
                                                <i className="fa-solid fa-bars text-primary fa-2xl"></i>
                                            }
                                        </button>
                                    }
                                </div>
                                <div className='hstack gap-2'>
                                    {isMeetingScreen &&
                                        <div className='header-action-btns'>
                                            {isHost ? (
                                                <div className='d-flex gap-2'>
                                                    {currentMeetingGeoFenceProtected &&
                                                        <button type='button' onClick={() => dispatch(waitingListModalHandler(true))} className='btn btn-primary position-relative' title={!isHost ? t('guest.guestJoin.lblInterestedToolTip') : ""}>
                                                            <img width={20} src={waitingListLogo} alt='waitingListLogo' />
                                                            <span className='ms-1 d-none d-md-inline'>{t('guest.guestJoin.lblMeetingWaitingList')}</span>
                                                            <span className="position-absolute top-0 start-90 translate-middle badge rounded-pill badge-notification bg-danger ms-2">{waitingList?.length > 0 ? waitingList?.length : ""}</span>
                                                        </button>
                                                    }
                                                    <button type='button' onClick={() => dispatch(interestedListModalHandler(true))} className='btn btn-primary position-relative' title={!isHost ? t('guest.guestJoin.lblInterestedToolTip') : ""}>
                                                        <img width={15} src={interestedLogo} alt='interestedLogo' />
                                                        <span className='ms-1 d-none d-md-inline'>{t('guest.guestJoin.lblIntrested')}</span>
                                                        <span className="position-absolute top-0 start-90 translate-middle badge rounded-pill badge-notification bg-danger ms-2">{interestedList?.length > 0 ? interestedList.length : ""}</span>
                                                    </button>

                                                    <button title={t('joinMeeting.endMeeting')} onClick={() => setEndMeeting(true)} className="btn btn-danger text-white rounded-45">
                                                        <i className="fa-solid fa-phone-slash"></i> <span className='d-none d-md-inline'>{t('joinMeeting.endMeeting')}</span>
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className='d-flex gap-2'>
                                                    {!isWaitingScreen &&

                                                        <button className={`btn ${isUserInterested ? 'btn-primary text-white' : 'btn-primary-light text-primary'} rounded-45`} onClick={() => isGuest ? interestedGuestHandler() : intrestedUserHandler()} title={t('guest.guestJoin.lblInterestedToolTip')}>
                                                            <img width={15} src={`${isUserInterested ? interestedLogo : interestLogo}`} alt='interestedLogo' /> <span className='ms-1 d-none d-md-inline'>{t('guest.guestJoin.lblIntrested')}</span> {isUserInterested ? '' : '?'}
                                                        </button>
                                                    }
                                                    <button title={t('guest.guestJoin.btnLeaveMeeting')} onClick={leaveMeetingHandler} className="btn btn-danger text-white rounded-45">
                                                        <i className="fa-solid fa-sign-out"></i> <span className='d-none d-md-inline'>{t('guest.guestJoin.btnLeaveMeeting')}</span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    }

                                    {!!isGuest ?
                                        <div className='hstack gap-2'>
                                            <i className="fa-solid fa-user fa-lg text-muted"></i>
                                            <span className='fw-medium text-truncate' style={{ maxWidth: 200 }}>
                                                {
                                                    (guestUser.isAnonymous ? t('common.lblAnonymous') : guestUser.name)
                                                }
                                            </span>
                                        </div>
                                        :
                                        <div className="dropdown">
                                            <button className="btn px-sm-2 px-lg-3" type="button"
                                                id="tour-profile" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className="hstack gap-2">
                                                    {!!usersImageDetails ?
                                                        <img src={usersImageDetails} alt="profile" className="profile-img-header" />
                                                        :
                                                        <i className="fa-solid fa-user text-muted"></i>
                                                    }
                                                    <span className="d-md-block d-none fw-medium text-truncate" style={{ maxWidth: 200 }}>{firstName + " " + lastName}</span>
                                                </span>
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="profile">
                                                <li><Link className="dropdown-item" to="/profile">{t('defaultHeaderLayout.lblProfile')}</Link></li>
                                                <li><Link className="dropdown-item" to="/changePassword">Change Password</Link></li>
                                                <li><Link className="dropdown-item" to="/logout">{t('defaultHeaderLayout.lblSignOut')}</Link></li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* alerts actions starts heres */}
                    <DialogBox show={leaveMeeting} clickOk={() => { handleLeaveMeeting(); setLeaveMeeting(false) }} clickCancel={() => { setLeaveMeeting(false) }} btncancel={t('deleteBox.btnNo')} btnyes={t('deleteBox.btnYes')} question={handleLeaveMessage()} />
                    <DialogBox show={endMeeting} clickOk={() => { handleEndMeeting(); setEndMeeting(false) }} clickCancel={() => { setEndMeeting(false) }} btncancel={t('deleteBox.btnNo')} btnyes={t('deleteBox.btnYes')} question={activeQuizId === 0 ? t('deleteBox.endMeeting') : t('deleteBox.endMeetingQuiz')} />
                    <GuestEmailModal guestEmailModal={guestEmailModal} hideGuestEmailModal={hideGuestEmailModal} intrestedGuestUserHandler={intrestedGuestUserHandler} handleChangeGuestEmail={handleChangeGuestEmail} guestUserEmail={guestUserEmail} />
                    {/* alerts actions ends heres */}

                </>
                :
                <>
                    {
                        guestLoginHeader ?
                            <div className='guestlogin-topbar'>
                                <div className='container'>
                                    <div className='hstack gap-3'>
                                        <div>
                                            <Link className="navbar-brand" to="/">
                                                <img className='img-fluid' src={logo} alt="logo" width="50" height="50" />
                                            </Link>
                                        </div>
                                        <div className='font-24 fw-medium text-white'>{currentMeetingTitle}</div>
                                    </div>
                                </div>
                            </div>
                            :
                            <nav className="sticky-top navbar navbar-light bg-white shadow">
                                <div className="container-fluid">
                                    <div className="navbar-header">
                                        <Link to='/'>
                                            <img className='img-fluid' src={logo} width="50" height="50" alt='logo' />
                                        </Link>
                                    </div>
                                    <div className="show">
                                        <ul className="nav navbar-right">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/signin">{t('header.btnLogin')}</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/signup">{t('header.btnSignup')}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                    }
                </>
            }
            {quizWarningModal && <QuizWarningModal />}
            {isHost && hostQuizWarningModal && <HostQuizWarningModal />}
        </>
    );
};

export default Header;