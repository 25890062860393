import { PayloadAction, createSlice } from '@reduxjs/toolkit'

//modal initial states
export interface IModal {
    waitingListModal: boolean
    interestedListModal: boolean
    meetingPasswordModal: boolean
    startMeetingModal: boolean
    findMeetingModal: boolean
    createMeetingModal: boolean
    userQuizResultModal: boolean
    quizWarningModal: boolean
    shareMeetingModal: boolean
    hostQuizWarningModal: boolean
}

const initialState: IModal = {
    waitingListModal: false,
    interestedListModal: false,
    meetingPasswordModal: false,
    startMeetingModal: false,
    findMeetingModal: false,
    createMeetingModal: false,
    userQuizResultModal: false,
    quizWarningModal: false,
    shareMeetingModal: false,
    hostQuizWarningModal: false,
}

//modalSlice
export const modalSlice = createSlice({
    name: 'modalSlice',
    initialState,
    reducers: {
        waitingListModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.waitingListModal = payload;
        },
        interestedListModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.interestedListModal = payload;
        },
        meetingPasswordModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.meetingPasswordModal = payload;
        },
        startMeetingModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.startMeetingModal = payload;
        },
        findMeetingModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.findMeetingModal = payload;
        },
        createMeetingModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.createMeetingModal = payload;
        },
        userQuizResultModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.userQuizResultModal = payload;
        },
        quizWarningModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.quizWarningModal = payload;
        },
        shareMeetingModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.shareMeetingModal = payload;
        },
        hostQuizWarningModalHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.hostQuizWarningModal = payload
        }
    }
})


export const { waitingListModalHandler, interestedListModalHandler, meetingPasswordModalHandler, startMeetingModalHandler, findMeetingModalHandler, createMeetingModalHandler, userQuizResultModalHandler, quizWarningModalHandler, shareMeetingModalHandler, hostQuizWarningModalHandler } = modalSlice.actions;
export default modalSlice.reducer;