import axios from "axios";
import authHeader from "./auth-header";
import languageHeader from "./language-header";

const API_URL = process.env.REACT_APP_API_URL

export const guestUserLogin = async (name: string, userName: string, isAnonymous: boolean, meetingId: number, email: string) => {
    return axios.post(API_URL + "guest-users/create", {
        name,
        userName,
        isAnonymous,
        meetingId,
        email
    })
    .then((response) => {
        return response;
    });
};

export const guestAddMeetingQuestion = async (question: string, meetingId: number, guestUserId: number) => {
    return axios.post(API_URL + "meetings/guest-create/meeting-question", {
        question,
        meetingId,
        guestUserId,
    });
}

export const guestEditMeetingQuestion = async (question: string, meetingId: number, guestUserId: number,meetingQuestionId: number, isEdited: boolean ) => {
    return axios.post(API_URL + "meetings/guest-edit/meeting-question", {
        question,
        meetingId,
        guestUserId,
        meetingQuestionId,
        isEdited
    });
}

export const addPollsQuestionsAnswerByGuest = async (meetingId: number, newSelectedAnswer :any, guestUserId: any,pollId:any) => {
    return axios.post(API_URL + "polls-questions-answers/create-by-guest", {
        meetingId,
        newSelectedAnswer,
        guestUserId,
        pollId
    }, { headers: authHeader() });
}


export const getPollsQuestionsAnswerByMeetingIdForGuest = async (meetingId: number, guestUserId: number) => {
    return axios.get(API_URL + "polls-questions-answers/by-meeting-guest/" + meetingId + '/' + guestUserId)
        .then((response) => {
            return response;
        });
};

export const saveUpvoteForMeetingQuestionForGuest = async (meetingId: number, meetingQuestionId: number, guestUserId: number) => {
    return axios.post(API_URL + "meetings-question-upvotes/create-by-guest-user", {
        meetingId,
        meetingQuestionId,
        guestUserId
    }, { headers: authHeader() });
}

export const addMeetingQuestionReplyForGuest = async (questionReply: string, meetingQuestionId: number, guestUserId: number) => {
    return axios.post(API_URL + "meeting-questions-reply/create/by-guest", {
        questionReply,
        meetingQuestionId,
        guestUserId
    }, { headers: authHeader() });
}

export const saveUpvoteForMeetingQuestionReplyForGuest = async (meetingQuestionId: number, meetingQuestionsReplyId: number, guestUserId: number) => {
    return axios.post(API_URL + "meeting-questions-reply-upvotes/create-by-guest-user", {
        meetingQuestionId,
        meetingQuestionsReplyId,
        guestUserId
    }, { headers: authHeader() });
}

export const setMeetingIntrestedGuestUser = async (meetingId: number, guestUserId: number, email: any) => {
    return axios.post(API_URL + "meeting-interested-users/intrested-by-guest-user", {
        meetingId,
        guestUserId,
        email
    }, { headers: authHeader() });
}

export const checkUserAccess = async (code: any, password: any, longitude: any, latitude: any, loggedInUser: any) => {
    return axios.post(API_URL + "meetings/check-meeting-join-access", {
        code,
        password,
        longitude,
        latitude,
        loggedInUser
    }, { headers: languageHeader() })
    .then((response) => {
        return response;
    });
};