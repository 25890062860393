import React, { useEffect, useRef, useState } from 'react'
import { meetingStatusUpdate, updateActiveQuizId } from '../../services/meeting.service'
import socket from '../../common/constants'
import { useLocation, useNavigate } from 'react-router-dom'
import Draggable from 'react-draggable';
import { getallWaitingList, userExitWaitingList } from '../../services/waitingList.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { currentMeetingHandler } from '../store/slices/meetingSlice';
import { feedbackFormHandler, miniSidebarHandler } from '../store/slices/settingsSlice';
import { debounce } from 'lodash';
import { meetingQuestionSendViaEmailHandler } from '../store/slices/joinMeetingSlice';
import DialogBox from './DeleteBox';
import { useTranslation } from 'react-i18next';
import { APPROVED, ENDED } from '../../types/commonTypes';
import { PENDING } from '../store/actions';

const ReturnToMeeting = () => {
    //single hooks starts here
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { t } = useTranslation('common');
    //single hooks ends here


    //custom variables starts here
    let pathname = location.pathname
    //custom variables ends here


    //useSelectors starts here
    const { user } = useSelector((state: RootState) => state.auth)
    const userId = user?.user?.id
    const { guestUser, isGuest } = useSelector((state: RootState) => state.guestUser)
    const guestUserId = guestUser?.id;
    const { currentMeeting } = useSelector((state: RootState) => state.meeting)
    const currentMeetingId = currentMeeting?.id
    const currentMeetingHostId = currentMeeting?.hostId
    const currentMeetingCode = currentMeeting?.code
    const currentMeetingTitle = currentMeeting?.title
    const isHost = !!userId && userId === currentMeetingHostId
    //useSelectors ends here


    //useRefs starts here
    const dragRef = useRef<Draggable | null>(null)
    const dragWidth = 225
    const dragHeight = 115
    const offsetPosition = 50
    const dragPosition = {
        x: window.innerWidth - dragWidth - offsetPosition,
        y: window.innerHeight - dragHeight - offsetPosition
    }
    //useRefs ends here


    //useStates starts here
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [endMeetingPopup, setEndMeetingPopup] = useState<boolean>(false)
    const [dragBounds, setDragBounds] = useState({
        left: 0,
        top: 0,
        right: window.innerWidth - dragWidth,
        bottom: window.innerHeight - dragHeight,
    });
    //useStates ends here


    //functions starts here
    const handleEndMeeting = async () => {
        let meetingStatus = await meetingStatusUpdate(currentMeetingId, ENDED)
        if (meetingStatus.status === 200) {
            updateActiveQuizId(currentMeetingId, 0)
            await dispatch(meetingQuestionSendViaEmailHandler({
                meetingId: currentMeetingId
            }));
            socket.emit('end-meeting-send', { meetingId: Number(currentMeetingId) })
            dispatch(currentMeetingHandler(null))
            navigate('/meeting')
        }
    }

    const handleLeaveMeeting = async () => {

        if (currentMeeting?.isGeoFenceProtected) {
            await getallWaitingList(currentMeetingId)
                .then((res: any) => {
                    if (res.data.length > 0) {
                        res.data.map(async (ele: any) => {
                            if (ele.meetingId === currentMeetingId && ele.attendeeId === userId) {
                                if (ele.status === APPROVED) {
                                    dispatch(feedbackFormHandler(true))
                                    navigate('/feedback')
                                } else if (ele.status === PENDING) {
                                    await userExitWaitingList({
                                        meetingId: currentMeetingId,
                                        attendeeId: userId,
                                        guestUserId: null
                                    })
                                    socket.emit('exit-waiting-list', { hostId: currentMeetingHostId, meetingId: currentMeetingId })
                                    dispatch(currentMeetingHandler(null))
                                }
                            } else {
                                dispatch(feedbackFormHandler(true))
                                navigate('/feedback')
                            }
                        })
                    } else {
                        dispatch(feedbackFormHandler(true))
                        navigate('/feedback')
                    }
                })
                .catch((error) => {
                    console.error("Error fetching waiting list:", error);
                });
        } else {
            dispatch(feedbackFormHandler(true))
            navigate('/feedback')
        }
        const leaveParams = {
            meetingId: currentMeeting?.id,
            userId: isGuest ? null : userId,
            guestUserId: isGuest ? guestUserId : null
        };
        socket.emit('leave-meeting-send', { meetingId: leaveParams?.meetingId, userId: leaveParams.userId, guestUserId: leaveParams.guestUserId });
        socket.on('connect', () => {
            socket.emit('leave-meeting-send', { meetingId: leaveParams?.meetingId, userId: leaveParams.userId, guestUserId: leaveParams.guestUserId })
        })
    }

    const handleReturnTo = () => {
        if (isHost) {
            navigate(`/joinMeeting/${currentMeetingCode}`)
        } else {
            if (currentMeeting?.isGeoFenceProtected) {
                getallWaitingList(currentMeetingId)
                    .then((res: any) => {
                        if (res.data.length > 0) {
                            res.data.map((ele: any) => {
                                if (ele.meetingId === currentMeetingId && ele.attendeeId === userId) {
                                    if (ele.status === APPROVED) {
                                        navigate(`/joinMeeting/${currentMeetingCode}`)
                                    }
                                    else if (ele.status === PENDING) {
                                        navigate(`/waiting-area/` + currentMeetingCode)
                                    }
                                } else {
                                    navigate(`/joinMeeting/${currentMeetingCode}`)
                                }
                                return null
                            })
                        } else {
                            navigate(`/joinMeeting/${currentMeetingCode}`)
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching waiting list:", error);
                    });
            } else {
                navigate(`/joinMeeting/${currentMeetingCode}`)
            }
        }
        if (window.innerWidth < 992) {
            dispatch(miniSidebarHandler(false))
        }
    }
    //functions ends here


    //useEffects starts here
    const handleResize = () => {
        if (showPopup) {
            let dragPosition = {
                x: window.innerWidth - dragWidth - offsetPosition,
                y: window.innerHeight - dragHeight - offsetPosition
            }
            let dragBounds = {
                left: 0,
                top: 0,
                right: window.innerWidth - dragWidth,
                bottom: window.innerHeight - dragHeight,
            }
            setDragBounds(dragBounds)
            dragRef?.current?.setState(dragPosition);
        }
    };

    const debouncedHandleResize = debounce(handleResize, 200);

    useEffect(() => {
        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [debouncedHandleResize]);

    useEffect(() => {
        setShowPopup(!(pathname.includes('/joinMeeting') || pathname.includes('/waiting-area') || pathname.includes('/feedback')))
    }, [pathname])
    //useEffects ends here


    return (
        <>
            {!!currentMeetingCode && showPopup &&
                <>
                    <Draggable
                        defaultPosition={dragPosition}
                        ref={dragRef}
                        handle=".drag-handle"
                        bounds={dragBounds}
                    >
                        <div className='meet-actions-wrapper'>
                            <div className='meet-actions d-flex flex-column text-white bg-primary rounded-4' style={{ minWidth: `${dragWidth}px` }}>
                                <div className='flex-50 text-center p-3 border-bottom drag-handle border-gray'>
                                    <span className='d-block text-truncate' style={{ maxWidth: 250 }}>{currentMeetingTitle}</span>
                                </div>
                                <div className='flex-50 d-flex align-items-stretch'>
                                    <button
                                        onClick={handleReturnTo}
                                        className="btn flex-50 hstack justify-content-center gap-2 p-3 rounded-0 text-white"
                                    >
                                        <i className="fa-solid fa-right-to-bracket"></i> Return
                                    </button>
                                    <span className='border-end border-gray'></span>
                                    <button
                                        onClick={() => isHost ? setEndMeetingPopup(true) : handleLeaveMeeting()}
                                        className="btn flex-50 hstack justify-content-center gap-2 p-3 rounded-0 text-white"
                                    >
                                        <i className="fa-solid fa-phone-slash"></i>
                                        {isHost ? ' End' : ' Leave'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                    <DialogBox show={endMeetingPopup} clickOk={() => { handleEndMeeting(); setEndMeetingPopup(false) }} clickCancel={() => { setEndMeetingPopup(false) }} btncancel={t('deleteBox.btnNo')} btnyes={t('deleteBox.btnYes')} question={t('deleteBox.endMeeting')} />
                </>
            }
        </>
    )
}

export default ReturnToMeeting