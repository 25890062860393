import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as actions from "../actions/index";
import { deleteQuiz, getQuizAnswerOfAttendee, getQuizAnswerOfGuest, quizAnswersByAttendee, quizAnswersByGuest, quizCreate, quizData, quizGetById, quizUpdateByID } from "../../../services/quiz.service";
import { get } from "lodash";
/* eslint-disable @typescript-eslint/no-unused-expressions */

//quiz initial states
export interface IQuizSlice {
    quizList: any[]
    quizMessage: string
    quizSuccessful: boolean
    totalQuizPage: number
    totalQuizRecord: number,
    activeQuizDataId: number,
    activeQuizId: number,
    allQuizAnswer: any[],
    meetingQuizList: any[]
}

const initialState: IQuizSlice = {
    quizList: [],
    quizMessage: '',
    quizSuccessful: false,
    totalQuizPage: 0,
    totalQuizRecord: 0,
    activeQuizDataId: 0,
    activeQuizId: 0,
    allQuizAnswer: [],
    meetingQuizList: []
}

interface IQuizCreate {
    title: string
    maxTimeToFinish: number
    maxTimeToFinishPage: number
    selectedQuestion: any[]
}

interface IQuizUpdate {
    ID: number
    title: string
    maxTimeToFinish: number
    maxTimeToFinishPage: number
    selectedQuestion: any[]
}

interface IQuizData {
    hostID: any,
    pageLimit: any,
    perPage: any,
    searchVal: any,
    sortColumnProp: any,
    sortColumnOrder: any,
}

//quiz api thunks
export const quizCreateHandler: any = createAsyncThunk(
    actions.CREATEQUIZ,
    (data: IQuizCreate, { rejectWithValue }) =>
        quizCreate(data?.title, data?.maxTimeToFinish, data?.maxTimeToFinishPage, data?.selectedQuestion,).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const quizGetByIdHandler: any = createAsyncThunk(
    actions.QUIZGETBYID,
    (data: any, { rejectWithValue }) =>
        quizGetById(data).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const quizUpdateByIdHandler: any = createAsyncThunk(
    actions.QUIZUPDATE,
    (data: IQuizUpdate, { rejectWithValue }) =>
        quizUpdateByID(data?.ID, data?.title, data?.maxTimeToFinish, data?.maxTimeToFinishPage, data?.selectedQuestion).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const deleteQuizHandler: any = createAsyncThunk(
    actions.DELETEQUIZ,
    (data: any, { rejectWithValue }) =>
        deleteQuiz(data).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const quizDataHandler: any = createAsyncThunk(
    actions.QUIZDATA,
    async (data: IQuizData, { rejectWithValue }) => {
        try {
            const response = quizData(data?.hostID, data?.pageLimit, data?.perPage, data?.searchVal, data?.sortColumnProp, data?.sortColumnOrder)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }
);
export const quizAnswerByAttendeeHandler: any = createAsyncThunk(
    actions.QUIZANSWERBYATTENDEE,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = quizAnswersByAttendee(data?.meetingId, data?.quizId, data?.questionsAnswers, data?.score, data?.quizJson)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }
);
export const quizAnswerByGuestHandler: any = createAsyncThunk(
    actions.QUIZANSWERBYGUEST,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = quizAnswersByGuest(data?.meetingId, data?.quizId, data?.guestUserId, data?.questionsAnswers, data?.score, data?.quizJson)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }
);
export const getQuizAnswerOfGuestHandler: any = createAsyncThunk(
    actions.GETQUIZANSWEROFGUEST,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = getQuizAnswerOfGuest(data?.meetingId, data?.guestUserId, data?.quizId)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }
);
export const getQuizAnswerOfAttendeeHandler: any = createAsyncThunk(
    actions.GETQUIZANSWEROFATTENDIEE,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = getQuizAnswerOfAttendee(data?.meetingId, data?.quizId)
            return response
        }
        catch (error) {
            return rejectWithValue(error)
        }
    }
);


//quizSlice
export const quizSlice = createSlice({
    name: 'quizSlice',
    initialState,
    reducers: {
        activeQuizDataIdHandler: (state, { payload }: PayloadAction<number>) => {
            state.activeQuizDataId = payload
        },
        activeQuizIdHandler: (state, { payload }: PayloadAction<number>) => {
            state.activeQuizId = payload
        },
        allQuizAnswerHandler: (state, { payload }: PayloadAction<any>) => {
            state.allQuizAnswer = payload
        },
        meetingQuizListHandler: (state, { payload }: PayloadAction<any>) => {
            state.meetingQuizList = payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(quizCreateHandler.fulfilled, (state, action) => {
            state.quizMessage = action?.payload?.data?.message;
            state.quizSuccessful = true;
        })
        builder.addCase(quizCreateHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, "payload.response.data.message");
            const networkError = get(error, "payload.message");
            if (errorMessage) {
                state.quizSuccessful = false;
                state.quizMessage = errorMessage;
            } else if (networkError) {
                state.quizSuccessful = false;
                state.quizMessage = networkError;
            }
        })
        builder.addCase(quizGetByIdHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, "payload.response.data.message");
            const networkError = get(error, "payload.message");
            if (errorMessage) {
                state.quizSuccessful = false;
                state.quizMessage = errorMessage;
            } else if (networkError) {
                state.quizSuccessful = false;
                state.quizMessage = networkError;
            }
        })
        builder.addCase(quizUpdateByIdHandler.fulfilled, (state, action) => {
            state.quizMessage = action.payload?.data?.message;
            state.quizSuccessful = true;
        })
        builder.addCase(quizUpdateByIdHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, "payload.response.data.message");
            const networkError = get(error, "payload.message");
            if (errorMessage) {
                state.quizSuccessful = false;
                state.quizMessage = errorMessage;
            } else if (networkError) {
                state.quizSuccessful = false;
                state.quizMessage = networkError;
            }
        });
        builder.addCase(quizDataHandler.fulfilled, (state, action) => {
            state.quizList = action.payload.data;
            state.totalQuizPage = action.payload.totalPages;
            state.totalQuizRecord = action.payload.totalItems;
        })
    },
})

export const { activeQuizDataIdHandler, activeQuizIdHandler, allQuizAnswerHandler, meetingQuizListHandler } = quizSlice.actions;
export default quizSlice.reducer;