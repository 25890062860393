/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IGuestUserLogin } from '../../types/guestUser.type';
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { meetingGetByCodeForCheckMeetingPassword, meetingGetByID } from '../../services/meeting.service';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { attendeeWaitingList } from '../../services/waitingList.service';
import socket from '../../common/constants';
import { currentMeetingHandler, isAllowedHandler } from '../store/slices/meetingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { guestUserHandler, checkUserAccessHandler, guestUserLoginHandler, rememberMeHandler, rememberHandler } from '../store/slices/guestUserSlice';
import { RootState, store } from '../store/store';
import { findMeetingModalHandler } from '../store/slices/modalSlice';
import { innerHeaderShowHandler, isWaitingUserHandler, miniSidebarHandler } from '../store/slices/settingsSlice';
import { CREATED, ENDED, INPROGRESS, STARTED } from '../../types/commonTypes';

const GuestLogin = () => {
    //single hooks starts here
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    let { code } = useParams();
    let navigate = useNavigate();
    //single hooks ends here


    //useSelectors starts here
    const { currentMeeting } = useSelector((state: RootState) => state.meeting)
    const currentMeetingId = currentMeeting?.id
    const currentMeetingCode = currentMeeting?.code
    const currentMeetingTitle = currentMeeting?.title
    const { rememberMe, rememberName } = useSelector((state: RootState) => state.guestUser)
    //useSelectors ends here


    //useStates starts here
    const [isAnonymousChecked, setIsAnonymousChecked] = useState(false);
    const [passwordProtected, setPasswordProtected] = useState<boolean>(false);
    const [emailProtected, setEmailProtected] = useState<boolean>(false);
    const [newLatitude, setNewLatitude] = useState<number>(0);
    const [newLongitude, setNewLongitude] = useState<number>(0);
    const [successful, setSuccessful] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    //useStates ends here


    //functions starts here
    const getMeetingDetailByMeetingCode = async (code: string) => {
        await meetingGetByCodeForCheckMeetingPassword(code, '').then(
            async (response) => {
                if (response.status === 200) {
                    setEmailProtected(response.data.isEmailProtected)
                    setPasswordProtected(response.data.isPasswordProtected)
                    if (response.data.isGeoFenceProtected === true) {
                        navigator.geolocation.getCurrentPosition(function (position) {
                            setNewLatitude(position.coords.latitude)
                            setNewLongitude(position.coords.longitude)
                        },
                            function (error) {
                                if (error.code === error.PERMISSION_DENIED)
                                    toast.warning(String(t('toastMessage.enableLocationJoinMeeting')), {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 2500,
                                    });
                            });
                    }
                    if (response.data.status !== INPROGRESS) {
                        if (response.data.status === CREATED) {
                            toast.error(String(t('toastMessage.NotStartedMeeting')), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2500,
                            });
                        } else if (response.data.status === STARTED) {
                            toast.success(String(t('toastMessage.NotStartedMeetingByHost')), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2500,
                            });
                        } else if (response.data.status === ENDED) {
                            toast.success(String(t('toastMessage.meetingEnded')), {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2500,
                            });
                        }
                        handleLeaveMeeting()
                    } else {
                        await meetingGetByID(response.data.id).then((meeting) => {
                            dispatch(currentMeetingHandler(meeting));
                        })
                    }
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                toast.error(String(t('toastMessage.meetingNotFound')), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                });
                handleLeaveMeeting();
                return resMessage
            }
        )
    }

    const handleLeaveMeeting = () => {
        navigate("/")
    }

    const handleGuestUserLogin = async (formValue: IGuestUserLogin, onSubmitProps: any) => {
        const { name, password, email } = formValue;
        let guestId: any;
        const data = {
            code: currentMeetingCode,
            password: password,
            longitude: newLongitude,
            latitude: newLatitude,
            loggedInUser: ''
        }
        await dispatch(checkUserAccessHandler(data)).then(async (res: any) => {
            const state = store.getState()
            dispatch(innerHeaderShowHandler(res?.payload?.data?.joinStatus))
            if (res.payload.data.joinStatus === true) {
                dispatch(isAllowedHandler(true))
                let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                dispatch(rememberHandler(name.trim()))
                const data = {
                    name: name.trim(),
                    userName: userName,
                    isAnonymous: isAnonymousChecked,
                    meetingId: currentMeetingId,
                    email: email
                }
                await dispatch(guestUserLoginHandler(data)).then(
                    async (response: any) => {
                        setMessage(response?.payload?.data.message);
                        setSuccessful(true);
                        dispatch(guestUserHandler(response?.payload?.data))
                        guestId = response?.payload?.data.id
                        navigate('/guest/join/' + currentMeetingCode)
                    },

                    (error: any) => {
                        const resMessage =
                            (error.response.payload &&
                                error.response.payload?.data &&
                                error.response.payload?.data.message) ||
                            error.message ||
                            error.toString();
                        setMessage(resMessage);
                        setSuccessful(false);
                    }
                )
            } else {
                if (state.meeting.currentMeeting?.isPasswordProtected === true) {
                    if (res?.payload?.data?.passwordAuthenticated === true) {
                        if (state.meeting.currentMeeting?.isGeoFenceProtected === true) {
                            if (res.payload.data.joinStatus === true) {
                                dispatch(isAllowedHandler(true))
                                let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                                dispatch(rememberHandler(name.trim()))

                                const data = {
                                    name: name.trim(),
                                    userName: userName,
                                    isAnonymous: isAnonymousChecked,
                                    meetingId: currentMeetingId,
                                    email: email
                                }
                                await dispatch(guestUserLoginHandler(data)).then(
                                    async (response: any) => {
                                        setMessage(response?.payload?.data.message);
                                        setSuccessful(true);
                                        dispatch(guestUserHandler(response?.payload?.data))
                                        guestId = response?.payload?.data.id
                                        navigate('/guest/join/' + currentMeetingCode)
                                    },

                                    (error: any) => {
                                        const resMessage =
                                            (error.response.payload &&
                                                error.response.payload?.data &&
                                                error.response.payload?.data.message) ||
                                            error.message ||
                                            error.toString();
                                        setMessage(resMessage);
                                        setSuccessful(false);
                                    }
                                )
                            } else {
                                let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                                dispatch(rememberHandler(name.trim()))

                                const data = {
                                    name: name.trim(),
                                    userName: userName,
                                    isAnonymous: isAnonymousChecked,
                                    meetingId: currentMeetingId,
                                    email: email
                                }
                                await dispatch(guestUserLoginHandler(data)).then(
                                    async (response: any) => {
                                        dispatch(guestUserHandler(response?.payload?.data))
                                        guestId = response?.payload?.data.id

                                    },

                                    (error: any) => {
                                        const resMessage =
                                            (error.response.payload &&
                                                error.response.payload?.data &&
                                                error.response.payload?.data.message) ||
                                            error.message ||
                                            error.toString();
                                        setMessage(resMessage);
                                        setSuccessful(false);
                                    }
                                )
                                meetingGetByID(currentMeetingId).then((res: any) => {
                                    dispatch(currentMeetingHandler(res))
                                    attendeeWaitingList(currentMeetingId, res.hostId, 0, guestId).then((response) => {
                                        if (response.status === 201) {
                                            dispatch(isWaitingUserHandler(true))
                                            setTimeout(() => {
                                                dispatch(findMeetingModalHandler(false))
                                            }, 1000);
                                            socket.emit("add-waiting-user", { meetingId: currentMeetingId, user: name.trim() })
                                            navigate('/waiting-area/' + currentMeetingCode)
                                        } else {
                                            if (response.data.statusCode === 403) {
                                                setTimeout(() => {
                                                    dispatch(findMeetingModalHandler(false))
                                                }, 1000);
                                                navigate('/waiting-area/' + res.code)
                                            } else if (response.data.statusCode === 307) {
                                                setTimeout(() => {
                                                    dispatch(findMeetingModalHandler(false))
                                                }, 1000);
                                                navigate('/guest/join/' + res.code)
                                            } else if (response.data.statusCode === 409) {
                                                dispatch(isWaitingUserHandler(false))
                                                setTimeout(() => {
                                                    dispatch(findMeetingModalHandler(false))
                                                }, 1000);
                                                toast.warning(response.data.message, {
                                                    position: toast.POSITION.TOP_RIGHT,
                                                    autoClose: 2500,
                                                });
                                                navigate('/guest/login/')
                                            }
                                        }
                                    }).catch((err: any) => {
                                        setTimeout(() => {
                                            dispatch(findMeetingModalHandler(false))
                                        }, 1000);
                                    })
                                })
                            }
                        } else {
                            let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                            dispatch(rememberHandler(name.trim()))

                            const data = {
                                name: name.trim(),
                                userName: userName,
                                isAnonymous: isAnonymousChecked,
                                meetingId: currentMeetingId,
                                email: email
                            }
                            await dispatch(guestUserLoginHandler(data)).then(
                                async (response: any) => {
                                    setMessage(response?.payload?.data.message);
                                    setSuccessful(true);
                                    dispatch(guestUserHandler(response?.payload?.data))
                                    guestId = response?.payload?.data.id
                                    navigate('/guest/join/' + currentMeetingCode)
                                },

                                (error: any) => {
                                    const resMessage =
                                        (error.response.payload &&
                                            error.response.payload?.data &&
                                            error.response.payload?.data.message) ||
                                        error.message ||
                                        error.toString();
                                    setMessage(resMessage);
                                    setSuccessful(false);
                                }
                            )
                        }
                    } else {
                        setMessage(res.payload.data.message)
                    }
                } else {
                    if (res.payload.data.joinStatus === true) {
                        dispatch(isAllowedHandler(true))
                        let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                        dispatch(rememberHandler(name.trim()))

                        const data = {
                            name: name.trim(),
                            userName: userName,
                            isAnonymous: isAnonymousChecked,
                            meetingId: currentMeetingId,
                            email: email
                        }
                        await dispatch(guestUserLoginHandler(data)).then(
                            async (response: any) => {
                                setMessage(response?.payload?.data.message);
                                setSuccessful(true);
                                dispatch(guestUserHandler(response?.payload?.data))
                                guestId = response?.payload?.data.id
                                navigate('/guest/join/' + currentMeetingCode)
                            },

                            (error: any) => {
                                const resMessage =
                                    (error.response.payload &&
                                        error.response.payload?.data &&
                                        error.response.payload?.data.message) ||
                                    error.message ||
                                    error.toString();
                                setMessage(resMessage);
                                setSuccessful(false);
                            }
                        )
                    } else {
                        let userName = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
                        dispatch(rememberHandler(name.trim()))

                        const data = {
                            name: name.trim(),
                            userName: userName,
                            isAnonymous: isAnonymousChecked,
                            meetingId: currentMeetingId,
                            email: email
                        }
                        await dispatch(guestUserLoginHandler(data)).then(
                            async (response: any) => {
                                dispatch(guestUserHandler(response?.payload?.data))
                                guestId = response?.payload?.data.id

                            },

                            (error: any) => {
                                const resMessage =
                                    (error.response.payload &&
                                        error.response.payload?.data &&
                                        error.response.payload?.data.message) ||
                                    error.message ||
                                    error.toString();
                                setMessage(resMessage);
                                setSuccessful(false);
                            }
                        )
                        meetingGetByID(currentMeetingId).then((res: any) => {
                            dispatch(currentMeetingHandler(res))
                            attendeeWaitingList(currentMeetingId, res.hostId, 0, guestId).then((response: any) => {
                                if (response.status === 201) {
                                    dispatch(isWaitingUserHandler(true))
                                    setTimeout(() => {
                                        dispatch(findMeetingModalHandler(false))
                                    }, 1000);
                                    socket.emit("add-waiting-user", { meetingId: currentMeetingId, user: name.trim() })
                                    navigate('/waiting-area/' + currentMeetingCode)
                                } else {
                                    if (response.data.statusCode === 403) {
                                        setTimeout(() => {
                                            dispatch(findMeetingModalHandler(false))
                                        }, 1000);
                                        navigate('/waiting-area/' + res.code)
                                    } else if (response.data.statusCode === 307) {
                                        setTimeout(() => {
                                            dispatch(findMeetingModalHandler(false))
                                        }, 1000);
                                        navigate('/guest/join/' + res.code)
                                    } else if (response.data.statusCode === 409) {
                                        dispatch(isWaitingUserHandler(false))
                                        setTimeout(() => {
                                            dispatch(findMeetingModalHandler(false))
                                        }, 1000);
                                        toast.warning(response.data.message, {
                                            position: toast.POSITION.TOP_RIGHT,
                                            autoClose: 2500,
                                        });
                                        navigate('/guest/login/')
                                    }
                                }
                            }).catch((err: any) => {
                                setTimeout(() => {
                                    dispatch(findMeetingModalHandler(false))
                                }, 1000);
                            })
                        })
                    }
                }
            }
        })
    }
    //functions ends here


    //formik starts here
    const initialValuesGuestUserLogin: IGuestUserLogin = {
        name: rememberName,
        passwordProtected: passwordProtected,
        password: "",
        emailProtected: emailProtected,
        email: ""
    }

    const validationSchemaGuestUserLogin = Yup.object().shape({
        name: Yup.string()
            .nullable()
            .required(t('validation.requiredField'))
            .test(
                "len",
                t('validation.miniLengthName'),
                (val: any) =>
                    val &&
                    val.trim().toString().length >= 3
            ),
        passwordProtected: Yup.boolean(),
        password: Yup.string().when("passwordProtected", {
            is: true,
            then: Yup.string()
                .required(t('validation.requiredField'))
                .test(
                    "len",
                    t('validation.minlengthPassword'),
                    (val: any) =>
                        val &&
                        val.trim().toString().length >= 3
                )
        }),
        emailProtected: Yup.boolean(),
        email: Yup.string().when("emailProtected", {
            is: true,
            then: Yup.string()
                .required(t('validation.requiredField'))
                .email(t('validation.notValidEmail')),
        }),
    });
    //formik ends here


    //useEffects starts here
    useEffect(() => {
        if (!rememberMe) {
            dispatch(rememberHandler(''));
        }
        dispatch(miniSidebarHandler(true))
    }, [])

    useEffect(() => {
        if (!!code) {
            getMeetingDetailByMeetingCode(code)
        }
    }, [code])
    //useEffects ends here


    return (
        <div className='guestlogin-wrapper d-flex flex-column '>
            <div className='position-relative guest-join-meeting flex-fill'>
                <div className='container'>
                    <Formik
                        initialValues={initialValuesGuestUserLogin}
                        validationSchema={validationSchemaGuestUserLogin}
                        onSubmit={handleGuestUserLogin}
                        enableReinitialize={true}
                    >
                        <Form autoComplete="off">
                            <h2 className='fw-bold mb-4'>{t('guest.guestLogin.lblJoin')} <u>{currentMeetingTitle}</u> {t('guest.guestLogin.lblMeeting')}</h2>
                            <div className="row">
                                <div className="col-lg-6 mb-4">
                                    <label htmlFor="guestuser-name" className="form-label">{t('guest.guestLogin.name')}</label>
                                    <Field type="text" name="name" className="form-control" id="guestuser-name" placeholder={t('guest.guestLogin.name')}
                                    />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                    {message &&
                                        <div className="form-group">
                                            <div
                                                className={
                                                    successful ? "alert alert-success" : "alert alert-danger p-2 rounded-0 border-0"
                                                }
                                                role="alert"
                                            >
                                                {message}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {emailProtected &&
                                <div className="row">
                                    <div className="col-lg-6 mb-4">
                                        <label htmlFor="guestuser-email" className="form-label">{t('guest.guestLogin.email')}</label>
                                        <Field type="text" name="email" className="form-control" id="guestuser-email" placeholder={t('guest.guestLogin.placeholderEmail')} />
                                        <ErrorMessage name="email" component="div" className="text-danger" />
                                    </div>
                                </div>
                            }
                            {passwordProtected &&
                                <div className="row">
                                    <div className="col-lg-6 mb-4">
                                        <label htmlFor="guestuser-password" className="form-label">{t('guest.guestLogin.password')}</label>
                                        <Field type="text" autoComplete="off" name="password" className="form-control password-text" id="guestuser-password" placeholder={t('guest.guestLogin.password')} />
                                        <ErrorMessage name="password" component="div" className="text-danger" />
                                    </div>
                                </div>
                            }
                            <div className='hstack flex-wrap gap-2 mb-4'>
                                <button className="btn btn-light rounded-45 mw-120" type="submit">{t('guest.guestLogin.btnJoin')}</button>
                                <button type='reset' className="btn btn-primary rounded-45 mw-120">{t('guest.guestLogin.btnReset')}</button>
                            </div>
                            <div className='vstack'>
                                <div className="form-check mb-3 d-inline">
                                    <Field className="form-check-input" name="isAnonymous" type="checkbox" value={isAnonymousChecked} id="guestuser-privacy" checked={isAnonymousChecked} onChange={(e: any) => setIsAnonymousChecked(e.target.checked)} />
                                    <label className="form-check-label" htmlFor="guestuser-privacy">
                                        {t('guest.guestLogin.checkboxName')}
                                    </label>
                                </div>
                                <div className="form-check mb-3 d-inline">
                                    <Field className="form-check-input" name="isRememberMe" type="checkbox" id="guestuser-remember" checked={rememberMe} onChange={(e: any) => dispatch(rememberMeHandler(e.target.checked))} />
                                    <label className="form-check-label" htmlFor="guestuser-remember">
                                        {t('guest.guestLogin.rememberMe')}
                                    </label>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default GuestLogin;