import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { FC } from 'react'
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IMeetingPassword } from '../../../types/meeting.type';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import * as Yup from "yup";
import { meetingPasswordModalHandler } from '../../store/slices/modalSlice';
import { joinMessageHandler } from '../../store/slices/meetingSlice';

interface MeetingPasswordModalProps {
    checkAccess: (meetingCodeHome: string, joinPassword: string | null, newLongitudeJoin: number, newLatitudeJoin: number) => void
    meetingCodeHome: string
    newLongitudeJoin: number
    newLatitudeJoin: number
}
const MeetingPasswordModal: FC<MeetingPasswordModalProps> = ({ checkAccess, meetingCodeHome, newLongitudeJoin, newLatitudeJoin }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const { joinMessage, joinSuccessful } = useSelector((state: RootState) => state.meeting);
    const { meetingPasswordModal } = useSelector((state: RootState) => state.modal);

    const initialValues: IMeetingPassword = {
        joinPassword: ""
    }

    const validationSchema = Yup.object().shape({
        joinPassword: Yup.string()
            .required(t('validation.requiredField')),
    });

    const handleJoinMeeting = async (formValue: IMeetingPassword) => {
        const { joinPassword } = formValue;
        checkAccess(meetingCodeHome, joinPassword, newLongitudeJoin, newLatitudeJoin);
    }

    return (
        <Modal centered show={meetingPasswordModal} onHide={() => { dispatch(meetingPasswordModalHandler(false)); dispatch(joinMessageHandler('')) }}>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='fw-semibold'>Join Meeting</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleJoinMeeting}
                    enableReinitialize={false}
                >
                    <Form id='joinMeetingForm' name="joinForm">
                        {joinMessage && (
                            <div className="form-group">
                                <div
                                    className={
                                        joinSuccessful ? "alert alert-success" : "alert alert-danger p-2 rounded-0 border-0"
                                    }
                                    role="alert"
                                >
                                    {joinMessage}
                                </div>
                            </div>
                        )}

                        <div className="mb-4">
                            <label htmlFor="meeting-password" className="form-label">{t('common.Password')}</label>
                            <Field type="text" name='joinPassword' className="form-control password-text" id="meeting-password" placeholder={t('deafultLeftLayout.joinMeetingModal.placeholderMeetingPassword')} />
                            <ErrorMessage name="joinPassword" component="div" className="text-danger txt-error" />

                        </div>
                        <div className="hstack justify-content-center">
                            <button type='submit' className="btn btn-primary mw-120">{t('deafultLeftLayout.joinMeetingModal.btnjoin')}</button>
                        </div>
                    </Form>
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default MeetingPasswordModal