/* eslint-disable react-hooks/exhaustive-deps */
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import React, { FC, useCallback} from 'react'
import { Spinner } from 'react-bootstrap';

interface MapComponentProps {
    newLatitude: number
    newLongitude: number
    setNewLatitude: (newLatitude: number) => void
    setNewLongitude: (newLongitude: number) => void
}

const MapComponent: FC<MapComponentProps> = ({ newLatitude, newLongitude, setNewLatitude, setNewLongitude }) => {
    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    const mapStyles = {
        width: '100%',
        height: '280px',
    };

    const center = {
        lat: newLatitude,
        lng: newLongitude
    };

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_API_KEY as string
    })

    const options = {
        minZoom: 1,
        maxZoom: 20,
    }

    const onMarkerDragEnd = useCallback(function callback(coords: any) {
        setNewLatitude(coords.latLng.lat());
        setNewLongitude(coords.latLng.lng());
    }, [])

    const onLoad = useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
    }, [])

    const renderMap = () => {
        return <div className='border rounded overflow-hidden'><GoogleMap
            options={options}
            onLoad={onLoad}
            mapContainerStyle={mapStyles}
            center={{ lat: newLatitude, lng: newLongitude }}
        >
            <MarkerF
                position={{ lat: newLatitude, lng: newLongitude }}
                draggable
                onDragEnd={onMarkerDragEnd}
            />
        </GoogleMap>
        </div>
    }

    if (loadError) {
        return <div>Sorry, map cannot be loaded right now.</div>
    }

    return isLoaded ?
        renderMap() :
        <div style={mapStyles} className='hstack justify-content-center bg-primary bg-opacity-10'><Spinner animation={'border'} variant="primary" /></div>

}

export default MapComponent