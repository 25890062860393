import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { startMeetingModalHandler } from '../../store/slices/modalSlice';
import { currentMeetingHandler, meetingStatusUpdateHandler } from '../../store/slices/meetingSlice';
import { meetingGetByCodeHandler } from '../../store/slices/joinMeetingSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ImgStartMeeting from "../../../images/meeting.svg"
import { CREATED, INPROGRESS, STARTED } from '../../../types/commonTypes';

const StartMeetingModal = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { t } = useTranslation('common');

    const { createdMeeting } = useSelector((state: RootState) => state.meeting);
    const { startMeetingModal } = useSelector((state: RootState) => state.modal);

    const [startCreatedMeeting, setStartCreatedMeeting] = useState<boolean>(false);

    const handleModalClose = (isJoin: any) => {
        setStartCreatedMeeting(false);
        dispatch(startMeetingModalHandler(false))
        if (isJoin) {
            dispatch(startMeetingModalHandler(false))
        }
    };

    const startMeetingHandler = async (id: any, status: string) => {
        const meetingStatusValues = { id, status }
        await dispatch(meetingStatusUpdateHandler(meetingStatusValues))
    }

    const joinMeetingHandler = async (id: any, status: string) => {
        const meetingStatusValues = { id, status };
        await dispatch(meetingStatusUpdateHandler(meetingStatusValues)).then((res: any) => {
            if (res?.type === 'meetingStatusUpdate/fulfilled') {
                if (res?.payload?.status === 200) {
                    dispatch(meetingGetByCodeHandler({ code: res?.payload?.data.code, userId: 0, guestUserId: 0 })).then((meetDetails: any) => {
                        if (meetDetails?.payload.status === 200) {
                            dispatch(currentMeetingHandler(meetDetails?.payload?.data));
                            navigate('/joinMeeting/' + res?.payload?.data.code);
                        }
                    })
                }
            }
        })
    }

    return (
        <Modal show={startMeetingModal} onHide={() => handleModalClose(false)}>
            <Modal.Header closeButton className='border-0'>
                <Modal.Title className='fw-semibold'> {t("startMeetingModal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!!createdMeeting &&
                    Object.keys(createdMeeting).length > 0 &&
                    <div className="card h-100 shadow">
                        <div className="card-body">
                            <div>{createdMeeting.title}</div>
                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                <div>
                                    <img
                                        className="img-fluid"
                                        src={ImgStartMeeting}
                                        alt="meeting"
                                    />
                                </div>
                                <div>
                                    {(createdMeeting.status === CREATED && !startCreatedMeeting) && (
                                        <div>
                                            <button
                                                onClick={() => {
                                                    startMeetingHandler(
                                                        createdMeeting.id,
                                                        INPROGRESS
                                                    );
                                                    setStartCreatedMeeting(true);
                                                }}
                                                className="btn btn-primary rounded-45 mw-120"
                                            >
                                                {t('myMeeting.btnStartMeeting')}
                                            </button>
                                        </div>
                                    )}

                                    {(createdMeeting.status === STARTED || createdMeeting.status === INPROGRESS || startCreatedMeeting) && (
                                        <div>
                                            <button onClick={() => {
                                                joinMeetingHandler(
                                                    createdMeeting.id,
                                                    INPROGRESS
                                                );
                                                handleModalClose(true);
                                            }}
                                                className="btn btn-primary rounded-45 mw-120"
                                            >
                                                {t('myMeeting.btnJoinMeeting')}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className='text-white' onClick={() => handleModalClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default StartMeetingModal