/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { moveQuestionHandler } from "../../store/slices/pollSlice";
import { Accordion } from 'react-bootstrap'
import { SelectedQuestion } from './SelectedQuestion';
import { useTranslation } from 'react-i18next';

//props interface starts here
interface SelectedQuestionListProps {
    meetingsLength: number
}
//props interface ends here

const SelectedQuestionList: FC<SelectedQuestionListProps> = ({ meetingsLength }) => {

    //single hooks starts here
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    //single hooks ends here


    //useSelectors starts here
    const { selectedQuestion } = useSelector((state: RootState) => state.poll)
    //useSelectors ends here


    //useStates starts here
    const [totalScore, setTotalScore] = useState<number>(0)
    //useStates end here

    //callback functions starts here
    const moveQuestion = useCallback((dragIndex: number, hoverIndex: number) => {
        dispatch(moveQuestionHandler({ dragIndex, hoverIndex }));
    }, [dispatch]);

    const renderQuestion = useCallback(
        (question: any, index: number) => {
            return (
                <SelectedQuestion
                    key={`SelectedQuestion${question.id}`}
                    index={index}
                    question={question}
                    meetingsLength={meetingsLength}
                    moveQuestion={moveQuestion}
                    title={question.title}
                />
            )
        }, [])
    //callback functions ends here

    useEffect(() => {
        setTotalScore(selectedQuestion.reduce((sum, question) => sum + question.score, 0))
    }, [selectedQuestion])

    return (
        <>
            <div className='d-flex flex-column align-items-start gap-3 mb-1 px-3'>
                <div className='hstack justify-content-between gap-3 flex-wrap'>
                    <div>Total Score: <span className='fw-medium'>{totalScore}</span></div>
                    <div>Total Questions: <span className='fw-medium'>{selectedQuestion?.length}</span></div>
                </div>
                {selectedQuestion?.length > 1 && <div className='text-center'>{t("droparea.reorder")}</div>}
            </div>
            <Accordion defaultActiveKey={['0']} alwaysOpen className="question-list p-3">
                {
                    selectedQuestion[0] !== undefined &&
                    selectedQuestion[0] !== null &&
                    selectedQuestion.map((question, index) => renderQuestion(question, index))
                }
            </Accordion>
        </>
    )
}

export default SelectedQuestionList