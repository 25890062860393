import { store } from "../pages/store/store";
import { EN } from "../types/commonTypes";

const authHeader = () => {

   //single hooks starts here
  const state = store.getState();
  const { user, isUser } = state.auth;
  const { language } = state.settings;
   //single hooks ends here

  if (isUser) {
    return {
      Authorization: 'Bearer ' + user.token, 'Accept-Language': `${language}`
    }; // for Spring Boot back-end
  } else {
    return { Authorization: '', 'Accept-Language': EN }; // for Spring Boot back-end
  }
}
export default authHeader