/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import socket from '../../common/constants';
import { useTranslation } from 'react-i18next';
import DialogBox from '../components/DeleteBox';
import { getAllPollsQuestionsAnswerByMeetingId, getPollQuestionsAnswerByGuest, getPollsForMeeting } from '../../services/poll.service';
import { toast } from 'react-toastify';
import { getallWaitingList } from '../../services/waitingList.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../store/store';
import { activeQuizIdGuestHandler, activeTabHandler, currentMeetingHandler, interestedListHandler, isAllowedHandler } from '../store/slices/meetingSlice';
import { addPollsQuestionsAnswerByGuestHandler } from '../store/slices/guestUserSlice';
import { feedbackFormHandler } from '../store/slices/settingsSlice';
import { Tab, Tabs } from 'react-bootstrap';
import { getSubmittedQuizzesByGuest, getQuizzesForMeeting } from '../../services/quiz.service';
import { getMeetingIntrestedUsersHandler } from '../store/slices/joinMeetingSlice';
import ShowPollResult from '../components/Poll/ShowPollResult';
import ActivePolls from '../components/Poll/ActivePolls';
import ShowQuizResult from '../components/Quiz/ShowQuizResult';
import ActiveQuiz from '../components/Quiz/ActiveQuiz';
import { resetQuizTimer } from '../../common/utils';
import { ACTIVE, ACTIVEPOLLSCOUNTLS, ACTIVEQUIZCOUNTLS, APPROVED, INACTIVE } from '../../types/commonTypes';
import ShareMeetingContainer from '../components/ShareMeeting/ShareMeetingContainer';
import { allQuizAnswerHandler, meetingQuizListHandler } from '../store/slices/quizSlice';
import QuestionTab from './common/QuestionTab';
import ShareMeetingMobile from '../components/ShareMeeting/ShareMeetingMobile';

const GuestJoin = () => {
    //single hooks starts here
    const dispatch = useDispatch()
    const { t } = useTranslation('common');
    let { code }: any = useParams();
    let navigate = useNavigate();
    //single hooks ends here


    //useSelectors starts here
    const { currentMeeting, activeQuizIdGuest } = useSelector((state: RootState) => state.meeting)
    const { guestUser } = useSelector((state: RootState) => state.guestUser)
    const { isFeedBackForm } = useSelector((state: RootState) => state.settings)
    const { allQuizAnswer, meetingQuizList } = useSelector((state: RootState) => state.quiz)
    const guestUserId = guestUser?.id
    const currentMeetingId = currentMeeting?.id
    //useSelectors ends here


    //useStates starts here
    const [meetingCode, setMeetingCode] = useState<string>('');
    const [meetingPollsList, setMeetingPollsList] = useState<any[]>([]);
    const [allPollQuestions, setAllPollQuestions] = useState<any[]>([]);
    const [submitPoll, setSubmitPoll] = useState<boolean>(false);
    const [submitPollId, setSubmitPollId] = useState<number>();
    const [newSelectedAnswer, setNewSelectedAnswers] = useState<any>([]);
    const [GroupedQuestions, setGroupedQuestions] = useState<any[]>([]);
    const [GroupedAnswers, setGroupedAnswers] = useState<any[]>([]);
    const [meetingAllPollsAnswerList, setMeetingAllPollsAnswerList] = useState<any[]>([]);
    const [isShowPollResult, setIsShowPollResult] = useState<boolean>(false);
    const [isShowQuizResult, setIsShowQuizResult] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [pollTab, setPollTab] = useState<boolean>(false)
    const [quizTab, setQuizTab] = useState<boolean>(false)
    const [activePolls, setActivePolls] = useState<number>(0)
    const [activeQuiz, setActiveQuiz] = useState<number>(0)
    const [activeQuizData, setActiveQuizData] = useState<any>([]);
    const [submitPollLoading, setSubmitPollLoading] = useState<boolean>(false);
    //useStates ends here



    //functions starts here
    const getPollsByMeetingId = async (meetingId: number) => {
        if (!!meetingId) {
            await getPollsForMeeting(meetingId).then((response) => {
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        setMeetingPollsList(response.data)
                        let allPollQuestions: any[] = []
                        response.data.map((poll: any, i: any) => {
                            return poll.pollDetails.pollsQuestionDetails.map((question: any, j: any) => {
                                return allPollQuestions.push(question)
                            })
                        })
                        setAllPollQuestions(allPollQuestions)

                        let groupedQuestions: any = []
                        response.data.forEach((item: any) => {
                            const pollId = item.pollId;
                            const questionDetails = item.pollDetails.pollsQuestionDetails;
                            const length = questionDetails.length;

                            if (!groupedQuestions[pollId]) {
                                groupedQuestions[pollId] = [];
                            }

                            groupedQuestions[pollId].push(length);
                        });
                        setGroupedQuestions(groupedQuestions)
                    } else {
                        setMeetingPollsList([])
                    }
                }
            })
        }
    }

    const handlePublishPoll = async (pollId: any) => {
        if (submitPollLoading) {
            return
        }
        setSubmitPollLoading(true)
        const selectedPollAnswers = selectedOptions.filter((answer: any) => answer.pollId === pollId);
        const selectedPollQuestion = allPollQuestions.filter((question: any) => question.pollId === pollId);

        if (selectedPollQuestion.length === selectedPollAnswers.length) {
            await onChangeGuestAnswer(selectedPollAnswers, pollId);
            setSubmitPollLoading(false)

        }
        else {
            setSubmitPollId(pollId);
            setSubmitPoll(true);
            setSubmitPollLoading(false)

        }
    };

    const handleSubmitPoll = (pollId: any) => {
        const selectedPollAnswers = selectedOptions.filter((answer: any) => answer.pollId === pollId);
        onChangeGuestAnswer(selectedPollAnswers, pollId);
    }

    const handleCheckboxChange = (e: any, selectedAnswer: any) => {
        const selectedArray = selectedOptions.length > 0 ? selectedOptions : newSelectedAnswer
        const existingIndex = selectedArray?.some((answer: any, i: any) => (answer.pollId === selectedAnswer.pollId && answer.questionId === selectedAnswer.questionId && answer.questionOptionId === selectedAnswer.questionOptionId));

        let updateSelectedAnswer;
        if (existingIndex) {
            e.target.checked = false
            updateSelectedAnswer = selectedArray.filter((item: any) =>
                !(item.pollId === selectedAnswer.pollId &&
                    item.questionId === selectedAnswer.questionId &&
                    item.questionOptionId === selectedAnswer.questionOptionId));

        } else {
            updateSelectedAnswer = [...selectedArray.filter((item: any) =>
                !(item.pollId === selectedAnswer.pollId && item.questionId === selectedAnswer.questionId)
            ), selectedAnswer];

        }

        setNewSelectedAnswers(updateSelectedAnswer);
        setSelectedOptions(updateSelectedAnswer)
    };

    const onChangeGuestAnswer = async (selectedPollAnswers: any, pollId: any) => {
        if (submitPollLoading) {
            return
        }
        setSubmitPollLoading(true)
        const data = {
            meetingId: currentMeetingId,
            newSelectedAnswer: selectedPollAnswers,
            guestUserId: guestUserId,
            pollId: pollId
        }
        dispatch(addPollsQuestionsAnswerByGuestHandler(data))
            .then(
                (response: any) => {
                    if (response?.payload?.status === 201) {
                        socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
                        socket.on('connect', () => {
                            socket.emit("meeting-polls-send", { meetingId: currentMeetingId })
                        })
                        toast.success(String(t('toastMessage.pollSubmitted')), {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2500,
                        });
                    }
                    setSubmitPollLoading(false)
                },
                (error: any) => {
                    const resMessage =
                        (error.response.payload &&
                            error.response.payload.data &&
                            error.response.payload.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                    setSubmitPollLoading(false)
                }
            )
    }

    const getPollAnswerList = async (meetingId: number, guestUser: any) => {
        getPollQuestionsAnswerByGuest(meetingId, guestUser).then(
            (response) => {
                if (response.status === 200) {
                    let groupedAnswers: any = []
                    response.data.forEach((item: any) => {
                        const pollId = item.pollId;
                        if (!groupedAnswers[pollId]) {
                            groupedAnswers[pollId] = [];
                        }
                        groupedAnswers[pollId].push(pollId);
                    });
                    groupedAnswers = groupedAnswers.map((item: any) => [item.length]);
                    setGroupedAnswers(groupedAnswers)
                    setNewSelectedAnswers(response.data.map((item: any) => ({
                        pollId: item.pollId,
                        questionId: item.questionId,
                        questionOptionId: item.questionOptionId
                    })))
                }

            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    const checkOptionAnswerAvailable = (id: any) => {
        let checkIdInAnswerList = newSelectedAnswer?.findIndex((item: any) => (item.pollId === id.pollId && item.questionId === id.questionId && item.questionOptionId === id.questionOptionId))
        if (checkIdInAnswerList > -1) {
            return true
        } else {
            return false
        }
    }

    const getAllPollAnswerList = async (meetingId: number, guestUserId: number) => {
        await getAllPollsQuestionsAnswerByMeetingId(meetingId).then(
            (response) => {
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        setMeetingAllPollsAnswerList(response.data)
                    }
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    const getAllQuizByMeetingId = async (meetingId: number) => {
        let quizzesListData = await getQuizzesForMeeting(meetingId)
        if (quizzesListData.status === 200) {
            dispatch(meetingQuizListHandler(quizzesListData.data?.meetingQuizData))
        }
    }

    const getQuizzesByMeetingId = async (meetingId: number, quizId: number) => {
        if (!!meetingId) {
            let quizzesListData = await getQuizzesForMeeting(meetingId)
            if (quizzesListData.status === 200) {
                let meetingQuizData = quizzesListData.data?.meetingQuizData
                let meetingQuizzesData = quizzesListData.data?.meetingQuizzesData
                meetingQuizData.map((item: any, index: number) => {
                    if (item.quizId === quizId) {
                        let activeQuiz = meetingQuizzesData[index]
                        setActiveQuizData(activeQuiz)
                    }
                    return null
                })
                dispatch(meetingQuizListHandler(meetingQuizData))
            }
        }
    }

    const handleTabSelect = (key: any) => {
        dispatch(activeTabHandler(key))
        if (Number(key) === 0) {
            setPollTab(false)
            setQuizTab(false)
        }
        else if (Number(key) === 1) {
            setPollTab(true)
            setQuizTab(false)
        }
        else if (Number(key) === 2) {
            setPollTab(false)
            setQuizTab(true)
        }
    };

    const getListOfMeetingIntrestedUsers = async (meetingId: number) => {
        dispatch(getMeetingIntrestedUsersHandler(meetingId))
            .then(
                (response: any) => {
                    if (response?.payload?.status === 200) {
                        dispatch(interestedListHandler(response?.payload?.data))
                    }
                },
                (error: any) => {
                    const resMessage =
                        (error.response?.payload &&
                            error.response.data &&
                            error.response?.payload?.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage ==>", resMessage)
                }
            )
    }

    const getSubmittedQuizzes = async () => {
        await getSubmittedQuizzesByGuest(currentMeetingId, guestUserId).then((res: any) => {
            dispatch(allQuizAnswerHandler(res.data))
        })
    }

    //functions ends here


    //useEffects starts here
    useEffect(() => {
        if (!!code) {
            setMeetingCode(code)
        }
    }, [code])

    useEffect(() => {
        if (!!meetingCode) {
            getQuizzesByMeetingId(currentMeetingId, activeQuizIdGuest);
        }
    }, [meetingCode])

    useEffect(() => {
        socket.on('join-meeting-res', (data) => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            const guestUserIdSocket = state.guestUser?.guestUser?.id
            if (!!currentMeetingIdSocket && guestUserIdSocket === data?.guestUser?.id) {
                getPollsByMeetingId(currentMeetingIdSocket)
                getPollAnswerList(currentMeetingIdSocket, guestUserId)
                getAllPollAnswerList(currentMeetingIdSocket, guestUserId)
                getListOfMeetingIntrestedUsers(currentMeetingIdSocket)
                getAllQuizByMeetingId(currentMeetingIdSocket)
            }
        })

        socket.on("meeting-polls-res", async () => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            if (!!currentMeetingIdSocket) {
                getPollsByMeetingId(currentMeetingIdSocket)
                getPollAnswerList(currentMeetingIdSocket, guestUserId)
                getAllPollAnswerList(currentMeetingIdSocket, guestUserId)
            }
        })

        socket.on("meeting-quiz-res", async (data: any) => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            if (!!currentMeetingIdSocket) {
                if (!!data.quizId) {
                    resetQuizTimer()

                    getQuizzesByMeetingId(currentMeetingIdSocket, data.quizId);
                    dispatch(activeQuizIdGuestHandler(data.quizId))
                    let allQuizAnswers: any[] = []
                    await getSubmittedQuizzesByGuest(currentMeetingIdSocket, guestUserId).then((res: any) => {
                        allQuizAnswers = res.data
                    })
                    const isNewQuiz = allQuizAnswers?.every((submittedQuiz) => {
                        return data.quizId !== submittedQuiz.quizId
                    });
                    if (isNewQuiz) {
                        setIsShowQuizResult(false)
                    }
                }
            }
        })

        socket.on("intrested-user-res", async () => {
            const state = store.getState()
            const currentMeetingIdSocket = state.meeting?.currentMeeting?.id
            if (!!currentMeetingIdSocket) {
                getListOfMeetingIntrestedUsers(currentMeetingIdSocket)
            }
        })

        return (() => {
            socket.off('join-meeting-res')
            socket.off('meeting-polls-res')
            socket.off('meeting-quiz-res')
            socket.off('intrested-user-res')
        })

    }, [currentMeeting])

    useEffect(() => {
        // Calculate the count of active polls/quiz in the meetingPollsList
        const pollcount = meetingPollsList.filter((ele) => ele.status === ACTIVE).length;
        const quizcount = !!meetingQuizList ? meetingQuizList.filter((ele) => ele.status === ACTIVE).length : 0;
        let storedPollCount: any;
        let storedQuizCount: any;
        if (localStorage.getItem(ACTIVEPOLLSCOUNTLS) !== null) {
            storedPollCount = localStorage.getItem(ACTIVEPOLLSCOUNTLS);
        } else {
            storedPollCount = 0;
        }
        if (localStorage.getItem(ACTIVEQUIZCOUNTLS) !== null) {
            storedQuizCount = localStorage.getItem(ACTIVEQUIZCOUNTLS);
        } else {
            storedQuizCount = 0;
        }
        // Calculate the difference and update the active polls/quiz count in local storage
        const updatedPollCount = pollcount - storedPollCount;
        localStorage.setItem(ACTIVEPOLLSCOUNTLS, pollcount.toString());
        const updatedQuizCount = quizcount - storedQuizCount;
        localStorage.setItem(ACTIVEQUIZCOUNTLS, quizcount.toString());
        // Update the active polls/quiz state with the calculated count difference
        setActivePolls((prevActivePolls) => prevActivePolls + updatedPollCount);
        setActiveQuiz((prevActiveQuiz) => prevActiveQuiz + updatedQuizCount);

        if (pollTab) {
            setActivePolls(0);
        }
        if (quizTab) {
            setActiveQuiz(0);
        }
    }, [meetingPollsList, pollTab, meetingQuizList, quizTab]);

    useEffect(() => {
        if (!!currentMeetingId) {
            getallWaitingList(currentMeetingId)
                .then((res: any) => {
                    res?.data?.map((ele: any) => {
                        if (ele.guestUserId === guestUserId && ele.status === APPROVED) {
                            dispatch(isAllowedHandler(true))
                        } else {
                            dispatch(isAllowedHandler(false))
                        }
                        return null
                    })
                })
                .catch((error) => {
                    dispatch(isAllowedHandler(false))
                    console.error("Error fetching waiting list:", error);
                });
        }

    }, [currentMeetingId]);

    useEffect(() => {
        if (typeof currentMeetingId === 'undefined' || isFeedBackForm) {
            dispatch(feedbackFormHandler(false))
            dispatch(currentMeetingHandler(null))
            navigate("/")
        }
    }, [currentMeetingId, isFeedBackForm, navigate]);

    useEffect(() => {
        if (!!currentMeetingId) {
            getSubmittedQuizzes()
        }
    }, [])
    //useEffects ends here


    //components starts here
    const PollHeader = () => {
        return (
            <div className="hstack flex-wrap justify-content-between">
                {isShowPollResult && <div className="font-20 fw-semibold">Poll Results</div>}
                <div className="hstack gap-2 flex-fill justify-content-end">
                    {meetingPollsList.length > 0 &&
                        meetingPollsList.some((poll: any) => poll?.status === INACTIVE) &&
                        <button
                            className="btn btn-light text-nowrap rounded-45 mw-120 btn-light-reset-hover"
                            onClick={() => setIsShowPollResult(!isShowPollResult)}
                        >
                            {isShowPollResult ? t('pollDetails.btnHideResult') : t('pollDetails.btnShowResult')}
                        </button>
                    }
                </div>
            </div>
        )
    }

    const QuizHeader = () => {
        const isSubmittedQuiz = allQuizAnswer?.length > 0 &&
            !meetingQuizList?.some(quiz => quiz.status === ACTIVE &&
                !allQuizAnswer.some(submittedQuiz => submittedQuiz.quizId === quiz.quizId));
        return (
            <div className="hstack flex-wrap justify-content-between">
                {isShowQuizResult && <div className="font-20 fw-semibold">Quiz Results</div>}
                {isSubmittedQuiz &&
                    <div className="hstack gap-2 flex-fill justify-content-end">
                        <button
                            className="btn btn-light text-nowrap rounded-45 mw-120 btn-light-reset-hover"
                            onClick={() => setIsShowQuizResult(!isShowQuizResult)}
                        >
                            {isShowQuizResult ? "Hide Result" : t('quizDetails.btnShowResult')}
                        </button>
                    </div>
                }
            </div>
        )
    }
    //components ends here

    return (
        <>
            <div className="flex-fill vstack guestuser-joinwrap">
                <div className="flex-fill d-flex">
                    <div className="flex-fill mb-3 mb-md-4 pt-4">
                        <div className="h-100 container-fluid">
                            <div className="h-100 row">
                                <ShareMeetingContainer />
                                <div className="col-xl-8">
                                    <div className="h-100 vstack custom-tab">
                                        <ShareMeetingMobile />
                                        <Tabs
                                            defaultActiveKey={0}
                                            onSelect={(key: any) => handleTabSelect(key)}
                                            variant="pills"
                                        >
                                            <Tab eventKey={0} title={t('common.question')}>
                                                <QuestionTab />
                                            </Tab>
                                            <Tab eventKey={1} title={
                                                <div className="hstack justify-content-center">
                                                    <span className="me-2">{t('common.Poll')}</span>
                                                    <span className="position-relative translate-middle badge rounded-pill badge-notification bg-danger fs-9 ms-1">
                                                        {activePolls > 0 ? activePolls : ""}
                                                    </span>
                                                </div>
                                            }>
                                                <div className="section-tab position-relative h-100">
                                                    <PollHeader />
                                                    <div className="polls-card">
                                                        <div className="vstack gap-3">
                                                            {meetingPollsList.length === 0 &&
                                                                <div className="status-block status-block-nodata"> {t('pollDetails.DataNotFound')}</div>
                                                            }
                                                            {isShowPollResult ?
                                                                <ShowPollResult
                                                                    meetingPollsList={meetingPollsList}
                                                                    meetingAllPollsAnswerList={meetingAllPollsAnswerList}
                                                                    newSelectedAnswer={newSelectedAnswer}
                                                                    isShowPollResult={isShowPollResult}
                                                                />

                                                                :
                                                                <ActivePolls
                                                                    meetingPollsList={meetingPollsList}
                                                                    GroupedQuestions={GroupedQuestions}
                                                                    GroupedAnswers={GroupedAnswers}
                                                                    handlePublishPoll={handlePublishPoll}
                                                                    isPollLoading={submitPollLoading}
                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                    checkOptionAnswerAvailable={checkOptionAnswerAvailable}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey={2} title={
                                                <div className="hstack justify-content-center">
                                                    <span className="me-2">{t('common.Quiz')}</span>
                                                    <span className="position-relative translate-middle badge rounded-pill badge-notification bg-danger fs-9 ms-1">
                                                        {activeQuiz > 0 ? activeQuiz : ""}
                                                    </span>
                                                </div>
                                            }>
                                                <div className="section-tab position-relative h-100">
                                                    <QuizHeader />
                                                    <div className="quiz-card">
                                                        <div className="vstack gap-3">
                                                            {isShowQuizResult ?
                                                                <ShowQuizResult allQuizAnswer={allQuizAnswer} />

                                                                :
                                                                <ActiveQuiz
                                                                    meetingQuizList={meetingQuizList}
                                                                    activeQuizData={activeQuizData}
                                                                    currentMeetingId={currentMeetingId}
                                                                    allQuizAnswer={allQuizAnswer}
                                                                    getSubmittedQuizzes={getSubmittedQuizzes}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* alerts actions starts heres */}
            <DialogBox show={submitPoll} clickOk={() => { handleSubmitPoll(submitPollId); setSubmitPoll(false) }} clickCancel={() => { setSubmitPoll(false) }} btncancel={t('deleteBox.btnNo')} btnyes={t('deleteBox.btnYes')} question={t('deleteBox.submitPollQuestion')} />
            {/* alerts actions ends heres */}
        </>
    );
};

export default GuestJoin;
