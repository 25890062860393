import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  guestUserLogin,
  guestAddMeetingQuestion,
  guestEditMeetingQuestion,
  addPollsQuestionsAnswerByGuest,
  saveUpvoteForMeetingQuestionForGuest,
  saveUpvoteForMeetingQuestionReplyForGuest,
  setMeetingIntrestedGuestUser,
  checkUserAccess,
} from "../../../services/guestUser.service";
import * as actions from "../actions/index";
/* eslint-disable @typescript-eslint/no-unused-expressions */

//guestUser initial states
export interface IGuestUserType {
  email: string;
  id: number;
  isAnonymous: boolean;
  name: string;
  userName: string;
  uuid: string;
}
export interface IGuestUser {
  guestUser: any
  isGuest: boolean
  rememberMe: boolean
  rememberName: string
  guestEmail:string
  isUserInterested:boolean
  // guestUserName: string
}

const initialState: IGuestUser = {
  guestUser: {},
  isGuest: false,
  rememberMe: false,
  rememberName: '',
  guestEmail:'',
  isUserInterested:false
  // guestUserName: ""
}

//guestUsers api thunks
export const guestUserLoginHandler: any = createAsyncThunk(
  actions.GUESTUSERLOGIN,
  (data: any, { rejectWithValue }) =>
    guestUserLogin(
      data?.name,
      data?.userName,
      data?.isAnonymous,
      data?.meetingId,
      data?.email
    ).catch((error) => error && rejectWithValue(error))
);

export const guestAddMeetingQuestionHandler: any = createAsyncThunk(
  actions.GUESTADDMEETINGQUESTION,
  (data: any, { rejectWithValue }) =>
    guestAddMeetingQuestion(
      data?.question,
      data?.meetingId,
      data?.guestUserId
    ).catch((error) => error && rejectWithValue(error))
);

export const guestEditMeetingQuestionHandler: any = createAsyncThunk(
  actions.GUESTEDITMEETINGQUESTION,
  (data: any, { rejectWithValue }) =>
    guestEditMeetingQuestion(
      data?.question,
      data?.meetingId,
      data?.guestUserId,
      data?.meetingQuestionId,
      data?.isEdited
    ).catch((error) => error && rejectWithValue(error))
);

export const addPollsQuestionsAnswerByGuestHandler: any = createAsyncThunk(
  actions.ADDPOLLSQUESTIONSANSWERBYGUEST,
  (data: any, { rejectWithValue }) =>
    addPollsQuestionsAnswerByGuest(
      data?.meetingId,
      data?.newSelectedAnswer,
      data?.guestUserId,
      data?.pollId
    ).catch((error) => error && rejectWithValue(error))
);

export const saveUpvoteForMeetingQuestionForGuestHandler: any =
  createAsyncThunk(
    actions.SAVEUPVOTEFORMEETINGQUESTIONFORGUESTPROFILE,
    (data: any, { rejectWithValue }) =>
      saveUpvoteForMeetingQuestionForGuest(
        data?.meetingId,
        data?.meetingQuestionId,
        data?.guestUserId
      ).catch((error) => error && rejectWithValue(error))
  );

export const saveUpvoteForMeetingQuestionReplyForGuestHandler: any =
  createAsyncThunk(
    actions.SAVEUPVOTEFORMEETINGQUESTIONREPLYFORGUEST,
    (data: any, { rejectWithValue }) =>
      saveUpvoteForMeetingQuestionReplyForGuest(
        data?.meetingQuestionId,
        data?.meetingQuestionsReplyId,
        data?.guestUserId
      ).catch((error) => error && rejectWithValue(error))
  );

export const setMeetingIntrestedGuestUserHandler: any = createAsyncThunk(
  actions.SETMEETINGINTRESTEDGUESTUSER,
  (data: any, { rejectWithValue }) =>
    setMeetingIntrestedGuestUser(
      data?.meetingId,
      data?.guestUserId,
      data?.email
    ).catch((error) => error && rejectWithValue(error))
);

export const checkUserAccessHandler: any = createAsyncThunk(
  actions.CHECKUSERACCESS,
  (data: any, { rejectWithValue }) =>
    checkUserAccess(
      data?.code,
      data?.password,
      data?.longitude,
      data?.latitude,
      data?.loggedInUser
    ).catch((error) => error && rejectWithValue(error))
);

//guestUserSlice
export const guestUserSlice = createSlice({
  name: "guestUserSlice",
  initialState,
  reducers: {
    guestUserHandler: (state, { payload }: PayloadAction<any>) => {
      state.guestUser = payload;
      state.isGuest = true;
    },
    guestUserEmailHandler: (state, { payload }: PayloadAction<any>) => {
      state.guestEmail = payload;
    },
    rememberMeHandler: (state, { payload }: PayloadAction<boolean>) => {
      state.rememberMe = payload
    },
    rememberHandler: (state, { payload }: PayloadAction<any>) => {
      state.rememberName = payload
    },
    interestedUserHandler: (state, { payload }: PayloadAction<any>) => {
      state.isUserInterested = payload
    },
    logoutGuestUserHandler: (state) => {
      state.guestUser = {};
      state.isGuest = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(guestUserLoginHandler.fulfilled, (state, action) => {
      state.guestUser = action.payload.data;
      state.isGuest = true;
    })
  }
});

export const { guestUserHandler, rememberMeHandler, logoutGuestUserHandler, rememberHandler,guestUserEmailHandler,interestedUserHandler } = guestUserSlice.actions;
export default guestUserSlice.reducer;