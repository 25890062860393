import { useRef, type FC } from 'react'
import { useDrop } from 'react-dnd'
import { DragItemTypes } from '../../../types/dragItemTypes'
import { useTranslation } from 'react-i18next'

export const DropArea: FC = () => {
    const { t } = useTranslation("common")
    const dropRef = useRef<HTMLDivElement>(null)
    //components starts here
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: DragItemTypes.QUESTION,
        drop: () => ({ name: 'DropArea' }),
        collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))

    const isActive = canDrop && isOver
    drop(dropRef)
    //components ends here


    return (
        <div
            ref={dropRef}
            data-testid={DragItemTypes.DROPAREA}
            className={`droparea ${isActive ? 'active' : ''}`}
        >
            {isActive ? t("droparea.release") : t("droparea.dragAndDrop")}
        </div>
    )
}
