import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;
const near_by_me_distance = process.env.REACT_APP_NEAR_BY_ME_DISTANCE;

export const meetingCreate = (title: string, code: string, password: string, startTime: Date, endTime: Date, isEmailProtected: any, isPasswordProtected: any, isGeoFenceProtected: any, longitude: any, latitude: any, brandFiles: any, sponsorsFiles: any) => {
    let formData = new FormData();
    formData.append('title', title)
    formData.append('code', code)
    formData.append('password', password)
    formData.append('startTime', startTime.toISOString())
    formData.append('endTime', endTime.toISOString())
    formData.append('isEmailProtected', isEmailProtected)
    formData.append('isPasswordProtected', isPasswordProtected)
    formData.append('isGeoFenceProtected', isGeoFenceProtected)
    formData.append('longitude', longitude)
    formData.append('latitude', latitude)

    for (const brandFile of brandFiles) {
        formData.append('brandFiles[]', brandFile);
    }

    for (const sponsorsFile of sponsorsFiles) {
        formData.append('sponsorsFiles[]', sponsorsFile);
    }

    let newHeader = {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
    }

    return axios({
        url: API_URL + "meetings/create",
        method: "post",
        headers: newHeader,
        data: formData
    })
};

export const meetingData = async (id: any, pageSize: any, perPage: any, filterData: any, searchVal: any, sortColumn: any, sortType: any) => {
    return axios.get(API_URL + "meetings/host/" + id + "?page=" + pageSize + "&limit=" + perPage + "&filterData=" + filterData + "&searchVal=" + searchVal + "&sortColumn=" + sortColumn + "&sortType=" + sortType)
        .then((response) => {
            return response.data;
        });
};

export const meetingStatusUpdate = async (id: any, status: string) => {
    return axios.put(API_URL + "meetings/status_update/" + id, {
        status
    }, { headers: authHeader() })
};

export const deleteMeeting = async (id: number) => {
    return axios.delete(API_URL + "meetings/" + id,
        { headers: authHeader() })
        .then((response) => {
            return response;
        });
};

export const meetingGetByID = async (id: number) => {
    return axios.get(API_URL + "meetings/" + id)
        .then((response) => {
            return response.data;
        });
};

export const meetingupdateByID = async (id: any, title: string, code: string, password: string, startTime: Date, endTime: Date, isEmailProtected: any, isPasswordProtected: any, isGeoFenceProtected: any, longitude: any, latitude: any, brandFiles: any, sponsorsFiles: any) => {
    let formData = new FormData();
    formData.append('title', title)
    formData.append('code', code)
    formData.append('password', password)
    formData.append('startTime', startTime.toISOString())
    formData.append('endTime', endTime.toISOString())
    formData.append('isEmailProtected', isEmailProtected)
    formData.append('isPasswordProtected', isPasswordProtected)
    formData.append('isGeoFenceProtected', isGeoFenceProtected)
    formData.append('longitude', longitude)
    formData.append('latitude', latitude)

    for (const brandFile of brandFiles) {
        formData.append('brandFiles[]', brandFile);
    }

    for (const sponsorsFile of sponsorsFiles) {
        formData.append('sponsorsFiles[]', sponsorsFile);
    }

    let newHeader = {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
    }

    return axios({
        url: API_URL + "meetings/" + id,
        method: "put",
        headers: newHeader,
        data: formData
    })
};

export const meetingGetByCode = async (code: any, userId: number, guestUserId: number) => {
    return axios.get(API_URL + "meetings/code/" + code + "?userId=" + userId + "&guestUserId=" + guestUserId)
        .then((response) => {
            return response;
        });
};

export const validateMeetingByCodeAndPassword = async (code: any, password: string) => {
    return axios.get(API_URL + "meetings/code/" + code + '/' + password, { headers: authHeader() })
        .then((response) => {
            return response;
        });
};

export const addMeetingQuestion = async (question: string, meetingId: number) => {
    return axios.post(API_URL + "meetings/create/meeting-question", {
        question,
        meetingId,
    }, { headers: authHeader() });
}

export const editMeetingQuestion = async (question: string, meetingQuestionId: number, isEdited: boolean) => {
    return axios.post(API_URL + "meetings/edit/meeting-question", {
        question,
        meetingQuestionId,
        isEdited
    }, { headers: authHeader() });
}

export const getMeetingQuestions = async (meetingId: number, selectedUser: any) => {

    let status = '', userId = '', guestUserId = '';
    if (!!selectedUser["all"] && selectedUser["all"] === "all") {
        status = "all"
    } else if (!!selectedUser["anonymous"] && selectedUser["anonymous"] === "anonymous") {
        status = "anonymous"
    } else if (!!selectedUser["userId"] && selectedUser["userId"] > 0) {
        userId = selectedUser["userId"];
    } else if (!!selectedUser["guestUserId"] && selectedUser["guestUserId"] > 0) {
        guestUserId = selectedUser["guestUserId"]
    } else {
        status = "all"
    }
    return axios.get(API_URL + "meetings/meeting-question/" + meetingId + "?status=" + status + '&userId=' + userId + '&guestUserId=' + guestUserId, { headers: authHeader() });
}

export const deleteMeetingQuestion = async (meetingQuestionId: number) => {
    return axios.delete(API_URL + "meetings/delete/meeting-question/" + meetingQuestionId,
        { headers: authHeader() })
        .then((response) => {
            return response;
        });
}


export const deleteMeetingQuestionReply = async (meetingQuestionReplyId: number) => {
    return axios.delete(API_URL + "meeting-questions-reply/" + meetingQuestionReplyId,
        { headers: authHeader() })
        .then((response) => {
            return response;
        });
}

export const updateMeetingQuestion = async (id: number, isArchive: boolean) => {
    return axios.put(API_URL + "meetings/is-archive/meeting-question/" + id, {
        isArchive
    }, { headers: authHeader() });
};


export const saveUpvoteForMeetingQuestion = async (meetingId: number, meetingQuestionId: number) => {
    return axios.post(API_URL + "meetings-question-upvotes/create-by-auth-user", {
        meetingId,
        meetingQuestionId
    }, { headers: authHeader() });
}

export const saveUpvoteForMeetingQuestionReply = async (meetingQuestionId: number, meetingQuestionsReplyId: number) => {
    return axios.post(API_URL + "meeting-questions-reply-upvotes/create-by-auth-user", {
        meetingQuestionId,
        meetingQuestionsReplyId
    }, { headers: authHeader() });
}


export const getAllUpvoteMeetingQuestionListrByMeetingId = async (meetingId: number) => {
    return axios.get(API_URL + "meetings-question-upvotes/get-list/" + meetingId, { headers: authHeader() });
}

export const getJoinedMeetingUsersByMeetingId = async (meetingId: number) => {
    return axios.get(API_URL + "meetings/joineduser/" + meetingId, { headers: authHeader() })
        .then((response) => {
            return response;
        });
}

export const addMeetingQuestionReply = async (questionReply: string, meetingQuestionId: number) => {
    return axios.post(API_URL + "meeting-questions-reply/create/by-host", {
        questionReply,
        meetingQuestionId,
    }, { headers: authHeader() });
}

export const meetingQuestionSendViaEmail = async (meetingId: number) => {
    return axios.post(API_URL + "send-email/send-questions-to-host-by-email", {
        meetingId
    }, { headers: authHeader() });
}

export const setMeetingIntrestedUser = async (meetingId: number) => {
    return axios.post(API_URL + "meeting-interested-users/intrested-by-auth-user", {
        meetingId,
    }, { headers: authHeader() });
}

export const getMeetingIntrestedUsers = async (meetingId: number) => {
    return axios.get(API_URL + "meeting-interested-users/get-list/" + meetingId, { headers: authHeader() })
        .then((response) => {
            return response;
        });
}
export const meetingGetByCodeForCheckMeetingPassword = async (code: string, loggedInUser: any) => {
    return axios.get(API_URL + "meetings/code-check-meeting-password/" + code + "?loggedInUser=" + loggedInUser)
        .then((response) => {
            return response;
        });
}

export const getMeetingNearbyMe = async (longitude: number, latitude: number) => {
    return axios.get(API_URL + "meetings/nearby/meetings?longitude=" + longitude + "&latitude=" + latitude + '&distance=' + near_by_me_distance)
        .then((response) => {
            return response.data;
        });
}

export const setMeetingSession = async (code: string, userId: number, guestUserId: number) => {
    return axios.get(API_URL + "meetings/set-session/" + code + "?userId=" + userId + "&guestUserId=" + guestUserId)
        .then((response) => {
            return response;
        });
};

export const updateActiveQuizId = async (meetingId: number, activeQuizId: number) => {
    return axios.put(API_URL + "meeting-active-quiz/" + meetingId, {
        activeQuizId
    }, { headers: authHeader() })
};

export const getActiveQuizId = async (meetingId: number) => {
    return axios.get(API_URL + "meeting-active-quiz/" + meetingId)
        .then((response) => {
            return response;
        });
};