import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL

export const attendeeWaitingList = (meetingId: number, hostId: number, attendeeId:any,guestUserId:any) => {
    return axios.post(`${API_URL}waiting-users/add`, {
        meetingId,
        hostId,
        attendeeId,
        guestUserId
    }, { headers: authHeader() });
};

export const getWaitingList = (meetingId: number) => {
    return axios.post(`${API_URL}waiting-users/get-list`,{ meetingId: meetingId });
};

export const getallWaitingList = (meetingId: number) => {
    return axios.post(`${API_URL}waiting-users/get-all-list`,{ meetingId: meetingId });
};

export const updateWaitingUserStatus = (details:any) => {
    return axios.put(`${API_URL}waiting-users/update-waiting-status`,{details});
};

export const userExitWaitingList = (details:any) => {
    return axios.post(`${API_URL}waiting-users/exit-waiting-list`,{details});
}