import React, { FC } from 'react'
import { useTranslation } from 'react-i18next';

interface SearchBarProps {
    filterValue: string
    setFilterValue: (e: string) => void
    resetPagination: () => void
}
const SearchBar: FC<SearchBarProps> = ({ filterValue, setFilterValue, resetPagination }) => {
    const { t } = useTranslation('common');
    return (
        <div className="d-flex justify-content-end mb-3">
            <div className="input-group w-auto">
                <input
                    type="text"
                    placeholder={t('searchplaceholder.placeholder')}
                    value={filterValue}
                    onChange={(e) => { setFilterValue(e.target.value); resetPagination() }}
                    className="form-control rounded-pill rounded-end"
                />
                <button onClick={() => setFilterValue('')} aria-label="Clear filter" className="btn btn-primary px-3">
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
        </div>
    )
}

export default SearchBar