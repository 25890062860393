/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import { hostQuizWarningModalHandler } from '../../store/slices/modalSlice';
import { toast } from 'react-toastify';
import { getQuizzesForMeeting, updateMeetingQuizStatus } from '../../../services/quiz.service';
import { updateActiveQuizId } from '../../../services/meeting.service';
import socket from '../../../common/constants';
import { meetingQuizListHandler } from '../../store/slices/quizSlice';
import { ACTIVE, HOSTQUIZTIMELS } from '../../../types/commonTypes';
import { useTranslation } from 'react-i18next';


const HostQuizWarningModal = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { currentMeeting } = useSelector((state: RootState) => state.meeting);
    const { hostQuizWarningModal } = useSelector((state: RootState) => state.modal);
    const { activeQuizId, activeQuizDataId } = useSelector((state: RootState) => state.quiz);
    const currentMeetingId = currentMeeting?.id
    const currentMeetingCode = currentMeeting?.code


    const [timer, setTimer] = useState(5);


    const doNotGoBackHandler = async () => {
        localStorage.removeItem(HOSTQUIZTIMELS);
        let status = ACTIVE
        let quizValueId = activeQuizDataId
        let quizId = activeQuizId
        await updateMeetingQuizStatus(quizValueId, status).then(
            async (response) => {
                if (response.status === 200) {

                    await updateActiveQuizId(currentMeetingId, 0)

                    socket.emit("meeting-quiz-send", { meetingId: currentMeetingId, quizId: quizId, status: status })
                    socket.on('connect', () => {
                        socket.emit("meeting-quiz-send", { meetingId: currentMeetingId, quizId: quizId, status: status })
                    })

                    let quizzesListData = await getQuizzesForMeeting(currentMeetingId)
                    if (quizzesListData.status === 200) {
                        dispatch(meetingQuizListHandler(quizzesListData.data?.meetingQuizData))
                    }

                    dispatch(hostQuizWarningModalHandler(false))
                    toast.warning(String(t("quiz.quizEnd")), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2500,
                    })
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log("resMessage ==>", resMessage)
            }
        )
    }

    const goBackHandler = () => {
        dispatch(hostQuizWarningModalHandler(false))
        navigate(`/joinMeeting/${currentMeetingCode}`)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            } else {
                clearInterval(interval);
                doNotGoBackHandler();
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timer]);

    return (
        <Modal centered backdrop="static" show={hostQuizWarningModal}>
            <Modal.Header className='border-0'>
                <Modal.Title className='fw-semibold'>Alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('quiz.quizLeaveEnd')}
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-danger text-white' onClick={doNotGoBackHandler}>OK</Button>
                <Button className='btn btn-primary' onClick={goBackHandler}>Go Back</Button>
                <div className='text-danger text-end' style={{ width: '2rem' }}>{timer} s</div>
            </Modal.Footer>
        </Modal>
    )
}

export default HostQuizWarningModal