
export interface ChildrenProps {
    children: React.ReactNode
}

//LS stand for Local Storage
export const QUIZTIMERDATALS = 'QUIZTIMERDATALS'
export const QUIZTIMELS = 'QUIZTIMELS'
export const QUESTIONTIMELS = 'QUESTIONTIMELS'
export const HOSTQUIZTIMELS = 'HOSTQUIZTIMELS'
export const WARNINGTIMELS = 'WARNINGTIMELS'

export const FILTERUSERLS = 'FILTERUSERLS'
export const SORTBYLS = 'SORTBYLS'

export const ACTIVEPOLLSCOUNTLS = 'ACTIVEPOLLSCOUNTLS'
export const ACTIVEQUIZCOUNTLS = 'ACTIVEQUIZCOUNTLS'

export const RADIOGROUP = 'radiogroup'
export const BOOLEAN = 'boolean'
export const RANKING = 'ranking'
export const RATING = 'rating'
export const IMAGEPICKER = 'imagepicker'

export const SORTBYALL = 'all'
export const SORTBYVOTES = 'upvoted'
export const SORTBYTIME = 'time'

export const APPROVED = 'approved'
export const PENDING = 'pending'
export const INPROGRESS = 'inProgress'
export const CREATED = 'created'
export const STARTED = 'started'
export const ENDED = 'ended'

export const INACTIVE = 'inActive'
export const ACTIVE = 'active'
export const ACTIVE1 = 'Active'
export const END = 'End'

export const PASSWORD = 'password'
export const EN = 'en'
export const DE = 'de'
