import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fontSizeHandler } from '../store/slices/settingsSlice'
import { RootState } from '../store/store'

const FontSizeChanger = () => {

   //single hooks starts here
    const dispatch = useDispatch()
   //single hooks ends here


    //useSelectors starts here
    const { defaultFontSize, fontSize, minFontSize, maxFontSize } = useSelector((state: RootState) => state.settings)
    //useSelectors ends here
    
    return (
        <div className="font-size-container btn-group ms-md-2">
            <button className="btn btn-outline-primary" onClick={() => dispatch(fontSizeHandler((fontSize + 2)))} disabled={fontSize === maxFontSize ? true : false} ><i className="fa-solid fa-plus"></i></button>
            <button className="btn btn-outline-primary" onClick={() => dispatch(fontSizeHandler(defaultFontSize))} title="Reset font size"><i className="fa-solid fa-a"></i></button>
            <button className="btn btn-outline-primary" onClick={() => dispatch(fontSizeHandler((fontSize - 2)))} disabled={fontSize === minFontSize ? true : false}><i className="fa-solid fa-minus"></i></button>
        </div>
    )
}

export default FontSizeChanger