import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as actions from "../actions/index";
import {
    pollCreate,
    pollData,
    pollGetById,
    pollUpdateByID,
    deletePoll
} from "../../../services/poll.service";
import { get } from "lodash";

/* eslint-disable @typescript-eslint/no-unused-expressions */

//poll initial states
export interface PollState {
    message: string;
    pollSuccessful: boolean;
    selectedQuestion: any[]
    pollMeetings: any[]
    pollList: any[],
    totalPage: number
    totalRecord: number,
    pollMessage: any,
    activePollsCount: number
}

const initialState: PollState = {
    message: "",
    pollSuccessful: false,
    selectedQuestion: [],
    pollMeetings: [],
    pollList: [],
    totalPage: 0,
    totalRecord: 0,
    pollMessage: '',
    activePollsCount: 0

};
export interface IPollData {
    hostID: any,
    pageLimit: any,
    perPage: any,
    searchVal: any,
    sortColumnProp: any,
    sortColumnOrder: any,


}
export interface IPollCreate {
    title: string,
    selectedQuestion: any[],

}

export interface IPollUpdate {
    ID?: any | null,
    title: string,
    selectedQuestion: any[],

}

//polls api thunks
export const pollCreateHandler: any = createAsyncThunk(
    actions.CREATEPOLL,
    (data: IPollCreate, { rejectWithValue }) =>
        pollCreate(data?.title, data?.selectedQuestion).catch(
            (error) => error && rejectWithValue(error)
        )
);

export const pollGetByIdHandler: any = createAsyncThunk(
    actions.POLLGETBYID,
    (data: any, { rejectWithValue }) =>
        pollGetById(data).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const pollUpdateByIdHandler: any = createAsyncThunk(
    actions.POLLUPDATE,
    (data: IPollUpdate, { rejectWithValue }) =>
        pollUpdateByID(data?.ID, data?.title, data?.selectedQuestion).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const DeletePollHandler: any = createAsyncThunk(
    actions.DELETEPOLL,
    (data: any, { rejectWithValue }) =>
        deletePoll(data).catch(
            (error) => error && rejectWithValue(error)
        )
);
export const PollDataHandler: any = createAsyncThunk(
    actions.POLLDATA,
    (data: IPollData, { rejectWithValue }) =>

        pollData(data?.hostID, data?.pageLimit, data?.perPage, data?.searchVal, data?.sortColumnProp, data?.sortColumnOrder).catch(
            (error) => error && rejectWithValue(error)
        )
);

//pollSlice
export const pollSlice = createSlice({
    name: "pollSlice",
    initialState,
    reducers: {
        selectedQuestionHandler: (state, { payload }: PayloadAction<any>) => {
            state.selectedQuestion = payload;
        },
        activePollCountHandler: (state, { payload }: PayloadAction<any>) => {
            state.activePollsCount = payload;
        },
        moveQuestionHandler: (state, action: PayloadAction<{ dragIndex: number; hoverIndex: number }>) => {
            const { dragIndex, hoverIndex } = action.payload;
            state.selectedQuestion = state.selectedQuestion.slice();
            const [draggedCard] = state.selectedQuestion.splice(dragIndex, 1);
            state.selectedQuestion.splice(hoverIndex, 0, draggedCard);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(pollCreateHandler.fulfilled, (state, action) => {
            state.pollMessage = action?.payload?.data?.message;
            state.pollSuccessful = true;
        })
        builder.addCase(pollCreateHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, "payload.response.data.message");
            const networkError = get(error, "payload.message");
            if (errorMessage) {
                state.pollSuccessful = false;
                state.pollMessage = errorMessage;
            } else if (networkError) {
                state.pollSuccessful = false;
                state.pollMessage = networkError;
            }
        })
        builder.addCase(pollGetByIdHandler.fulfilled, (state, action) => {
            state.pollMeetings = action.payload?.data?.pollsMeetingDetails;
        })
        builder.addCase(pollUpdateByIdHandler.fulfilled, (state, action) => {
            state.pollMessage = action.payload?.data?.message;
            state.pollSuccessful = true;
        })
        builder.addCase(pollUpdateByIdHandler.rejected, (state, action) => {
            const error = action;
            const errorMessage = get(error, "payload.response.data.message");
            const networkError = get(error, "payload.message");
            if (errorMessage) {
                state.pollSuccessful = false;
                state.pollMessage = errorMessage;
            } else if (networkError) {
                state.pollSuccessful = false;
                state.pollMessage = networkError;
            }
        });
        builder.addCase(PollDataHandler.fulfilled, (state, action) => {
            state.pollList = action.payload.data;
            state.totalPage = action.payload.totalPages;
            state.totalRecord = action.payload.totalItems;
        })
        builder.addCase(DeletePollHandler.fulfilled, (state, action) => {
            state.pollMeetings = action.payload?.data?.pollsMeetingDetails
        })
    },
});

// Action creators are generated for each case reducer function
export const { selectedQuestionHandler, activePollCountHandler, moveQuestionHandler } = pollSlice.actions;
export default pollSlice.reducer;
