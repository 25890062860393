import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL

export const feedbackForm = (meetingId: string, userId: number, guestUserId: number, rating: number, comments: string) => {
    return axios.post(API_URL + "feedback/create", {
        meetingId,
        userId,
        guestUserId,
        rating,
        comments
    }, { headers: authHeader() });
};